<script>
export default {
    name: 'OrderItemDistributionDetails',
};
</script>

<script setup>
import { ref } from 'vue';

import { getBatchUnitQtyDisplay } from '@/utils/batchUnitsUtils';

const props = defineProps({
    orderItem: {
        type: Object,
        required: true,
    },
});

/*------------------------------------------------------------------------
                             Order item state
------------------------------------------------------------------------*/

const isDistributionDetailsOpened = ref(false);

const toggleDistributionDetails = () => (isDistributionDetailsOpened.value = !isDistributionDetailsOpened.value);

const getProducedDistributionQtyDisplay = (BYQty) => {
    const { units_data: unitsData } = props.orderItem;

    return getBatchUnitQtyDisplay(unitsData, unitsData.batch_yield_unit, unitsData.prep_list_ordering_unit, BYQty);
};
</script>

<template>
    <div
        class="pl-order-item-distribution"
        :class="{
            'pl-order-item-distribution--opened': isDistributionDetailsOpened,
        }"
    >
        <button
            type="button"
            @click="toggleDistributionDetails"
        >
            <span>View assignees ({{ orderItem.distribution.length }})</span>

            <CaretDownIcon
                :width="8"
                :height="6"
            />
        </button>

        <ul v-if="isDistributionDetailsOpened">
            <li
                v-for="{ assignee, qty } in orderItem.distribution"
                :key="assignee"
            >
                <b>{{ assignee }}</b>

                <span>{{ getProducedDistributionQtyDisplay(qty) }}</span>
            </li>
        </ul>
    </div>
</template>

<style lang="scss" scoped>
.pl-order-item-distribution {
    width: 100%;

    & > button {
        display: flex;
        align-items: center;
        gap: custom-space(0.5);

        > span {
            border-bottom: 2px solid $primary;
            color: $primary;
            text-transform: uppercase;
            font-weight: $font-weight-normal;
            font-size: $font-size-base * 0.9;
            line-height: $font-size-base * 1.2;
        }

        > svg {
            fill: $primary;
        }
    }

    & > ul {
        margin: custom-space(0.5) 0 0;
        padding: 0;

        & > li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: custom-space(0.25) 0;
            margin: 0;
            border-bottom: 1px solid $gray-400;

            & > b {
                font-weight: $font-weight-normal;
            }

            & > span {
                flex-shrink: 0;
                font-size: $font-size-base * 0.95;
            }

            &:first-child {
                border-top: 1px solid $gray-400;
            }
        }
    }

    &--opened > button svg {
        transform: rotate(180deg);
    }
}
</style>
