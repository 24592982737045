import ApiClient from '@/plugins/ApiClient';
import Model from './Model';

// Prep List PAR*
export default class PARTemplateSelectableItem extends Model {
    static all(orgId, templateId) {
        return this.withAbort(ApiClient.get(`organizations/${orgId}/par-templates/${templateId}/selectable-items`), ({ data }) =>
            this.make(data)
        );
    }

    static async setQty(orgId, templateId, prototypeId, attributes) {
        const { data } = await ApiClient.post(
            `organizations/${orgId}/par-templates/${templateId}/selectable-items/${prototypeId}/set-qty`,
            attributes
        );

        return PARTemplateSelectableItem.make(data);
    }
}
