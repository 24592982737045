<script>
export default {
    name: 'AddressInput',
};
</script>

<script setup>
import { ref, onBeforeMount, watch } from 'vue';
import axios from 'axios';

import TextInput from '@/components/form-controls/TextInput';

const props = defineProps({
    modelValue: {
        type: [String, Number],
        default: '',
    },
    loading: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    hasError: {
        type: Boolean,
        default: false,
    },
    includeAsterisk: {
        type: Boolean,
        default: false,
    },
    dataTestId: {
        type: String,
        default: null,
    },
});

const emit = defineEmits(['update:modelValue', 'set-timezone']);

/*------------------------------------------------------------------------
                               Address state
------------------------------------------------------------------------*/

const inputRef = ref(null);
const address = ref('');

watch(address, (value) => emit('update:modelValue', value));

watch(
    () => props.modelValue,
    (value) => (address.value = value),
    { immediate: true }
);

/*------------------------------------------------------------------------
                              Google Maps API
------------------------------------------------------------------------*/

const googleApiKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;

let autocompleteInstance = null;

const fetchTimezone = (lat, lng) => {
    axios
        .get('https://maps.googleapis.com/maps/api/timezone/json', {
            params: {
                location: `${lat},${lng}`,
                timestamp: Math.floor(Date.now() / 1000),
                key: googleApiKey,
            },
        })
        .then(({ data }) => {
            if (data.timeZoneId) {
                emit('set-timezone', data.timeZoneId);
            }
        });
};

const initializeAutocomplete = () => {
    if (typeof window.google === 'undefined' || autocompleteInstance !== null) {
        return;
    }

    const input = inputRef.value.$el.querySelector('input[name="address"]');

    autocompleteInstance = new window.google.maps.places.Autocomplete(input, {
        types: ['geocode'],
        componentRestrictions: { country: 'us' },
    });

    autocompleteInstance.addListener('place_changed', () => {
        const place = autocompleteInstance.getPlace();

        if (place?.formatted_address) {
            address.value = place.formatted_address;
        }

        if (place?.geometry) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();

            fetchTimezone(lat, lng);
        }
    });
};

const loadGoogleMapsApi = () => {
    if (typeof window.google !== 'undefined' && window.google.maps) {
        return Promise.resolve();
    }

    const script = document.createElement('script');

    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places&language=en&loading=async`;
    script.async = true;
    script.defer = true;

    document.head.appendChild(script);
};

onBeforeMount(loadGoogleMapsApi);
</script>

<template>
    <TextInput
        ref="inputRef"
        v-model="address"
        enable-empty-placeholder
        label="Address"
        name="address"
        class="pl-address-input"
        :data-test-id="dataTestId"
        :disabled="disabled"
        :has-error="hasError"
        :include-asterisk="includeAsterisk"
        @focus="initializeAutocomplete"
        @keydown.enter.prevent
    />
</template>

<style lang="scss">
.pl-address-input {
    z-index: 11;
}

.pac-container {
    padding-top: custom-space(2);
    padding-bottom: custom-space(0.325);
    margin-top: custom-space(-1.5);
    border-radius: 0 0 $border-radius $border-radius;
    z-index: 10;
    transform: translateX(1px);

    &:after {
        margin-right: custom-space(0.5);
    }

    & > .pac-item {
        cursor: pointer;
    }
}
</style>
