import { reactive } from 'vue';

const useSelectableItemsQty = () => {
    const state = reactive({
        areActionsDisabled: false,
        isAnyItemAdded: false,
    });

    const update = (updatedState) => Object.assign(state, updatedState);

    const isInvalid = () => !state.isAnyItemAdded || state.areActionsDisabled;

    return {
        selectableItemsQtyState: state,
        selectableItemsQty: {
            update,
            isInvalid,
        },
    };
};

export default useSelectableItemsQty;
