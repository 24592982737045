import { reactive } from 'vue';

const useConfirmationModal = () => {
    const state = reactive({
        model: null,
        isOpened: false,
        isPending: false,
    });

    const openModal = (model) => {
        state.model = model;
        state.isOpened = true;
    };

    const closeModal = () => {
        if (!state.isPending) {
            state.model = null;
            state.isOpened = false;
        }
    };

    const submit = (callback, enableCloseAfterComplete = true) => {
        state.isPending = true;

        return callback(state.model).finally(() => {
            if (enableCloseAfterComplete) {
                state.isPending = false;

                closeModal();
            }
        });
    };

    return {
        confirmationState: state,
        confirmationModal: {
            open: openModal,
            close: closeModal,
            submit,
        },
    };
};

export default useConfirmationModal;
