import { onBeforeUnmount, ref } from 'vue';
import axios from 'axios';

import useCodeGenerator from '@/composition/useCodeGenerator';

const useAbortableRequest = () => {
    const { generateUniqueCode, clearUsedCode } = useCodeGenerator();

    /*------------------------------------------------------------------------
                                  Requests state
    ------------------------------------------------------------------------*/

    const requests = ref(new Map());

    const sendAbortableRequest = (request) => {
        const requestId = generateUniqueCode();

        requests.value.set(requestId, request);

        return request
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return null;
                }

                return Promise.reject(error);
            })
            .finally(() => {
                requests.value.delete(requestId);

                clearUsedCode(requestId);
            });
    };

    const abortRequests = () => {
        requests.value.forEach((request) => request.abort?.());

        requests.value.clear();
    };

    onBeforeUnmount(abortRequests);

    return { sendAbortableRequest, abortRequests };
};

export default useAbortableRequest;
