<script>
export default {
    name: 'JoinView',
};
</script>

<script setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { ALERT_TYPES } from '@/enums/componentsEnums';
import AuthModel from '@/models/Auth';
import AccountModel from '@/models/Account';
import Alert from '@/components/Alert';
import AuthLayout from '@/components/layouts/AuthLayout';

const route = useRoute();
const store = useStore();

/*------------------------------------------------------------------------
                               General state
------------------------------------------------------------------------*/

const isActionProcessing = ref(true);

const acceptInvitationByToken = () => {
    AuthModel.acceptInvitationByToken(route.query.join_token)
        .then(({ data }) => {
            store.commit('auth/setCurrentAccount', AccountModel.make(data.account));

            window.location.href = route.query.come_from || '/';
        })
        .catch(() => (isActionProcessing.value = false));
};

onMounted(() => {
    if (store.state.auth.currentAccount !== null) {
        store.dispatch('auth/logout').then(() => {
            store.commit('auth/setIsLogOutPending', false);

            acceptInvitationByToken();
        });
    } else {
        acceptInvitationByToken();
    }
});
</script>

<template>
    <AuthLayout>
        <transition
            appear
            name="down"
            type="transition"
            mode="out-in"
        >
            <div
                v-if="isActionProcessing"
                class="pl-join__wrapper"
            >
                <h4>Joining Prep List...</h4>

                <InPlaceLoader />
            </div>

            <Alert
                v-else
                :type="ALERT_TYPES.FAIL"
            >
                Your invitation link is invalid. Please contact company owner to get a new one.
            </Alert>
        </transition>
    </AuthLayout>
</template>

<style lang="scss" scoped>
.pl-join__wrapper {
    background-color: $white;
    padding-top: custom-space(0.75);
    border-radius: $border-radius;
    box-shadow: $box-shadow;

    > h4 {
        margin-bottom: 0;
        text-align: center;
    }

    > .pl-in-place-loader {
        height: custom-space(5);
    }
}
</style>
