<script>
export default {
    name: 'EditItemView',
};
</script>

<script setup>
import { onBeforeMount, computed, reactive, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { catchUnprocessableEntity, catchNotFoundError } from '@/utils/httpUtils';
import { ITEMS_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { AVAILABLE_ORDER_TYPES } from '@/enums/itemEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import ItemModel from '@/models/Item';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useConfirmationModal from '@/composition/useConfirmationModal';
import Alert from '@/components/Alert';
import ConfirmationModal from '@/components/ConfirmationModal';
import ItemForm from '@/components/forms/item-form';

const props = defineProps({
    itemId: {
        type: [String, Number],
        required: true,
    },
});

const $acl = inject('$acl');

const router = useRouter();
const route = useRoute();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(ITEMS_RESTRICTIONS.UPDATE, currentAccount)) {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to edit items.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                              Item form state
------------------------------------------------------------------------*/

const itemFormState = reactive({
    data: null,
    validationErrors: null,
    isDataLoading: true,
    isSubmitting: false,
});

const loadItemData = () => {
    sendAbortableRequest(ItemModel.find(orgId, props.itemId, { 'on_demand[]': 'location_specific_settings' }))
        .then((model) => (itemFormState.data = model))
        .finally(() => (itemFormState.isDataLoading = false));
};

const submitUpdateItem = (attributes) => {
    itemFormState.isSubmitting = true;

    itemFormState.data
        .update(attributes)
        .then(() => {
            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: 'Item settings has been successfully updated',
            });

            router.push({ name: SETTINGS_ROUTE_NAMES.ITEMS.INDEX, query: route.query });
        })
        .catch((error) =>
            catchUnprocessableEntity(error, (errors) => {
                itemFormState.validationErrors = errors;
            })
        )
        .finally(() => (itemFormState.isSubmitting = false));
};

const getItemAvailableOrderType = () => {
    const { is_quick_fill: isQuickFill, is_prep_list: isPrepList } = itemFormState.data;

    if (isQuickFill && isPrepList) {
        return AVAILABLE_ORDER_TYPES.BOTH;
    }

    if (isQuickFill) {
        return AVAILABLE_ORDER_TYPES.QUICK_FILL;
    }

    return AVAILABLE_ORDER_TYPES.PREP_LIST;
};

const itemFormProps = computed(() => {
    const { data } = itemFormState;

    if (!data) {
        return {};
    }

    return {
        name: data.name,
        batchSizeAmount: data.batch_size_amount,
        batchSizeUnitId: data.batch_size_unit?.id,
        batchYieldAmount: data.batch_yield_amount,
        batchYieldUnitId: data.batch_yield_unit?.id,
        availableOrderType: getItemAvailableOrderType(),
        departmentId: data.department.id,
        category: data.category.id,
        stations: data.stations.map(({ id }) => id),
        shelfLife: data.shelf_life,
        locationSpecificSettings: data.location_specific_settings,
        batchWeightAmount: data.batch_weight_amount,
        batchWeightUnit: data.batch_weight_unit?.value,
        batchCost: data.batch_cost,
        batchLaborTime: data.batch_labor_time,
        laborTimeSettings: data.labor_time_settings,
        quickFillOrderingUnitId: data.quick_fill_ordering_unit?.id,
        prepListOrderingUnitId: data.prep_list_ordering_unit?.id,
        prepTasks: data.prep_tasks,
        defaultReportingUnitId: data.default_reporting_unit?.id,
        isChangeableReportingUnit: data.is_changeable_reporting_unit,
    };
});

onBeforeMount(loadItemData);

/*------------------------------------------------------------------------
                         Confirmation modal state
------------------------------------------------------------------------*/

const { confirmationState, confirmationModal } = useConfirmationModal();

const handleSuccessfulDeletion = () => {
    EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
        type: ALERT_TYPES.SUCCESS,
        message: 'Item has been successfully deleted',
    });

    router.push({ name: SETTINGS_ROUTE_NAMES.ITEMS.INDEX, query: route.query });
};

const submitDeleteItem = () => {
    confirmationModal
        .submit((model) => model.delete())
        .then(handleSuccessfulDeletion)
        .catch((error) => catchNotFoundError(error, handleSuccessfulDeletion));
};
</script>

<template>
    <TopBarLayout
        include-back-route-query
        :back-route="SETTINGS_ROUTE_NAMES.ITEMS.INDEX"
    >
        <template #title>
            <h1>Edit Item</h1>
        </template>

        <Alert
            v-if="itemFormState.data === null && !itemFormState.isDataLoading"
            :type="ALERT_TYPES.FAIL"
        >
            Item could not be loaded for some reason. Please try again later.
        </Alert>

        <template v-else>
            <ItemForm
                :name="itemFormProps.name"
                :batch-size-amount="itemFormProps.batchSizeAmount"
                :batch-size-unit-id="itemFormProps.batchSizeUnitId"
                :batch-yield-amount="itemFormProps.batchYieldAmount"
                :batch-yield-unit-id="itemFormProps.batchYieldUnitId"
                :available-order-type="itemFormProps.availableOrderType"
                :department-id="itemFormProps.departmentId"
                :category="itemFormProps.category"
                :stations="itemFormProps.stations"
                :shelf-life="itemFormProps.shelfLife"
                :location-specific-settings="itemFormProps.locationSpecificSettings"
                :batch-weight-amount="itemFormProps.batchWeightAmount"
                :batch-weight-unit="itemFormProps.batchWeightUnit"
                :batch-cost="itemFormProps.batchCost"
                :batch-labor-time="itemFormProps.batchLaborTime"
                :labor-time-settings="itemFormProps.laborTimeSettings"
                :quick-fill-ordering-unit-id="itemFormProps.quickFillOrderingUnitId"
                :prep-list-ordering-unit-id="itemFormProps.prepListOrderingUnitId"
                :prep-tasks="itemFormProps.prepTasks"
                :default-reporting-unit-id="itemFormProps.defaultReportingUnitId"
                :is-changeable-reporting-unit="itemFormProps.isChangeableReportingUnit"
                :validation-errors="itemFormState.validationErrors"
                :is-data-loading="itemFormState.isDataLoading"
                :is-submitting="itemFormState.isSubmitting"
                @submit="submitUpdateItem"
            >
                <template #delete-btn="{ isLoading: isItemLoading }">
                    <BtnUI
                        data-testid="item_delete_btn"
                        :disabled="isItemLoading"
                        @click="confirmationModal.open(itemFormState.data)"
                    >
                        Delete Item
                    </BtnUI>
                </template>
            </ItemForm>

            <ConfirmationModal
                v-if="confirmationState.isOpened"
                qa-prefix="delete_item_modal"
                :is-pending="confirmationState.isPending"
                @close="confirmationModal.close"
                @submit-delete="submitDeleteItem"
            >
                Are you sure you want to delete the item "{{ confirmationState.model.name }}" from the company?
            </ConfirmationModal>
        </template>
    </TopBarLayout>
</template>
