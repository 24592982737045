// Quick Fill Orders
export const QFO_RESTRICTIONS = {
    CREATE: 'quick-fill-orders.create',
    UPDATE: 'quick-fill-orders.update',
    DELETE: 'quick-fill-orders.delete',
};

// Prep Lists
export const PL_RESTRICTIONS = {
    CREATE: 'prep-lists.create',
    UPDATE: 'prep-lists.update',
    DELETE: 'prep-lists.delete',
};

export const REPORTS_RESTRICTIONS = {
    VIEW: 'reports.view',
};

export const PEOPLE_RESTRICTIONS = {
    CREATE: 'people.create',
    UPDATE: 'people.update',
    DELETE: 'people.delete',
    REASSIGN: 'people.reassign',
};

export const ITEMS_RESTRICTIONS = {
    VIEW: 'items.view',
    CREATE: 'items.create',
    UPDATE: 'items.update',
};

export const PREP_TASKS_RESTRICTIONS = {
    CREATE: 'prep-tasks.create',
    UPDATE: 'prep-tasks.update',
};

export const DEPARTMENTS_RESTRICTIONS = {
    VIEW: 'departments.view',
    CREATE: 'departments.create',
    UPDATE: 'departments.update',
    DELETE: 'departments.delete',
};

export const LOCATIONS_RESTRICTIONS = {
    VIEW: 'locations.view',
    CREATE: 'locations.create',
    UPDATE: 'locations.update',
    DELETE: 'locations.delete',
};

export const SUPPLIERS_RESTRICTIONS = {
    VIEW: 'suppliers.view',
    CREATE: 'suppliers.create',
    UPDATE: 'suppliers.update',
    DELETE: 'suppliers.delete',
};

// No Prep Actions
export const NPA_RESTRICTIONS = {
    VIEW: 'no-prep-actions.view',
};

// Configuration Of Item
export const COI_RESTRICTIONS = {
    VIEW: 'configuration-of-item.view',
    CREATE: 'configuration-of-item.create',
    UPDATE: 'configuration-of-item.update',
    DELETE: 'configuration-of-item.delete',
};

// PAR Templates
export const PAR_RESTRICTIONS = {
    VIEW: 'par-templates.view',
    CREATE: 'par-templates.create',
    UPDATE: 'par-templates.update',
    DELETE: 'par-templates.delete',
};

export const ORGANIZATION_RESTRICTIONS = {
    UPDATE: 'organization.update',
};
