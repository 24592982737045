<script>
export default {
    name: 'SuppliersIndexView',
};
</script>

<script setup>
import { onBeforeMount, inject, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { catchNotFoundError } from '@/utils/httpUtils';
import { RESPONSE_DATA_TYPES } from '@/enums/httpEnums';
import { SUPPLIERS_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import SupplierModel from '@/models/Supplier';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useConfirmationModal from '@/composition/useConfirmationModal';
import Alert from '@/components/Alert';
import ConfirmationModal from '@/components/ConfirmationModal';
import ListDisplay from '@/components/ListDisplay';
import SupplierCard from './partials/SupplierCard';

const $acl = inject('$acl');

const router = useRouter();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(SUPPLIERS_RESTRICTIONS.VIEW, currentAccount)) {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to suppliers.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                              Suppliers state
------------------------------------------------------------------------*/

const suppliersState = reactive({
    data: null,
    isDataLoading: true,
    hasBeenJustUpdated: false,
});

const loadSuppliers = () => {
    sendAbortableRequest(SupplierModel.all(orgId))
        .then((models) => (suppliersState.data = models))
        .finally(() => (suppliersState.isDataLoading = false));
};

const updateSuppliersState = (models) => {
    suppliersState.data = models;
    suppliersState.hasBeenJustUpdated = true;

    setTimeout(() => (suppliersState.hasBeenJustUpdated = false), 250);
};

onBeforeMount(loadSuppliers);

/*------------------------------------------------------------------------
                         Confirmation modal state
------------------------------------------------------------------------*/

const { confirmationState, confirmationModal } = useConfirmationModal();

const handleSuccessfulDeletion = (models) => {
    updateSuppliersState(models);

    EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
        type: ALERT_TYPES.SUCCESS,
        message: 'The supplier has been successfully deleted',
    });
};

const submitDeleteSupplier = () => {
    const { model } = confirmationState;

    confirmationModal
        .submit((model) => model.delete(orgId, { response_data: RESPONSE_DATA_TYPES.LIST }))
        .then(handleSuccessfulDeletion)
        .catch((error) => {
            catchNotFoundError(error, () => {
                const filteredModels = suppliersState.data.filter(({ id }) => model.id !== id);

                handleSuccessfulDeletion(filteredModels);
            });
        });
};
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.INDEX">
        <template #title>
            <h1>Suppliers</h1>
        </template>

        <InPlaceLoader v-if="suppliersState.isDataLoading" />

        <Alert
            v-else-if="suppliersState.data === null"
            :type="ALERT_TYPES.FAIL"
        >
            Suppliers could not be loaded for some reason. Please try again later.
        </Alert>

        <template v-else>
            <transition
                appear
                name="down"
                type="transition"
                mode="out-in"
            >
                <template v-if="!suppliersState.hasBeenJustUpdated">
                    <ListDisplay
                        v-if="suppliersState.data.length > 0"
                        has-borders
                        data-testid="suppliers_list"
                        :items="suppliersState.data"
                    >
                        <template #item="{ item: supplier }">
                            <SupplierCard :supplier="supplier">
                                <template #actions="{ qaPrefix }">
                                    <router-link
                                        :data-testid="`${qaPrefix}_edit_btn`"
                                        :to="{
                                            name: SETTINGS_ROUTE_NAMES.SUPPLIERS.EDIT,
                                            params: { supplierId: supplier.id },
                                        }"
                                    >
                                        <EditIcon />
                                    </router-link>

                                    <button
                                        type="button"
                                        data-testid="delete_supplier_btn"
                                        @click.stop="confirmationModal.open(supplier)"
                                    >
                                        <DeleteIcon />
                                    </button>
                                </template>
                            </SupplierCard>
                        </template>
                    </ListDisplay>

                    <p
                        v-else
                        class="pl-suppliers__message"
                        data-testid="suppliers_empty_list_message"
                    >
                        Supplier list is empty.
                    </p>
                </template>
            </transition>

            <transition
                appear
                name="down"
                type="transition"
            >
                <div
                    class="pl-suppliers__action"
                    style="transition-delay: 50ms"
                >
                    <router-link
                        class="pl-btn pl-btn--filled"
                        data-testid="create_supplier_btn"
                        :to="{ name: SETTINGS_ROUTE_NAMES.SUPPLIERS.CREATE }"
                    >
                        Create supplier
                    </router-link>
                </div>
            </transition>

            <ConfirmationModal
                v-if="confirmationState.isOpened"
                qa-prefix="delete_supplier_modal"
                :is-pending="confirmationState.isPending"
                @close="confirmationModal.close"
                @submit-delete="submitDeleteSupplier"
            >
                Are you sure you want to delete "{{ confirmationState.model.name }}" from the company?
            </ConfirmationModal>
        </template>
    </TopBarLayout>
</template>

<style lang="scss" scoped>
.pl-suppliers {
    &__message {
        text-align: center;
        padding-top: custom-space(1);
    }

    &__action {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        margin-top: auto;
        padding-top: custom-space(1);
        width: 50%;

        @include media-breakpoint-down(lg) {
            width: 100%;
        }

        & > a {
            width: 100%;
        }
    }
}
</style>
