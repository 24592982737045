import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';

import PARTemplatesIndexView from '@/views/dashboard/settings/PAR/index';

import ShowPLPARTemplateView from '@/views/dashboard/settings/PAR/show/prep-list';
import ShowQFPARTemplateView from '@/views/dashboard/settings/PAR/show/quick-fill';

import CreatePLPARTemplateView from '@/views/dashboard/settings/PAR/create/prep-list';
import CreatePLPARTemplateStep1View from '@/views/dashboard/settings/PAR/create/prep-list/step-1';
import CreatePLPARTemplateStep2View from '@/views/dashboard/settings/PAR/create/prep-list/step-2';
import CreatePLPARTemplateStep3View from '@/views/dashboard/settings/PAR/create/prep-list/step-3';
import CreatePLPARTemplateStep4View from '@/views/dashboard/settings/PAR/create/prep-list/step-4';

import CreateQFPARTemplateView from '@/views/dashboard/settings/PAR/create/quick-fill';
import CreateQFPARTemplateStep1View from '@/views/dashboard/settings/PAR/create/quick-fill/step-1';
import CreateQFPARTemplateStep2View from '@/views/dashboard/settings/PAR/create/quick-fill/step-2';
import CreateQFPARTemplateStep3View from '@/views/dashboard/settings/PAR/create/quick-fill/step-3';

import EditPLPARTemplateView from '@/views/dashboard/settings/PAR/edit/prep-list';
import EditPLPARTemplateStep1View from '@/views/dashboard/settings/PAR/edit/prep-list/step-1';
import EditPLPARTemplateStep2View from '@/views/dashboard/settings/PAR/edit/prep-list/step-2';
import EditPLPARTemplateStep3View from '@/views/dashboard/settings/PAR/edit/prep-list/step-3';
import EditPLPARTemplateStep4View from '@/views/dashboard/settings/PAR/edit/prep-list/step-4';

import EditQFPARTemplateView from '@/views/dashboard/settings/PAR/edit/quick-fill';
import EditQFPARTemplateStep1View from '@/views/dashboard/settings/PAR/edit/quick-fill/step-1';
import EditQFPARTemplateStep2View from '@/views/dashboard/settings/PAR/edit/quick-fill/step-2';
import EditQFPARTemplateStep3View from '@/views/dashboard/settings/PAR/edit/quick-fill/step-3';

export default [
    {
        path: 'settings/par-templates',
        name: SETTINGS_ROUTE_NAMES.PAR.INDEX,
        component: PARTemplatesIndexView,
    },

    {
        path: 'settings/par-templates/prep-list/:templateId',
        name: SETTINGS_ROUTE_NAMES.PAR.SHOW.PL,
        component: ShowPLPARTemplateView,
        props: true,
    },
    {
        path: 'settings/par-templates/quick-fill/:templateId',
        name: SETTINGS_ROUTE_NAMES.PAR.SHOW.QF,
        component: ShowQFPARTemplateView,
        props: true,
    },

    {
        path: 'settings/par-templates/prep-list/create',
        component: CreatePLPARTemplateView,
        children: [
            {
                path: '',
                name: SETTINGS_ROUTE_NAMES.PAR.CREATE.PL.INDEX,
                component: CreatePLPARTemplateStep1View,
            },
            {
                path: 'add-items',
                name: SETTINGS_ROUTE_NAMES.PAR.CREATE.PL.ADD_ITEMS,
                component: CreatePLPARTemplateStep2View,
            },
            {
                path: 'set-assignees',
                name: SETTINGS_ROUTE_NAMES.PAR.CREATE.PL.SET_ASSIGNEES,
                component: CreatePLPARTemplateStep3View,
            },
            {
                name: SETTINGS_ROUTE_NAMES.PAR.CREATE.PL.REVIEW_AND_CONFIRM,
                path: 'review-and-confirm',
                component: CreatePLPARTemplateStep4View,
                meta: { parentProps: { enableGroupItems: true } },
            },
        ],
    },

    {
        path: 'settings/par-templates/quick-fill/create',
        component: CreateQFPARTemplateView,
        children: [
            {
                path: '',
                name: SETTINGS_ROUTE_NAMES.PAR.CREATE.QF.INDEX,
                component: CreateQFPARTemplateStep1View,
            },
            {
                path: 'add-items',
                name: SETTINGS_ROUTE_NAMES.PAR.CREATE.QF.ADD_ITEMS,
                component: CreateQFPARTemplateStep2View,
            },
            {
                name: SETTINGS_ROUTE_NAMES.PAR.CREATE.QF.REVIEW_AND_CONFIRM,
                path: 'review-and-confirm',
                component: CreateQFPARTemplateStep3View,
            },
        ],
    },

    {
        path: 'settings/par-templates/prep-list/:templateId/edit',
        component: EditPLPARTemplateView,
        props: true,
        children: [
            {
                path: '',
                name: SETTINGS_ROUTE_NAMES.PAR.EDIT.PL.INDEX,
                component: EditPLPARTemplateStep1View,
                props: true,
            },
            {
                path: 'add-items',
                name: SETTINGS_ROUTE_NAMES.PAR.EDIT.PL.ADD_ITEMS,
                component: EditPLPARTemplateStep2View,
                props: true,
            },
            {
                path: 'set-assignees',
                name: SETTINGS_ROUTE_NAMES.PAR.EDIT.PL.SET_ASSIGNEES,
                component: EditPLPARTemplateStep3View,
                props: true,
            },
            {
                name: SETTINGS_ROUTE_NAMES.PAR.EDIT.PL.REVIEW_AND_CONFIRM,
                path: 'review-and-confirm',
                component: EditPLPARTemplateStep4View,
                props: true,
                meta: { parentProps: { enableGroupItems: true } },
            },
        ],
    },

    {
        path: 'settings/par-templates/quick-fill/:templateId/edit',
        component: EditQFPARTemplateView,
        props: true,
        children: [
            {
                path: '',
                name: SETTINGS_ROUTE_NAMES.PAR.EDIT.QF.INDEX,
                component: EditQFPARTemplateStep1View,
                props: true,
            },
            {
                path: 'add-items',
                name: SETTINGS_ROUTE_NAMES.PAR.EDIT.QF.ADD_ITEMS,
                component: EditQFPARTemplateStep2View,
                props: true,
            },
            {
                name: SETTINGS_ROUTE_NAMES.PAR.EDIT.QF.REVIEW_AND_CONFIRM,
                path: 'review-and-confirm',
                component: EditQFPARTemplateStep3View,
                props: true,
            },
        ],
    },
];
