import { ref, reactive, computed, watch } from 'vue';
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router';
import { useStore } from 'vuex';

import { getFilteredQueryParams } from '@/utils/routerUtils';
import { formatDate } from '@/utils/dateUtils';
import TimelineModel from '@/models/Timeline';
import useAbortableRequest from '@/composition/useAbortableRequest';

const useTimeline = (factory, initialFiltersConfig = {}) => {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const { sendAbortableRequest, abortRequests } = useAbortableRequest();

    const { currentAccount } = store.state.auth;
    const orgId = currentAccount.organization.id;

    /*------------------------------------------------------------------------
                                      Filters
    ------------------------------------------------------------------------*/

    const getInitialFilters = () =>
        Object.entries(initialFiltersConfig).reduce(
            (acc, [key, defaultValue]) => ({
                ...acc,
                [key]: route.query[key] || defaultValue,
            }),
            {}
        );

    const filters = ref(getInitialFilters());

    const updateFilters = (updatedFilters) => {
        filters.value = {
            ...filters.value,
            ...updatedFilters,
        };
    };

    /*------------------------------------------------------------------------
                                  Timeline state
    ------------------------------------------------------------------------*/

    const timelineState = reactive({
        data: {},
        selectedDate: route.query.date ?? formatDate(),
        isDataLoading: true,
        hasError: false,
        hasBeenJustUpdated: false,
    });

    const setTimeline = (model) => {
        if (model !== null) {
            const { selectedDate } = timelineState;

            timelineState.data[selectedDate] = model.data[selectedDate];
            timelineState.isDataLoading = false;
            timelineState.hasBeenJustUpdated = false;
        }
    };

    const loadTimeline = (dynamicFactory = null, dates = [timelineState.selectedDate]) => {
        timelineState.hasError = false;

        router.push({
            query: {
                ...route.query,
                ...getFilteredQueryParams(filters.value),
                date: timelineState.selectedDate,
            },
        });

        return sendAbortableRequest(
            TimelineModel.all(orgId, {
                ...filters.value,
                factory: factory ?? dynamicFactory,
                dates,
            })
        ).catch(() => (timelineState.hasError = true));
    };

    const loadTouchedDatesTimeline = (dynamicFactory, touchedDates) => {
        const dates = touchedDates.filter((date) => timelineState.data[date] !== undefined);

        if (dates.length > 0) {
            loadTimeline(dynamicFactory, dates).then((model) => {
                if (model !== null) {
                    timelineState.data = {
                        ...timelineState.data,
                        ...model.data,
                    };
                }
            });
        }
    };

    const timelineDateChanged = (dynamicFactory = null) => {
        abortRequests();

        timelineState.hasBeenJustUpdated = false;
        timelineState.isDataLoading = false;

        if (timelineState.data[timelineState.selectedDate] === undefined) {
            timelineState.isDataLoading = true;

            loadTimeline(dynamicFactory).then(setTimeline);
        }
    };

    const calendarDateChanged = (dynamicFactory = null) => {
        timelineState.data = {};

        timelineDateChanged(dynamicFactory);
    };

    const selectedOrderList = computed(() => {
        if (timelineState.isDataLoading) {
            return factory === null ? [] : {};
        }

        const data = timelineState.data[timelineState.selectedDate];

        if (factory === null) {
            return data ?? [];
        }

        return Array.isArray(data) ? {} : data ?? {};
    });

    watch(
        () => filters.value,
        () => {
            if (factory !== null) {
                timelineState.data = {};
                timelineState.isDataLoading = true;

                loadTimeline().then(setTimeline);
            }
        },
        { immediate: true, deep: true }
    );

    onBeforeRouteUpdate((updatedRoute) => {
        const isSameViewMode = route.query.viewmode === updatedRoute.query.viewmode;

        if (isSameViewMode && updatedRoute.query.date === undefined) {
            router.replace({ query: { ...route.query, date: timelineState.selectedDate } });
        }
    });

    return {
        filters,
        updateFilters,

        timelineState,
        selectedOrderList,
        setTimeline,
        loadTimeline,
        loadTouchedDatesTimeline,
        timelineDateChanged,
        calendarDateChanged,
    };
};

export default useTimeline;
