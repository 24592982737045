import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';

import MyProfileIndexView from '@/views/dashboard/settings/my-profile';

export default [
    {
        path: 'settings/my-profile',
        name: SETTINGS_ROUTE_NAMES.MY_PROFILE,
        component: MyProfileIndexView,
    },
];
