<script>
export default {
    name: 'FormUI',
};
</script>

<script setup>
import { useSlots } from 'vue';

defineProps({
    dataTestId: {
        type: String,
        required: true,
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
    disableAnimation: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['submit']);

const slots = useSlots();

/*------------------------------------------------------------------------
                                Class names
------------------------------------------------------------------------*/

const rootClassName = 'pl-form';

const classNames = {
    spacerSm: `${rootClassName}__spacer--sm`,
    spacerMd: `${rootClassName}__spacer--md`,
    spacerLg: `${rootClassName}__spacer--lg`,
    ribbon: `${rootClassName}__ribbon`,
};
</script>

<template>
    <form
        novalidate
        :class="rootClassName"
        :data-testid="dataTestId"
        @submit.prevent="emit('submit')"
    >
        <OverlayLoader
            v-if="isLoading"
            enable-sticky
        />

        <transition-group
            name="down"
            type="transition"
            :appear="!disableAnimation"
        >
            <div
                key="form-ui--content"
                :class="`${rootClassName}__content`"
            >
                <slot
                    name="content"
                    :classNames="classNames"
                    :qaPrefix="dataTestId"
                />
            </div>

            <div
                v-if="slots['actions']"
                key="form-ui--actions"
                :class="`${rootClassName}__actions`"
            >
                <slot
                    name="actions"
                    :qaPrefix="dataTestId"
                />
            </div>
        </transition-group>
    </form>
</template>

<style lang="scss">
.pl-form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: custom-space(2.25);
    max-width: 380px;
    width: 100%;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
        max-width: 100%;
    }

    &__content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    &__spacer {
        &--sm {
            margin-bottom: custom-space(0.5);
        }

        &--md {
            margin-bottom: custom-space(1);
        }

        &--lg {
            margin-bottom: custom-space(1.5);
        }
    }

    &__ribbon {
        display: block;
        margin-top: custom-space(0.25);
        color: $gray-800;
    }

    &__actions {
        display: flex;
        flex-direction: column;
        gap: custom-space(0.75);
    }
}
</style>
