import ApiClient from '@/plugins/ApiClient';
import Model from './Model';

// Quick Fill PAR*
export default class QFPARTemplateSelectableItem extends Model {
    static all(orgId, templateId) {
        return this.withAbort(
            ApiClient.get(`organizations/${orgId}/quick-fill-par-templates/${templateId}/selectable-items`),
            ({ data }) => this.make(data)
        );
    }

    static async setQty(orgId, templateId, prototypeId, attributes) {
        const { data } = await ApiClient.post(
            `organizations/${orgId}/quick-fill-par-templates/${templateId}/selectable-items/${prototypeId}/set-qty`,
            attributes
        );

        return QFPARTemplateSelectableItem.make(data);
    }
}
