<script>
export default {
    name: 'ReportViewTemplate',
};
</script>

<script setup>
import { computed } from 'vue';

import { REPORTS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { REPORTS } from '@/enums/reportsEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import Alert from '@/components/Alert';
import DropDown from '@/components/DropDown';
import ListDisplay from '@/components/ListDisplay';
import TreeDisplay from '@/components/TreeDisplay';
import GroupedSettings from '@/components/constructions/grouped-settings';
import SummaryTotal from '@/components/model-specific/orders/SummaryTotal';

const props = defineProps({
    reportTitle: {
        type: String,
        required: true,
    },
    summaries: {
        type: Object,
        default: () => ({}),
    },
    isReportLoading: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['download']);

/*------------------------------------------------------------------------
                               Report state
------------------------------------------------------------------------*/

const getTotalDisplayValue = (node) => {
    if (props.reportTitle === REPORTS.EMPLOYEE_PERFORMANCE.TITLE) {
        return `${node.labor_time ?? '??h'} / $${node.total_cost ?? '??'}`;
    }

    return `Total cost: $${node.total_cost ?? '??'}`;
};

const reportState = computed(() => {
    const { summaries } = props;

    return {
        isUnavailable: summaries === null,
        isEmpty: Object.entries(summaries?.data || {}).length === 0,
    };
});

const summariesData = computed(() => {
    const { summaries } = props;

    return {
        totalLaborTime: summaries?.labor_time ?? null,
        totalFoodCost: summaries?.food_cost ?? null,
        totalLaborCost: summaries?.labor_cost ?? null,
        totalCost: summaries?.total_cost ?? null,
    };
});
</script>

<template>
    <TopBarLayout :back-route="REPORTS_ROUTE_NAMES.INDEX">
        <template #title>
            <h1>
                {{ reportTitle }}
            </h1>
        </template>

        <template
            v-if="!isReportLoading"
            #actions
        >
            <slot name="report-actions" />

            <DropDown v-if="!reportState.isEmpty">
                <template #content>
                    <GroupedSettings.Root>
                        <GroupedSettings.List title="Actions">
                            <GroupedSettings.ListItem @click="emit('download')">
                                Download
                            </GroupedSettings.ListItem>
                        </GroupedSettings.List>
                    </GroupedSettings.Root>
                </template>
            </DropDown>
        </template>

        <div class="pl-report">
            <InPlaceLoader v-if="isReportLoading" />

            <Alert
                v-else-if="reportState.isUnavailable"
                :type="ALERT_TYPES.FAIL"
            >
                Report could not be loaded for some reason. Please try again later.
            </Alert>

            <template v-else>
                <div class="pl-report__top-section">
                    <slot name="top-section" />
                </div>

                <p
                    v-if="reportState.isEmpty"
                    class="pl-report__message"
                >
                    Nothing found for this period.
                </p>

                <template v-else>
                    <TreeDisplay
                        node-source-column="data"
                        class="pl-report__tree-items"
                        :data="summaries"
                    >
                        <template #node="{ key, node }">
                            <div class="pl-report__tree-details">
                                <div class="pl-report__tree-name">
                                    {{ key }}
                                </div>

                                <div
                                    v-if="node.total_cost"
                                    class="pl-report__tree-value"
                                >
                                    {{ getTotalDisplayValue(node) }}
                                </div>
                            </div>
                        </template>

                        <template #leaf="{ leaf }">
                            <ListDisplay :items="leaf.data">
                                <template #item="{ item }">
                                    <slot
                                        name="summary-card"
                                        :item="item"
                                    />
                                </template>
                            </ListDisplay>
                        </template>
                    </TreeDisplay>

                    <div class="pl-report__summary-total">
                        <SummaryTotal
                            :labor-time="summariesData.totalLaborTime"
                            :food-cost="summariesData.totalFoodCost"
                            :labor-cost="summariesData.totalLaborCost"
                            :total-cost="summariesData.totalCost"
                        />
                    </div>
                </template>
            </template>
        </div>
    </TopBarLayout>
</template>

<style lang="scss" scoped>
.pl-report {
    margin-top: custom-space(-0.5);

    &__message {
        padding: custom-space(3) 0;
        border-top: 1px solid $gray-100;
        text-align: center;
    }

    &__top-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: custom-space(0.6);
        margin-top: custom-space(-0.3);
        padding-bottom: custom-space(0.75);

        & > :slotted(h4) {
            margin: 0;

            @media screen and (max-width: 600px) {
                font-size: $font-size-base * 1.1;
            }
        }
    }

    &__tree-items > :deep(.pl-tree-display__node) {
        > .pl-report__tree-details {
            display: flex;
            align-items: center;
            font-weight: $font-weight-bolder;
            font-size: $font-size-base * 1.25;
            padding-top: custom-space(1);
            border-top: 1px solid $gray-100;

            @include media-breakpoint-down(lg) {
                font-size: $font-size-base * 1.1;
            }

            > .pl-report__tree-value {
                @include media-breakpoint-down(lg) {
                    font-size: $font-size-base;
                }
            }
        }

        > .pl-tree-display > .pl-tree-display__node {
            & > .pl-report__tree-details {
                display: flex;
                align-items: center;
                margin-top: custom-space(0.5);
                background-color: $gray-900;
                padding: custom-space(0.7) custom-space(1);
                border: none;
                border-radius: 0 0 $border-radius $border-radius;
                font-size: $font-size-base * 1.125;
                color: $white;
                font-weight: $font-weight-bolder;

                @include media-breakpoint-down(lg) {
                    font-size: $font-size-base * 0.975;
                    margin-top: custom-space(0.25);
                    padding: custom-space(0.5) custom-space(0.75);
                }
            }

            & > .pl-tree-display > .pl-tree-display__node {
                margin-top: custom-space(0.75);

                .pl-report-summary {
                    padding: custom-space(0.25) 0 custom-space(1) 0;
                }

                & > .pl-report__tree-details {
                    display: flex;
                    align-items: center;
                    font-size: $font-size-base;
                    font-weight: $font-weight-bolder;

                    .pl-report__tree-name {
                        color: $primary;
                    }
                }

                &:not(:last-child) .pl-report-summary {
                    border-bottom: 1px dashed rgba($gray-600, 0.3);
                }
            }
        }

        .pl-report__tree-name {
            flex-grow: 1;
            padding-right: custom-space(1);
        }

        .pl-report__tree-value {
            flex-shrink: 0;
        }
    }

    &__summary-total {
        margin-top: custom-space(1);
    }

    :deep(.pl-list-display) > .pl-list-display__item {
        &:not(:last-child) .pl-report-summary {
            border-bottom: 1px dashed rgba($gray-600, 0.3);
        }

        .pl-item-summary {
            margin-bottom: 0;
        }
    }
}
</style>
