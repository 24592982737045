<script>
export default {
    name: 'AccountCard',
};
</script>

<script setup>
import { ALERT_TYPES } from '@/enums/componentsEnums';
import Alert from '@/components/Alert';
import Badge from '@/components/Badge';

defineProps({
    account: {
        type: Object,
        required: true,
    },
    isCurrent: {
        type: Boolean,
        default: false,
    },
    isJoinedList: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['enter-company', 'accept-invitation']);

/*------------------------------------------------------------------------
                                    QA
------------------------------------------------------------------------*/

const qaPrefix = 'account';
</script>

<template>
    <div class="pl-account-card">
        <div class="pl-account-card__wrapper">
            <h6 :data-testid="`${qaPrefix}_organization_name`">
                {{ account.organization.name }}
            </h6>

            <div class="pl-account-card__actions">
                <Badge
                    v-if="isCurrent"
                    size="sm"
                    :data-testid="`${qaPrefix}_current_organization`"
                >
                    Current
                </Badge>

                <BtnUI
                    v-else-if="isJoinedList"
                    is-filled
                    size="sm"
                    :data-testid="`${qaPrefix}_switch_btn`"
                    @click="emit('enter-company')"
                >
                    Switch
                </BtnUI>

                <BtnUI
                    v-else
                    is-filled
                    size="sm"
                    :data-testid="`${qaPrefix}_accept_btn`"
                    :disabled="!account.organization.onboarding_completed"
                    @click="emit('accept-invitation')"
                >
                    Accept
                </BtnUI>
            </div>
        </div>

        <Alert
            v-if="!account.organization.onboarding_completed"
            :type="ALERT_TYPES.FAIL"
        >
            {{
                isJoinedList
                    ? 'The onboarding has not been completed!'
                    : 'The onboarding has not been completed by the company owner yet!'
            }}
        </Alert>
    </div>
</template>

<style lang="scss" scoped>
.pl-account-card {
    display: flex;
    flex-direction: column;
    gap: custom-space(0.5);

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: custom-space(1);

        & > h6 {
            margin: 0;
            font-weight: $font-weight-normal;
        }
    }

    &__actions {
        flex-shrink: 0;
    }
}
</style>
