<template>
    <div class="pl-checkbox">
        <label
            v-if="label"
            class="form-label mb-3"
        >
            {{ label }}
        </label>

        <div
            v-for="({ value, text, qaSuffix }, index) in options"
            :key="`checkbox-option-${index}`"
            class="form-check"
        >
            <input
                :id="`${name}_${value}`"
                v-model="checkedOptions"
                type="checkbox"
                class="form-check-input"
                :class="{ 'is-invalid': hasError }"
                :name="`${name}_${value}`"
                :data-testid="qaPrefix && qaSuffix ? `${qaPrefix}_${qaSuffix}` : `checkbox_option_${value}`"
                :value="value"
                :checked="isOptionChecked(value)"
                @input="chooseOption"
                @blur="emit('blur')"
            />

            <label
                v-if="text"
                class="form-check-label"
                :for="`${name}_${value}`"
            >
                {{ text }}
            </label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CheckboxInput',
};
</script>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
    label: {
        type: String,
        default: null,
    },
    options: {
        type: Array,
        default: () => [],
    },
    modelValue: {
        type: [Array, Boolean],
        required: true,
    },
    hasError: {
        type: Boolean,
        default: false,
    },
    name: {
        type: String,
        required: true,
    },
    qaPrefix: {
        type: String,
        default: '',
    },
    enableBooleanMode: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['blur', 'update:modelValue']);

const checkedOptions = ref(props.enableBooleanMode ? false : []);

watch(
    () => props.modelValue,
    (newValue) => (checkedOptions.value = newValue),
    { immediate: true }
);

const isOptionChecked = (optionValue) => {
    if (props.enableBooleanMode) {
        return checkedOptions.value;
    }

    return checkedOptions.value.includes(optionValue);
};

const chooseOption = ($event) => {
    if (props.enableBooleanMode) {
        emit('update:modelValue', !checkedOptions.value);

        return;
    }

    const value = $event.target.value;

    const checkedOptionIndex = checkedOptions.value.indexOf(value);

    if (checkedOptionIndex !== -1) {
        checkedOptions.value.splice(checkedOptionIndex, 1);
    } else {
        checkedOptions.value.push(value);
    }

    emit('update:modelValue', checkedOptions.value);
};
</script>

<style lang="scss">
.pl-checkbox {
    position: relative;
    z-index: 1;

    .form-check:not(:last-child) {
        margin-bottom: $spacer * 1.3;
    }
}
</style>
