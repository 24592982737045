<script>
export default {
    name: 'TimelineMainManager',
};
</script>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { isAdmin } from '@/utils/accountUtils';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import { TIMELINE_VIEW_MODES } from '@/enums/timelineEnums';
import PLOrderModel from '@/models/PLOrder';
import useConfirmationModal from '@/composition/useConfirmationModal';
import ConfirmationModal from '@/components/ConfirmationModal';
import TimelineOrderCard from '@/components/model-specific/orders/TimelineOrderCard';
import TimelineViewTemplate from '../../partials/timeline-view-template';

const store = useStore();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                             Timeline settings
------------------------------------------------------------------------*/

const factory = isAdmin(currentAccount) ? 'admin_by_order' : 'manager_by_order';

const initialFiltersConfig = {
    department_id: null,
    category_id: null,
    status: null,
    only_my: isAdmin(currentAccount) ? '0' : '1',
};

const freshTimeline = ref(null);

/*------------------------------------------------------------------------
                         Confirmation modal state
------------------------------------------------------------------------*/

const { confirmationState, confirmationModal } = useConfirmationModal();

const submitDismissOrder = () => {
    confirmationModal
        .submit((model) => PLOrderModel.markAsClosed(orgId, model.id, { factory }))
        .then((model) => {
            freshTimeline.value = model;

            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: 'Order has been successfully dismissed',
            });
        });
};
</script>

<template>
    <TimelineViewTemplate
        regular-order-list-key="regular"
        other-order-list-key="overproduction"
        :view-mode="TIMELINE_VIEW_MODES.MAIN"
        :factory="factory"
        :initial-filters-config="initialFiltersConfig"
        :fresh-timeline="freshTimeline"
    >
        <template #order-list-item="{ item: order, isRegularList }">
            <TimelineOrderCard
                :order="order"
                :items-data-key="`${isRegularList ? 'ordered' : 'other'}`"
                @dismiss-order="confirmationModal.open(order)"
            />
        </template>

        <template
            v-if="confirmationState.isOpened"
            #modal
        >
            <ConfirmationModal
                qa-prefix="dismiss_order_modal"
                submit-btn-text="Yes"
                cancel-btn-text="No"
                :is-pending="confirmationState.isPending"
                @close="confirmationModal.close"
                @submit-delete="submitDismissOrder"
            >
                {{ `Are you sure you want to dismiss ${confirmationState.model.name}?` }}
            </ConfirmationModal>
        </template>
    </TimelineViewTemplate>
</template>
