<script>
export default {
    name: 'TimelineViewTemplate',
};
</script>

<script setup>
import { ref, inject, computed, watch, useSlots } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { isAdmin, isManager, isAdminOrManager } from '@/utils/accountUtils';
import { mapGetters } from '@/utils/vuexUtils';
import { isOrderEditing } from '@/utils/orderUtils';
import { PL_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { PL_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { TIMELINE_VIEW_MODES, TIMELINE_TAB_OPTIONS, TIMELINE_TABS } from '@/enums/timelineEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import useTaskReminderRibbon from '@/composition/useTaskReminderRibbon';
import useTimeline from '@/composition/useTimeline';
import TaskReminderRibbon from '@/components/TaskReminderRibbon';
import Alert from '@/components/Alert';
import DropDown from '@/components/DropDown';
import Tabs from '@/components/Tabs';
import DatePickerHOC from '@/components/HOC/DatePickerHOC';
import ListDisplay from '@/components/ListDisplay';
import UnfinishedOrderRibbon from '@/components/model-specific/orders/UnfinishedOrderRibbon';
import TimelineDatesSlider from '@/components/model-specific/orders/TimelineDatesSlider';
import ThreeDotsContentAdmin from './partials/ThreeDotsContentAdmin';
import ThreeDotsContentManager from './partials/ThreeDotsContentManager';
import ThreeDotsContentCook from './partials/ThreeDotsContentCook';
import CreateOrderFab from './partials/CreateOrderFab';
import OrderFilters from './partials/OrderFilters';

const props = defineProps({
    regularOrderListKey: {
        type: String,
        required: true,
    },
    otherOrderListKey: {
        type: String,
        required: true,
    },
    viewMode: {
        type: String,
        required: true,
    },
    factory: {
        type: String,
        required: true,
    },
    initialFiltersConfig: {
        type: Object,
        required: true,
    },
    freshTimeline: {
        type: Object,
        default: null,
    },
    includeTabs: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['set-selected-order-list']);

const $acl = inject('$acl');

const slots = useSlots();
const router = useRouter();
const route = useRoute();
const store = useStore();

const { currentPLOrder } = mapGetters(['currentPLOrder']);

const { isTaskReminderVisible } = useTaskReminderRibbon(currentPLOrder.value === null);

const { currentAccount } = store.state.auth;

/*------------------------------------------------------------------------
                              Timeline state
------------------------------------------------------------------------*/

const {
    filters,
    updateFilters,

    timelineState,
    selectedOrderList,
    setTimeline,
    timelineDateChanged,
    calendarDateChanged,
} = useTimeline(props.factory, props.initialFiltersConfig);

const unfinishedOrderLink = computed(() => {
    const order = currentPLOrder.value;

    if (isOrderEditing(order)) {
        return {
            name: PL_ROUTE_NAMES.EDIT.INDEX,
            params: { orderId: order.parent_id },
        };
    }

    return { name: PL_ROUTE_NAMES.CREATE.INDEX };
});

const isAdminOrManagerMainView = computed(
    () => isAdminOrManager(currentAccount) && route.query.viewmode !== TIMELINE_VIEW_MODES.AS_COOK
);

watch(selectedOrderList, (orderList) => emit('set-selected-order-list', orderList));

watch(
    () => props.freshTimeline,
    (timelineModel) => {
        if (Object.keys(timelineModel || {}).length > 0) {
            setTimeline(timelineModel);
        }
    }
);

/*------------------------------------------------------------------------
                                   Tabs
------------------------------------------------------------------------*/

const activeTab = ref(route.query.tab || TIMELINE_TABS.ASSIGNED);

const setActiveTab = (value) => (activeTab.value = value);

const tabContentConfig = computed(() => {
    const { regularOrderListKey, otherOrderListKey } = props;

    if (activeTab.value === TIMELINE_TABS.ASSIGNED) {
        return {
            otherList: selectedOrderList.value[otherOrderListKey] || [],
            mainList: selectedOrderList.value[regularOrderListKey] || [],
        };
    }

    return {
        otherList: [],
        mainList: selectedOrderList.value['open_items'] || [],
    };
});

const includeArbitrarySection = () => {
    if (activeTab.value === TIMELINE_TABS.ASSIGNED) {
        return tabContentConfig.value.otherList.length > 0 || !!slots['arbitrary-section'];
    }

    return false;
};

watch(activeTab, (tab) => router.push({ query: { ...route.query, tab } }));

/*------------------------------------------------------------------------
                           Three dots component
------------------------------------------------------------------------*/

const threeDotsContentComponent = computed(() => {
    if (isAdmin(currentAccount)) {
        return ThreeDotsContentAdmin;
    }

    if (isManager(currentAccount)) {
        return ThreeDotsContentManager;
    }

    return ThreeDotsContentCook;
});
</script>

<template>
    <TopBarLayout exclude-content-container>
        <template #title>
            <h1>Prep Lists</h1>
        </template>

        <template #actions>
            <DatePickerHOC
                v-model="timelineState.selectedDate"
                menu-class-name="pl-timeline-view-calendar"
                @update:modelValue="calendarDateChanged()"
            />

            <OrderFilters
                v-if="isAdminOrManagerMainView"
                :initial-filters="filters"
                @update-filters="updateFilters"
            />

            <DropDown>
                <template #content>
                    <component
                        :is="threeDotsContentComponent"
                        :view-mode="viewMode"
                    />
                </template>
            </DropDown>
        </template>

        <template
            v-if="(isAdminOrManagerMainView && currentPLOrder !== null) || isTaskReminderVisible"
            #ribbon
        >
            <UnfinishedOrderRibbon
                v-if="isAdminOrManagerMainView && currentPLOrder !== null"
                :navigate-to="unfinishedOrderLink"
            />

            <TaskReminderRibbon v-else />
        </template>

        <div
            v-if="timelineState.hasError"
            class="container"
        >
            <Alert :type="ALERT_TYPES.FAIL">
                Order list could not be loaded for some reason. Please try again later.
            </Alert>
        </div>

        <template v-else>
            <TimelineDatesSlider
                v-model="timelineState.selectedDate"
                @slide-start="timelineState.hasBeenJustUpdated = true"
                @update:modelValue="timelineDateChanged()"
            />

            <transition
                appear
                name="fade"
                type="transition"
                mode="out-in"
            >
                <InPlaceLoader v-if="timelineState.isDataLoading" />

                <div
                    v-else
                    class="container"
                >
                    <Tabs
                        :exclude-tab-options="!includeTabs"
                        :model-value="activeTab"
                        :tab-options="TIMELINE_TAB_OPTIONS"
                        :has-been-content-just-updated="timelineState.hasBeenJustUpdated"
                        @update:modelValue="setActiveTab"
                    >
                        <template #content>
                            <ListDisplay
                                v-if="tabContentConfig.mainList.length > 0"
                                data-testid="orders_list"
                                class="pl-timeline-view__order-list"
                                :items="tabContentConfig.mainList"
                                :class="{
                                    'pl-timeline-view__order-list--space': tabContentConfig.otherList.length === 0,
                                }"
                            >
                                <template #item="{ item }">
                                    <slot
                                        name="order-list-item"
                                        :item="item"
                                        :is-regular-list="true"
                                    />
                                </template>
                            </ListDisplay>

                            <p v-else>
                                There are no orders for the selected prep date.
                            </p>

                            <div
                                v-if="includeArbitrarySection()"
                                class="pl-timeline-view__section--other"
                            >
                                <h2>
                                    <span>Other</span>
                                </h2>

                                <ListDisplay
                                    v-if="tabContentConfig.otherList.length > 0"
                                    data-testid="other_orders_list"
                                    class="pl-timeline-view__order-list pl-timeline-view__order-list--space"
                                    :items="tabContentConfig.otherList"
                                >
                                    <template #item="{ item }">
                                        <slot
                                            name="order-list-item"
                                            :item="item"
                                            :is-regular-list="false"
                                        />
                                    </template>
                                </ListDisplay>

                                <slot name="arbitrary-section" />
                            </div>
                        </template>
                    </Tabs>
                </div>
            </transition>

            <slot name="modal" />

            <CreateOrderFab v-if="isAdminOrManagerMainView && $acl.can(PL_RESTRICTIONS.CREATE, currentAccount)" />
        </template>
    </TopBarLayout>
</template>

<style lang="scss" scoped>
.pl-timeline-view {
    &__order-list--space {
        padding-bottom: custom-space(3);
    }

    &__section--other > h2 {
        font-size: calc(1.275rem + 0.3vw);
        position: relative;
        text-align: center;
        margin: custom-space(1) 0 custom-space(0.5) 0;

        > span {
            position: relative;
            background-color: $white;
            padding: 0 custom-space(0.6);
            z-index: 2;
        }

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            display: block;
            height: 4px;
            background-image: radial-gradient(circle, $gray-500 31%, rgba($white, 0) 3%);
            background-position: center;
            background-size: 10px 2px;
            background-repeat: repeat-x;
            border-radius: 2px;
            z-index: 1;
        }
    }
}

:deep(.pl-tabs__content) > p {
    padding: custom-space(2) 0;
    margin: custom-space(0.5) 0 0;
}
</style>
