<script>
export default {
    name: 'EmployeeCard',
};
</script>

<script setup>
import { computed, useSlots } from 'vue';
import { useStore } from 'vuex';

import EmployeeAvatar from '@/components/model-specific/employee/EmployeeAvatar';

const props = defineProps({
    employee: {
        type: Object,
        required: true,
    },
    excludeAvatar: {
        type: Boolean,
        default: false,
    },
});

const slots = useSlots();
const store = useStore();

/*------------------------------------------------------------------------
                              Employee state
------------------------------------------------------------------------*/

const phoneNumber = computed(() => {
    const { employee } = props;
    const { phone_number: phoneNumber } = employee;
    const { phone_country_id: phoneCountryId, phone } = employee.phone;

    if (phoneNumber) {
        return phoneNumber;
    }

    const country = store.state.countries.countries?.find(({ id }) => phoneCountryId === id);

    return country ? `+${country.phone_code}${phone}` : null;
});

/*------------------------------------------------------------------------
                                    QA
------------------------------------------------------------------------*/

const qaPrefix = 'employee';
</script>

<template>
    <div
        class="pl-employee-card"
        :data-testid="qaPrefix"
    >
        <div class="pl-employee-card__wrapper">
            <div
                v-if="!excludeAvatar"
                class="pl-employee-card__avatar"
            >
                <EmployeeAvatar :url="employee.avatar_path" />

                <slot name="avatar-badge" />
            </div>

            <div class="pl-employee-card__content">
                <span :data-testid="`${qaPrefix}_name`">
                    {{ employee.name }}
                </span>

                <b :data-testid="`${qaPrefix}_phone_number`">
                    {{ phoneNumber }}
                </b>
            </div>
        </div>

        <div
            v-if="slots['actions']"
            class="pl-employee-card__actions"
        >
            <slot
                name="actions"
                :qaPrefix="qaPrefix"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.pl-employee-card {
    display: flex;
    align-items: center;
    gap: custom-space(1);

    &__wrapper {
        flex-grow: 1;
        display: flex;
        align-items: center;
    }

    &__avatar {
        flex-shrink: 0;
        margin-right: custom-space(1);
    }

    &__content {
        display: flex;
        flex-direction: column;
        line-height: $font-size-base * 1.25;

        & > span {
            font-weight: $font-weight-light;
            word-break: break-word;
        }

        & > b {
            font-weight: $font-weight-normal;
        }
    }

    &__actions {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        gap: custom-space(0.75);
    }
}
</style>
