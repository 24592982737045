<script>
export default {
    name: 'LoginView',
};
</script>

<script setup>
import { reactive, onBeforeMount, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { catchUnprocessableEntity } from '@/utils/httpUtils';
import { isFieldEmpty } from '@/utils/formUtils';
import { requiredField, phone, required, minLength } from '@/utils/formValidators';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import AuthModel from '@/models/Auth';
import useForm from '@/composition/useForm';
import FullScreenLoader from '@/components/FullScreenLoader';
import Alert from '@/components/Alert';
import AuthLayout from '@/components/layouts/AuthLayout';
import PhoneInput from '@/components/form-controls/PhoneInput';
import ConfirmationCodeInput from '@/components/form-controls/ConfirmationCodeInput';

const route = useRoute();
const store = useStore();

/*------------------------------------------------------------------------
                               General state
------------------------------------------------------------------------*/

const generalState = reactive({
    isDataLoading: true,
    hasError: false,
    isFormSubmitting: false,
    isCodeSent: false,
});

onBeforeMount(() => {
    store
        .dispatch('countries/loadCountries')
        .then(() => {
            phoneForm.reinitializeValidationRules({
                phone: {
                    requiredField: requiredField('phone'),
                    phone: phone(store.state.countries.countries),
                },
            });
        })
        .catch(() => (generalState.hasError = true))
        .finally(() => (generalState.isDataLoading = false));
});

/*------------------------------------------------------------------------
                             Setup phone form
------------------------------------------------------------------------*/

const { formState: phoneFormState, ...phoneForm } = useForm({
    phone: {
        phone: null,
        phone_country_id: null,
    },
});

const submitRequestCode = () => {
    if (phoneForm.validate()) {
        generalState.isFormSubmitting = true;

        AuthModel.requestConfirmationCode(phoneFormState)
            .then(() => (generalState.isCodeSent = true))
            .catch((error) => catchUnprocessableEntity(error, phoneForm.setValidationErrors))
            .finally(() => (generalState.isFormSubmitting = false));
    }
};

/*------------------------------------------------------------------------
                          Setup confirmation form
------------------------------------------------------------------------*/

const { formState: confirmationFormState, ...confirmationForm } = useForm(
    {
        confirmation_code: '',
    },
    {
        confirmation_code: {
            required,
            minLength: minLength(4),
        },
    },
    {
        confirmation_code: 'Confirmation code',
    }
);

const scrollToOtpForm = () => {
    window.scrollTo({
        left: 0,
        top: document.body.scrollHeight || document.documentElement.scrollHeight,
        behavior: 'smooth',
    });
};

const submitConfirmLogin = () => {
    if (confirmationForm.validate()) {
        generalState.isFormSubmitting = true;

        AuthModel.login({ ...phoneFormState, ...confirmationFormState })
            .then(() => (window.location.href = route.query.come_from || '/select-account'))
            .catch((error) => {
                generalState.isFormSubmitting = false;

                catchUnprocessableEntity(error, confirmationForm.setValidationErrors);
            });
    }
};

const onPhoneChanged = () => {
    generalState.isCodeSent = false;

    phoneForm.clearAllErrors();

    confirmationFormState.confirmation_code = '';
    confirmationForm.clearAllErrors();
};

const onPhoneCountryChanged = () => {
    generalState.isCodeSent = false;

    if (isFieldEmpty(phoneFormState.phone.phone)) {
        phoneForm.clearAllErrors();
    } else {
        phoneForm.validate('phone');
    }

    confirmationFormState.confirmation_code = '';
    confirmationForm.clearAllErrors();
};

const isLoginBtnDisabled = computed(
    () =>
        generalState.isDataLoading ||
        generalState.isFormSubmitting ||
        phoneForm.isDisabled() ||
        confirmationForm.isDisabled()
);
</script>

<template>
    <AuthLayout>
        <FullScreenLoader v-if="generalState.isDataLoading" />

        <Alert
            v-else-if="generalState.hasError"
            :type="ALERT_TYPES.FAIL"
        >
            Something went wrong during loading the page. Please try again later.
        </Alert>

        <div
            v-else
            class="pl-login__wrapper"
        >
            <b>Welcome to</b>

            <section>
                <img
                    src="/images/logo.svg"
                    width="280"
                    alt="Logo"
                />

                <img
                    src="/images/splash_bg.svg"
                    width="280"
                    alt="Illustration"
                />
            </section>

            <section
                class="pl-login__form-section"
                :class="{
                    'pl-login__form-section--moved-up': !generalState.isCodeSent,
                }"
            >
                <OverlayLoader v-if="generalState.isFormSubmitting" />

                <h5>Please Log in or Sign up</h5>

                <form
                    novalidate
                    @submit.prevent="submitRequestCode"
                >
                    <PhoneInput
                        v-model="phoneFormState.phone"
                        include-asterisk
                        data-testid="login_phone_field"
                        label="Phone Number"
                        :disabled="generalState.isFormSubmitting"
                        :has-error="phoneForm.hasErrors('phone')"
                        @blur="phoneForm.validate('phone')"
                        @country-changed="onPhoneCountryChanged"
                        @update:modelValue="onPhoneChanged"
                        @send="submitRequestCode"
                    />

                    <ValidationErrors
                        v-if="phoneForm.hasErrors('phone')"
                        data-testid="login_phone_error"
                        :errors="phoneForm.getErrors('phone')"
                    />
                </form>

                <transition
                    appear
                    name="down"
                    type="transition"
                    @after-enter="scrollToOtpForm"
                >
                    <form
                        v-if="generalState.isCodeSent"
                        novalidate
                        class="pl-login__otp-form"
                        @submit.prevent="submitConfirmLogin"
                    >
                        <label>Enter the code</label>

                        <ConfirmationCodeInput
                            v-model="confirmationFormState.confirmation_code"
                            data-testid="auth_code_field"
                            :focused="generalState.isCodeSent"
                            :disabled="generalState.isFormSubmitting"
                            :has-error="confirmationForm.hasErrors('confirmation_code')"
                            @update:modelValue="confirmationForm.clearErrors('confirmation_code')"
                        />

                        <ValidationErrors
                            v-if="confirmationForm.hasErrors('confirmation_code')"
                            data-testid="auth_code_error"
                            :errors="confirmationForm.getErrors('confirmation_code')"
                        />

                        <BtnUI
                            is-filled
                            type="submit"
                            data-testid="login_btn"
                            :disabled="isLoginBtnDisabled"
                        >
                            Enter
                        </BtnUI>
                    </form>
                </transition>
            </section>
        </div>
    </AuthLayout>
</template>

<style lang="scss" scoped>
.pl-auth-layout {
    position: relative;

    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 40%;
        background-color: $white;
        box-shadow: 0 -50px 50px 10px $white;
        z-index: 10;
    }

    > :deep(.container) {
        max-width: 380px;
    }
}

.pl-login {
    &__wrapper {
        text-align: center;

        > b {
            display: block;
            text-transform: uppercase;
        }

        > section {
            > img {
                &:first-child {
                    position: relative;
                    z-index: 2;
                }

                &:last-child {
                    position: relative;
                    top: custom-space(-3);
                    z-index: 1;
                }
            }
        }
    }

    &__form-section {
        position: relative;
        z-index: 21;

        > h5 {
            margin-bottom: custom-space(1);
            font-weight: $font-weight-normal;
        }

        &--moved-up {
            top: custom-space(-5.5);
        }
    }

    &__otp-form {
        margin-top: custom-space(3);

        > .pl-btn {
            width: 100%;
            margin-top: custom-space(3);
        }
    }
}
</style>
