<script>
export default {
    name: 'OnboardingStep2View',
};
</script>

<script setup>
import { onBeforeMount, reactive } from 'vue';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { catchUnprocessableEntity } from '@/utils/httpUtils';
import { RESPONSE_DATA_TYPES } from '@/enums/httpEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import LocationModel from '@/models/Location';
import useAbortableRequest from '@/composition/useAbortableRequest';
import Tooltip from '@/components/Tooltip';
import OnboardingLayout from '@/components/layouts/OnboardingLayout';
import OnboardingStepLayout from '@/components/layouts/OnboardingStepLayout';
import LocationForm from '@/components/forms/LocationForm';
import LocationCard from '@/components/model-specific/location/LocationCard';

const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Locations state
------------------------------------------------------------------------*/

const locationsState = reactive({
    data: [],
    isDataLoading: true,
});

const loadLocations = () => {
    sendAbortableRequest(LocationModel.all(orgId))
        .then((models) => (locationsState.data = models || []))
        .finally(() => (locationsState.isDataLoading = false));
};

const updateOnboardingStatus = (models) => {
    store.commit('onboarding/updateSettings', {
        has_locations: models.some((location) => !location.is_default),
    });
};

const handleDeleteSuccess = (models) => {
    locationsState.data = models;

    updateOnboardingStatus(models);

    EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
        type: ALERT_TYPES.SUCCESS,
        message: 'The location has been successfully deleted!',
    });
};

onBeforeMount(loadLocations);

/*------------------------------------------------------------------------
                            Location form state
------------------------------------------------------------------------*/

const locationFormState = reactive({
    validationErrors: null,
    isSubmitting: false,
    isResetTriggered: false,
});

const submitCreateLocation = (attributes) => {
    locationFormState.isSubmitting = true;

    LocationModel.create(orgId, attributes, { response_data: RESPONSE_DATA_TYPES.LIST })
        .then((models) => {
            locationsState.data = models;
            locationFormState.isResetTriggered = true;

            updateOnboardingStatus(models);

            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: 'Location has been successfully created!',
            });
        })
        .catch((error) =>
            catchUnprocessableEntity(error, (errors) => {
                locationFormState.validationErrors = errors;
            })
        )
        .finally(() => (locationFormState.isSubmitting = false));
};
</script>

<template>
    <OnboardingLayout
        title="Locations"
        :is-data-loading="locationsState.isDataLoading"
        :is-submitting="locationFormState.isSubmitting"
    >
        <OnboardingStepLayout
            form-title="Create new Location"
            list-title="Locations added"
            :list-items="locationsState.data"
            :is-data-loading="locationsState.isDataLoading"
            @delete-success="handleDeleteSuccess"
        >
            <template #list-item="{ item: location, openDelete }">
                <LocationCard
                    exclude-employees-count
                    :location="location"
                >
                    <template #actions="{ qaPrefix }">
                        <button
                            v-if="location.can_be_deleted"
                            type="button"
                            :data-testid="`${qaPrefix}_delete_btn`"
                            @click="openDelete(location)"
                        >
                            <DeleteIcon />
                        </button>

                        <Tooltip
                            v-else
                            enable-transparency
                            text="You can't delete this location while some accounts are associated with only this location. Please move the accounts to other locations or delete them, before you can delete this location."
                        >
                            <DeleteIcon />
                        </Tooltip>
                    </template>
                </LocationCard>
            </template>

            <LocationForm
                disable-animation
                exclude-employees
                submit-btn-text="create location"
                :validation-errors="locationFormState.validationErrors"
                :is-submitting="locationFormState.isSubmitting"
                :is-reset-triggered="locationFormState.isResetTriggered"
                @reset-success="locationFormState.isResetTriggered = false"
                @submit="submitCreateLocation"
            />
        </OnboardingStepLayout>
    </OnboardingLayout>
</template>

<style lang="scss" scoped>
:deep(.pl-form__content) > .pl-form__spacer--lg:last-child {
    margin-bottom: 0;
}
</style>
