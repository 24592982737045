import { createApp } from 'vue';
import { Chart, ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';

import store from './store';
import router from './router';
import App from './app';

import { isOwner, isAdmin, isManager, isAdminOrManager } from '@/utils/accountUtils';
import {
    isOrderDateGone,
    isOrderCreator,
    hasOrderDraft,
    isOrderInProgress,
    isOrderCompleted,
} from '@/utils/orderUtils';
import ACL, { acl } from '@/plugins/ACL';
import {
    QFO_RESTRICTIONS,
    PL_RESTRICTIONS,
    REPORTS_RESTRICTIONS,
    PEOPLE_RESTRICTIONS,
    ITEMS_RESTRICTIONS,
    PREP_TASKS_RESTRICTIONS,
    DEPARTMENTS_RESTRICTIONS,
    LOCATIONS_RESTRICTIONS,
    SUPPLIERS_RESTRICTIONS,
    NPA_RESTRICTIONS,
    COI_RESTRICTIONS,
    PAR_RESTRICTIONS,
    ORGANIZATION_RESTRICTIONS,
} from '@/enums/restrictionsEnums';
import ClickOutside from '@/directives/ClickOutside';
import DigitsOnly from '@/directives/DigitsOnly';
import PhoneSpacing from '@/directives/PhoneSpacing';
import InPlaceLoader from '@/components/InPlaceLoader';
import OverlayLoader from '@/components/OverlayLoader';
import TopBarLayout from '@/components/layouts/top-bar-layout';
import ValidationErrors from '@/components/form-controls/ValidationErrors';
import BtnUI from '@/components/UI/BtnUI';
import ArrowDownIcon from '@/components/icons/ArrowDownIcon';
import BackArrowIcon from '@/components/icons/BackArrowIcon';
import CaretDownIcon from '@/components/icons/CaretDownIcon';
import CheckIcon from '@/components/icons/CheckIcon';
import CrossIcon from '@/components/icons/CrossIcon';
import DeleteIcon from '@/components/icons/DeleteIcon';
import EditIcon from '@/components/icons/EditIcon';
import OrderIcon from '@/components/icons/OrderIcon';
import PlusIcon from '@/components/icons/PlusIcon';
import SettingsIcon from '@/components/icons/SettingsIcon';

/*-----------------------------------------------------------------------------------
                                        chart.js
-----------------------------------------------------------------------------------*/

Chart.register(ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

/*------------------------------------------------------------------------
                                 ACL rules
------------------------------------------------------------------------*/

acl.defineRule(QFO_RESTRICTIONS.CREATE, isAdminOrManager)
    .defineRule(QFO_RESTRICTIONS.UPDATE, (account, order) => {
        if (hasOrderDraft(order)) {
            return isOrderCreator(account, order.parent_id ? order : order.draft);
        }

        if (isOrderDateGone(order)) {
            return false;
        }

        return (
            isOwner(account) ||
            isOrderCreator(account, order) ||
            (isAdminOrManager(account) && account.location.id === order.destination.id)
        );
    })
    .defineRule(QFO_RESTRICTIONS.DELETE, (account, order) => {
        if (isOrderDateGone(order)) {
            return false;
        }

        return isOwner(account) || isOrderCreator(account, order);
    })

    .defineRule(PL_RESTRICTIONS.CREATE, isAdminOrManager)
    .defineRule(PL_RESTRICTIONS.UPDATE, (account, order) => {
        if (hasOrderDraft(order)) {
            return isOrderCreator(account, order.parent_id ? order : order.draft);
        }

        if (isOrderDateGone(order)) {
            return false;
        }

        return !isOrderCompleted(order) && (isAdmin(account) || (isManager(account) && isOrderCreator(account, order)));
    })
    .defineRule(PL_RESTRICTIONS.DELETE, (account, order) => {
        if (isOrderDateGone(order)) {
            return false;
        }

        return isOrderInProgress(order) && (isAdmin(account) || (isManager(account) && isOrderCreator(account, order)));
    })

    .defineRule(REPORTS_RESTRICTIONS.VIEW, isAdminOrManager)

    .defineRule(PEOPLE_RESTRICTIONS.CREATE, isAdmin)
    .defineRule(
        PEOPLE_RESTRICTIONS.UPDATE,
        (account, target) => isOwner(account) || (isAdmin(account) && !isOwner(target))
    )
    .defineRule(PEOPLE_RESTRICTIONS.DELETE, (account, target) => isAdmin(account) && !isOwner(target))
    .defineRule(
        PEOPLE_RESTRICTIONS.REASSIGN,
        (account, target) => !isOwner(target) && target.joined_at !== null && isAdminOrManager(account)
    )

    .defineRule(ITEMS_RESTRICTIONS.VIEW, isAdminOrManager)
    .defineRule(ITEMS_RESTRICTIONS.CREATE, isAdmin)
    .defineRule(ITEMS_RESTRICTIONS.UPDATE, isAdmin)

    .defineRule(PREP_TASKS_RESTRICTIONS.CREATE, isAdmin)
    .defineRule(PREP_TASKS_RESTRICTIONS.UPDATE, isAdmin)

    .defineRule(DEPARTMENTS_RESTRICTIONS.VIEW, isAdmin)
    .defineRule(DEPARTMENTS_RESTRICTIONS.CREATE, isAdmin)
    .defineRule(DEPARTMENTS_RESTRICTIONS.UPDATE, isAdmin)
    .defineRule(DEPARTMENTS_RESTRICTIONS.DELETE, isAdmin)

    .defineRule(LOCATIONS_RESTRICTIONS.VIEW, isAdmin)
    .defineRule(LOCATIONS_RESTRICTIONS.CREATE, isAdmin)
    .defineRule(LOCATIONS_RESTRICTIONS.UPDATE, isAdmin)
    .defineRule(LOCATIONS_RESTRICTIONS.DELETE, isAdmin)

    .defineRule(SUPPLIERS_RESTRICTIONS.VIEW, isAdmin)
    .defineRule(SUPPLIERS_RESTRICTIONS.CREATE, isAdmin)
    .defineRule(SUPPLIERS_RESTRICTIONS.UPDATE, isAdmin)
    .defineRule(SUPPLIERS_RESTRICTIONS.DELETE, isAdmin)

    .defineRule(NPA_RESTRICTIONS.VIEW, isAdminOrManager)

    .defineRule(COI_RESTRICTIONS.VIEW, isAdmin)
    .defineRule(COI_RESTRICTIONS.CREATE, isAdmin)
    .defineRule(COI_RESTRICTIONS.UPDATE, isAdmin)
    .defineRule(COI_RESTRICTIONS.DELETE, isAdmin)

    .defineRule(PAR_RESTRICTIONS.VIEW, isAdmin)
    .defineRule(PAR_RESTRICTIONS.CREATE, isAdmin)
    .defineRule(PAR_RESTRICTIONS.UPDATE, (account, order) => {
        if (hasOrderDraft(order)) {
            return isOrderCreator(account, order.parent_id ? order : order.draft);
        }

        return true;
    })
    .defineRule(PAR_RESTRICTIONS.DELETE, (account, order) => {
        if (!isOrderCompleted(order)) {
            return false;
        }

        return isAdmin(account);
    })

    .defineRule(ORGANIZATION_RESTRICTIONS.UPDATE, isOwner);

/*-----------------------------------------------------------------------------------
                                    Create the app
-----------------------------------------------------------------------------------*/

const app = createApp(App);

app.directive('click-outside', ClickOutside)
    .directive('digits-only', DigitsOnly)
    .directive('phone-spacing', PhoneSpacing);

app.component('InPlaceLoader', InPlaceLoader)
    .component('OverlayLoader', OverlayLoader)
    .component('TopBarLayout', TopBarLayout)
    .component('ValidationErrors', ValidationErrors)
    .component('BtnUI', BtnUI)
    .component('ArrowDownIcon', ArrowDownIcon)
    .component('BackArrowIcon', BackArrowIcon)
    .component('CaretDownIcon', CaretDownIcon)
    .component('CheckIcon', CheckIcon)
    .component('CrossIcon', CrossIcon)
    .component('DeleteIcon', DeleteIcon)
    .component('EditIcon', EditIcon)
    .component('OrderIcon', OrderIcon)
    .component('PlusIcon', PlusIcon)
    .component('SettingsIcon', SettingsIcon);

app.use(ACL).use(store).use(router).mount('#app');
