import ApiClient from '@/plugins/ApiClient';
import Model from './Model';

export default class Notification extends Model {
    static all(query = {}) {
        return this.withAbort(ApiClient.get('notifications', { params: query }), ({ data: response }) => {
            response.data = this.make(response.data);

            return response;
        });
    }

    static async markAllAsRead() {
        const { data } = await ApiClient.put(`notifications/mark-all-read`);

        data.notifications = this.make(data.notifications);

        return data;
    }

    markAsRead() {
        return ApiClient.put(`notifications/${this.id}/mark-as-read`);
    }
}
