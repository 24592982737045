<script>
export default {
    name: 'AuthLayout',
};
</script>

<template>
    <section class="pl-auth-layout">
        <div class="container">
            <slot />
        </div>
    </section>
</template>

<style lang="scss" scoped>
.pl-auth-layout {
    display: flex;
    flex-direction: column;
    height: 100%;

    & > .container {
        max-width: 400px;
        margin-top: auto;
        margin-bottom: auto;
        padding-top: custom-space(2);
        padding-bottom: custom-space(2);
    }
}
</style>
