import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

/**
 * @return {dayjs.Dayjs}
 */
export const getCurrentDate = () => dayjs().startOf('day');

const today = getCurrentDate();

/**
 * @param date {dayjs.Dayjs || string}
 * @param targetDate {dayjs.Dayjs || string}
 *
 * @return {boolean}
 */
export const isDateSame = (date, targetDate) => dayjs(date).isSame(targetDate);

/**
 * @param date {dayjs.Dayjs || string}
 *
 * @return {boolean}
 */
export const isYesterday = (date) => {
    const currentDate = getCurrentDate();

    return isDateSame(currentDate.subtract(1, 'day'), date);
};

/**
 * @param date {dayjs.Dayjs || string}
 *
 * @return {boolean}
 */
export const isToday = (date) => isDateSame(date, today);

/**
 * @param date {dayjs.Dayjs || string}
 * @param targetDate {dayjs.Dayjs || string}
 *
 * @return {boolean}
 */
export const isDateBefore = (date, targetDate = today) => dayjs(date).isBefore(targetDate);

/**
 * @param date {dayjs.Dayjs || string}
 * @param targetDate {dayjs.Dayjs || string}
 *
 * @return {boolean}
 */
export const isDateAfter = (date, targetDate = today) => dayjs(date).isAfter(targetDate);

/**
 * @param date {dayjs.Dayjs || string}
 * @param targetDate {dayjs.Dayjs || string}
 *
 * @return {boolean}
 */
export const isDateSameOrBefore = (date, targetDate = today) =>
    isDateSame(date, targetDate) || isDateBefore(date, targetDate);

/**
 * @param date {dayjs.Dayjs || string}
 * @param targetDate {dayjs.Dayjs || string}
 *
 * @return {boolean}
 */
export const isDateSameOrAfter = (date, targetDate = today) =>
    isDateSame(date, targetDate) || isDateAfter(date, targetDate);

/**
 * @param date {dayjs.Dayjs || string}
 * @param format {string}
 *
 * @return {string}
 */
export const formatDate = (date = today, format = 'YYYY-MM-DD') => dayjs(date).format(format);

/**
 * @param date {dayjs.Dayjs || string}
 * @param withTime {boolean}
 *
 * @return {string}
 */
export const getHumanReadableDate = (date, withTime = true) => {
    const dateObj = dayjs(date);
    const time = dateObj.format('HH:mm');

    const formatTime = (label) => (withTime ? `${label} at ${time}` : label);

    if (isYesterday(dateObj)) {
        return formatTime('yesterday');
    }

    if (isToday(dateObj)) {
        return formatTime('today');
    }

    return dateObj.fromNow();
};

/**
 * @return {string || null}
 */
export const getCurrentTimezone = () => {
    // DEV NOTE: fix old timezone names
    const timezoneMap = {
        'Europe/Kiev': 'Europe/Kyiv',
    };

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    return timezoneMap[timeZone] || timeZone || null;
};
