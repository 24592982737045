import ApiClient from '@/plugins/ApiClient';
import Model from './Model';

export default class Station extends Model {
    static all(orgId, query = {}) {
        return this.withAbort(ApiClient.get(`organizations/${orgId}/stations`, { params: query }), ({ data }) =>
            this.make(data)
        );
    }

    static async create(orgId, attributes) {
        const { data } = await ApiClient.post(`organizations/${orgId}/stations`, attributes);

        return this.make(data);
    }

    static async update(orgId, stationId, attributes) {
        const { data } = await ApiClient.put(`organizations/${orgId}/stations/${stationId}`, attributes);

        return this.make(data);
    }

    async delete(orgId) {
        const { data } = await ApiClient.delete(`organizations/${orgId}/stations/${this.id}`);

        return Station.make(data);
    }
}
