<script>
export default {
    name: 'ReassignModal',
};
</script>

<script setup>
import { reactive, computed, onBeforeMount } from 'vue';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { getBatchUnitQtyDisplay } from '@/utils/batchUnitsUtils';
import {
    constructAssigneeOptions,
    constructAssigneeSelectedOption,
    getOpenItemsOptionData,
} from '@/utils/selectableItemsUtils';
import { DISTRIBUTION_MULTIPLE_OPTION, DISTRIBUTION_TYPES } from '@/enums/selectableItemsEnums';
import { ORDER_ITEM_REASSIGN_TYPES, ORDER_ITEM_REASSIGN_TYPES_OPTIONS } from '@/enums/orderItemEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import PLOrderModel from '@/models/PLOrder';
import useAbortableRequest from '@/composition/useAbortableRequest';
import Modal from '@/components/Modal';
import ItemDistributionModal from '@/components/model-specific/prep-lists/item-distribution-modal';
import SelectInput from '@/components/form-controls/SelectInput';

const props = defineProps({
    type: {
        type: String,
        required: true,
    },
    orderId: {
        type: Number,
        required: true,
    },
    assigneeId: {
        type: [Number, String],
        required: true,
    },
    departmentId: {
        type: Number,
        required: true,
    },
});

const emit = defineEmits(['close', 'refresh-order']);

const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount?.organization.id;

/*-----------------------------------------------------------------------------------
                                    Reassign state
-----------------------------------------------------------------------------------*/

const reassignState = reactive({
    data: null,
    selectedAssignees: null,
    isDataLoading: true,
    isActionProcessing: false,
});

const getAssigneeArg = () => {
    const { assigneeId } = props;

    if (assigneeId === DISTRIBUTION_TYPES.OPEN_ITEMS) {
        return 'open-items';
    }

    return assigneeId;
};

const setInitialSelectedAssignees = (data) => {
    const { assigneeId } = props;

    if (props.type === ORDER_ITEM_REASSIGN_TYPES.ALL) {
        reassignState.selectedAssignees = null;
    } else {
        reassignState.selectedAssignees = data.reduce((acc, item) => {
            const { id } = item;

            acc[id] = {
                item_id: id,
                distribution: [{ assignee_id: assigneeId }],
                distribution_type:
                    assigneeId === DISTRIBUTION_TYPES.OPEN_ITEMS
                        ? DISTRIBUTION_TYPES.OPEN_ITEMS
                        : DISTRIBUTION_TYPES.DIRECT,
            };

            return acc;
        }, {});
    }
};

const loadReassignStateData = () => {
    sendAbortableRequest(PLOrderModel.getReassignState(orgId, props.orderId, props.departmentId, getAssigneeArg()))
        .then((response) => {
            if (response !== null) {
                const { data } = response;

                reassignState.data = data;

                setInitialSelectedAssignees(data);
            }
        })
        .finally(() => (reassignState.isDataLoading = false));
};

const isSubmittingDisabled = computed(() => {
    const { type, assigneeId } = props;
    const { isActionProcessing, isDataLoading, selectedAssignees } = reassignState;

    if (isActionProcessing || isDataLoading) {
        return true;
    }

    if (type === ORDER_ITEM_REASSIGN_TYPES.ALL) {
        return selectedAssignees === null;
    }

    if (assigneeId === DISTRIBUTION_TYPES.OPEN_ITEMS) {
        return Object.values(selectedAssignees).every(
            (assignee) => assignee.distribution_type === DISTRIBUTION_TYPES.OPEN_ITEMS
        );
    }

    return Object.values(selectedAssignees).every(({ distribution, distribution_type: distributionType }) => {
        if (assigneeId === DISTRIBUTION_TYPES.OPEN_ITEMS) {
            return distributionType === DISTRIBUTION_TYPES.OPEN_ITEMS;
        }

        return distributionType === DISTRIBUTION_TYPES.DIRECT && distribution[0].assignee_id === assigneeId;
    });
});

const getRemainingQtyDisplay = (item) => {
    const { units_data: unitsData } = item;

    const remainingBYQty = item.ordered_BY_qty - item.produced_BY_qty;

    return getBatchUnitQtyDisplay(
        unitsData,
        unitsData.batch_yield_unit,
        unitsData.prep_list_ordering_unit,
        remainingBYQty
    );
};

const getPayload = () => {
    const { type, assigneeId } = props;
    const { selectedAssignees } = reassignState;

    if (selectedAssignees === DISTRIBUTION_TYPES.OPEN_ITEMS) {
        return { type: 'all_items_to_open_items' };
    }

    const payload = { type };

    if (type === ORDER_ITEM_REASSIGN_TYPES.SELECTED) {
        payload.items = Object.values(selectedAssignees).filter(
            ({ distribution, distribution_type: distributionType }) => {
                if (assigneeId === DISTRIBUTION_TYPES.OPEN_ITEMS) {
                    return distributionType !== DISTRIBUTION_TYPES.OPEN_ITEMS;
                }

                return distributionType !== DISTRIBUTION_TYPES.DIRECT || distribution[0].assignee_id !== assigneeId;
            }
        );
    } else {
        payload.assignee_id = selectedAssignees;
    }

    return payload;
};

const handleSubmitReassign = () => {
    if (!isSubmittingDisabled.value) {
        reassignState.isActionProcessing = true;

        PLOrderModel.setReassign(orgId, props.orderId, props.departmentId, getAssigneeArg(), getPayload())
            .then((data) => {
                emit('refresh-order', data);

                EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                    type: ALERT_TYPES.SUCCESS,
                    message: 'Order have been successfully updated',
                });
            })
            .finally(() => (reassignState.isActionProcessing = false));
    }
};

/*-----------------------------------------------------------------------------------
                            Item distribution modal state
-----------------------------------------------------------------------------------*/

const itemDistributionModalState = reactive({
    item: null,
    itemAssigneeData: null,
    isOpened: false,
});

const openDistributionModal = (item) => {
    itemDistributionModalState.isOpened = true;
    itemDistributionModalState.itemAssigneeData = reassignState.selectedAssignees[item.id];
    itemDistributionModalState.item = {
        ...item,
        ordered_BY_qty: item.ordered_BY_qty - item.produced_BY_qty,
    };
};

const closeDistributionModal = () => {
    itemDistributionModalState.isOpened = false;
    itemDistributionModalState.item = null;
    itemDistributionModalState.itemAssigneeData = null;
};

const getItemAssigneeData = (item = null) => {
    const assigneeData = item !== null ? reassignState.selectedAssignees[item.id] : reassignState.data[0];

    return {
        distributionType: assigneeData.distribution_type,
        distribution: assigneeData.distribution,
    };
};

const getSelectedAssigneeId = (item) => {
    const { distributionType, distribution } = getItemAssigneeData(item);

    return constructAssigneeSelectedOption(distributionType, distribution);
};

const getAssigneeOptions = (item = null) => {
    const { available_assignees: availableAssignees } = item || reassignState.data[0];

    if (props.type === ORDER_ITEM_REASSIGN_TYPES.ALL) {
        const options = availableAssignees
            .filter(({ id }) => props.assigneeId !== id)
            .map(({ id, name }) => ({ value: id, text: name }));

        if (props.assigneeId !== DISTRIBUTION_TYPES.OPEN_ITEMS) {
            options.push(getOpenItemsOptionData());
        }

        return options;
    }

    const { distributionType, distribution } = getItemAssigneeData(item);

    return constructAssigneeOptions(distributionType, distribution, availableAssignees);
};

const setAssignee = (value, item) => {
    const { id } = item;
    const { ordered_BY_qty: orderedBYQty } = item;

    if (value === DISTRIBUTION_MULTIPLE_OPTION) {
        openDistributionModal(item);
    } else {
        const assigneeData = reassignState.selectedAssignees[id];

        if (typeof value === 'number') {
            assigneeData.distribution_type = DISTRIBUTION_TYPES.DIRECT;
            assigneeData.distribution = [{ assignee_id: value, qty: orderedBYQty }];
        } else {
            assigneeData.distribution_type = value;
            assigneeData.distribution = [{ qty: orderedBYQty }];
        }
    }
};

const getSelectInputLabel = (item) => {
    const selectedId = getSelectedAssigneeId(item);

    return props.assigneeId === selectedId ? 'Assigned to' : 'Reassign to';
};

const setDistribution = ({ distributionType, distribution }) => {
    const { item } = itemDistributionModalState;

    const assigneeData = reassignState.selectedAssignees[item.id];

    assigneeData.distribution_type = distributionType;
    assigneeData.distribution = distribution;

    closeDistributionModal();
};

const hasAvailableAssignees = (item) => {
    const assigneeOptions = getAssigneeOptions(item);

    return assigneeOptions.filter(({ value }) => typeof value === 'number' && props.assigneeId !== value).length > 0;
};

const modalTitle = computed(() => {
    const { text } = ORDER_ITEM_REASSIGN_TYPES_OPTIONS.find(({ value }) => props.type === value);

    return `Reassign ${text}`;
});

const isLastSelectOptionMarked = computed(() => {
    if (props.type !== ORDER_ITEM_REASSIGN_TYPES.ALL) {
        return true;
    }

    return props.assigneeId !== DISTRIBUTION_TYPES.OPEN_ITEMS;
});

/*-----------------------------------------------------------------------------------
                                  Load necessary data
-----------------------------------------------------------------------------------*/

onBeforeMount(loadReassignStateData);
</script>

<template>
    <Modal
        v-if="!itemDistributionModalState.isOpened"
        disable-click-outside-mechanism
        class="pl-reassign-modal"
        :is-close-disabled="reassignState.isActionProcessing"
        @close="emit('close')"
    >
        <template #title>
            {{ modalTitle }}
        </template>

        <template #content>
            <OverlayLoader v-if="reassignState.isActionProcessing" />

            <InPlaceLoader v-if="reassignState.isDataLoading" />

            <template v-else>
                <template v-if="type === ORDER_ITEM_REASSIGN_TYPES.ALL">
                    <SelectInput
                        v-model="reassignState.selectedAssignees"
                        searchable
                        size="sm"
                        label="Reassign to"
                        :is-last-option-marked="isLastSelectOptionMarked"
                        :options="getAssigneeOptions(null)"
                    />

                    <small
                        v-if="!hasAvailableAssignees(null)"
                        class="text-info"
                    >
                        There is no available assignees in department
                    </small>
                </template>

                <template v-else>
                    <div
                        v-for="item in reassignState.data"
                        :key="item.id"
                        class="pl-reassign-card"
                    >
                        <div class="pl-reassign-card__select-box">
                            <p>
                                <span v-if="item.parent_item_name">{{ item.parent_item_name }}</span>

                                {{ item.name }}
                            </p>

                            <span>{{ getRemainingQtyDisplay(item) }}</span>
                        </div>

                        <SelectInput
                            searchable
                            size="sm"
                            :model-value="getSelectedAssigneeId(item)"
                            :is-last-option-marked="isLastSelectOptionMarked"
                            :label="getSelectInputLabel(item)"
                            :options="getAssigneeOptions(item)"
                            @update:modelValue="setAssignee($event, item)"
                        />

                        <small
                            v-if="!hasAvailableAssignees(item)"
                            class="text-info"
                        >
                            There is no available assignees in department
                        </small>
                    </div>
                </template>
            </template>
        </template>

        <template #actions>
            <BtnUI @click="emit('close')">
                Cancel
            </BtnUI>

            <BtnUI
                is-filled
                :disabled="isSubmittingDisabled"
                @click="handleSubmitReassign"
            >
                Save
            </BtnUI>
        </template>
    </Modal>

    <ItemDistributionModal
        v-else
        :item="itemDistributionModalState.item"
        :item-assignee-data="itemDistributionModalState.itemAssigneeData"
        @set-distribution="setDistribution"
        @close-modal="closeDistributionModal"
    />
</template>

<style lang="scss" scoped>
.pl-reassign-modal :deep(.pl-modal__wrapper) > section {
    max-width: custom-space(28);

    .pl-select--sm {
        max-width: 100%;
    }

    .pl-radio {
        margin-top: custom-space(1);
        padding-left: custom-space(0.75);

        .form-check {
            &:not(:last-child) {
                margin-bottom: custom-space(1);
            }

            label {
                font-size: $font-size-base;
                font-weight: $font-weight-light;
            }
        }
    }
}

.pl-reassign-card {
    padding: custom-space(0.5);
    border: 1px solid $gray-400;
    border-radius: $border-radius;

    &:not(:last-child) {
        margin-bottom: custom-space(0.5);
    }

    &__select-box {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: custom-space(1);
        margin-bottom: custom-space(0.1);

        & > p {
            display: flex;
            flex-direction: column;
            font-size: $font-size-base * 0.95;
            font-weight: $font-weight-normal;
            margin: 0;

            & > span {
                color: $primary;
                font-size: $font-size-base * 0.9;
            }
        }

        & > span {
            flex-shrink: 0;
            font-size: $font-size-base * 0.9;
        }
    }
}
</style>
