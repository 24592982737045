<script>
export default {
    name: 'RibbonUI',
};
</script>

<script setup>
defineProps({
    navigateTo: {
        type: Object,
        required: true,
    },
    text: {
        type: String,
        required: true,
    },
});
</script>

<template>
    <transition
        appear
        name="down"
        type="transition"
    >
        <router-link
            class="pl-ribbon"
            :to="navigateTo"
        >
            <slot name="icon">
                <OrderIcon
                    :width="19"
                    :height="23"
                />
            </slot>

            <span>{{ text }}</span>

            <ArrowDownIcon
                class="pl-ribbon__arrow-icon"
                :width="13"
                :height="9"
            />
        </router-link>
    </transition>
</template>

<style lang="scss">
.pl-ribbon {
    $space: custom-space(0.75);

    display: flex;
    align-items: center;
    gap: custom-space(0.75);
    min-height: 36px;
    background-color: $orange-300;
    padding: custom-space(0.4) $space;
    border-radius: 0 0 $border-radius $border-radius;
    cursor: pointer;

    @include media-breakpoint-down(lg) {
        padding: custom-space(0.4) 0;
        border-radius: 0;
        box-shadow: $space 0 0 $orange-300, -$space 0 0 $orange-300;
    }

    > span {
        flex: 1;
        color: $orange-700;
        font-size: $font-size-base * 0.8;
        font-weight: $font-weight-normal;
        line-height: $small-line-height;
    }

    > svg {
        flex-shrink: 0;
    }

    &__arrow-icon {
        transform: rotate(-90deg);

        path {
            fill: $orange-700;
        }
    }
}
</style>
