<script>
export default {
    name: 'CreateLocationView',
};
</script>

<script setup>
import { inject, onBeforeMount, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { catchUnprocessableEntity } from '@/utils/httpUtils';
import { LOCATIONS_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import LocationModel from '@/models/Location';
import LocationForm from '@/components/forms/LocationForm';

const $acl = inject('$acl');

const router = useRouter();
const store = useStore();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(LOCATIONS_RESTRICTIONS.CREATE, currentAccount)) {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to create locations.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                            Location form state
------------------------------------------------------------------------*/

const locationFormState = reactive({
    validationErrors: null,
    isSubmitting: false,
});

const submitCreateLocation = (attributes) => {
    locationFormState.isSubmitting = true;

    LocationModel.create(orgId, attributes)
        .then(() => {
            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: 'Location has been successfully created!',
            });

            router.push({ name: SETTINGS_ROUTE_NAMES.LOCATIONS.INDEX });
        })
        .catch((error) =>
            catchUnprocessableEntity(error, (errors) => {
                locationFormState.validationErrors = errors;
            })
        )
        .finally(() => (locationFormState.isSubmitting = false));
};
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.LOCATIONS.INDEX">
        <template #title>
            <h1>Create Location</h1>
        </template>

        <LocationForm
            :validation-errors="locationFormState.validationErrors"
            :is-submitting="locationFormState.isSubmitting"
            @submit="submitCreateLocation"
        />
    </TopBarLayout>
</template>
