import { PL_ROUTE_NAMES } from '@/enums/routesNameEnums';

import PLOrdersIndexMainView from '@/views/dashboard/prep-lists/index/main-view';
import PLOrdersIndexSecondaryView from '@/views/dashboard/prep-lists/index/secondary-view';

import ShowPLOrderView from '@/views/dashboard/prep-lists/show';
import ShowOverproductionPLOrderView from '@/views/dashboard/prep-lists/show/overproduction';

import CreatePLOrderFromTemplateView from '@/views/dashboard/prep-lists/create-from-template';

import CreatePLOrderIndexView from '@/views/dashboard/prep-lists/create';
import CreatePLOrderStep1View from '@/views/dashboard/prep-lists/create/step-1';
import CreatePLOrderStep2View from '@/views/dashboard/prep-lists/create/step-2';
import CreatePLOrderStep3View from '@/views/dashboard/prep-lists/create/step-3';
import CreatePLOrderStep4View from '@/views/dashboard/prep-lists/create/step-4';

import EditPLOrderIndexView from '@/views/dashboard/prep-lists/edit';
import EditPLOrderStep1View from '@/views/dashboard/prep-lists/edit/step-1';
import EditPLOrderStep2View from '@/views/dashboard/prep-lists/edit/step-2';
import EditPLOrderStep3View from '@/views/dashboard/prep-lists/edit/step-3';
import EditPLOrderStep4View from '@/views/dashboard/prep-lists/edit/step-4';

export default [
    {
        path: 'prep-lists',
        name: PL_ROUTE_NAMES.INDEX,
        component: PLOrdersIndexMainView,
    },

    {
        path: 'prep-lists/secondary-view',
        name: PL_ROUTE_NAMES.SECONDARY_VIEW,
        component: PLOrdersIndexSecondaryView,
    },

    {
        path: 'prep-lists/:orderId',
        name: PL_ROUTE_NAMES.SHOW,
        component: ShowPLOrderView,
        props: true,
    },
    {
        path: 'prep-lists/overproduction/:orderId',
        name: PL_ROUTE_NAMES.OVERPRODUCTION,
        component: ShowOverproductionPLOrderView,
        props: true,
    },

    {
        path: 'prep-lists/create-from-template',
        name: PL_ROUTE_NAMES.CREATE_FROM_TEMPLATE,
        component: CreatePLOrderFromTemplateView,
    },

    {
        path: 'prep-lists/create',
        component: CreatePLOrderIndexView,
        children: [
            {
                path: '',
                name: PL_ROUTE_NAMES.CREATE.INDEX,
                component: CreatePLOrderStep1View,
            },
            {
                path: 'add-items',
                name: PL_ROUTE_NAMES.CREATE.ADD_ITEMS,
                component: CreatePLOrderStep2View,
            },
            {
                path: 'set-assignees',
                name: PL_ROUTE_NAMES.CREATE.SET_ASSIGNEES,
                component: CreatePLOrderStep3View,
            },
            {
                path: 'review-and-confirm',
                name: PL_ROUTE_NAMES.CREATE.REVIEW_AND_CONFIRM,
                component: CreatePLOrderStep4View,
                meta: { parentProps: { enableGroupItems: true } },
            },
        ],
    },

    {
        path: 'prep-lists/:orderId/edit',
        component: EditPLOrderIndexView,
        props: true,
        children: [
            {
                path: '',
                name: PL_ROUTE_NAMES.EDIT.INDEX,
                component: EditPLOrderStep1View,
                props: true,
            },
            {
                path: 'add-items',
                name: PL_ROUTE_NAMES.EDIT.ADD_ITEMS,
                component: EditPLOrderStep2View,
                props: true,
            },
            {
                path: 'set-assignees',
                name: PL_ROUTE_NAMES.EDIT.SET_ASSIGNEES,
                component: EditPLOrderStep3View,
                props: true,
            },
            {
                path: 'review-and-confirm',
                name: PL_ROUTE_NAMES.EDIT.REVIEW_AND_CONFIRM,
                component: EditPLOrderStep4View,
                props: true,
                meta: { parentProps: { enableGroupItems: true } },
            },
        ],
    },
];
