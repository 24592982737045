import ApiClient from '@/plugins/ApiClient';
import Model from './Model';

export default class NoPrepAction extends Model {
    static all(orgId, query = {}) {
        return this.withAbort(ApiClient.get(`organizations/${orgId}/prep-actions`, { params: query }), ({ data }) =>
            this.make(data)
        );
    }
}
