<script>
export default {
    name: 'ShowQFOrderView',
};
</script>

<script setup>
import { onBeforeMount, ref, inject, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { mapGetters } from '@/utils/vuexUtils';
import { catchNotFoundError } from '@/utils/httpUtils';
import { isOrderEditing } from '@/utils/orderUtils';
import { QFO_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { QFO_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { ORDER_GROUP_ITEMS_BY } from '@/enums/orderEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import QFOrderModel from '@/models/QFOrder';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useConfirmationModal from '@/composition/useConfirmationModal';
import Alert from '@/components/Alert';
import DropDown from '@/components/DropDown';
import ConfirmationModal from '@/components/ConfirmationModal';
import GroupedSettings from '@/components/constructions/grouped-settings';
import OrderItemsWrapper from './partials/OrderItemsWrapper';

const props = defineProps({
    orderId: {
        type: [Number, String],
        required: true,
    },
});

const $acl = inject('$acl');

const store = useStore();
const router = useRouter();

const { currentAccount } = store.state.auth;
const orgId = currentAccount?.organization.id;

const { currentQFOrder } = mapGetters(['currentQFOrder']);

const { sendAbortableRequest } = useAbortableRequest();

/*-----------------------------------------------------------------------------------
                                    Order state
-----------------------------------------------------------------------------------*/

const order = ref(null);
const isDataLoading = ref(true);

const loadOrder = () => {
    isDataLoading.value = true;

    sendAbortableRequest(
        QFOrderModel.find(orgId, props.orderId, {
            group_items_by: ORDER_GROUP_ITEMS_BY.CATEGORY,
        })
    )
        .then((model) => (order.value = model))
        .finally(() => (isDataLoading.value = false));
};

const handleRefreshOrder = (freshOrder) => (order.value = freshOrder);

const orderPermissions = computed(() => ({
    update: $acl.can(QFO_RESTRICTIONS.UPDATE, currentAccount, order.value),
    delete: $acl.can(QFO_RESTRICTIONS.DELETE, currentAccount, order.value),
}));

/*------------------------------------------------------------------------
                         Confirmation modal state
------------------------------------------------------------------------*/

const { confirmationState, confirmationModal } = useConfirmationModal();

const handleOpenDeleteOrderModal = () => {
    if (order.value.has_batches_done) {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.FAIL,
            message: 'You are not allowed to delete the order, because some items are fulfilled.',
        });

        confirmationModal.close();
    } else {
        confirmationModal.open(order.value);
    }
};

const handleSuccessfulDeletion = () => {
    EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
        type: ALERT_TYPES.SUCCESS,
        message: 'Order has been successfully deleted',
    });

    router.push({ name: QFO_ROUTE_NAMES.INDEX });
};

const submitDeleteOrder = () => {
    confirmationModal
        .submit((model) => model.delete())
        .then(handleSuccessfulDeletion)
        .catch((error) => catchNotFoundError(error, handleSuccessfulDeletion));
};

/*-----------------------------------------------------------------------------------
                                    Init order editing
-----------------------------------------------------------------------------------*/

const isFormSubmitting = ref(false);

const handleInitOrderEditing = () => {
    if (currentQFOrder.value !== null) {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.WARNING,
            message: 'You currently have a pending order. Please finalize or cancel it, before editing a new one.',
        });

        if (isOrderEditing(currentQFOrder.value)) {
            router.push({
                name: QFO_ROUTE_NAMES.EDIT.INDEX,
                params: { orderId: currentQFOrder.value.parent_id },
            });
        } else {
            router.replace({ name: QFO_ROUTE_NAMES.CREATE.INDEX });
        }
    } else if (order.value.has_batches_done) {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.FAIL,
            message: 'You are not allowed to edit the order, because some items are fulfilled.',
        });
    } else {
        isFormSubmitting.value = true;

        order.value
            .createDraft()
            .then(({ data }) => {
                store.commit('setCurrentQFOrder', data);

                router.push({
                    name: QFO_ROUTE_NAMES.EDIT.INDEX,
                    params: { orderId: order.value.id },
                });
            })
            .catch((error) => {
                catchNotFoundError(error, () => {
                    EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                        type: ALERT_TYPES.FAIL,
                        message: 'The order has been deleted',
                    });

                    router.replace({ name: QFO_ROUTE_NAMES.INDEX });
                });
            })
            .finally(() => (isFormSubmitting.value = false));
    }
};

/*-----------------------------------------------------------------------------------
                                    Load necessary data
-----------------------------------------------------------------------------------*/

onBeforeMount(loadOrder);
</script>

<template>
    <TopBarLayout
        exclude-content-container
        :back-route="QFO_ROUTE_NAMES.INDEX"
    >
        <template #title>
            <h1>Order Details</h1>
        </template>

        <template
            v-if="order !== null"
            #actions
        >
            <DropDown>
                <template #content>
                    <GroupedSettings.Root>
                        <GroupedSettings.List title="Group items by">
                            <GroupedSettings.ListItem is-selected>
                                Category
                            </GroupedSettings.ListItem>
                        </GroupedSettings.List>

                        <GroupedSettings.List
                            v-if="orderPermissions.update || orderPermissions.delete"
                            title="Actions"
                        >
                            <GroupedSettings.ListItem
                                v-if="orderPermissions.update"
                                @click="handleInitOrderEditing"
                            >
                                {{ order.draft !== null ? ' Continue editing' : 'Edit order' }}
                            </GroupedSettings.ListItem>

                            <GroupedSettings.ListItem
                                v-if="orderPermissions.delete"
                                @click="handleOpenDeleteOrderModal"
                            >
                                Delete order
                            </GroupedSettings.ListItem>
                        </GroupedSettings.List>
                    </GroupedSettings.Root>
                </template>
            </DropDown>
        </template>

        <InPlaceLoader v-if="isDataLoading" />

        <div
            v-else-if="order === null"
            class="container"
        >
            <Alert :type="ALERT_TYPES.FAIL">
                Order could not be loaded for some reason. Please try again later.
            </Alert>
        </div>

        <template v-else>
            <OverlayLoader
                v-if="isFormSubmitting"
                enable-sticky
            />

            <OrderItemsWrapper
                :order="order"
                @handle-refresh-order="handleRefreshOrder"
            />

            <ConfirmationModal
                v-if="confirmationState.isOpened"
                qa-prefix="delete_item_modal"
                :is-pending="confirmationState.isPending"
                @close="confirmationModal.close"
                @submit-delete="submitDeleteOrder"
            >
                Are you sure you want to delete the order "{{ confirmationState.model.name }}"?
            </ConfirmationModal>
        </template>
    </TopBarLayout>
</template>
