<script>
export default {
    name: 'PLOrdersIndexSecondaryView',
};
</script>

<script setup>
import OrdersIndexViewHOC from '@/components/model-specific/orders/OrdersIndexViewHOC';
import TimelineSecondaryCook from './partials/TimelineSecondaryCook';
import TimelineSecondaryManager from './partials/TimelineSecondaryManager';
</script>

<template>
    <OrdersIndexViewHOC>
        <template #view-as-cook>
            <TimelineSecondaryCook />
        </template>

        <template #view-as-manager>
            <TimelineSecondaryManager />
        </template>
    </OrdersIndexViewHOC>
</template>
