<script>
export default {
    name: 'CreateQFOrderStep3View',
};
</script>

<script setup>
import { reactive, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { catchOrderPrepDateGoneError } from '@/utils/httpUtils';
import { mapGetters } from '@/utils/vuexUtils';
import { QFO_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ORDER_TYPES, ORDER_GROUP_ITEMS_BY } from '@/enums/orderEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import QFOrderModel from '@/models/QFOrder';
import useAbortableRequest from '@/composition/useAbortableRequest';
import OrderStepTemplate from '@/components/model-specific/orders/OrderStepTemplate';
import OrderSummaryCard from '@/components/model-specific/orders/order-summary-card';

const store = useStore();
const router = useRouter();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount?.organization.id;

const { currentQFOrder } = mapGetters(['currentQFOrder']);

/*------------------------------------------------------------------------
                                Order state
------------------------------------------------------------------------*/

const orderState = reactive({
    data: null,
    isDataLoading: true,
    isActionProcessing: false,
});

const loadOrder = () => {
    sendAbortableRequest(
        QFOrderModel.find(orgId, currentQFOrder.value.id, {
            group_items_by: ORDER_GROUP_ITEMS_BY.CATEGORY,
        })
    )
        .then((data) => (orderState.data = data))
        .finally(() => (orderState.isDataLoading = false));
};

const confirmOrder = () => {
    orderState.isActionProcessing = true;

    orderState.data
        .confirm()
        .then(() => {
            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: 'Order has been successfully confirmed',
            });

            router.push({
                name: QFO_ROUTE_NAMES.INDEX,
                query: { date: orderState.data.prep_date },
            });

            store.commit('setCurrentQFOrder', null);
        })
        .catch(catchOrderPrepDateGoneError)
        .finally(() => (orderState.isActionProcessing = false));
};

/*------------------------------------------------------------------------
                            Load necessary data
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (currentQFOrder.value !== null) {
        loadOrder();
    }
});
</script>

<template>
    <OrderStepTemplate :is-step-submitting="orderState.isActionProcessing">
        <template #step-content>
            <InPlaceLoader v-if="orderState.isDataLoading" />

            <OrderSummaryCard
                v-if="orderState.data !== null"
                :order="orderState.data"
                :order-type="ORDER_TYPES.QUICK_FILL"
            />
        </template>

        <template #prev-step-link>
            <router-link
                class="pl-btn"
                :to="{ name: QFO_ROUTE_NAMES.CREATE.ADD_ITEMS }"
            >
                Select Items
            </router-link>
        </template>

        <template #next-step-action>
            <BtnUI
                is-filled
                :disabled="orderState.isDataLoading || orderState.isActionProcessing"
                @click="confirmOrder"
            >
                Confirm
            </BtnUI>
        </template>
    </OrderStepTemplate>
</template>
