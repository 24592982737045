<script>
export default {
    name: 'ConfirmationModal',
};
</script>

<script setup>
import Modal from '@/components/Modal';

defineProps({
    qaPrefix: {
        type: String,
        required: true,
    },
    isPending: {
        type: Boolean,
        default: false,
    },
    submitBtnText: {
        type: String,
        default: 'Delete',
    },
    cancelBtnText: {
        type: String,
        default: 'Cancel',
    },
});

const emit = defineEmits(['close', 'submit-delete']);
</script>

<template>
    <Modal
        is-content-centered
        :is-close-disabled="isPending"
        @close="emit('close')"
    >
        <template #title>
            <slot name="title">
                Action Required
            </slot>
        </template>

        <template #content>
            <slot />
        </template>

        <template #actions>
            <BtnUI
                :data-testid="`${qaPrefix}_close_btn`"
                :disabled="isPending"
                @click="emit('close')"
            >
                {{ cancelBtnText }}
            </BtnUI>

            <BtnUI
                is-filled
                :data-testid="`${qaPrefix}_delete_btn`"
                :is-loading="isPending"
                @click="emit('submit-delete')"
            >
                {{ submitBtnText }}
            </BtnUI>
        </template>
    </Modal>
</template>
