import { computed, onBeforeMount } from 'vue';
import { useStore } from 'vuex';

import { isAdmin } from '@/utils/accountUtils';
import AccountModel from '@/models/Account';
import useAbortableRequest from '@/composition/useAbortableRequest';

const useTaskReminderRibbon = (isAllowed = true) => {
    const store = useStore();

    const { sendAbortableRequest } = useAbortableRequest();

    const { currentAccount } = store.state.auth;
    const orgId = currentAccount.organization.id;

    /*------------------------------------------------------------------------
                                  Reminder state
    ------------------------------------------------------------------------*/

    const isReminderAllowed = () => {
        const { isReminderSkipped } = store.state.onboarding;

        return isAllowed && !isReminderSkipped && isAdmin(currentAccount);
    };

    const isTaskReminderVisible = computed(() => {
        const { settings } = store.state.onboarding;

        return isReminderAllowed() && settings !== null;
    });

    onBeforeMount(() => {
        if (isReminderAllowed()) {
            store.commit('onboarding/setSettings', null);

            sendAbortableRequest(AccountModel.getOnboardingSettings(orgId)).then((data) => {
                store.commit('onboarding/setSettings', data);
            });
        }
    });

    return { isTaskReminderVisible };
};

export default useTaskReminderRibbon;
