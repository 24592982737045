<script>
export default {
    name: 'TimelineOrderItemCard',
};
</script>

<script setup>
import { computed, ref } from 'vue';

import {
    getPLOrderItemOrderedQtyDisplay,
    getPLOrderItemProducedQtyDisplay,
    getPLOrderItemProducedPercentage,
} from '@/utils/orderItemUtils';
import { BADGE_TYPES } from '@/enums/componentsEnums';
import Badge from '@/components/Badge';
import Progress from '@/components/Progress';
import TimelineCardUI from '@/components/model-specific/orders/TimelineCardUI';
import TimelineOrderItem from '@/components/model-specific/orders/TimelineOrderItem';
import OrderItemDistributionDetails from '@/components/model-specific/prep-lists/OrderItemDistributionDetails';

const props = defineProps({
    orderItem: {
        type: Object,
        required: true,
    },
});

/*------------------------------------------------------------------------
                           Prep tasks visibility
------------------------------------------------------------------------*/

const arePrepTasksVisible = ref(false);

const togglePrepTasksVisibility = () => (arePrepTasksVisible.value = !arePrepTasksVisible.value);

/*------------------------------------------------------------------------
                             Order item state
------------------------------------------------------------------------*/

const orderItemState = computed(() => {
    const { orderItem } = props;
    const { status } = orderItem;

    const isOverproduction = status === 'overproduction';
    const isOther = isOverproduction || status === 'only_reported';

    const orderedQtyDisplay = getPLOrderItemOrderedQtyDisplay(orderItem);
    const producedQtyDisplay = getPLOrderItemProducedQtyDisplay(orderItem);
    const producedPercentage = getPLOrderItemProducedPercentage(orderItem, true);

    return {
        isOverproduction,
        isOther,
        qtyDisplay: isOther ? producedQtyDisplay : orderedQtyDisplay,
        producedValue: orderItem.prep_tasks.length === 0 ? producedQtyDisplay : producedPercentage,
    };
});

/*------------------------------------------------------------------------
                                    QA
------------------------------------------------------------------------*/

const qaPrefix = 'order_item';
</script>

<template>
    <TimelineCardUI
        class="pl-timeline-order-item-card"
        :is-completed="orderItem.is_completed"
        :is-overproduction="orderItemState.isOther"
        :data-testid="qaPrefix"
    >
        <template #header-content="{ classNames }">
            <Badge
                v-if="orderItemState.isOverproduction"
                is-absolute
                size="sm"
                :type="BADGE_TYPES.ORDER_CARD"
            >
                overproduction
            </Badge>

            <div :class="classNames.name">
                <h2 :data-testid="`${qaPrefix}_name`">
                    {{ orderItem.name }}
                </h2>

                <span :data-testid="`${qaPrefix}_ordered_qty`">
                    {{ orderItemState.qtyDisplay }}
                </span>
            </div>
        </template>

        <template
            v-if="!orderItemState.isOther"
            #main-content
        >
            <div class="pl-timeline-order-item-card__progress-wrapper">
                <Progress
                    :min="0"
                    :max="orderItem.ordered_BY_qty"
                    :value="orderItem.produced_BY_qty"
                />

                <span>Done {{ orderItemState.producedValue }}</span>
            </div>
        </template>

        <template
            v-if="orderItem.prep_tasks.length > 0 || orderItem.distribution.length > 0"
            #footer-content
        >
            <template v-if="orderItem.prep_tasks.length > 0">
                <button
                    type="button"
                    class="pl-timeline-order-item-card__list-invoker"
                    :class="{
                        'pl-timeline-order-item-card__list-invoker--opened': arePrepTasksVisible,
                    }"
                    @click="togglePrepTasksVisibility"
                >
                    <span>View prep tasks ({{ orderItem.prep_tasks.length }})</span>

                    <CaretDownIcon
                        :width="8"
                        :height="6"
                    />
                </button>

                <div
                    v-if="arePrepTasksVisible"
                    class="pl-timeline-order-item-card__list-items"
                >
                    <TimelineOrderItem
                        v-for="prepTask in orderItem.prep_tasks"
                        :key="`prep-task-${prepTask.id}`"
                        is-prep-task
                        :order-item="prepTask"
                    />
                </div>
            </template>

            <OrderItemDistributionDetails
                v-if="orderItem.distribution.length > 0"
                :order-item="orderItem"
            />
        </template>
    </TimelineCardUI>
</template>

<style lang="scss" scoped>
.pl-timeline-order-item-card {
    &__progress-wrapper {
        .pl-progress {
            margin-bottom: custom-space(0.25);
        }

        span {
            font-weight: $font-weight-normal;
            font-size: $font-size-base * 0.85;
        }
    }

    &__list-invoker {
        display: flex;
        align-items: center;

        &--opened svg {
            transform: rotate(180deg);
        }

        span {
            color: $primary;
            text-transform: uppercase;
            font-weight: $font-weight-bolder;
            font-size: $font-size-base * 0.9;
            line-height: $font-size-base * 1.2;
            border-bottom: 2px solid $primary;
        }

        svg {
            fill: $primary;
            margin-left: custom-space(0.5);
        }
    }

    &__list-items {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: custom-space(0.5);

        .pl-timeline-order-item:first-child {
            border-top: 1px solid $gray-400;
        }
    }
}

.pl-timeline-card {
    &--completed,
    &--overproduction {
        :deep(.pl-timeline-order-item) {
            color: $white;
        }

        :deep(.pl-timeline-order-item__progress) {
            background-color: transparent;
            border: 1px solid $white;
        }

        :deep(.pl-order-item-distribution) {
            > button {
                span {
                    color: $white;
                    border-bottom-color: $white;
                }

                svg {
                    fill: $white;
                }
            }

            > ul {
                color: $white;
            }
        }

        .pl-timeline-card__name span,
        .pl-timeline-order-item-card__progress-wrapper span {
            color: $white;
        }

        .pl-timeline-order-item-card__list-invoker {
            span {
                color: $white;
                border-bottom-color: $white;
            }

            svg {
                fill: $white;
            }
        }
    }

    &--completed .pl-progress {
        border: 1px solid lighten($primary, 15%);
    }

    &--overproduction {
        .pl-progress {
            border: 1px solid lighten($orange, 15%);
        }

        :deep(.pl-progress__bar) {
            background: $progress-bar-bg--overproduction;
        }
    }

    &__name {
        align-items: flex-end;
        justify-content: space-between;

        h2 {
            padding-right: custom-space(1);
        }

        span {
            flex-shrink: 0;
            font-weight: $font-weight-normal;
            font-size: $font-size-base * 0.85;
        }
    }

    :deep(.pl-timeline-card__header) {
        margin-bottom: custom-space(0.25);
    }

    :deep(.pl-timeline-card__footer) {
        flex-direction: column;
        align-items: flex-start;
    }
}
</style>
