<script>
export default {
    name: 'SummaryTotal',
};
</script>

<script setup>
defineProps({
    laborTime: {
        type: String,
        default: null,
    },
    foodCost: {
        type: Number,
        default: null,
    },
    laborCost: {
        type: Number,
        default: null,
    },
    totalCost: {
        type: Number,
        default: null,
    },
    hasOnHandQty: {
        type: Boolean,
        default: false,
    },
});
</script>

<template>
    <div class="pl-summary-total">
        <h5>
            <span>Total (${{ totalCost ?? '??' }})</span>
        </h5>

        <ul>
            <li>
                <span>Prep Time</span>

                <span>{{ laborTime ?? '??h' }}</span>
            </li>

            <li>
                <span>Food Cost</span>

                <span>${{ foodCost ?? '??' }}</span>
            </li>

            <li>
                <span>Labor Cost</span>

                <span>${{ laborCost ?? '??' }}</span>
            </li>
        </ul>

        <p>
            <small>Please note the values were calculated using only data that had been specified.</small>
            <small v-if="hasOnHandQty">* relates to the specified qty on hand.</small>
        </p>
    </div>
</template>

<style lang="scss" scoped>
.pl-summary-total {
    display: flex;
    flex-direction: column;
    gap: custom-space(0.5);

    & > h5 {
        position: relative;
        text-align: center;
        margin: 0;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            display: block;
            height: 1px;
            background-image: radial-gradient(circle, $gray-500 50%, transparent 10%);
            background-position: center;
            background-size: 5px 1px;
            background-repeat: repeat-x;
            z-index: 1;
        }

        & > span {
            position: relative;
            padding: 0 custom-space(0.5);
            background-color: $white;
            z-index: 2;
        }
    }

    & > ul {
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: custom-space(0.5);
        margin-bottom: custom-space(0.5);
        padding: 0;
        list-style: none;

        & > li {
            display: flex;
            flex-direction: column;
            text-align: center;
            font-weight: $font-weight-normal;
        }
    }

    & > p {
        display: flex;
        flex-direction: column;
        gap: custom-space(0.25);

        & > small {
            color: $gray-800;
        }
    }
}
</style>
