<script>
export default {
    name: 'OnboardingStep1View',
};
</script>

<script setup>
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { getCurrentTimezone } from '@/utils/dateUtils';
import { catchForbiddenError, catchUnprocessableEntity } from '@/utils/httpUtils';
import { required, maxLength, minLength, email, minValue } from '@/utils/formValidators';
import { ONBOARDING_ROUTE_NAMES, SELECT_ACCOUNT_ROUTE_NAME } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import userModel from '@/models/User';
import useForm from '@/composition/useForm';
import OnboardingLayout from '@/components/layouts/OnboardingLayout';
import TextInput from '@/components/form-controls/TextInput';
import NumericInput from '@/components/form-controls/NumericInput';
import FormUI from '@/components/UI/FormUI';

const router = useRouter();
const store = useStore();

const { currentAccount } = store.state.auth;

/*------------------------------------------------------------------------
                                Setup form
------------------------------------------------------------------------*/

const estimatedDataKeys = ['estimated_locations_amount', 'estimated_employee_amount', 'estimated_items_amount'];

const { formState, ...form } = useForm(
    {
        name: currentAccount?.name || '',
        email: currentAccount?.email || '',
        company_name: currentAccount?.organization?.name || '',
        estimated_locations_amount: null,
        estimated_employee_amount: null,
        estimated_items_amount: null,
    },
    {
        name: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(255),
        },
        email: {
            required,
            email,
            maxLength: maxLength(255),
        },
        company_name: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(255),
        },
        estimated_locations_amount: {
            required,
            minValue: minValue(1),
        },
        estimated_employee_amount: {
            required,
            minValue: minValue(1),
        },
        estimated_items_amount: {
            required,
            minValue: minValue(1),
        },
    },
    {
        name: 'Name',
        company_name: 'Company name',
        estimated_locations_amount: 'Number of locations',
        estimated_employee_amount: 'Number of employees',
        estimated_items_amount: 'Number of items',
    }
);

const isFormSubmitting = ref(false);

const hasCompanyDataChanged = computed(() => {
    if (currentAccount === null) {
        return false;
    }

    const { settings } = store.state.onboarding;

    const dataConfig = [
        { original: currentAccount.name, current: formState.name },
        { original: currentAccount.email, current: formState.email },
        { original: currentAccount.organization.name, current: formState.company_name },
    ];

    estimatedDataKeys.forEach((key) => {
        dataConfig.push({
            original: settings[key],
            current: +formState[key],
        });
    });

    return dataConfig.some(({ original, current }) => original !== current);
});

const redirectIfForbiddenResponse = (error) => {
    catchForbiddenError(error, () => {
        store.commit('auth/setCurrentAccount', null);

        router.replace({ name: SELECT_ACCOUNT_ROUTE_NAME });
    });
};

const redirectToNextStep = () => {
    router.push({ name: ONBOARDING_ROUTE_NAMES.INDEX });
};

const submitForm = () => {
    if (!form.validate()) {
        return;
    }

    const payload = {
        ...formState,
        timezone: getCurrentTimezone(),
    };

    if (currentAccount === null) {
        isFormSubmitting.value = true;

        return userModel
            .createCompany(payload)
            .then((model) => {
                store.dispatch('reinitializeStore', model);

                redirectToNextStep();

                EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                    type: ALERT_TYPES.SUCCESS,
                    message: 'New company has been successfully created!',
                });
            })
            .catch((error) => {
                catchUnprocessableEntity(error, form.setValidationErrors);

                redirectIfForbiddenResponse(error);
            })
            .finally(() => (isFormSubmitting.value = false));
    }

    if (hasCompanyDataChanged.value) {
        isFormSubmitting.value = true;

        userModel
            .updateCompany(currentAccount.organization.id, payload)
            .then((model) => {
                store.commit('auth/setCurrentAccount', model);
                store.commit(
                    'onboarding/updateSettings',
                    estimatedDataKeys.reduce((acc, key) => ({ ...acc, [key]: +formState[key] }), {})
                );

                redirectToNextStep();

                EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                    type: ALERT_TYPES.SUCCESS,
                    message: 'The company information has been successfully updated!',
                });
            })
            .catch((error) => {
                catchUnprocessableEntity(error, form.setValidationErrors);

                redirectIfForbiddenResponse(error);
            })
            .finally(() => (isFormSubmitting.value = false));
    } else {
        redirectToNextStep();
    }
};

const submitBtnText = computed(() => {
    if (currentAccount === null) {
        return 'create a new company';
    }

    if (hasCompanyDataChanged.value) {
        return 'update';
    }

    return 'continue';
});

watch(
    () => store.state.onboarding.settings,
    (settings) => {
        if (settings !== null && currentAccount !== null) {
            estimatedDataKeys.forEach((key) => {
                formState[key] = settings[key];
            });
        }
    },
    { immediate: true }
);
</script>

<template>
    <OnboardingLayout
        title="Create company"
        subtitle="Please enter your information to create a new company"
        :is-submitting="isFormSubmitting"
    >
        <FormUI
            disable-animation
            data-test-id="organization_form"
            :is-loading="isFormSubmitting"
            @submit="submitForm"
        >
            <template #content="{ classNames, qaPrefix }">
                <div :class="classNames.spacerMd">
                    <TextInput
                        v-model="formState.name"
                        include-asterisk
                        label="Your name"
                        name="name"
                        autocomplete="name"
                        :data-test-id="`${qaPrefix}_name_input`"
                        :disabled="isFormSubmitting"
                        :has-error="form.hasErrors('name')"
                        @blur="form.validate('name')"
                        @update:modelValue="form.clearErrors('name')"
                    />

                    <ValidationErrors
                        v-if="form.hasErrors('name')"
                        :data-testid="`${qaPrefix}_name_errors`"
                        :errors="form.getErrors('name')"
                    />
                </div>

                <div :class="classNames.spacerMd">
                    <TextInput
                        v-model="formState.email"
                        include-asterisk
                        name="email"
                        type="email"
                        label="Your email"
                        :data-test-id="`${qaPrefix}_email_input`"
                        :disabled="isFormSubmitting"
                        :has-error="form.hasErrors('email')"
                        @blur="form.validate('email')"
                        @update:modelValue="form.clearErrors('email')"
                    />

                    <ValidationErrors
                        v-if="form.hasErrors('email')"
                        :data-testid="`${qaPrefix}_email_error`"
                        :errors="form.getErrors('email')"
                    />
                </div>

                <div :class="classNames.spacerMd">
                    <TextInput
                        v-model="formState.company_name"
                        include-asterisk
                        label="Company name"
                        name="company-name"
                        autocomplete="company"
                        :data-test-id="`${qaPrefix}_company_name_input`"
                        :disabled="isFormSubmitting"
                        :has-error="form.hasErrors('company_name')"
                        @blur="form.validate('company_name')"
                        @update:modelValue="form.clearErrors('company_name')"
                    />

                    <ValidationErrors
                        v-if="form.hasErrors('company_name')"
                        :data-testid="`${qaPrefix}_company_name_errors`"
                        :errors="form.getErrors('company_name')"
                    />
                </div>

                <div :class="classNames.spacerMd">
                    <NumericInput
                        v-model="formState.estimated_locations_amount"
                        only-positive
                        include-asterisk
                        label="Number of Locations"
                        name="estimated_locations_amount"
                        :max="9999"
                        :data-test-id="`${qaPrefix}_estimated_locations_amount_input`"
                        :disabled="isFormSubmitting"
                        :has-error="form.hasErrors('estimated_locations_amount')"
                        @blur="form.validate('estimated_locations_amount')"
                        @update:modelValue="form.clearErrors('estimated_locations_amount')"
                    />

                    <ValidationErrors
                        v-if="form.hasErrors('estimated_locations_amount')"
                        :data-testid="`${qaPrefix}_estimated_locations_amount_errors`"
                        :errors="form.getErrors('estimated_locations_amount')"
                    />
                </div>

                <div :class="classNames.spacerMd">
                    <NumericInput
                        v-model="formState.estimated_employee_amount"
                        only-positive
                        include-asterisk
                        label="Number of Employees"
                        name="estimated_employee_amount"
                        :max="9999"
                        :data-test-id="`${qaPrefix}_estimated_employee_amount_input`"
                        :disabled="isFormSubmitting"
                        :has-error="form.hasErrors('estimated_employee_amount')"
                        @blur="form.validate('estimated_employee_amount')"
                        @update:modelValue="form.clearErrors('estimated_employee_amount')"
                    />

                    <ValidationErrors
                        v-if="form.hasErrors('estimated_employee_amount')"
                        :data-testid="`${qaPrefix}_estimated_employee_amount_errors`"
                        :errors="form.getErrors('estimated_employee_amount')"
                    />
                </div>

                <NumericInput
                    v-model="formState.estimated_items_amount"
                    only-positive
                    include-asterisk
                    label="Number of Items"
                    name="estimated_items_amount"
                    :max="9999"
                    :data-test-id="`${qaPrefix}_estimated_items_amount_input`"
                    :disabled="isFormSubmitting"
                    :has-error="form.hasErrors('estimated_items_amount')"
                    @blur="form.validate('estimated_items_amount')"
                    @update:modelValue="form.clearErrors('estimated_items_amount')"
                />

                <ValidationErrors
                    v-if="form.hasErrors('estimated_items_amount')"
                    :data-testid="`${qaPrefix}_estimated_items_amount_errors`"
                    :errors="form.getErrors('estimated_items_amount')"
                />
            </template>

            <template #actions="{ qaPrefix }">
                <BtnUI
                    is-filled
                    type="submit"
                    :data-testid="`${qaPrefix}_submit_btn`"
                    :disabled="isFormSubmitting || form.isDisabled()"
                >
                    {{ submitBtnText }}
                </BtnUI>
            </template>
        </FormUI>
    </OnboardingLayout>
</template>

<style lang="scss" scoped>
:deep(.pl-onboarding-layout__content) {
    flex-grow: 1;
}
</style>
