<script>
export default {
    name: 'EditPLPARTemplateStep3View',
};
</script>

<script setup>
import { mapGetters } from '@/utils/vuexUtils';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import PARTemplateModel from '@/models/PARTemplate';
import useSelectableItemsAssignee from '@/composition/useSelectableItemsAssignee';
import OrderStepTemplate from '@/components/model-specific/orders/OrderStepTemplate';
import SelectableItemsAssignee from '@/components/model-specific/prep-lists/selectable-items-assignee';

defineProps({
    templateId: {
        type: [String, Number],
        required: true,
    },
});

const { currentPARTemplate } = mapGetters(['currentPARTemplate']);

/*------------------------------------------------------------------------
                      Selectable items assignee state
------------------------------------------------------------------------*/

const { selectableOrderItemsState, selectableItemsAssigneeState, selectableItemsAssignee } = useSelectableItemsAssignee(
    PARTemplateModel,
    currentPARTemplate.value.id
);
</script>

<template>
    <OrderStepTemplate
        is-template
        is-edit-mode
        exclude-content-container
        :is-step-submitting="selectableItemsAssigneeState.isSubmitting"
    >
        <template #step-content>
            <SelectableItemsAssignee
                :order-items="selectableOrderItemsState.data"
                :items-assignee="selectableItemsAssigneeState.data"
                :is-data-loading="selectableOrderItemsState.isDataLoading"
                @set-assignee="selectableItemsAssignee.setAssignee"
                @set-distribution="selectableItemsAssignee.setDistribution"
                @set-qty="selectableItemsAssignee.setQty"
            />
        </template>

        <template #prev-step-link>
            <router-link
                class="pl-btn"
                :to="{
                    name: SETTINGS_ROUTE_NAMES.PAR.EDIT.PL.INDEX,
                    params: { templateId },
                }"
            >
                Select Items
            </router-link>
        </template>

        <template #next-step-action>
            <BtnUI
                is-filled
                :disabled="selectableItemsAssignee.isInvalid()"
                @click="
                    selectableItemsAssignee.submit({
                        name: SETTINGS_ROUTE_NAMES.PAR.EDIT.PL.REVIEW_AND_CONFIRM,
                        params: { templateId },
                    })
                "
            >
                Review And Confirm
            </BtnUI>
        </template>
    </OrderStepTemplate>
</template>
