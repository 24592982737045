import {
    ONBOARDING_REMINDER_CONFIG,
    ONBOARDING_REMINDER_KEY_BY_ROUTE,
    ONBOARDING_INCLUSION_KEY_BY_ROUTE,
} from '@/enums/onboardingEnums';
import AccountModel from '@/models/Account';

export default {
    namespaced: true,

    state: () => ({
        settings: null,
        currentRequest: null,
        isReminderSkipped: false,
    }),

    getters: {
        areSettingsLoaded: (state) => state.settings !== null,
    },

    mutations: {
        setSettings(state, settings) {
            state.settings = settings;

            if (settings === null) {
                state.isReminderSkipped = false;
            } else {
                state.isReminderSkipped = !ONBOARDING_REMINDER_CONFIG.some(({ name }) => {
                    const reminderKey = ONBOARDING_REMINDER_KEY_BY_ROUTE[name];
                    const inclusionKey = ONBOARDING_INCLUSION_KEY_BY_ROUTE[name];

                    return settings[reminderKey] && !settings[inclusionKey];
                });
            }
        },
        updateSettings(state, updatedSettings) {
            state.settings = {
                ...state.settings,
                ...updatedSettings,
            };
        },
        setCurrentRequest(state, request) {
            state.currentRequest = request;
        },
    },

    actions: {
        loadSettings({ state, commit, getters }, orgId) {
            if (state.currentRequest !== null) {
                return state.currentRequest;
            }

            if (!getters.areSettingsLoaded) {
                const request = AccountModel.getOnboardingSettings(orgId);

                commit('setCurrentRequest', request);

                return request
                    .then((data) => {
                        commit('setSettings', data);

                        return data;
                    })
                    .catch(() => commit('setSettings', null))
                    .finally(() => commit('setCurrentRequest', null));
            }

            return Promise.resolve(state.settings);
        },
    },
};
