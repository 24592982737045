<script>
export default {
    name: 'OnboardingIndexView',
};
</script>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { ONBOARDING_ROUTE_NAMES, PL_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import { ONBOARDING_STEPS, ONBOARDING_ESTIMATION_TIME_CONFIG } from '@/enums/onboardingEnums';
import UserModel from '@/models/User';
import ListDisplay from '@/components/ListDisplay';
import OnboardingLayout from '@/components/layouts/OnboardingLayout';

const router = useRouter();
const store = useStore();

/*------------------------------------------------------------------------
                               General state
------------------------------------------------------------------------*/

const isOnboardingCompleting = ref(false);

const completeOnboarding = () => {
    isOnboardingCompleting.value = true;

    UserModel.completeOnboarding(store.state.auth.currentAccount.organization.id)
        .then(() => {
            store.commit('auth/setOnboardingCompleted');

            router.push({ name: PL_ROUTE_NAMES.INDEX });

            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: "Congratulations! You've successfully completed the onboarding",
            });
        })
        .finally(() => (isOnboardingCompleting.value = false));
};

const isStepCompleted = (step) => {
    const { settings } = store.state.onboarding;

    if (settings === null) {
        return false;
    }

    const stateByStep = {
        [ONBOARDING_ROUTE_NAMES.CREATE_LOCATIONS]: settings.has_locations,
        [ONBOARDING_ROUTE_NAMES.INVITE_EMPLOYEES]: settings.has_employees,
        [ONBOARDING_ROUTE_NAMES.CREATE_DEPARTMENTS]: settings.has_departments,
        [ONBOARDING_ROUTE_NAMES.CREATE_ITEMS]: settings.has_items,
    };

    return stateByStep[step];
};

const getStepEstimationTime = (step) => {
    const { settings } = store.state.onboarding;

    if (settings === null) {
        return null;
    }

    const { dataKey, multiplier } = ONBOARDING_ESTIMATION_TIME_CONFIG[step];

    const time = Math.ceil((settings[dataKey] * multiplier) / 60);

    return `Est. time: ${time}m`;
};
</script>

<template>
    <OnboardingLayout
        title="Getting started"
        subtitle="Tell us more about your company to start using Prep List"
        note="All steps are optional. You will be able to fill in the missing information later in the app."
        :is-submitting="isOnboardingCompleting"
    >
        <div class="pl-onboarding">
            <OverlayLoader v-if="isOnboardingCompleting" />

            <ListDisplay
                has-borders
                data-testid="onboarding_step_list"
                :items="ONBOARDING_STEPS"
            >
                <template #item="{ item: step }">
                    <router-link
                        class="pl-onboarding-step-link"
                        :to="{ name: step.name }"
                    >
                        <p>
                            <b>{{ step.title }}</b>
                            <span>{{ getStepEstimationTime(step.name) }}</span>
                        </p>

                        <CheckIcon
                            v-if="isStepCompleted(step.name)"
                            class="pl-onboarding-step-link__check-icon"
                            :width="16"
                            :height="16"
                        />

                        <ArrowDownIcon
                            v-else
                            class="pl-onboarding-step-link__arrow-icon"
                            :width="13"
                            :height="8"
                        />
                    </router-link>
                </template>
            </ListDisplay>

            <BtnUI
                is-filled
                data-testid="onboarding_complete_btn"
                :disabled="isOnboardingCompleting"
                @click="completeOnboarding"
            >
                complete onboarding
            </BtnUI>
        </div>
    </OnboardingLayout>
</template>

<style lang="scss" scoped>
:deep(.pl-onboarding-layout__content) {
    flex-grow: 1;
}

.pl-onboarding {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: custom-space(2);
    width: 100%;

    > :deep(.pl-list-display > .pl-list-display__item) {
        padding: 0;
    }
}

.pl-onboarding-step-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: custom-space(2);
    padding-top: custom-space(0.75);
    padding-bottom: custom-space(0.75);

    > p {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        margin: 0;
        color: $gray-900;
        line-height: 1.25;

        > b {
            font-weight: $font-weight-normal;
        }

        > span {
            font-size: $font-size-base * 0.9;
        }
    }

    > svg {
        flex-shrink: 0;
    }

    &__arrow-icon {
        transform: rotate(-90deg);
    }

    &__check-icon > :deep(path) {
        fill: $success;
    }
}
</style>
