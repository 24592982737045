<script>
export default {
    name: 'SupplierForm',
};
</script>

<script setup>
import { computed, watch } from 'vue';

import { required, maxLength, minLength, email } from '@/utils/formValidators';
import useForm from '@/composition/useForm';
import TextInput from '@/components/form-controls/TextInput';
import FormUI from '@/components/UI/FormUI';

const props = defineProps({
    name: {
        type: String,
        default: '',
    },
    email: {
        type: String,
        default: '',
    },
    validationErrors: {
        type: Object,
        default: null,
    },
    isDataLoading: {
        type: Boolean,
        default: false,
    },
    isSubmitting: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['submit']);

/*------------------------------------------------------------------------
                                Setup form
------------------------------------------------------------------------*/

const { formState, ...form } = useForm(
    {
        name: '',
        email: '',
    },
    {
        name: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(255),
        },
        email: {
            email,
            maxLength: maxLength(255),
        },
    },
    {
        name: 'Supplier name',
    }
);

const submitForm = () => {
    if (form.validate()) {
        emit('submit', formState);
    }
};

const isLoading = computed(() => props.isDataLoading || props.isSubmitting);

watch(() => props.validationErrors, form.setValidationErrors);

watch(
    () => props.isDataLoading,
    (isDataLoading) => {
        if (!isDataLoading) {
            formState.name = props.name;
            formState.email = props.email;
        }
    },
    { immediate: true }
);
</script>

<template>
    <FormUI
        data-test-id="supplier_form"
        :is-loading="isLoading"
        @submit="submitForm"
    >
        <template #content="{ classNames, qaPrefix }">
            <div :class="classNames.spacerMd">
                <TextInput
                    v-model="formState.name"
                    include-asterisk
                    label="Supplier name"
                    name="name"
                    :data-test-id="`${qaPrefix}_name_input`"
                    :disabled="isLoading"
                    :has-error="form.hasErrors('name')"
                    @blur="form.validate('name')"
                    @update:modelValue="form.clearErrors('name')"
                />

                <ValidationErrors
                    v-if="form.hasErrors('name')"
                    :data-testid="`${qaPrefix}_name_errors`"
                    :errors="form.getErrors('name')"
                />
            </div>

            <TextInput
                v-model="formState.email"
                label="Email"
                name="email"
                autocomplete="email"
                :data-test-id="`${qaPrefix}_email_input`"
                :disabled="isLoading"
                :has-error="form.hasErrors('email')"
                @blur="form.validate('email')"
                @update:modelValue="form.clearErrors('email')"
            />

            <ValidationErrors
                v-if="form.hasErrors('email')"
                :data-testid="`${qaPrefix}_email_errors`"
                :errors="form.getErrors('email')"
            />
        </template>

        <template #actions="{ qaPrefix }">
            <BtnUI
                is-filled
                type="submit"
                :data-testid="`${qaPrefix}_submit_btn`"
                :disabled="isLoading || form.isDisabled()"
            >
                Save
            </BtnUI>
        </template>
    </FormUI>
</template>
