<script>
export default {
    name: 'CreateDepartmentView',
};
</script>

<script setup>
import { inject, onBeforeMount, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { catchUnprocessableEntity } from '@/utils/httpUtils';
import { DEPARTMENTS_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import DepartmentModel from '@/models/Department';
import DepartmentForm from '@/components/forms/DepartmentForm';

const $acl = inject('$acl');

const router = useRouter();
const store = useStore();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(DEPARTMENTS_RESTRICTIONS.CREATE, currentAccount)) {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to create departments.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                           Department form state
------------------------------------------------------------------------*/

const departmentFormState = reactive({
    validationErrors: null,
    isSubmitting: false,
});

const submitCreateDepartment = (attributes) => {
    departmentFormState.isSubmitting = true;

    DepartmentModel.create(orgId, attributes)
        .then(() => {
            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: 'Department has been successfully created',
            });

            router.push({ name: SETTINGS_ROUTE_NAMES.DEPARTMENTS.INDEX });
        })
        .catch((error) =>
            catchUnprocessableEntity(error, (errors) => {
                departmentFormState.validationErrors = errors;
            })
        )
        .finally(() => (departmentFormState.isSubmitting = false));
};
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.DEPARTMENTS.INDEX">
        <template #title>
            <h1>Create Department</h1>
        </template>

        <DepartmentForm
            :validation-errors="departmentFormState.validationErrors"
            :is-submitting="departmentFormState.isSubmitting"
            @submit="submitCreateDepartment"
        />
    </TopBarLayout>
</template>
