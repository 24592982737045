import Rule from './Rule';

export default class ACL {
    constructor() {
        this.rules = new Map();
    }

    defineRule(name, callback) {
        if (this.rules.has(name)) {
            throw new Error(`The ACL rule with the name "${name}" is already defined.`);
        }

        this.rules.set(name, new Rule(name, callback));

        return this;
    }

    can(name, ...args) {
        const rule = this.rules.get(name);

        if (!rule) {
            throw new Error(`The ACL rule "${name}" is not defined.`);
        }

        return rule.execute(args);
    }
}
