<script>
export default {
    name: 'Badge',
};
</script>

<script setup>
import { BADGE_TYPES } from '@/enums/componentsEnums';

defineProps({
    isAbsolute: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: 'md',
        validator: (propValue) => ['md', 'sm', 'xs'].includes(propValue),
    },
    type: {
        type: String,
        default: null,
        validator: (propValue) => {
            if (propValue === null) {
                return true;
            }

            return Object.values(BADGE_TYPES).includes(propValue);
        },
    },
});
</script>

<template>
    <div
        class="pl-badge"
        :class="{
            [`pl-badge--${size}`]: true,
            [`pl-badge--${type}`]: type !== null,
            'pl-badge--absolute': isAbsolute,
        }"
    >
        <slot />
    </div>
</template>

<style lang="scss" scoped>
.pl-badge {
    display: inline-flex;
    align-items: center;
    max-width: fit-content;
    background-color: $orange-300;
    padding: custom-space(0.175) custom-space(0.475);
    border-radius: custom-space(2);
    font-size: $font-size-base * 0.85;
    font-weight: $font-weight-normal;
    color: $orange-700;
    line-height: 1;

    &--sm {
        font-size: $font-size-base * 0.75;
    }

    &--xs {
        font-size: $font-size-base * 0.625;
    }

    &--absolute {
        position: absolute;
        top: 0;
        right: 0;

        &.pl-badge--item-card {
            top: custom-space(-0.8);
            right: custom-space(-0.8);
            border-radius: 0 custom-space(0.4);
        }

        &.pl-badge--order-card {
            top: -1px;
            left: custom-space(0.75);
            right: auto;
            border-radius:  0 0 custom-space(0.4) custom-space(0.4);
        }

        &.pl-badge--par {
            $space: custom-space(0.25);

            right: auto;
            left: calc(100% + $space);
        }
    }

    &--rounded {
        padding: custom-space(0.125) custom-space(0.4);
    }

    &--order-card {
        border-radius: custom-space(1.25);

        &:not(:first-child) {
            margin-left: custom-space(0.25);
        }
    }

    &--par {
        color: $primary;
        background-color: $secondary;
        max-width: max-content;
        width: 100%;
        padding: custom-space(0.125) custom-space(0.475);
        border-radius: custom-space(1.25);
    }
}
</style>
