<script>
export default {
    name: 'ItemCardUI',
};
</script>

<script setup>
import { useSlots } from 'vue';

const slots = useSlots();

/*------------------------------------------------------------------------
                                Class names
------------------------------------------------------------------------*/

const rootClassName = 'pl-item-card';

const classNames = {
    infoList: `${rootClassName}__info-list`,
    stationsList: `${rootClassName}__stations-list`,
};
</script>

<template>
    <article :class="rootClassName">
        <div :class="`${rootClassName}__content`">
            <slot
                name="content"
                :classNames="classNames"
            />
        </div>

        <footer v-if="slots['footer']">
            <slot name="footer" />
        </footer>
    </article>
</template>

<style lang="scss">
.pl-item-card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: custom-space(1);

    &__content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;

        > b {
            color: $primary;
        }
    }

    &__info-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0 custom-space(0.3);
        padding: 0;
        list-style: none;
        font-size: $font-size-base * 0.875;
        line-height: 1.35;

        &:not(:last-child) {
            margin-bottom: space(1);
        }

        & > li {
            display: flex;
            align-items: center;
            gap: custom-space(0.3);

            &:not(:first-child)::before {
                color: $primary;
                content: '\2022';
            }
        }
    }

    &__stations-list > li > span:not(:last-child):after {
        content: ',';
        display: inline;
    }

    > footer {
        flex: 0 0 auto;
    }
}
</style>
