<script>
export default {
    name: 'ConfigurationOfItemIndexView',
};
</script>

<script setup>
import { computed, inject, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { catchNotFoundError } from '@/utils/httpUtils';
import { COI_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import { ITEM_CONFIGURATION_ENTITIES_NAME } from '@/enums/itemEnums';
import useItemConfiguration from '@/composition/useItemConfiguration';
import useConfirmationModal from '@/composition/useConfirmationModal';
import Accordion from '@/components/Accordion';
import ConfirmationModal from '@/components/ConfirmationModal';
import ListDisplay from '@/components/ListDisplay';
import Tooltip from '@/components/Tooltip';
import Alert from '@/components/Alert';
import SimpleEntityFormModal from '@/components/forms/SimpleEntityFormModal';
import CreateItemEntityFab from './partials/CreateItemEntityFab';

const $acl = inject('$acl');

const router = useRouter();
const store = useStore();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(COI_RESTRICTIONS.VIEW, currentAccount)) {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.FAIL,
            message: "You do not have access to item's configuration.",
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                            Item configuration
------------------------------------------------------------------------*/

const entitiesQueryParams = {
    [ITEM_CONFIGURATION_ENTITIES_NAME.CATEGORY]: {
        with_categories: true,
    },
};

const {
    itemConfigurationState,
    loadItemConfigurationState,

    activeConfigurationEntity,
    setActiveEntityConfiguration,
    updateItemEntityState,

    itemEntityFormState,
    openItemEntityForm,
    closeItemEntityForm,
    submitItemEntityForm,
} = useItemConfiguration(entitiesQueryParams, true);

const permissions = computed(() => ({
    create: $acl.can(COI_RESTRICTIONS.CREATE, currentAccount),
    update: $acl.can(COI_RESTRICTIONS.UPDATE, currentAccount),
    delete: $acl.can(COI_RESTRICTIONS.DELETE, currentAccount),
}));

/*------------------------------------------------------------------------
                         Confirmation modal state
------------------------------------------------------------------------*/

const { confirmationState, confirmationModal } = useConfirmationModal();

const openEntityDeletionModal = (entityModel, entityName) => {
    confirmationModal.open(entityModel);

    setActiveEntityConfiguration(entityName);
};

const notifySuccessfulDeletion = () => {
    EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
        type: ALERT_TYPES.SUCCESS,
        message: `The ${activeConfigurationEntity.value} has been successfully deleted`,
    });
};

const submitDeleteEntity = () => {
    confirmationModal
        .submit((model) =>
            model.delete(orgId, {
                ...(entitiesQueryParams[activeConfigurationEntity.value] || {}),
                'on_demand[]': 'can_be_deleted',
            })
        )
        .then((modelData) => {
            notifySuccessfulDeletion();
            updateItemEntityState(modelData);
        })
        .catch((error) =>
            catchNotFoundError(error, () => {
                loadItemConfigurationState().then(notifySuccessfulDeletion);
            })
        );
};

/*------------------------------------------------------------------------
                                    QA
------------------------------------------------------------------------*/

const qaPrefix = 'configuration_of_item';
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.INDEX">
        <template #title>
            <h1>Item's Configuration</h1>
        </template>

        <OverlayLoader
            v-if="itemConfigurationState.isDataLoading"
            enable-sticky
        />

        <transition
            appear
            name="down"
            type="transition"
            mode="out-in"
        >
            <Alert
                v-if="itemConfigurationState.hasError"
                :type="ALERT_TYPES.FAIL"
            >
                Item's configuration could not be loaded for some reason. Please try again later.
            </Alert>

            <div
                v-else
                class="pl-configuration-of-item__wrapper"
            >
                <Accordion
                    title="Categories"
                    :data-test-id="`${qaPrefix}_categories`"
                >
                    <template v-if="itemConfigurationState.departments.length > 0">
                        <div
                            v-for="department in itemConfigurationState.departments"
                            :key="`department: ${department.id}`"
                            class="pl-configuration-of-item__list"
                        >
                            <h6>
                                {{ department.name }}
                            </h6>

                            <ListDisplay
                                has-borders
                                data-testid="categories_list"
                                :items="department.categories"
                            >
                                <template #item="{ item: category }">
                                    <div class="pl-item-entity-configuration">
                                        <h6>
                                            {{ category.name }}
                                        </h6>

                                        <div
                                            v-if="!category.is_default && (permissions.update || permissions.delete)"
                                            class="pl-item-entity-configuration__actions"
                                        >
                                            <button
                                                v-if="permissions.update"
                                                type="button"
                                                @click.stop="
                                                    openItemEntityForm(
                                                        ITEM_CONFIGURATION_ENTITIES_NAME.CATEGORY,
                                                        category
                                                    )
                                                "
                                            >
                                                <EditIcon />
                                            </button>

                                            <template v-if="permissions.delete">
                                                <button
                                                    v-if="category.can_be_deleted"
                                                    type="button"
                                                    data-testid="category_delete_btn"
                                                    @click.stop="
                                                        openEntityDeletionModal(
                                                            category,
                                                            ITEM_CONFIGURATION_ENTITIES_NAME.CATEGORY
                                                        )
                                                    "
                                                >
                                                    <DeleteIcon />
                                                </button>

                                                <Tooltip
                                                    v-else
                                                    enable-transparency
                                                    text="You can't delete this category while some items are still associated with it."
                                                >
                                                    <DeleteIcon />
                                                </Tooltip>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                            </ListDisplay>
                        </div>
                    </template>

                    <p
                        v-else
                        data-testid="categories_list_empty_note"
                    >
                        List is empty.
                    </p>
                </Accordion>

                <Accordion
                    title="Batch Units"
                    :data-test-id="`${qaPrefix}_batch_units`"
                >
                    <ListDisplay
                        v-if="itemConfigurationState.batchUnits.length > 0"
                        has-borders
                        class="pl-configuration-of-item__list"
                        data-testid="batch_units_list"
                        :items="itemConfigurationState.batchUnits"
                    >
                        <template #item="{ item: batchUnit }">
                            <div class="pl-item-entity-configuration">
                                <h6>
                                    {{ batchUnit.text }}
                                </h6>

                                <div
                                    v-if="permissions.update || permissions.delete"
                                    class="pl-item-entity-configuration__actions"
                                >
                                    <button
                                        v-if="permissions.update"
                                        type="button"
                                        @click.stop="
                                            openItemEntityForm(ITEM_CONFIGURATION_ENTITIES_NAME.UNIT, batchUnit)
                                        "
                                    >
                                        <EditIcon />
                                    </button>

                                    <template v-if="permissions.delete">
                                        <button
                                            v-if="batchUnit.can_be_deleted"
                                            type="button"
                                            data-testid="batch_unit_delete_btn"
                                            @click.stop="
                                                openEntityDeletionModal(
                                                    batchUnit,
                                                    ITEM_CONFIGURATION_ENTITIES_NAME.UNIT
                                                )
                                            "
                                        >
                                            <DeleteIcon />
                                        </button>

                                        <Tooltip
                                            v-else
                                            enable-transparency
                                            text="You can't delete this unit while some items are still associated with it."
                                        >
                                            <DeleteIcon />
                                        </Tooltip>
                                    </template>
                                </div>
                            </div>
                        </template>
                    </ListDisplay>

                    <p
                        v-else
                        data-testid="batch_units_list_empty_note"
                    >
                        List is empty.
                    </p>
                </Accordion>

                <Accordion
                    title="Stations"
                    :data-test-id="`${qaPrefix}_stations`"
                >
                    <ListDisplay
                        v-if="itemConfigurationState.stations.length > 0"
                        has-borders
                        class="pl-configuration-of-item__list"
                        data-testid="stations_list"
                        :items="itemConfigurationState.stations"
                    >
                        <template #item="{ item: station }">
                            <div class="pl-item-entity-configuration">
                                <h6>
                                    {{ station.name }}
                                </h6>

                                <div
                                    v-if="permissions.update || permissions.delete"
                                    class="pl-item-entity-configuration__actions"
                                >
                                    <button
                                        v-if="permissions.update"
                                        type="button"
                                        @click.stop="
                                            openItemEntityForm(ITEM_CONFIGURATION_ENTITIES_NAME.STATION, station)
                                        "
                                    >
                                        <EditIcon />
                                    </button>

                                    <template v-if="permissions.delete">
                                        <button
                                            v-if="station.can_be_deleted"
                                            type="button"
                                            data-testid="station_delete_btn"
                                            @click.stop="
                                                openEntityDeletionModal(
                                                    station,
                                                    ITEM_CONFIGURATION_ENTITIES_NAME.STATION
                                                )
                                            "
                                        >
                                            <DeleteIcon />
                                        </button>

                                        <Tooltip
                                            v-else
                                            enable-transparency
                                            text="You can't delete this station while some items are still associated with it."
                                        >
                                            <DeleteIcon />
                                        </Tooltip>
                                    </template>
                                </div>
                            </div>
                        </template>
                    </ListDisplay>

                    <p
                        v-else
                        data-testid="stations_list_empty_note"
                    >
                        List is empty.
                    </p>
                </Accordion>
            </div>
        </transition>

        <CreateItemEntityFab
            v-if="permissions.create && !itemConfigurationState.isDataLoading"
            @set-active-entity="openItemEntityForm"
        />

        <SimpleEntityFormModal
            v-if="itemEntityFormState.isModalOpened"
            is-edit-mode-allowed
            :departments="itemConfigurationState.departments"
            :entity-name="activeConfigurationEntity"
            :form-data="itemEntityFormState.entityData"
            :validation-errors="itemEntityFormState.validationErrors"
            :is-submitting="itemEntityFormState.isSubmitting"
            @submit="submitItemEntityForm"
            @close="closeItemEntityForm"
        />

        <ConfirmationModal
            v-if="confirmationState.isOpened"
            qa-prefix="delete_item_configuration_entity_modal"
            :is-pending="confirmationState.isPending"
            @close="confirmationModal.close"
            @submit-delete="submitDeleteEntity"
        >
            Are you sure you want to delete "{{ confirmationState.model.name || confirmationState.model.value }}" from
            the company?
        </ConfirmationModal>
    </TopBarLayout>
</template>

<style lang="scss" scoped>
.pl-configuration-of-item {
    &__wrapper .pl-accordion {
        margin-bottom: custom-space(0.25);

        &__content > p {
            text-align: center;
            margin-bottom: 0;
        }

        &:last-child {
            margin-bottom: custom-space(4);
        }
    }

    &__list {
        & > h6 {
            color: $primary;
            margin-bottom: custom-space(0.25);
        }

        &:not(:last-child) {
            margin-bottom: custom-space(1.75);
        }
    }
}

.pl-item-entity-configuration {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: custom-space(0.5);

    & > h6 {
        flex-grow: 1;
        margin-bottom: 0;
        font-weight: $font-weight-normal;
    }

    &__actions {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        gap: custom-space(0.75);
    }
}
</style>
