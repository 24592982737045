<script>
export default {
    name: 'ThreeDotsContentManager',
};
</script>

<script setup>
import { useRoute } from 'vue-router';

import { PL_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { TIMELINE_VIEW_MODES } from '@/enums/timelineEnums';
import GroupedSettings from '@/components/constructions/grouped-settings';

defineProps({
    viewMode: {
        type: String,
        required: true,
    },
});

const route = useRoute();
</script>

<template>
    <GroupedSettings.Root class="pl-three-dots-content-manager">
        <GroupedSettings.List title="View mode">
            <GroupedSettings.ListItem
                :is-selected="
                    viewMode === TIMELINE_VIEW_MODES.MAIN && route.query.viewmode !== TIMELINE_VIEW_MODES.AS_COOK
                "
            >
                <router-link
                    :to="{
                        name: PL_ROUTE_NAMES.INDEX,
                        query: { date: route.query.date },
                    }"
                >
                    Assigned to others (by order)
                </router-link>
            </GroupedSettings.ListItem>

            <GroupedSettings.ListItem
                :is-selected="
                    viewMode === TIMELINE_VIEW_MODES.SECONDARY && route.query.viewmode !== TIMELINE_VIEW_MODES.AS_COOK
                "
            >
                <router-link
                    :to="{
                        name: PL_ROUTE_NAMES.SECONDARY_VIEW,
                        query: { date: route.query.date },
                    }"
                >
                    Assigned to others (by item)
                </router-link>
            </GroupedSettings.ListItem>

            <GroupedSettings.ListItem
                :is-selected="
                    viewMode === TIMELINE_VIEW_MODES.MAIN && route.query.viewmode === TIMELINE_VIEW_MODES.AS_COOK
                "
            >
                <router-link
                    :to="{
                        name: PL_ROUTE_NAMES.INDEX,
                        query: {
                            viewmode: TIMELINE_VIEW_MODES.AS_COOK,
                            date: route.query.date,
                        },
                    }"
                >
                    Assigned to me (by item)
                </router-link>
            </GroupedSettings.ListItem>

            <GroupedSettings.ListItem
                :is-selected="
                    viewMode === TIMELINE_VIEW_MODES.SECONDARY && route.query.viewmode === TIMELINE_VIEW_MODES.AS_COOK
                "
            >
                <router-link
                    :to="{
                        name: PL_ROUTE_NAMES.SECONDARY_VIEW,
                        query: {
                            viewmode: TIMELINE_VIEW_MODES.AS_COOK,
                            date: route.query.date,
                        },
                    }"
                >
                    Assigned to me (by order)
                </router-link>
            </GroupedSettings.ListItem>
        </GroupedSettings.List>

        <GroupedSettings.List title="Orders">
            <GroupedSettings.ListItem>
                <router-link
                    :to="{
                        name: PL_ROUTE_NAMES.CREATE.INDEX,
                        query: { action: 'new-order' },
                    }"
                >
                    Create new order
                </router-link>
            </GroupedSettings.ListItem>
            <GroupedSettings.ListItem>
                <router-link :to="{ name: PL_ROUTE_NAMES.CREATE_FROM_TEMPLATE }">
                    Create new PAR-guided order
                </router-link>
            </GroupedSettings.ListItem>
        </GroupedSettings.List>
    </GroupedSettings.Root>
</template>

<style lang="scss" scoped>
.pl-three-dots-content-manager {
    width: custom-space(16);
}
</style>
