<script>
export default {
    name: 'FilterSettingsUI',
};
</script>

<script setup>
import Modal from '@/components/Modal';
import FilterIcon from '@/components/icons/FilterIcon';

defineProps({
    isModalOpened: {
        type: Boolean,
        default: false,
    },
    isAnySettingActive: {
        type: Boolean,
        default: false,
    },
    isApplyBtnDisabled: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['open', 'close', 'apply-changes']);

/*------------------------------------------------------------------------
                                    QA
------------------------------------------------------------------------*/

const qaPrefix = 'filter_settings';

/*------------------------------------------------------------------------
                                Class names
------------------------------------------------------------------------*/

const rootClassName = 'pl-filter-settings';

const classNames = {
    block: `${rootClassName}__block`,
    selectBlock: `${rootClassName}__select-block`,
    title: `${rootClassName}__title`,
    options: `${rootClassName}__options`,
    option: `${rootClassName}__option`,
    optionActive: `${rootClassName}__option--active`,
};
</script>

<template>
    <div :class="rootClassName">
        <button
            :data-testid="`${qaPrefix}_invoker_btn`"
            :class="{
                [`${rootClassName}__invoker`]: true,
                [`${rootClassName}__invoker--active`]: isAnySettingActive,
            }"
            @click="emit('open')"
        >
            <slot name="invoker-content">
                <span v-if="isAnySettingActive" />

                <FilterIcon
                    :width="21"
                    :height="21"
                />
            </slot>
        </button>

        <Modal
            v-if="isModalOpened"
            @close="emit('close')"
        >
            <template #content>
                <div :class="`${rootClassName}__content`">
                    <slot
                        name="settings"
                        :classNames="classNames"
                        :qaPrefix="qaPrefix"
                    />
                </div>
            </template>

            <template #actions>
                <BtnUI
                    :class="`${rootClassName}__btn`"
                    :data-testid="`${qaPrefix}_cancel_btn`"
                    @click="emit('close')"
                >
                    Cancel
                </BtnUI>

                <BtnUI
                    is-filled
                    :class="`${rootClassName}__btn`"
                    :data-testid="`${qaPrefix}_apply_btn`"
                    :disabled="isApplyBtnDisabled"
                    @click="emit('apply-changes')"
                >
                    Apply
                </BtnUI>
            </template>
        </Modal>
    </div>
</template>

<style lang="scss">
.pl-filter-settings {
    &__invoker {
        position: relative;
        display: flex;
        align-items: center;

        &--active > span {
            position: absolute;
            top: -2px;
            right: 0;
            width: 9px;
            height: 9px;
            background-color: $orange;
            border: 1px solid $primary;
            border-radius: 50%;
        }
    }

    &__block:not(:last-of-type) {
        margin-bottom: custom-space(0.75);
    }

    &__select-block {
        margin-bottom: custom-space(0.75);

        .pl-select {
            width: 100%;
            max-width: 100%;
            color: $black;

            .form-label {
                color: $gray-700;
                font-size: $font-size-base * 0.875;
                font-weight: $font-weight-normal;
            }

            .pl-select-selected-option span {
                margin-left: custom-space(0.25);
                font-size: $font-size-base;
            }
        }
    }

    &__title {
        color: $gray-700;
        font-size: $font-size-base * 0.875;
        font-weight: $font-weight-normal;
        margin-bottom: custom-space(0.35);
    }

    &__options {
        display: flex;
        align-items: center;
        gap: custom-space(0.3);
    }

    &__option {
        flex: 0 1 50%;
        background-color: $gray-400;
        padding: custom-space(0.25) custom-space(0.75);
        text-transform: capitalize;
        font-size: $font-size-base * 0.875;
        font-weight: $font-weight-normal;
        color: $black;
        border-radius: custom-space(1.5);

        &--active {
            background-color: $primary;
            color: $white;
        }
    }

    &__btn.pl-btn {
        @include media-breakpoint-up(md) {
            padding-top: custom-space(0.75);
            padding-bottom: custom-space(0.75);
        }
    }

    .pl-checkbox {
        padding-top: custom-space(0.5);

        .form-check {
            margin: 0;
        }

        .form-check-label {
            font-size: $font-size-base * 0.95;
        }

        .form-check-input[type='checkbox'] {
            border-color: $black;
            border-radius: custom-space(0.25);
        }
    }

    .pl-modal__actions {
        @include media-breakpoint-up(md) {
            flex-direction: column-reverse;

            & > button {
                flex: auto;
                width: 100%;
            }
        }
    }
}
</style>
