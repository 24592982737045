<script>
export default {
    name: 'CreateQFOrderStep2View',
};
</script>

<script setup>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { mapGetters } from '@/utils/vuexUtils';
import { QFO_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import useSelectableItemsQty from '@/composition/useSelectableItemsQty';
import OrderStepTemplate from '@/components/model-specific/orders/OrderStepTemplate';
import SelectableItemsQty from '@/components/model-specific/orders/selectable-items-qty';

const router = useRouter();
const store = useStore();

const { selectableItemsQtyState, selectableItemsQty } = useSelectableItemsQty();

const { currentQFOrder } = mapGetters(['currentQFOrder']);

/*------------------------------------------------------------------------
                                Order state
------------------------------------------------------------------------*/

const cancelOrder = () => {
    currentQFOrder.value.cancel().finally(() => {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.SUCCESS,
            message: 'Order has been successfully cancelled',
        });

        router.push({ name: QFO_ROUTE_NAMES.INDEX });

        store.commit('setCurrentQFOrder', null);
    });
};
</script>

<template>
    <OrderStepTemplate
        exclude-content-container
        :is-cancel-process-disabled="selectableItemsQtyState.areActionsDisabled"
        @cancel-process="cancelOrder"
    >
        <template
            v-if="currentQFOrder !== null"
            #step-content
        >
            <SelectableItemsQty
                is-quick-fill
                :order-id="currentQFOrder.id"
                @update-status="selectableItemsQty.update"
            />
        </template>

        <template #next-step-action>
            <BtnUI
                is-filled
                :disabled="selectableItemsQty.isInvalid()"
                @click="router.push({ name: QFO_ROUTE_NAMES.CREATE.REVIEW_AND_CONFIRM })"
            >
                Review and Confirm
            </BtnUI>
        </template>

        <template
            v-if="!selectableItemsQtyState.isAnyItemAdded"
            #ribbon-text
        >
            Please add at least one item to your order.
        </template>
    </OrderStepTemplate>
</template>
