<script>
export default {
    name: 'App',
};
</script>

<script setup>
import { ref, onErrorCaptured, onMounted } from 'vue';
import { useStore } from 'vuex';

import AccountModel from '@/models/Account';
import FullScreenLoader from '@/components/FullScreenLoader';
import OccurredErrorModal from './partials/OccurredErrorModal';
import FlashNotifications from './partials/FlashNotifications';
import GlobalModal from './partials/GlobalModal';

const store = useStore();

/*------------------------------------------------------------------------
                           Occurred error state
------------------------------------------------------------------------*/

const occurredErrorMessage = ref(null);

onErrorCaptured((error) => {
    occurredErrorMessage.value = error?.message || 'Something went wrong...';

    return false;
});

/*------------------------------------------------------------------------
                                 App state
------------------------------------------------------------------------*/

const isAppStateLoading = ref(true);

const getLocationIdFromURL = () => {
    const params = new URLSearchParams(window.location.search);

    return +params.get('location_id');
};

const submitChangeLocation = (locationId, currentAccount) => {
    const orgId = currentAccount.organization.id;

    AccountModel.changeLocation(orgId, currentAccount.id, { location_id: locationId })
        .then(() => window.location.reload())
        .catch(() => (isAppStateLoading.value = false));
};

onMounted(() => {
    store
        .dispatch('loadAppState')
        .then((currentAccount) => {
            const locationId = getLocationIdFromURL();

            if (
                currentAccount &&
                locationId &&
                currentAccount.location.id !== locationId &&
                currentAccount.available_locations.some(({ id }) => locationId === id)
            ) {
                submitChangeLocation(locationId, currentAccount);
            } else {
                isAppStateLoading.value = false;
            }
        })
        .catch(() => {
            isAppStateLoading.value = false;

            occurredErrorMessage.value = 'The page could not be loaded...';
        });
});
</script>

<template>
    <FullScreenLoader v-if="isAppStateLoading" />

    <div
        v-else
        class="pl-app"
    >
        <OccurredErrorModal
            v-if="occurredErrorMessage !== null"
            :error-message="occurredErrorMessage"
        />

        <template v-else>
            <FullScreenLoader v-if="store.state.auth.isLogOutPending" />

            <router-view />

            <FlashNotifications />

            <GlobalModal />
        </template>
    </div>
</template>

<style lang="scss">
@import '~@/assets/sass/main.scss';
</style>
