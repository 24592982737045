import { acl } from '@/plugins/ACL';
import {
    REPORTS_RESTRICTIONS,
    ITEMS_RESTRICTIONS,
    DEPARTMENTS_RESTRICTIONS,
    LOCATIONS_RESTRICTIONS,
    SUPPLIERS_RESTRICTIONS,
    NPA_RESTRICTIONS,
    COI_RESTRICTIONS,
    PAR_RESTRICTIONS,
    ORGANIZATION_RESTRICTIONS,
} from '@/enums/restrictionsEnums';
import { QFO_ROUTE_NAMES, PL_ROUTE_NAMES, REPORTS_ROUTE_NAMES, SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';

export const MAIN_NAVIGATION_CONFIG = [
    {
        name: 'Prep Lists',
        route: PL_ROUTE_NAMES.INDEX,
    },
    {
        name: 'Quick Fill Orders',
        route: QFO_ROUTE_NAMES.INDEX,
    },
    {
        name: 'Reports',
        route: REPORTS_ROUTE_NAMES.INDEX,
        guards: [(store) => acl.can(REPORTS_RESTRICTIONS.VIEW, store.state.auth.currentAccount)],
    },
];

export const SETTINGS_NAVIGATION_CONFIG = [
    {
        name: 'People',
        route: SETTINGS_ROUTE_NAMES.PEOPLE.INDEX,
    },
    {
        name: 'Items',
        route: SETTINGS_ROUTE_NAMES.ITEMS.INDEX,
        guards: [(store) => acl.can(ITEMS_RESTRICTIONS.VIEW, store.state.auth.currentAccount)],
    },
    {
        name: 'Prep tasks',
        route: SETTINGS_ROUTE_NAMES.PREP_TASKS.INDEX,
    },
    {
        name: 'Departments',
        route: SETTINGS_ROUTE_NAMES.DEPARTMENTS.INDEX,
        guards: [(store) => acl.can(DEPARTMENTS_RESTRICTIONS.VIEW, store.state.auth.currentAccount)],
    },
    {
        name: 'Locations',
        route: SETTINGS_ROUTE_NAMES.LOCATIONS.INDEX,
        guards: [(store) => acl.can(LOCATIONS_RESTRICTIONS.VIEW, store.state.auth.currentAccount)],
    },
    {
        name: 'Suppliers',
        route: SETTINGS_ROUTE_NAMES.SUPPLIERS.INDEX,
        guards: [(store) => acl.can(SUPPLIERS_RESTRICTIONS.VIEW, store.state.auth.currentAccount)],
    },
    {
        name: 'No prep actions',
        route: SETTINGS_ROUTE_NAMES.NO_PREP_ACTIONS,
        guards: [(store) => acl.can(NPA_RESTRICTIONS.VIEW, store.state.auth.currentAccount)],
    },
    {
        name: "Item's configuration",
        route: SETTINGS_ROUTE_NAMES.CONFIGURATION_OF_ITEM,
        guards: [(store) => acl.can(COI_RESTRICTIONS.VIEW, store.state.auth.currentAccount)],
    },
    {
        name: 'PAR',
        route: SETTINGS_ROUTE_NAMES.PAR.INDEX,
        guards: [(store) => acl.can(PAR_RESTRICTIONS.VIEW, store.state.auth.currentAccount)],
    },
    {
        name: 'My profile',
        route: SETTINGS_ROUTE_NAMES.MY_PROFILE,
    },
    {
        name: 'My accounts',
        route: SETTINGS_ROUTE_NAMES.MY_ACCOUNTS,
    },
    {
        name: 'Company settings',
        route: SETTINGS_ROUTE_NAMES.COMPANY,
        guards: [(store) => acl.can(ORGANIZATION_RESTRICTIONS.UPDATE, store.state.auth.currentAccount)],
    },
];
