import ApiClient from '@/plugins/ApiClient';
import Model from './Model';

// Quick Fill PAR*
export default class QFPARTemplate extends Model {
    static all(orgId, query = {}) {
        return this.withAbort(
            ApiClient.get(`organizations/${orgId}/quick-fill-par-templates`, { params: query }),
            ({ data }) => this.make(data)
        );
    }

    static find(orgId, templateId, query = {}) {
        return this.withAbort(
            ApiClient.get(`organizations/${orgId}/quick-fill-par-templates/${templateId}`, { params: query }),
            ({ data }) => this.make(data)
        );
    }

    static async create(orgId, attributes) {
        const { data } = await ApiClient.post(`organizations/${orgId}/quick-fill-par-templates`, attributes);

        return this.make(data);
    }

    async confirm() {
        const { data } = await ApiClient.put(
            `organizations/${this.organization_id}/quick-fill-par-templates/${this.id}/confirm`
        );

        return this.fill(data);
    }

    async update(attributes) {
        const { data } = await ApiClient.put(
            `organizations/${this.organization_id}/quick-fill-par-templates/${this.id}`,
            attributes
        );

        return this.fill(data);
    }

    async createDraft() {
        const { data } = await ApiClient.post(
            `organizations/${this.organization_id}/quick-fill-par-templates/${this.id}/draft`
        );

        return this.fill(data);
    }

    cancelDraft() {
        return ApiClient.delete(
            `organizations/${this.organization_id}/quick-fill-par-templates/${this.parent_id}/draft/cancel`
        );
    }

    async completeDraft() {
        const { data } = await ApiClient.post(
            `organizations/${this.organization_id}/quick-fill-par-templates/${this.parent_id}/draft/complete`
        );

        return this.fill(data);
    }

    async cancel() {
        return ApiClient.delete(`organizations/${this.organization_id}/quick-fill-par-templates/${this.id}/cancel`);
    }

    delete() {
        return ApiClient.delete(`organizations/${this.organization_id}/quick-fill-par-templates/${this.id}`);
    }
}
