<script>
export default {
    name: 'SelectableItemsQty',
};
</script>

<script setup>
import { watch, onBeforeMount, computed, reactive, ref } from 'vue';
import { useStore } from 'vuex';

import { catchOrderPrepDateGoneError } from '@/utils/httpUtils';
import { getBatchUnitQtyAmount } from '@/utils/batchUnitsUtils';
import { findLeafs } from '@/utils/treeUtils';
import { SELECTABLE_ITEM_STATUSES } from '@/enums/selectableItemsEnums';
import { ALERT_TYPES, QTY_INPUT_EVENTS } from '@/enums/componentsEnums';
import PLSelectableItemModel from '@/models/PLSelectableItem';
import PARTemplateSelectableItemModel from '@/models/PARTemplateSelectableItem';
import QFSelectableItemModel from '@/models/QFSelectableItem';
import QFPARTemplateSelectableItemModel from '@/models/QFPARTemplateSelectableItem';
import useAbortableRequest from '@/composition/useAbortableRequest';
import Alert from '@/components/Alert';
import Tabs from '@/components/Tabs';
import ListDisplay from '@/components/ListDisplay';
import SelectableItemQtyCard from './partials/SelectableItemQtyCard';

const props = defineProps({
    orderId: {
        type: [String, Number],
        required: true,
    },
    isTemplate: {
        type: Boolean,
        default: false,
    },
    isQuickFill: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update-status']);

const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                          Selectable items state
------------------------------------------------------------------------*/

const getSelectableItemsModel = () => {
    const { isTemplate, isQuickFill } = props;

    if (isQuickFill) {
        return isTemplate ? QFPARTemplateSelectableItemModel : QFSelectableItemModel;
    }

    return isTemplate ? PARTemplateSelectableItemModel : PLSelectableItemModel;
};

const selectableItemsState = reactive({
    model: getSelectableItemsModel(),
    data: {},
    statuses: new Map(),
    isDataLoading: true,
});

const loadSelectableItemsData = () => {
    const { model } = selectableItemsState;

    sendAbortableRequest(model.all(orgId, props.orderId))
        .then((models) => (selectableItemsState.data = models))
        .finally(() => (selectableItemsState.isDataLoading = false));
}

watch(
    () => selectableItemsState.data,
    (items) => {
        const selectedItemsQty = findLeafs(items, ({ qty }) => qty > 0).length;

        emit('update-status', { isAnyItemAdded: selectedItemsQty > 0 });
    },
    { deep: true }
);

onBeforeMount(loadSelectableItemsData);

/*------------------------------------------------------------------------
                                 Statuses
------------------------------------------------------------------------*/

const getItemStatuses = (item) => {
    const { statuses } = selectableItemsState;

    return Object.values(SELECTABLE_ITEM_STATUSES).reduce(
        (acc, status) => ({
            ...acc,
            [status]: Boolean((statuses.get(item.prototype_id) || {})[status]),
        }),
        {}
    );
};

const updateItemStatuses = (item, updatedStatuses) => {
    const { prototype_id: id } = item;

    const { statuses } = selectableItemsState;

    statuses.set(id, {
        ...(statuses.get(id) || {}),
        ...updatedStatuses,
    });
};

/*------------------------------------------------------------------------
                                    Qty
------------------------------------------------------------------------*/

const pendingRequestsCount = ref(new Map());
const updateQtyTimeoutIds = ref(new Map());
const savedCaptionTimeoutIds = ref(new Map());

const updateItemPendingRequestCount = (item, qty) => {
    const { prototype_id: id } = item;

    const currentCount = pendingRequestsCount.value.get(id) ?? 0;

    pendingRequestsCount.value.set(id, currentCount + qty);

    if (pendingRequestsCount.value.get(id) === 0) {
        pendingRequestsCount.value.delete(id);
    }
};

const hasItemPendingRequests = (item) => {
    const { prototype_id: id } = item;

    return (pendingRequestsCount.value.get(id) ?? 0) > 0;
};

const setItemTimeout = (item, timeoutIds, delay, callback) => {
    const { prototype_id: id } = item;

    timeoutIds.set(id, setTimeout(callback, delay));
};

const clearItemTimeout = (item, timeoutIds) => {
    const { prototype_id: id } = item;

    clearTimeout(timeoutIds.get(id));

    timeoutIds.delete(id);
};

const getUpdatedItemQty = (item, isQtyOnHand, value) => {
    const { units_data: unitsData } = item;

    const orderingUnit = props.isQuickFill ? unitsData.quick_fill_ordering_unit : unitsData.prep_list_ordering_unit;

    const qtyKey = isQtyOnHand ? 'par_on_hand_qty' : 'qty';

    item[qtyKey] = getBatchUnitQtyAmount(unitsData, orderingUnit, unitsData.batch_yield_unit, value);

    return item[qtyKey];
};

const updateQtyAfterOnHandQtySaved = (item) => {
    const { units_data: unitsData } = item;

    const orderingUnit = props.isQuickFill ? unitsData.quick_fill_ordering_unit : unitsData.prep_list_ordering_unit;

    const qty = item.qty;
    const qtyOnHand = item.par_on_hand_qty;

    const toOrderQty = getBatchUnitQtyAmount(
        unitsData,
        unitsData.batch_yield_unit,
        orderingUnit,
        Math.max(0, item.par_default_qty - qtyOnHand)
    );

    if (toOrderQty !== qty) {
        updateItemQty(item, false, toOrderQty, QTY_INPUT_EVENTS.UPDATE);
    }
};

const updateItemQty = (item, isQtyOnHand, value, type, undoCallback, isTriggeredByUndo, successCallback) => {
    if (isTriggeredByUndo) {
        return;
    }

    clearItemTimeout(item, updateQtyTimeoutIds.value);
    clearItemTimeout(item, savedCaptionTimeoutIds.value);

    updateItemStatuses(item, {
        [SELECTABLE_ITEM_STATUSES.IS_CONTROL_LOADING]: type === QTY_INPUT_EVENTS.UPDATE,
        [SELECTABLE_ITEM_STATUSES.IS_BEING_SAVED]: true,
        [SELECTABLE_ITEM_STATUSES.HAS_BEEN_JUST_SAVED]: false,
    });

    emit('update-status', { areActionsDisabled: true });

    const updatedQty = getUpdatedItemQty(item, isQtyOnHand, value);

    setItemTimeout(item, updateQtyTimeoutIds.value, 500, () => {
        updateItemPendingRequestCount(item, 1);

        const { model } = selectableItemsState;

        const requestCallback = isQtyOnHand ? model.setOnHandQty : model.setQty;

        requestCallback(orgId, props.orderId, item.prototype_id, { qty: updatedQty })
            .then((models) => {
                updateItemPendingRequestCount(item, -1);

                clearItemTimeout(item, savedCaptionTimeoutIds.value);

                setItemTimeout(item, savedCaptionTimeoutIds.value, 2000, () => {
                    updateItemStatuses(item, {
                        [SELECTABLE_ITEM_STATUSES.HAS_BEEN_JUST_SAVED]: false,
                    });
                });

                if (!hasItemPendingRequests(item)) {
                    updateItemStatuses(item, {
                        [SELECTABLE_ITEM_STATUSES.IS_CONTROL_LOADING]: false,
                        [SELECTABLE_ITEM_STATUSES.IS_BEING_SAVED]: false,
                        [SELECTABLE_ITEM_STATUSES.HAS_BEEN_JUST_SAVED]: true,
                    });
                }

                if (pendingRequestsCount.value.size === 0) {
                    selectableItemsState.data = models;
                }

                if (isQtyOnHand) {
                    updateQtyAfterOnHandQtySaved(item);
                }

                successCallback?.(isQtyOnHand);
            })
            .catch((error) => {
                catchOrderPrepDateGoneError(error);

                // TODO: doesn't work properly
                undoCallback();
            })
            .finally(() => emit('update-status', { areActionsDisabled: false }));
    });
};

/*--------------------------------------------------------------------
                                 Tabs
--------------------------------------------------------------------*/

const getItemsByTab = (tab) => {
    const selectedItems = [];
    const notSelectedItems = [];

    findLeafs(selectableItemsState.data[tab], (item) => {
        if (item.is_overlaid) {
            selectedItems.push(item);
        } else {
            notSelectedItems.push(item);
        }
    });

    return [...selectedItems, ...notSelectedItems];
};

const departmentOptions = computed(() => {
    const { data } = selectableItemsState;

    return Object.keys(data).map((department) => {
        const amount = findLeafs(data[department], (item) => item.qty > 0).length;

        return {
            value: department,
            text: `${department} (${amount})`,
            qaPrefix: 'department',
        };
    });
});
</script>

<template>
    <InPlaceLoader v-if="selectableItemsState.isDataLoading" />

    <div
        v-else-if="Object.entries(selectableItemsState.data).length === 0"
        class="container"
    >
        <Alert :type="ALERT_TYPES.WARNING">
            There are no items to select.
        </Alert>
    </div>

    <Tabs
        v-else
        enable-slider-mode
        include-content-container
        :tab-options="departmentOptions"
    >
        <template #content="{ activeTab }">
            <ListDisplay :items="getItemsByTab(activeTab)">
                <template #item="{ item }">
                    <SelectableItemQtyCard
                        :key="`selectable item: ${item.prototype_id}`"
                        :item="item"
                        :is-template="isTemplate"
                        :is-quick-fill="isQuickFill"
                        :statuses="getItemStatuses(item)"
                        @update-qty="updateItemQty"
                    />
                </template>
            </ListDisplay>
        </template>
    </Tabs>
</template>
