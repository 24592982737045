<script>
export default {
    name: 'EmployeeForm',
};
</script>

<script setup>
import { reactive, onBeforeMount, computed, watch, ref } from 'vue';
import { useStore } from 'vuex';

import { required, requiredField, email, minLength, maxLength, minValue, phone } from '@/utils/formValidators';
import { isFieldEmpty } from '@/utils/formUtils';
import { ACCOUNT_ROLES, ROLE_OPTIONS } from '@/enums/accountEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import LocationModel from '@/models/Location';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useForm from '@/composition/useForm';
import Alert from '@/components/Alert';
import TextInput from '@/components/form-controls/TextInput';
import PhoneInput from '@/components/form-controls/PhoneInput';
import SelectInput from '@/components/form-controls/SelectInput';
import NumericInput from '@/components/form-controls/NumericInput';
import CheckboxInput from '@/components/form-controls/CheckboxInput';
import FormUI from '@/components/UI/FormUI';

const props = defineProps({
    name: {
        type: String,
        default: '',
    },
    hourlyRate: {
        type: [String, Number],
        default: null,
    },
    phone: {
        type: Object,
        default: () => ({
            phone: null,
            phone_country_id: null,
        }),
    },
    email: {
        type: String,
        default: null,
    },
    availableLocations: {
        type: Array,
        default: () => [],
    },
    hiddenFields: {
        type: Array,
        default: () => [],
    },
    validationErrors: {
        type: Object,
        default: null,
    },
    isDataLoading: {
        type: Boolean,
        default: false,
    },
    isSubmitting: {
        type: Boolean,
        default: false,
    },
    addedEmployees: {
        type: Array,
        default: () => [],
    },
    submitBtnText: {
        type: String,
        default: 'Add to invitation list',
    },
    disableAnimation: {
        type: Boolean,
        default: false,
    },
    isResetTriggered: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['reset-success', 'submit']);

const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                               General state
------------------------------------------------------------------------*/

const generalState = reactive({
    locations: [],
    isLoading: true,
    hasError: false,
});

const isFieldVisible = (name) => !props.hiddenFields.includes(name);

const loadGeneralState = () => {
    Promise.all([
        isFieldVisible('available_locations') ? sendAbortableRequest(LocationModel.all(orgId)) : Promise.resolve(),
        isFieldVisible('phone') ? store.dispatch('countries/loadCountries') : Promise.resolve(),
    ])
        .then(([locationModels]) => (generalState.locations = locationModels || []))
        .catch(() => (generalState.hasError = true))
        .finally(() => (generalState.isLoading = false));
};

onBeforeMount(loadGeneralState);

/*------------------------------------------------------------------------
                                Setup form
------------------------------------------------------------------------*/

const { formState, ...form } = useForm(
    {
        name: '',
        hourly_rate: null,
        phone: props.phone,
        email: null,
        available_locations: [],
    },
    {},
    {
        name: 'Employee name',
        hourly_rate: 'Hourly rate',
    }
);

const setFormPropsValues = () => {
    const propsValues = {
        name: props.name,
        hourly_rate: props.hourlyRate,
        phone: {
            phone: props.phone.phone,
            phone_country_id: props.phone.phone_country_id || formState.phone.phone_country_id,
        },
        email: props.email,
        available_locations: [...props.availableLocations],
    };

    const formValues = Object.entries(propsValues).reduce((acc, [key, value]) => {
        if (isFieldVisible(key)) {
            acc[key] = value;
        }

        return acc;
    }, {});

    form.reinitializeFormState(formValues);
};

const setFormValidationRules = () => {
    const validationRules = {
        name: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(255),
        },
        hourly_rate: {
            required,
            minValue: minValue(1),
        },
        email: {
            email,
            maxLength: maxLength(255),
        },
        phone: {
            requiredField: requiredField('phone'),
            phone: phone(store.state.countries.countries),
        },
        available_locations: { required },
    };

    const rules = Object.keys(validationRules).reduce((acc, key) => {
        if (isFieldVisible(key)) {
            acc[key] = validationRules[key];
        }

        return acc;
    }, {});

    form.reinitializeValidationRules(rules);
};

const isIdenticalPhone = (target) => {
    const { phone } = formState;

    return phone.phone === target.phone && phone.phone_country_id === target.phone_country_id;
};

const validatePhoneNumber = () => {
    if (!isFieldVisible('phone') || isIdenticalPhone(props.phone)) {
        return true;
    }

    let validationMessage = null;

    const isPhoneAlreadyAdded = props.addedEmployees.some(({ phone }) => isIdenticalPhone(phone));

    if (isPhoneAlreadyAdded) {
        validationMessage = 'The employee is already invited.';
    } else if (isIdenticalPhone(store.state.auth.user)) {
        validationMessage = 'You can`t invite yourself.';
    }

    if (validationMessage) {
        form.setErrors('phone', validationMessage);
    }

    return validationMessage === null;
};

const setInitialFormState = () => {
    setFormPropsValues();

    if (isFieldVisible('available_locations')) {
        activeLocations.value = generalState.locations.reduce(
            (acc, { id }, index) => ({
                ...acc,
                [index]: formState.available_locations.some((location) => location.id === id),
            }),
            {}
        );
    }
};

const submitForm = () => {
    if (form.validate() && validatePhoneNumber()) {
        emit('submit', formState);
    }
};

const isLoading = computed(() => generalState.isLoading || props.isDataLoading || props.isSubmitting);

watch(
    () => props.isResetTriggered,
    (isResetTriggered) => {
        if (isResetTriggered) {
            setInitialFormState();

            emit('reset-success');
        }
    }
);

watch(() => props.validationErrors, form.setValidationErrors);

watch(
    () => [generalState.isLoading, props.isDataLoading],
    (loadingState) => {
        if (loadingState.every((isLoading) => !isLoading)) {
            setInitialFormState();
            setFormValidationRules();
        }
    },
    { immediate: true }
);

/*------------------------------------------------------------------------
                                   Phone
------------------------------------------------------------------------*/

const isContactsAPISupported = 'contacts' in navigator && 'select' in navigator.contacts;

const getProcessedPhoneData = (phone) => {
    const { countries } = store.state.countries;

    const phoneNumber = phone.replace('+', '').replace(new RegExp(/^00/), '');

    for (const country of countries) {
        const { phone_code: code } = country;

        if (new RegExp(`^${code}`).test(phoneNumber)) {
            return {
                phone: phoneNumber.replace(code, ''),
                phoneCountryId: country.id,
            };
        }
    }

    return {
        phone: phoneNumber,
        phoneCountryId: formState.phone.phone_country_id,
    };
};

const fillContactData = (contact) => {
    if (isFieldVisible('name')) {
        formState.name = contact.name.shift();

        form.validate('name');
    }

    if (isFieldVisible('email')) {
        formState.email = contact.email.length ? contact.email.shift() : '';

        form.validate('email');
    }

    if (isFieldVisible('phone')) {
        const { phone, phoneCountryId } = getProcessedPhoneData(contact.tel.shift());

        formState.phone.phone = phone;
        formState.phone.phone_country_id = phoneCountryId;

        form.validate('phone');
    }
};

const openContactsBook = async () => {
    const contacts = await navigator.contacts.select(['name', 'tel', 'email'], {
        multiple: false,
    });

    if (contacts.length !== 0) {
        const contact = contacts.shift();

        fillContactData(contact);
    }
};

const onPhoneCountryChanged = (phoneCountryId) => {
    formState.phone.phone_country_id = phoneCountryId;

    if (isFieldEmpty(formState.phone.phone)) {
        form.clearErrors('phone');
    } else {
        form.validate('phone');
    }
};

/*------------------------------------------------------------------------
                            Available locations
------------------------------------------------------------------------*/

const activeLocations = ref({});

const getLocationRoleValue = (location) =>
    formState.available_locations.find(({ id }) => location.id === id)?.role ?? null;

const handleActiveLocationChanged = (location, index) => {
    if (activeLocations.value[index]) {
        formState.available_locations.push({
            id: location.id,
            role: ACCOUNT_ROLES.COOK,
        });
    } else {
        formState.available_locations = formState.available_locations.filter(({ id }) => location.id !== id);
    }
};

const handleLocationRoleChanged = (role, currentLocation) => {
    formState.available_locations = formState.available_locations.map((location) => {
        if (location.id === currentLocation.id) {
            return {
                ...location,
                role,
            };
        }

        return location;
    });
};
</script>

<template>
    <FormUI
        data-test-id="employee_form"
        :disable-animation="disableAnimation"
        :is-loading="isLoading"
        @submit="submitForm"
    >
        <template #content="{ classNames, qaPrefix }">
            <Alert
                v-if="generalState.hasError"
                :type="ALERT_TYPES.FAIL"
            >
                Something went wrong during loading the page. Please try again later.
            </Alert>

            <template v-else>
                <BtnUI
                    v-if="isContactsAPISupported"
                    is-filled
                    size="sm"
                    :class="classNames.spacerLg"
                    :data-testid="`${qaPrefix}_select_from_contacts_btn`"
                    @click="openContactsBook"
                >
                    Select from contacts
                </BtnUI>

                <div
                    v-if="isFieldVisible('name')"
                    :class="classNames.spacerMd"
                >
                    <TextInput
                        v-model="formState.name"
                        include-asterisk
                        name="name"
                        label="Name of Employee"
                        :data-test-id="`${qaPrefix}_name_input`"
                        :disabled="isLoading"
                        :has-error="form.hasErrors('name')"
                        @blur="form.validate('name')"
                        @update:modelValue="form.clearErrors('name')"
                    />

                    <ValidationErrors
                        v-if="form.hasErrors('name')"
                        :data-testid="`${qaPrefix}_name_error`"
                        :errors="form.getErrors('name')"
                    />
                </div>

                <div
                    v-if="isFieldVisible('hourly_rate')"
                    :class="classNames.spacerMd"
                >
                    <NumericInput
                        v-model="formState.hourly_rate"
                        include-asterisk
                        only-positive
                        always-use-dot
                        name="hourly_rate"
                        label="Hourly Rate"
                        :max-precision="2"
                        :max="9999"
                        :data-test-id="`${qaPrefix}_hourly_rate_input`"
                        :disabled="isLoading"
                        :has-error="form.hasErrors('hourly_rate')"
                        @blur="form.validate('hourly_rate')"
                        @update:modelValue="form.clearErrors('hourly_rate')"
                    />

                    <ValidationErrors
                        v-if="form.hasErrors('hourly_rate')"
                        :data-testid="`${qaPrefix}_hourly_rate_error`"
                        :errors="form.getErrors('hourly_rate')"
                    />
                </div>

                <div
                    v-if="isFieldVisible('phone')"
                    :class="classNames.spacerMd"
                >
                    <PhoneInput
                        v-model="formState.phone"
                        include-asterisk
                        label="Phone Number"
                        :data-testid="`${qaPrefix}_phone_input`"
                        :send-button="false"
                        :disabled="isLoading"
                        :has-error="form.hasErrors('phone')"
                        @blur="form.validate('phone')"
                        @country-changed="onPhoneCountryChanged"
                        @update:modelValue="form.clearErrors('phone')"
                    />

                    <ValidationErrors
                        v-if="form.hasErrors('phone')"
                        :data-testid="`${qaPrefix}_phone_error`"
                        :errors="form.getErrors('phone')"
                    />
                </div>

                <div
                    v-if="isFieldVisible('email')"
                    :class="classNames.spacerLg"
                >
                    <TextInput
                        v-model="formState.email"
                        name="email"
                        type="email"
                        label="Email"
                        :data-test-id="`${qaPrefix}_email_input`"
                        :disabled="isLoading"
                        :has-error="form.hasErrors('email')"
                        @blur="form.validate('email')"
                        @update:modelValue="form.clearErrors('email')"
                    />

                    <ValidationErrors
                        v-if="form.hasErrors('email')"
                        :data-testid="`${qaPrefix}_email_error`"
                        :errors="form.getErrors('email')"
                    />
                </div>

                <template v-if="isFieldVisible('available_locations') && Object.keys(activeLocations).length > 0">
                    <h5 :class="classNames.spacerSm">
                        {{ `Locations (${formState.available_locations.length})` }}
                    </h5>

                    <div
                        v-for="(location, index) in generalState.locations"
                        :key="`available location: ${index}`"
                        class="pl-employee-location"
                        :class="classNames.spacerSm"
                    >
                        <CheckboxInput
                            v-model="activeLocations[index]"
                            enable-boolean-mode
                            :name="`available_locations.${index}.active`"
                            :qa-prefix="qaPrefix"
                            :options="[{ value: true }]"
                            @update:modelValue="handleActiveLocationChanged(location, index)"
                        />

                        <div class="pl-employee-location__wrapper">
                            <SelectInput
                                :model-value="getLocationRoleValue(location)"
                                :data-test-id="`${qaPrefix}_available_locations_role_select`"
                                :include-asterisk="formState.available_locations.length === 0"
                                :label="location.name"
                                :options="ROLE_OPTIONS"
                                :disabled="!activeLocations[index] || isLoading"
                                @update:modelValue="(value) => handleLocationRoleChanged(value, location)"
                            />
                        </div>
                    </div>
                </template>
            </template>
        </template>

        <template #actions="{ qaPrefix }">
            <BtnUI
                is-filled
                type="submit"
                :data-testid="`${qaPrefix}_submit_btn`"
                :disabled="isLoading || form.isDisabled()"
            >
                {{ submitBtnText }}
            </BtnUI>
        </template>
    </FormUI>
</template>

<style lang="scss" scoped>
.pl-employee-location {
    display: flex;
    align-items: flex-start;

    &__wrapper {
        flex: 1;
    }

    :deep(.pl-checkbox) {
        margin-top: custom-space(1);

        .form-check {
            margin: 0;
        }

        .form-check-input[type='checkbox'] {
            border-color: $black;
            border-radius: custom-space(0.25);
        }
    }
}

h5.pl-form__spacer--sm {
    border-bottom: 1px solid $gray-200;
}
</style>
