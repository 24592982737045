export const mapLeafs = (data, callback) => {
    if (Array.isArray(data)) {
        return data.map(callback);
    }

    for (let node in data) {
        data[node] = mapLeafs(data[node], callback);
    }

    return data;
};

export const filterLeafs = (data, callback) => {
    const filteredData = {};

    if (Array.isArray(data)) {
        return data.filter(callback);
    }

    for (let node in data) {
        const filteredLeafs = filterLeafs(data[node], callback);

        if (Array.isArray(filteredLeafs) && filteredLeafs.length > 0) {
            filteredData[node] = filteredLeafs;
        } else if (typeof filteredLeafs === 'object' && Object.keys(filteredLeafs).length > 0) {
            filteredData[node] = filteredLeafs;
        }
    }

    return filteredData;
};

export const findLeafs = (tree, callback, result = []) => {
    for (let node in tree) {
        if (Array.isArray(tree[node])) {
            for (const item of tree[node]) {
                if (callback(item)) {
                    result.push(item);
                }
            }
        } else {
            findLeafs(tree[node], callback, result);
        }
    }

    return result;
};
