<script>
export default {
    name: 'SettingsIndexView',
};
</script>

<script setup>
import { useStore } from 'vuex';

import { SETTINGS_NAVIGATION_CONFIG } from '@/enums/navigationEnums';
import useTaskReminderRibbon from '@/composition/useTaskReminderRibbon';
import VerticalNavigation from '@/components/VerticalNavigation';
import TaskReminderRibbon from '@/components/TaskReminderRibbon';

const store = useStore();

const { isTaskReminderVisible } = useTaskReminderRibbon();

const { currentAccount } = store.state.auth;

/*------------------------------------------------------------------------
                                  Log out
------------------------------------------------------------------------*/

const logout = () => store.dispatch('auth/logout').then(() => (window.location.href = '/'));
</script>

<template>
    <TopBarLayout>
        <template #title>
            <h1>Menu</h1>
        </template>

        <transition
            appear
            name="down"
            type="transition"
        >
            <h5 data-testid="company_name">
                {{ currentAccount.organization.name }}
            </h5>
        </transition>

        <TaskReminderRibbon v-if="isTaskReminderVisible" />

        <VerticalNavigation
            data-test-id="settings_vertical_navigation"
            :navigation-config="SETTINGS_NAVIGATION_CONFIG"
        />

        <transition
            appear
            name="down"
            type="transition"
        >
            <div
                class="pl-settings__action"
                style="transition-delay: 100ms"
            >
                <BtnUI
                    is-filled
                    data-testid="settings_logout_btn"
                    @click="logout"
                >
                    Logout
                </BtnUI>
            </div>
        </transition>
    </TopBarLayout>
</template>

<style lang="scss" scoped>
:deep(.pl-layout__content) > h5 {
    text-align: center;
}

:deep(.pl-task-reminder-ribbon) {
    margin-bottom: custom-space(0.5);
    padding: custom-space(0.5) custom-space(0.75);
    border-radius: $border-radius;
    box-shadow: none;
}

.pl-settings__action {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-top: auto;
    padding-top: custom-space(2);
    width: 50%;

    @include media-breakpoint-down(lg) {
        width: 100%;
    }

    & > button {
        width: 100%;
    }
}
</style>
