<script>
export default {
    name: 'CreatePLOrderStep3View',
};
</script>

<script setup>
import { mapGetters } from '@/utils/vuexUtils';
import { PL_ROUTE_NAMES } from '@/enums/routesNameEnums';
import PLOrderModel from '@/models/PLOrder';
import useSelectableItemsAssignee from '@/composition/useSelectableItemsAssignee';
import OrderStepTemplate from '@/components/model-specific/orders/OrderStepTemplate';
import SelectableItemsAssignee from '@/components/model-specific/prep-lists/selectable-items-assignee';

const { currentPLOrder } = mapGetters(['currentPLOrder']);

/*------------------------------------------------------------------------
                      Selectable items assignee state
------------------------------------------------------------------------*/

const { selectableOrderItemsState, selectableItemsAssigneeState, selectableItemsAssignee } = useSelectableItemsAssignee(
    PLOrderModel,
    currentPLOrder.value.id
);
</script>

<template>
    <OrderStepTemplate
        exclude-content-container
        :is-step-submitting="selectableItemsAssigneeState.isSubmitting"
    >
        <template #step-content>
            <SelectableItemsAssignee
                :order-items="selectableOrderItemsState.data"
                :items-assignee="selectableItemsAssigneeState.data"
                :is-data-loading="selectableOrderItemsState.isDataLoading"
                @set-assignee="selectableItemsAssignee.setAssignee"
                @set-distribution="selectableItemsAssignee.setDistribution"
                @set-qty="selectableItemsAssignee.setQty"
            />
        </template>

        <template #prev-step-link>
            <router-link
                class="pl-btn"
                :to="{ name: PL_ROUTE_NAMES.CREATE.ADD_ITEMS }"
            >
                Select Items
            </router-link>
        </template>

        <template #next-step-action>
            <BtnUI
                is-filled
                :disabled="selectableItemsAssignee.isInvalid()"
                @click="selectableItemsAssignee.submit({ name: PL_ROUTE_NAMES.CREATE.REVIEW_AND_CONFIRM })"
            >
                Review And Confirm
            </BtnUI>
        </template>
    </OrderStepTemplate>
</template>
