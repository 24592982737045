import ApiClient from '@/plugins/ApiClient';
import Model from './Model';
import OrganizationModel from './Organization';

export default class Account extends Model {
    static all(orgId, query = {}) {
        return this.withAbort(ApiClient.get(`organizations/${orgId}/accounts`, { params: query }), ({ data }) =>
            this.make(data)
        );
    }

    static find(orgId, accountId, query = {}) {
        return this.withAbort(
            ApiClient.get(`organizations/${orgId}/accounts/${accountId}`, { params: query }),
            ({ data }) => this.make(data)
        );
    }

    static async downloadTemplate(orgId) {
        const { data } = await ApiClient.get(`organizations/${orgId}/accounts/import-get-template`, {
            responseType: 'blob',
        });

        return data;
    }

    static async parseImportFile(orgId, formData) {
        const { data } = await ApiClient.post(`organizations/${orgId}/accounts/import-file`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        return data;
    }

    static async applyImport(orgId, attributes) {
        const { data } = await ApiClient.post(`organizations/${orgId}/accounts/import`, { data: attributes });

        return data;
    }

    static getProfile(orgId, accountId) {
        return this.withAbort(ApiClient.get(`organizations/${orgId}/accounts/${accountId}/profile`), ({ data }) =>
            this.make(data)
        );
    }

    static getReassignState(orgId, accountId) {
        return this.withAbort(
            ApiClient.get(`organizations/${orgId}/accounts/${accountId}/reassign`),
            ({ data }) => data
        );
    }

    static async create(orgId, attributes, query = {}) {
        const { data } = await ApiClient.post(`organizations/${orgId}/accounts`, attributes, { params: query });

        return this.make(data);
    }

    static changeLocation(orgId, accountId, attributes) {
        return ApiClient.post(`organizations/${orgId}/accounts/${accountId}/change-current-location`, attributes);
    }

    static setReassign(orgId, accountId, attributes) {
        return ApiClient.post(`organizations/${orgId}/accounts/${accountId}/reassign`, attributes);
    }

    async join() {
        const { data } = await ApiClient.put(`organizations/${this.organization.id}/accounts/${this.id}/join`);

        return this.fill(data);
    }

    async update(attributes) {
        const { data } = await ApiClient.put(`organizations/${this.organization.id}/accounts/${this.id}`, attributes);

        return this.fill(data);
    }

    async uploadAvatar(formData) {
        const { data } = await ApiClient.post(
            `organizations/${this.organization.id}/accounts/${this.id}/upload-avatar`,
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        );

        return data;
    }

    static getOnboardingSettings(orgId) {
        return this.withAbort(ApiClient.get(`organizations/${orgId}/onboarding-settings`), ({ data }) => data);
    }

    static async updateOnboardingSettings(orgId, attributes) {
        const { data } = await ApiClient.put(`organizations/${orgId}/onboarding-settings`, attributes);

        return data;
    }

    async deleteAvatar() {
        return ApiClient.delete(`organizations/${this.organization.id}/accounts/${this.id}/delete-avatar`);
    }

    async delete(query = {}) {
        const { data } = await ApiClient.delete(`organizations/${this.organization.id}/accounts/${this.id}`, {
            params: query,
        });

        return Account.make(data);
    }

    getRelationships() {
        return {
            organization: OrganizationModel,
        };
    }
}
