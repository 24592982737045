<script>
export default {
    name: 'CreateSupplierView',
};
</script>

<script setup>
import { reactive, onBeforeMount, inject } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { catchUnprocessableEntity } from '@/utils/httpUtils';
import { SUPPLIERS_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import SupplierModel from '@/models/Supplier';
import SupplierForm from '@/components/forms/SupplierForm';

const $acl = inject('$acl');

const router = useRouter();
const store = useStore();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(SUPPLIERS_RESTRICTIONS.CREATE, currentAccount)) {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to create supplier.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                            Supplier form state
------------------------------------------------------------------------*/

const supplierFormState = reactive({
    validationErrors: null,
    isSubmitting: false,
});

const submitCreateSupplier = (attributes) => {
    supplierFormState.isSubmitting = true;

    SupplierModel.create(orgId, attributes)
        .then(() => {
            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: 'The supplier has been successfully created!',
            });

            router.push({ name: SETTINGS_ROUTE_NAMES.SUPPLIERS.INDEX });
        })
        .catch((error) => {
            catchUnprocessableEntity(error, (errors) => {
                supplierFormState.validationErrors = errors;
            });
        })
        .finally(() => (supplierFormState.isSubmitting = false));
};
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.SUPPLIERS.INDEX">
        <template #title>
            <h1>Create supplier</h1>
        </template>

        <SupplierForm
            :validation-errors="supplierFormState.validationErrors"
            :is-submitting="supplierFormState.isSubmitting"
            @submit="submitCreateSupplier"
        />
    </TopBarLayout>
</template>
