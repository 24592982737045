import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';

import DepartmentsIndexView from '@/views/dashboard/settings/departments';
import CreateDepartmentView from '@/views/dashboard/settings/departments/create';
import EditDepartmentView from '@/views/dashboard/settings/departments/edit';

export default [
    {
        path: 'settings/departments',
        name: SETTINGS_ROUTE_NAMES.DEPARTMENTS.INDEX,
        component: DepartmentsIndexView,
    },
    {
        path: 'settings/departments/create',
        name: SETTINGS_ROUTE_NAMES.DEPARTMENTS.CREATE,
        component: CreateDepartmentView,
    },
    {
        path: 'settings/departments/:departmentId/edit',
        name: SETTINGS_ROUTE_NAMES.DEPARTMENTS.EDIT,
        component: EditDepartmentView,
        props: true,
    },
];
