<script>
export default {
    name: 'GlobalModal',
};
</script>

<script setup>
import { onBeforeMount, onBeforeUnmount, reactive } from 'vue';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import Modal from '@/components/Modal';

/*------------------------------------------------------------------------
                            Global modal state
------------------------------------------------------------------------*/

const globalModalState = reactive({
    title: null,
    message: null,
    isOpened: false,
});

const openGlobalModal = ({ title, message }) => {
    globalModalState.title = title;
    globalModalState.message = message;
    globalModalState.isOpened = true;
};

const closeGlobalModal = () => {
    globalModalState.title = null;
    globalModalState.message = null;
    globalModalState.isOpened = false;
};

onBeforeMount(() => EventBus.on(EVENT_BUS_EVENTS.GLOBAL_MODAL, openGlobalModal));
onBeforeUnmount(() => EventBus.off(EVENT_BUS_EVENTS.GLOBAL_MODAL));
</script>

<template>
    <Modal
        v-if="globalModalState.isOpened"
        is-content-centered
        @close="closeGlobalModal"
    >
        <template
            v-if="globalModalState.title"
            #title
        >
            {{ globalModalState.title }}
        </template>

        <template
            v-if="globalModalState.message"
            #content
        >
            <span>{{ globalModalState.message }}</span>
        </template>

        <template #actions>
            <BtnUI
                is-filled
                @click="closeGlobalModal"
            >
                Close
            </BtnUI>
        </template>
    </Modal>
</template>
