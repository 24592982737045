<script>
export default {
    name: 'CreatePLPARTemplateView',
};
</script>

<script setup>
import { inject, onBeforeMount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { mapGetters } from '@/utils/vuexUtils';
import { getBackRoute } from '@/utils/navigationUtils';
import { isOrderEditing } from '@/utils/orderUtils';
import { PAR_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { ALERT_TYPES, BADGE_TYPES } from '@/enums/componentsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ORDER_GROUP_ITEMS_BY_OPTIONS } from '@/enums/orderEnums';
import Badge from '@/components/Badge';
import DropDown from '@/components/DropDown';
import GroupedSettings from '@/components/constructions/grouped-settings';

const $acl = inject('$acl');

const router = useRouter();
const route = useRoute();
const store = useStore();

const { currentPARTemplate } = mapGetters(['currentPARTemplate']);

const { currentAccount } = store.state.auth;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

const notifyAboutPendingTemplate = () => {
    EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
        type: ALERT_TYPES.WARNING,
        message: 'You currently have a pending template. Please finalize or cancel it, before creating a new one.',
    });
};

onBeforeMount(() => {
    if (!$acl.can(PAR_RESTRICTIONS.CREATE, currentAccount)) {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to create PAR templates.',
        });

        return router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }

    const template = currentPARTemplate.value;

    if (template === null) {
        return router.replace({ name: SETTINGS_ROUTE_NAMES.PAR.CREATE.PL.INDEX });
    }

    if (isOrderEditing(template)) {
        notifyAboutPendingTemplate();

        router.replace({
            name: SETTINGS_ROUTE_NAMES.PAR.EDIT.PL.INDEX,
            params: { templateId: template.parent_id },
        });
    } else if (route.query.action === 'new-template') {
        notifyAboutPendingTemplate();
    }
});
</script>

<template>
    <TopBarLayout
        exclude-content-container
        :back-route="getBackRoute(route, SETTINGS_ROUTE_NAMES.PAR.CREATE.PL, SETTINGS_ROUTE_NAMES.PAR.INDEX)"
    >
        <template #title>
            <h1>
                <span>
                    Create PAR

                    <Badge
                        is-absolute
                        size="xs"
                        :type="BADGE_TYPES.PAR"
                    >
                        Prep List
                    </Badge>
                </span>
            </h1>
        </template>

        <template
            v-if="route.name !== SETTINGS_ROUTE_NAMES.PAR.CREATE.PL.INDEX"
            #actions
        >
            <DropDown>
                <template #content>
                    <GroupedSettings.Root>
                        <GroupedSettings.List title="Group items by">
                            <template v-if="!!route.meta.parentProps?.enableGroupItems">
                                <GroupedSettings.ListItem
                                    v-for="(text, value) in ORDER_GROUP_ITEMS_BY_OPTIONS"
                                    :key="`group items by option: ${value}`"
                                    :is-selected="store.state.PLOrderViewSettings.groupItemsBy === value"
                                    @click="store.commit('PLOrderViewSettings/setGroupItemsBy', value)"
                                >
                                    {{ text }}
                                </GroupedSettings.ListItem>
                            </template>

                            <GroupedSettings.ListItem
                                v-else
                                is-selected
                            >
                                Category
                            </GroupedSettings.ListItem>
                        </GroupedSettings.List>
                    </GroupedSettings.Root>
                </template>
            </DropDown>
        </template>

        <router-view />
    </TopBarLayout>
</template>
