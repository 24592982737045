import ApiClient from '@/plugins/ApiClient';
import Model from './Model';
import OrganizationModel from './Organization';
import AccountModel from './Account';

export default class User extends Model {
    static all(query = {}) {
        return this.withAbort(ApiClient.get('user/accounts', { params: query }), ({ data }) => AccountModel.make(data));
    }

    static async createCompany(attributes) {
        const { data } = await ApiClient.post('user/company', attributes);

        return AccountModel.make(data);
    }

    static async updateCompany(orgId, attributes) {
        const { data } = await ApiClient.put(`user/company/${orgId}`, attributes);

        return AccountModel.make(data);
    }

    static completeOnboarding(orgId) {
        return ApiClient.put(`user/company/${orgId}/complete-onboarding`);
    }

    static deleteAccounts() {
        return ApiClient.delete(`user/delete-accounts`);
    }

    getRelationships() {
        return {
            organization: OrganizationModel,
        };
    }
}
