import { ref } from 'vue';

const useCodeGenerator = (codeLength = 4) => {
    const uniqueCodes = ref(new Set());

    const generateCode = () => {
        const numbers = [];

        for (let i = 0; i < codeLength; i++) {
            numbers.push(Math.ceil(Math.random() * 10));
        }

        return numbers.join('').slice(0, codeLength);
    };

    const generateUniqueCode = () => {
        let code;

        do {
            code = generateCode();
        } while (uniqueCodes.value.has(code));

        uniqueCodes.value.add(code);

        return code;
    };

    const clearUsedCode = (usedCode) => uniqueCodes.value.delete(usedCode);

    return { generateUniqueCode, clearUsedCode };
};

export default useCodeGenerator;
