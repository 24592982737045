<script>
// TODO: hover & focus (later)
export default {
    name: 'BtnUI',
};
</script>

<script setup>
defineProps({
    type: {
        type: String,
        default: 'button',
    },
    size: {
        type: String,
        default: null,
    },
    isFilled: {
        type: Boolean,
        default: false,
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});
</script>

<template>
    <button
        class="pl-btn"
        :class="{
            [`pl-btn--${size}`]: size !== null,
            'pl-btn--filled': isFilled,
            'pl-btn--loading': isLoading,
        }"
        :type="type"
        :disabled="disabled || isLoading"
    >
        <OverlayLoader v-if="isLoading" />

        <slot />
    </button>
</template>

<style lang="scss">
.pl-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: custom-space(1) custom-space(1.625);
    border-radius: 100px;
    cursor: pointer;
    color: $white;
    font-size: $font-size-base * 1.125;
    font-weight: $font-weight-bolder;
    line-height: 1.25;
    text-align: center;
    text-transform: uppercase;

    &:not(&--filled) {
        color: $primary;
        box-shadow: inset 0 0 0 2px $primary;

        @include hover {
            color: $primary;
        }
    }

    &--filled {
        background-image: linear-gradient(270deg, $red-light 0%, $primary 100%);
        box-shadow: 0 6px 8px 0 rgba($primary, 0.25);

        @include hover {
            color: $white;
        }
    }

    &--loading {
        position: relative;
        color: transparent;

        > .pl-overlay-spinner {
            background-color: transparent;

            > img {
                height: custom-space(1);
                width: custom-space(1);
            }
        }
    }

    &--sm {
        padding: custom-space(0.625) custom-space(0.925);
        box-shadow: none;
        font-size: $font-size-base * 0.75;
    }

    &:disabled,
    &[disabled] {
        background: $gray-400;
        border-color: $gray-400;
        box-shadow: none;
        user-select: none;
        pointer-events: none;

        &:not(.pl-btn--loading) {
            color: $gray-500;
        }
    }
}
</style>
