<script>
export default {
    name: 'ImportEntityModal',
};
</script>

<script setup>
import { computed, reactive, ref, watch } from 'vue';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { required, minLength, maxLength, email, minValue, maxValue } from '@/utils/formValidators';
import { ALERT_TYPES, IMPORT_ENTITIES, IMPORT_ENTITY_CHUNK_SIZE } from '@/enums/componentsEnums';
import Modal from '@/components/Modal';
import Alert from '@/components/Alert';

const props = defineProps({
    entity: {
        type: String,
        required: true,
        validator: (value) => Object.values(IMPORT_ENTITIES).includes(value),
    },
    data: {
        type: Object,
        default: null,
    },
    progress: {
        type: Number,
        required: true,
    },
    isActionProcessing: {
        type: Boolean,
        required: true,
    },
});

const emit = defineEmits(['download-template', 'parse-file', 'close', 'submit']);

/*------------------------------------------------------------------------
                                Forms state
------------------------------------------------------------------------*/

const formsState = reactive({
    current: null,
    skipped: new Set(),
});

const skipForm = (rowIndex) => {
    formsState.current[rowIndex].errors.clear();
    formsState.skipped.add(rowIndex);
};

const clearSkippedForms = () => {
    const indexesToSkip = Array.from(formsState.skipped).sort((a, b) => b - a);

    indexesToSkip.forEach((index) => {
        formsState.current.splice(index, 1);
    });

    formsState.skipped.clear();
};

const generateInitialFormState = ({ data, errors }) => ({
    data: { ...data },
    errors: new Map(Object.entries(errors)),
});

const submitFormsData = () => {
    const payload = formsState.current.reduce((acc, { data }, rowIndex) => {
        if (!formsState.skipped.has(rowIndex)) {
            acc.push(data);
        }

        return acc;
    }, []);

    emit('submit', payload);
};

const clearFieldErrors = (rowIndex, columnKey) => {
    const { errors } = formsState.current[rowIndex];

    if (errors.has(columnKey)) {
        errors.delete(columnKey);
    }
};

const validateField = (rowIndex, columnKey) => {
    clearFieldErrors(rowIndex, columnKey);

    if (formsState.skipped.has(rowIndex)) {
        return true;
    }

    const { label, validators } = formConfig.value[columnKey];

    const fieldValue = formsState.current[rowIndex].data[columnKey];

    for (const key in validators) {
        const validationStatus = validators[key]({ value: fieldValue }, label);

        if (validationStatus !== null) {
            const { errors } = formsState.current[rowIndex];

            errors.set(columnKey, [...(errors.get(columnKey) || []), validationStatus]);

            return false;
        }
    }

    return true;
};

const formConfig = computed(() => {
    const entityConfigs = {
        [IMPORT_ENTITIES.EMPLOYEE]: {
            name: {
                label: 'Employee name',
                validators: {
                    required,
                    minLength: minLength(2),
                    maxLength: maxLength(255),
                },
            },
            phone: {
                label: 'Phone',
                validators: {
                    required,
                },
            },
            email: {
                label: 'Email',
                validators: {
                    email,
                    minLength: minLength(2),
                    maxLength: maxLength(255),
                },
            },
            hourly_rate: {
                label: 'Hourly rate',
                validators: {
                    minValue: minValue(0.01),
                    maxValue: maxValue(9999.99),
                },
            },
        },
        [IMPORT_ENTITIES.ITEM]: {
            name: {
                label: 'Item name',
                validators: {
                    required,
                    minLength: minLength(2),
                    maxLength: maxLength(255),
                },
            },
            batch_size_amount: {
                label: 'Production output',
                validators: {
                    required,
                    minValue: minValue(1),
                },
            },
            batch_size_unit: {
                label: 'Production output unit',
                validators: {
                    required,
                    minLength: minLength(2),
                    maxLength: maxLength(255),
                },
            },
            batch_yield_amount: {
                label: 'Batch yield amount',
                validators: {
                    required,
                    minValue: minValue(1),
                },
            },
            batch_yield_unit: {
                label: 'Batch yield unit',
                validators: {
                    required,
                    minLength: minLength(2),
                    maxLength: maxLength(255),
                },
            },
            batch_cost: {
                label: 'Batch cost',
                validators: {
                    required,
                },
            },
            batch_labor_time: {
                label: 'Batch labor time',
                validators: {
                    required,
                    minValue: minValue(1),
                },
            },
        },
    };

    return entityConfigs[props.entity];
});

const hasAnyFormErrors = computed(() => formsState.current.some(({ errors }) => errors.size > 0));

const importEntitiesSize = computed(() => {
    if (formsState.current === null) {
        return 0;
    }

    return formsState.current.length - formsState.skipped.size;
});

const isSmallDevice = computed(() => window.innerWidth <= 768);

watch(
    () => [props.progress, props.isActionProcessing],
    ([, isActionProcessing], [prevProgress]) => {
        const { data } = props;

        if (isActionProcessing || data === null) {
            return;
        }

        if (formsState.current === null) {
            formsState.current = data.rows.map(generateInitialFormState);

            return;
        }

        clearSkippedForms();

        formsState.current.splice(
            0,
            prevProgress + IMPORT_ENTITY_CHUNK_SIZE,
            ...data.rows.slice(0, IMPORT_ENTITY_CHUNK_SIZE).map(generateInitialFormState)
        );
    }
);

/*------------------------------------------------------------------------
                                File state
------------------------------------------------------------------------*/

const allowedFileExtensions = ['xlsx', 'csv', 'ods'];
const fileAcceptTypes = allowedFileExtensions.map((ext) => `.${ext}`).join(',');

const fileInputRef = ref(null);

const initFileUploading = () => fileInputRef.value.click();

const onFileChanged = (event) => {
    const file = event.target.files[0];

    if (!file) return;

    fileInputRef.value.value = '';

    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (allowedFileExtensions.includes(fileExtension)) {
        const formData = new FormData();

        formData.append('file', file);

        emit('parse-file', formData);
    } else {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.FAIL,
            message: 'The file should be in either .xlsx, .csv or .ods extension.',
        });
    }
};
</script>

<template>
    <Modal
        :is-close-disabled="isActionProcessing || formsState.current !== null"
        :class="{
            'pl-import-entity-modal': formsState.current !== null,
        }"
        @close="emit('close')"
    >
        <template #title>
            <slot
                name="title"
                :data="data"
                :importEntitiesSize="importEntitiesSize"
            />

            <small v-if="data !== null">Please review your data below and update it if needed before submitting</small>

            <Alert
                v-if="formsState.current === null && isSmallDevice"
                :type="ALERT_TYPES.WARNING"
            >
                For the best experience, we recommend using this feature on a desktop or laptop.
            </Alert>
        </template>

        <template #content>
            <div class="pl-import-entity-content">
                <OverlayLoader v-if="isActionProcessing">
                    <template
                        v-if="formsState.current !== null"
                        #progress
                    >
                        {{ `${progress} / ${importEntitiesSize}` }}
                    </template>
                </OverlayLoader>

                <template v-if="formsState.current === null">
                    <button
                        type="button"
                        :disabled="isActionProcessing"
                        @click="emit('download-template')"
                    >
                        Download template
                    </button>

                    <h6>and</h6>

                    <BtnUI
                        is-filled
                        size="sm"
                        :disabled="isActionProcessing"
                        @click="initFileUploading"
                    >
                        provide file for import

                        <input
                            ref="fileInputRef"
                            type="file"
                            :accept="fileAcceptTypes"
                            :disabled="isActionProcessing"
                            @change="onFileChanged"
                        />
                    </BtnUI>
                </template>

                <table
                    v-else
                    class="pl-import-entity-content__table"
                >
                    <thead>
                        <tr>
                            <th>#</th>

                            <th
                                v-for="{ label, validators } in formConfig"
                                :key="`table label: ${label}`"
                            >
                                {{ validators.required !== undefined ? `${label}*` : label }}
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr
                            v-for="(row, rowIndex) in formsState.current"
                            :key="`table row: ${rowIndex}`"
                            :class="{
                                'pl-import-entity-content__row--skipped': formsState.skipped.has(rowIndex),
                            }"
                        >
                            <td>
                                <p>{{ rowIndex + 1 }}</p>

                                <button
                                    v-if="
                                        !formsState.skipped.has(rowIndex) &&
                                            formsState.current[rowIndex].errors.size > 0
                                    "
                                    type="button"
                                    class="pl-import-entity-content__cross-icon"
                                    @click="skipForm(rowIndex)"
                                >
                                    <CrossIcon
                                        :width="12"
                                        :height="12"
                                    />
                                </button>
                            </td>

                            <td
                                v-for="columnKey in Object.keys(row.data)"
                                :key="`table cell: ${columnKey}`"
                                class="pl-import-entity-content__cell"
                                :class="{
                                    'pl-import-entity-content__cell--has-error':
                                        formsState.current[rowIndex].errors.has(columnKey),
                                }"
                            >
                                <input
                                    v-model="formsState.current[rowIndex].data[columnKey]"
                                    type="text"
                                    :disabled="isActionProcessing || formsState.skipped.has(rowIndex)"
                                    @blur="validateField(rowIndex, columnKey)"
                                    @input="clearFieldErrors(rowIndex, columnKey)"
                                />

                                <ValidationErrors
                                    v-if="formsState.current[rowIndex].errors.has(columnKey)"
                                    :errors="formsState.current[rowIndex].errors.get(columnKey)"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>

        <template #actions>
            <BtnUI
                :disabled="isActionProcessing"
                @click="emit('close')"
            >
                cancel
            </BtnUI>

            <BtnUI
                v-if="formsState.current !== null"
                is-filled
                :disabled="isActionProcessing || importEntitiesSize === 0 || hasAnyFormErrors"
                @click="submitFormsData"
            >
                submit
            </BtnUI>
        </template>
    </Modal>
</template>

<style lang="scss" scoped>
.pl-import-entity-content {
    display: flex;
    flex-direction: column;
    gap: custom-space(0.5);
    text-align: center;

    > button:not(.pl-btn) {
        color: $primary;
        font-weight: $font-weight-normal;
        text-decoration: underline;
    }

    > h6 {
        margin: 0 0 custom-space(0.25) 0;
        font-weight: $font-weight-normal;
    }

    & > .pl-btn {
        align-self: center;

        > input {
            display: none;
        }
    }

    &__table {
        background-color: $white;
        font-size: $font-size-base * 0.95;
        text-align: left;

        > thead > tr > th {
            font-weight: $font-weight-normal;
        }

        > tbody > tr > td > p {
            margin: 0;
        }

        > thead > tr > th,
        > tbody > tr > td > input,
        > tbody > tr > td > ul {
            padding: 0 custom-space(0.5);
        }

        > thead > tr,
        > tbody > tr:not(:last-child) {
            border-bottom: 1px solid $gray-300;
        }

        > thead > tr > th:not(:first-child),
        > tbody > tr > td:not(:first-child) {
            border-left: 1px solid $gray-300;
        }

        > thead > tr > th:first-child,
        > tbody > tr > td:first-child {
            min-width: 40px;
            font-weight: $font-weight-normal;
            text-align: center;
        }
    }

    &__row--skipped {
        background-color: $gray-100;

        > td > input {
            background: none;
            text-decoration-line: line-through;
        }
    }

    &__cell {
        vertical-align: baseline;

        > input {
            position: relative;
            width: 100%;
            min-width: 200px;
            border: none;
            outline: none;
            border-radius: 0;
            font-weight: $font-weight-light;
            @include pl-transition(box-shadow);

            &:not(:disabled):not([disabled]):is(:hover, :focus) {
                box-shadow: 0 0 0 1px $black;
            }
        }

        > :deep(.pl-validation-errors) > li {
            margin: 0;
            padding: custom-space(0.5) 0;
            font-size: $font-size-base * 0.9;
            line-height: 1;
        }

        &--has-error > input:not(:disabled):not([disabled]) {
            box-shadow: 0 0 0 1px $danger;
        }

        &--skipped {
        }
    }
}

.pl-import-entity-modal {
    :deep(.pl-modal__wrapper) {
        margin: 0;

        > section {
            height: 100%;
            align-items: center;
            max-width: custom-space(60);

            > h2 {
                display: flex;
                flex-direction: column;
                align-items: center;

                & > small {
                    font-weight: $font-weight-light;

                    @include media-breakpoint-down(xl) {
                        max-width: 220px;
                    }
                }
            }

            > .pl-modal__content-wrapper {
                border: 1px solid $gray-300;
                overflow-y: auto;

                > .pl-modal__content {
                    height: 100%;
                }
            }
        }
    }

    :deep(.pl-modal__actions) {
        width: 100%;
        max-width: custom-space(25);
        margin-top: auto;
        padding-top: custom-space(0.5);
    }
}
</style>
