import { isValidPhoneNumber } from 'libphonenumber-js';

import { isFieldEmpty } from '@/utils/formUtils';

export const required = ({ value }, name, message = 'required') => {
    if (isFieldEmpty(value)) {
        return `${name} is ${message}.`;
    }

    return null;
};

export const requiredField =
    (field) =>
    ({ value }, name) =>
        required({ value: value[field] }, name);

export const requiredWith =
    (field) =>
    ({ state, value }, name) => {
        const isFieldFilled = required({ value: state[field] }, field) === null;

        if (isFieldFilled) {
            return required({ value }, name);
        }

        return null;
    };

export const requiredIf =
    (conditionCallback) =>
    ({ value }, name) => {
        if (conditionCallback()) {
            return required({ value }, name);
        }

        return null;
    };

export const maxLength =
    (length) =>
    ({ value }, name, message = 'too long') => {
        if (isFieldEmpty(value)) {
            return null;
        }

        const valueLength = (Array.isArray(value) ? value : String(value)).length;

        if (valueLength > length) {
            return `${name} is ${message}.`;
        }

        return null;
    };

export const minLength =
    (length) =>
    ({ value }, name, message = 'too short') => {
        if (isFieldEmpty(value)) {
            return null;
        }

        const valueLength = (Array.isArray(value) ? value : String(value)).length;

        if (valueLength < length) {
            return `${name} is ${message}.`;
        }

        return null;
    };

export const minValue =
    (min) =>
    ({ value }, name, message = "can't be less then") => {
        if (isFieldEmpty(value)) {
            return null;
        }

        if (Number.isNaN(+value)) {
            return `${name} should be number`;
        }

        if (min > +value) {
            return `${name} ${message} ${min}`;
        }

        return null;
    };

export const maxValue =
    (max) =>
    ({ value }, name, message = "can't be less then") => {
        if (isFieldEmpty(value)) {
            return null;
        }

        if (Number.isNaN(+value)) {
            return `${name} should be number`;
        }

        if (+value > max) {
            return `${name} ${message} ${max}`;
        }

        return null;
    };

export const email = ({ value }, name, message = 'not valid') => {
    if (isFieldEmpty(value)) {
        return null;
    }

    const emailRegex = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    if (!emailRegex.test(value)) {
        return `${name} is ${message}.`;
    }

    return null;
};

export const phone =
    (countries) =>
    ({ value }) => {
        const targetCountry = countries.find((country) => country.id === value.phone_country_id);

        if (targetCountry !== undefined && !isValidPhoneNumber('+' + targetCountry.phone_code + value.phone)) {
            return 'You provided an incorrect or incompatible phone number, please correct it and try again.';
        }

        return null;
    };
