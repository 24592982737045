<script>
export default {
    name: 'MyAccountsIndexView',
};
</script>

<script setup>
import { computed, onBeforeMount, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import { ONBOARDING_ROUTE_NAMES, PL_ROUTE_NAMES, SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import UserModel from '@/models/User';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useConfirmationModal from '@/composition/useConfirmationModal';
import Alert from '@/components/Alert';
import ListDisplay from '@/components/ListDisplay';
import Tabs from '@/components/Tabs';
import ConfirmationModal from '@/components/ConfirmationModal';
import AccountCard from '@/components/model-specific/account/AccountCard';

const router = useRouter();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;

/*------------------------------------------------------------------------
                               Accounts state
------------------------------------------------------------------------*/

const accountsState = reactive({
    isDataLoading: true,
    isActionProcessing: false,
    hasError: false,
});

const loadAccounts = () =>
    sendAbortableRequest(UserModel.all())
        .then((models) => {
            if (models !== null) {
                store.commit('setAccounts', models);
            }
        })
        .catch(() => (accountsState.hasError = true))
        .finally(() => (accountsState.isDataLoading = false));

const initCreateNewCompany = () => {
    store.commit('auth/setCurrentAccount', null);

    router.replace({ name: ONBOARDING_ROUTE_NAMES.CREATE_COMPANY });
};

const enterCompany = (account) => {
    store.dispatch('reinitializeStore', account);

    router.push({ name: PL_ROUTE_NAMES.INDEX });
};

const acceptInvitation = (account) => {
    accountsState.isActionProcessing = true;

    account
        .join()
        .then((model) => {
            enterCompany(model);

            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: "Congratulations! You've successfully accepted the invitation.",
            });
        })
        .finally(() => (accountsState.isActionProcessing = false));
};

const getSortedAccountList = (list) =>
    [...list].sort((a, b) => {
        if (currentAccount.id === a.id) {
            return -1;
        }

        if (currentAccount.id === b.id) {
            return 1;
        }

        return 0;
    });

const accountsLists = computed(() => ({
    joined: getSortedAccountList(store.getters.joinedAccounts),
    invitations: store.getters.invitedAccounts,
}));

onBeforeMount(loadAccounts);

/*------------------------------------------------------------------------
                                   Tabs
------------------------------------------------------------------------*/

const tabOptions = computed(() =>
    Object.entries(accountsLists.value).map(([key, values]) => ({
        value: key,
        text: `${key} (${values.length})`,
    }))
);

/*------------------------------------------------------------------------
                         Confirmation modal state
------------------------------------------------------------------------*/

const { confirmationState, confirmationModal } = useConfirmationModal();

const submitDeleteAccounts = () => {
    confirmationModal
        .submit(() => UserModel.deleteAccounts(), false)
        .then(() => {
            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: 'Your account has been successfully deleted!',
            });

            setTimeout(() => {
                store.commit('auth/setCurrentAccount', null);

                window.location.href = '/';
            }, 1000);
        });
};
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.INDEX">
        <template #title>
            <h1>My accounts</h1>
        </template>

        <OverlayLoader v-if="accountsState.isActionProcessing" />

        <InPlaceLoader v-if="accountsState.isDataLoading" />

        <Alert
            v-else-if="accountsState.hasError"
            :type="ALERT_TYPES.FAIL"
        >
            Accounts could not be loaded for some reason. Please try again later.
        </Alert>

        <Tabs
            v-else
            :tab-options="tabOptions"
        >
            <template #content="{ activeTab }">
                <ListDisplay
                    v-if="accountsLists[activeTab].length > 0"
                    has-borders
                    :data-testid="`${activeTab}_accounts_list`"
                    :items="accountsLists[activeTab]"
                >
                    <template #item="{ item: account }">
                        <AccountCard
                            :account="account"
                            :is-current="currentAccount.id === account.id"
                            :is-joined-list="tabOptions[0].value === activeTab"
                            @enter-company="enterCompany(account)"
                            @accept-invitation="acceptInvitation(account)"
                        />
                    </template>
                </ListDisplay>

                <p
                    v-else
                    :data-testid="`${activeTab}_empty_list_message`"
                >
                    The list is empty.
                </p>

                <div
                    class="pl-accounts__actions"
                    style="transition-delay: 50ms"
                >
                    <button
                        type="button"
                        @click="confirmationModal.open"
                    >
                        Delete Account
                    </button>

                    <BtnUI
                        is-filled
                        @click="initCreateNewCompany"
                    >
                        Create a new company
                    </BtnUI>
                </div>
            </template>
        </Tabs>

        <ConfirmationModal
            v-if="confirmationState.isOpened"
            qa-prefix="delete_account_modal"
            :is-pending="confirmationState.isPending"
            @close="confirmationModal.close"
            @submit-delete="submitDeleteAccounts"
        >
            <template #title>
                Delete your Prep List account?
            </template>

            <div class="pl-delete-account-modal-content">
                <span>Organization(s) you joined:</span>

                <span
                    v-for="account in store.state.accounts"
                    :key="`account: ${account.id}`"
                >
                    {{ account.organization.name }}
                </span>
            </div>

            <b>Are you sure you want to delete your account?</b>
        </ConfirmationModal>
    </TopBarLayout>
</template>

<style lang="scss" scoped>
.pl-accounts__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: custom-space(0.5);
    margin-top: auto;
    padding-top: custom-space(2);

    & > button:first-child {
        color: $primary;
        font-weight: $font-weight-light;
    }

    & > .pl-btn--filled {
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
}

.pl-delete-account-modal-content {
    display: flex;
    flex-direction: column;
    margin-bottom: custom-space(1);
}
</style>
