<script>
export default {
    name: 'SelectableItemsAssignee',
};
</script>

<script setup>
import { reactive, computed } from 'vue';

import { getBatchYieldQtyAmount } from '@/utils/batchUnitsUtils';
import { DISTRIBUTION_MULTIPLE_OPTION } from '@/enums/selectableItemsEnums';
import Tabs from '@/components/Tabs';
import TreeDisplay from '@/components/TreeDisplay';
import ListDisplay from '@/components/ListDisplay';
import ItemDistributionModal from '@/components/model-specific/prep-lists/item-distribution-modal';
import SelectableItemAssigneeCard from './partials/SelectableItemAssigneeCard';

const props = defineProps({
    orderItems: {
        type: Object,
        required: true,
    },
    itemsAssignee: {
        type: Object,
        required: true,
    },
    isDataLoading: {
        type: Boolean,
        required: true,
    },
});

const emit = defineEmits(['set-assignee', 'set-distribution', 'set-qty']);

/*------------------------------------------------------------------------
                          Item distribution modal
------------------------------------------------------------------------*/

const itemDistributionState = reactive({
    item: null,
    itemAssigneeData: null,
    isModalOpened: false,
});

const openItemDistributionModal = (item) => {
    const { id, units_data: unitsData } = item;

    const itemAssigneeData = props.itemsAssignee[id];

    const getFreshOrderedBatchYieldQty = () => {
        if (!itemAssigneeData.qty) {
            return item.ordered_BY_qty;
        }

        return getBatchYieldQtyAmount(unitsData, unitsData.prep_list_ordering_unit, itemAssigneeData.qty);
    };

    itemDistributionState.isModalOpened = true;
    itemDistributionState.itemAssigneeData = itemAssigneeData;
    itemDistributionState.item = {
        ...item,
        ordered_BY_qty: getFreshOrderedBatchYieldQty(),
    };
};

const closeItemDistributionModal = () => (itemDistributionState.isModalOpened = false);

const setDistribution = (payload) => {
    emit('set-distribution', { ...payload, id: itemDistributionState.item.id });

    closeItemDistributionModal();
};

/*------------------------------------------------------------------------
                             Order items state
------------------------------------------------------------------------*/

const setAssignee = ({ item, value }) => {
    if (value === DISTRIBUTION_MULTIPLE_OPTION) {
        openItemDistributionModal(item);
    } else {
        emit('set-assignee', { item, value });
    }
};

const setQty = ({ prepTask, value }) => {
    emit('set-qty', { prepTask, value });
};

/*------------------------------------------------------------------------
                                   Tabs
------------------------------------------------------------------------*/

const departmentOptions = computed(() =>
    Object.keys(props.orderItems).map((department) => ({ value: department, text: department }))
);
</script>

<template>
    <InPlaceLoader v-if="isDataLoading" />

    <Tabs
        v-else
        include-content-container
        enable-slider-mode
        :tab-options="departmentOptions"
    >
        <template #content="{ activeTab }">
            <TreeDisplay :data="orderItems[activeTab]">
                <template #leaf="{ leaf }">
                    <ListDisplay :items="leaf">
                        <template #item="{ item }">
                            <SelectableItemAssigneeCard
                                :item="item"
                                :items-assignee="itemsAssignee"
                                @set-assignee="setAssignee"
                                @set-qty="setQty"
                            />
                        </template>
                    </ListDisplay>
                </template>
            </TreeDisplay>
        </template>
    </Tabs>

    <ItemDistributionModal
        v-if="itemDistributionState.isModalOpened"
        :item="itemDistributionState.item"
        :item-assignee-data="itemDistributionState.itemAssigneeData"
        @close-modal="closeItemDistributionModal"
        @set-distribution="setDistribution"
    />
</template>
