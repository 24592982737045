<script>
export default {
    name: 'EmployeeAvatar',
};
</script>

<script setup>
import { ref, useSlots } from 'vue';

import ImageCropper from '@/components/ImageCropper';
import UserIcon from '@/components/icons/UserIcon';
import UploadIcon from '@/components/icons/UploadIcon';

const props = defineProps({
    size: {
        type: Number,
        default: 50,
    },
    url: {
        type: String,
        default: null,
    },
    includeControls: {
        type: Boolean,
        default: false,
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['delete', 'save']);

const slots = useSlots();

/*------------------------------------------------------------------------
                                File state
------------------------------------------------------------------------*/

const fileInputRef = ref(null);
const imageFileUrl = ref(null);

const initFileUploading = () => {
    if (props.includeControls) {
        fileInputRef.value.click();
    }
};

const onFileChanged = (event) => {
    const file = event.target.files[0];

    if (!file) return;

    if (imageFileUrl.value) {
        URL.revokeObjectURL(imageFileUrl.value);
    }

    imageFileUrl.value = URL.createObjectURL(file);

    fileInputRef.value.value = '';
};

const cropImage = (formData) => {
    imageFileUrl.value = null;

    emit('save', formData);
};
</script>

<template>
    <div
        class="pl-employee-avatar"
        :class="{
            'pl-employee-avatar--selected': url && !isLoading,
        }"
        :style="`width: ${size}px; height: ${size}px;`"
        @click="initFileUploading"
    >
        <input
            ref="fileInputRef"
            type="file"
            accept="image/png, image/jpg, image/jpeg"
            :disabled="isLoading"
            @change="onFileChanged"
        />

        <InPlaceLoader v-if="isLoading" />

        <img
            v-else-if="url"
            alt="avatar"
            :src="url"
        />

        <UserIcon v-else />

        <div
            v-if="includeControls && !isLoading"
            class="pl-employee-avatar__actions"
            :class="{
                'pl-employee-avatar__actions--only-upload': !url && !slots['actions'],
            }"
        >
            <slot
                v-if="includeControls && !isLoading"
                name="actions"
            >
                <button
                    v-if="url"
                    type="button"
                    @click.stop="emit('delete')"
                >
                    <DeleteIcon
                        enable-current-color
                        :width="16"
                        :height="16"
                    />
                </button>

                <button type="button">
                    <UploadIcon
                        :width="20"
                        :height="20"
                    />
                </button>
            </slot>
        </div>
    </div>

    <ImageCropper
        v-if="imageFileUrl !== null"
        :img-src="imageFileUrl"
        @close="imageFileUrl = null"
        @crop="cropImage"
    />
</template>

<style lang="scss" scoped>
.pl-employee-avatar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray-200;
    border-radius: 50%;
    color: $white;
    cursor: pointer;

    & > img {
        max-width: 100%;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        object-fit: cover;
    }

    & > svg {
        width: 75%;
        height: 75%;
    }

    & > input[type='file'] {
        display: none;
    }

    &:not(&--selected) {
        border: 1px solid $gray-200;
    }

    &__actions {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > button {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white;
            color: $primary;
            border: 1px solid $gray-200;
            border-radius: 50%;
            @include pl-transition((background-color, border));

            @include hover {
                background-color: $primary;
                border-color: $primary;
                color: $white;
            }

            & > svg {
                @include pl-transition(all);
            }
        }

        &--only-upload {
            justify-content: center;
            bottom: custom-space(-0.75);

            & > button {
                border-color: $primary;
            }
        }
    }
}
</style>
