<script>
export default {
    name: 'PARTemplateCard',
};
</script>

<script setup>
import { computed } from 'vue';

import { isOrderEditing, isOrderCreating, isOrderCompleted } from '@/utils/orderUtils';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ORDER_TYPES } from '@/enums/orderEnums';
import Badge from '@/components/Badge';

const props = defineProps({
    currentAccount: {
        type: Object,
        required: true,
    },
    template: {
        type: Object,
        required: true,
    },
    templateType: {
        type: String,
        required: true,
    },
});

/*------------------------------------------------------------------------
                            PAR template state
------------------------------------------------------------------------*/

const getPARTemplateLink = (template) => {
    const { templateType } = props;

    const isTemplateAuthor = props.currentAccount.id === template.author.id;

    if (template.id === null || (isTemplateAuthor && isOrderCreating(template))) {
        return {
            name:
                templateType === ORDER_TYPES.PREP_LIST
                    ? SETTINGS_ROUTE_NAMES.PAR.CREATE.PL.INDEX
                    : SETTINGS_ROUTE_NAMES.PAR.CREATE.QF.INDEX,
        };
    }

    if (isTemplateAuthor && isOrderEditing(template)) {
        return {
            name:
                templateType === ORDER_TYPES.PREP_LIST
                    ? SETTINGS_ROUTE_NAMES.PAR.EDIT.PL.INDEX
                    : SETTINGS_ROUTE_NAMES.PAR.EDIT.QF.INDEX,
            params: { templateId: template.id },
        };
    }

    if (isOrderCompleted(template)) {
        return {
            name:
                templateType === ORDER_TYPES.PREP_LIST
                    ? SETTINGS_ROUTE_NAMES.PAR.SHOW.PL
                    : SETTINGS_ROUTE_NAMES.PAR.SHOW.QF,
            params: { templateId: template.id },
        };
    }

    return { name: SETTINGS_ROUTE_NAMES.PAR.INDEX };
};

const templateState = computed(() => {
    const { template } = props;

    return {
        link: getPARTemplateLink(template),
        isAuthor: props.currentAccount.id === template.author.id,
        isPending: template.status !== null && !isOrderCompleted(template),
    };
});
</script>

<template>
    <div class="pl-par-template-card">
        <div class="pl-par-template-card__name">
            <OrderIcon />

            <h6>{{ template.name }}</h6>
        </div>

        <div
            v-if="templateState.isPending && !templateState.isAuthor"
            class="pl-par-template-card__badge-wrapper"
        >
            <Badge size="sm">
                {{ `being ${template.status} by ${template.author.name}` }}
            </Badge>
        </div>

        <router-link
            v-else
            class="pl-btn pl-btn--sm"
            :class="{
                'pl-btn--filled': templateState.isPending,
            }"
            :to="templateState.link"
        >
            {{ templateState.isPending ? `Continue ${template.status}` : 'View Details' }}
        </router-link>
    </div>
</template>

<style lang="scss" scoped>
.pl-par-template-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: custom-space(0.25);

    &__name {
        display: flex;
        align-items: center;
        gap: custom-space(0.5);

        & > h6 {
            margin: 0;
            font-weight: $font-weight-normal;
        }
    }

    &__badge-wrapper {
        flex: 1 1 100%;
        display: flex;
    }
}
</style>
