import {
    JOIN_ROUTE_NAME,
    LOGIN_ROUTE_NAME,
    ONBOARDING_ROUTE_NAMES,
    SELECT_ACCOUNT_ROUTE_NAME,
} from '@/enums/routesNameEnums';

import LoginView from '@/views/auth/login';
import JoinView from '@/views/auth/join';
import SelectAccountView from '@/views/auth/select-account';

import OnboardingIndexView from '@/views/auth/onboarding';
import OnboardingStep1View from '@/views/auth/onboarding/step-1';
import OnboardingStep2View from '@/views/auth/onboarding/step-2';
import OnboardingStep3View from '@/views/auth/onboarding/step-3';
import OnboardingStep4View from '@/views/auth/onboarding/step-4';
import OnboardingStep5View from '@/views/auth/onboarding/step-5';

export default [
    {
        path: '/',
        name: LOGIN_ROUTE_NAME,
        component: LoginView,
    },
    {
        path: '/join',
        name: JOIN_ROUTE_NAME,
        component: JoinView,
    },
    {
        path: '/select-account',
        name: SELECT_ACCOUNT_ROUTE_NAME,
        component: SelectAccountView,
    },

    {
        path: '/onboarding',
        children: [
            {
                path: '',
                name: ONBOARDING_ROUTE_NAMES.INDEX,
                component: OnboardingIndexView,
            },

            {
                path: '/create-company',
                name: ONBOARDING_ROUTE_NAMES.CREATE_COMPANY,
                component: OnboardingStep1View,
            },
            {
                path: 'create-locations',
                name: ONBOARDING_ROUTE_NAMES.CREATE_LOCATIONS,
                component: OnboardingStep2View,
            },
            {
                path: 'invite-employees',
                name: ONBOARDING_ROUTE_NAMES.INVITE_EMPLOYEES,
                component: OnboardingStep3View,
            },
            {
                path: 'create-departments',
                name: ONBOARDING_ROUTE_NAMES.CREATE_DEPARTMENTS,
                component: OnboardingStep4View,
            },
            {
                path: 'create-items',
                name: ONBOARDING_ROUTE_NAMES.CREATE_ITEMS,
                component: OnboardingStep5View,
            },
        ],
    },
];
