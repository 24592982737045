<script>
export default {
    name: 'SimpleEntityFormModal',
};
</script>

<script setup>
import { computed, watch } from 'vue';

import { required, maxLength, minLength } from '@/utils/formValidators';
import { capitalizeFirstLetter } from '@/utils/stringUtils';
import { ITEM_CONFIGURATION_ENTITIES_NAME } from '@/enums/itemEnums';
import useForm from '@/composition/useForm';
import Modal from '@/components/Modal';
import SelectInput from '@/components/form-controls/SelectInput';
import TextInput from '@/components/form-controls/TextInput';
import FormUI from '@/components/UI/FormUI';

const props = defineProps({
    entityName: {
        type: String,
        required: true,
    },
    formData: {
        type: Object,
        default: null,
    },
    departments: {
        type: Array,
        default: () => [],
    },
    validationErrors: {
        type: Object,
        default: null,
    },
    isSubmitting: {
        type: Boolean,
        default: false,
    },
    isEditModeAllowed: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['submit', 'close']);

/*------------------------------------------------------------------------
                                Setup form
------------------------------------------------------------------------*/

const includeDepartmentId = props.entityName === ITEM_CONFIGURATION_ENTITIES_NAME.CATEGORY;

const { formState, ...form } = useForm(
    {
        ...(includeDepartmentId ? { department_id: null } : {}),
        name: '',
    },
    {
        ...(includeDepartmentId ? { department_id: { required } } : {}),
        name: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(255),
        },
    },
    {
        department_id: 'Department',
        name: `${capitalizeFirstLetter(props.entityName)} name`,
    }
);

const submitForm = () => {
    if (form.validate()) {
        emit('submit', formState);
    }
};

const isSaveBtnDisabled = computed(() => {
    const { value, name } = props.formData || {};

    const currentName = name || value;

    return formState.name === currentName || props.isSubmitting || form.isDisabled();
});

watch(
    () => props.formData,
    (formData) => {
        if (formData !== null) {
            formState.name = formData.name || formData.value;

            if (includeDepartmentId) {
                formState.department_id = formData.department_id;
            }
        }
    },
    { immediate: true }
);

watch(() => props.validationErrors, form.setValidationErrors);

/*------------------------------------------------------------------------
                                Departments
------------------------------------------------------------------------*/

const departmentOptions = computed(() => props.departments.map(({ id, name }) => ({ value: id, text: name })));
</script>

<template>
    <Modal
        :is-close-disabled="isSubmitting"
        @close="emit('close')"
    >
        <template #title>
            {{ `${formData !== null && isEditModeAllowed ? `Edit ${entityName}` : `Create new ${entityName}`}` }}
        </template>

        <template #content>
            <FormUI
                id="pl-simple-entity-form"
                disable-animation
                :data-test-id="`${entityName}_form`"
                :is-loading="isSubmitting"
                @submit="submitForm"
            >
                <template #content="{ classNames, qaPrefix }">
                    <div
                        v-if="includeDepartmentId"
                        :class="classNames.spacerMd"
                    >
                        <SelectInput
                            v-model="formState.department_id"
                            searchable
                            include-asterisk
                            label="Department"
                            :data-test-id="`${qaPrefix}_department_select`"
                            :options="departmentOptions"
                            :disabled="departmentOptions.length === 0 || formData !== null || isSubmitting"
                            :has-error="form.hasErrors('department_id')"
                            @blur="form.validate('department_id')"
                            @update:modelValue="form.hasErrors('department_id')"
                        />

                        <ValidationErrors
                            v-if="form.hasErrors('department_id')"
                            :data-testid="`${qaPrefix}_department_errors`"
                            :errors="form.getErrors('department_id')"
                        />
                    </div>

                    <TextInput
                        v-model="formState.name"
                        include-asterisk
                        name="name"
                        autocomplete="name"
                        :label="`Name of ${entityName}`"
                        :data-test-id="`${qaPrefix}_name_input`"
                        :disabled="isSubmitting"
                        :has-error="form.hasErrors('name')"
                        @blur="form.validate('name')"
                        @update:modelValue="form.clearErrors('name')"
                    />

                    <ValidationErrors
                        v-if="form.hasErrors('name')"
                        :data-test-id="`${qaPrefix}_name_error`"
                        :errors="form.getErrors('name')"
                    />
                </template>
            </FormUI>
        </template>

        <template #actions>
            <BtnUI @click="emit('close')">
                Cancel
            </BtnUI>

            <BtnUI
                is-filled
                type="submit"
                form="pl-simple-entity-form"
                :data-test-id="`${entityName}_form_submit_btn`"
                :disabled="isSaveBtnDisabled"
            >
                Save
            </BtnUI>
        </template>
    </Modal>
</template>

<style lang="scss" scoped>
:deep(.pl-form) > .pl-overlay-spinner {
    border-radius: $border-radius;
}
</style>
