import { onBeforeMount, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { mapLeafs } from '@/utils/treeUtils';
import { getBatchUnitQtyStep, getBatchYieldQtyAmount } from '@/utils/batchUnitsUtils';
import { catchOrderPrepDateGoneError } from '@/utils/httpUtils';
import { ORDER_GROUP_ITEMS_BY } from '@/enums/orderEnums';
import { DISTRIBUTION_TYPES } from '@/enums/selectableItemsEnums';
import useAbortableRequest from '@/composition/useAbortableRequest';

const useSelectableItemsAssignee = (model, orderId) => {
    const router = useRouter();
    const store = useStore();

    const { sendAbortableRequest } = useAbortableRequest();

    const { currentAccount } = store.state.auth;
    const orgId = currentAccount.organization.id;

    /*------------------------------------------------------------------------
                                Order items state
    ------------------------------------------------------------------------*/

    const orderItemsState = reactive({
        data: {},
        isDataLoading: true,
    });

    const loadOrderItems = () => {
        sendAbortableRequest(
            model.getAssignees(orgId, orderId, {
                group_items_by: ORDER_GROUP_ITEMS_BY.CATEGORY,
            })
        )
            .then((data) => {
                const { items = {} } = data || {};

                orderItemsState.data = items;

                setInitialItemsAssignees(items);
            })
            .finally(() => (orderItemsState.isDataLoading = false));
    };

    onBeforeMount(loadOrderItems);

    /*------------------------------------------------------------------------
                               Items assignee state
    ------------------------------------------------------------------------*/

    const itemsAssigneeState = reactive({
        data: {},
        isSubmitting: false,
    });

    const setInitialItemAssignee = (item) => {
        const {
            id,
            assignee_id: defaultAssigneeId,
            available_assignees: availableAssignees,
            ordered_BY_qty: orderedBYQty,
        } = item;

        const availableAssigneeIds = new Set(availableAssignees.map((assignee) => assignee.id));

        let distributionType = item.distribution_type;
        let distribution = item.distribution;

        if (distribution.length > 0 && distribution[0].assignee_id === null) {
            distributionType = DISTRIBUTION_TYPES.OPEN_ITEMS;
            distribution = [{ qty: orderedBYQty }];
        }

        const isAssigneeUnavailable = distribution.some((assignee) => !availableAssigneeIds.has(assignee.assignee_id));

        if (distributionType !== DISTRIBUTION_TYPES.OPEN_ITEMS && isAssigneeUnavailable) {
            distributionType = null;
            distribution = [];
        }

        if (distributionType === null && availableAssigneeIds.has(defaultAssigneeId)) {
            distributionType = DISTRIBUTION_TYPES.DIRECT;
            distribution = [{ assignee_id: defaultAssigneeId, qty: orderedBYQty }];
        }

        itemsAssigneeState.data[id] = {
            item_id: id,
            distribution_type: distributionType,
            distribution,
        };
    };

    const setInitialItemsAssignees = (items) => {
        mapLeafs(items, (item) => {
            const { prep_tasks: prepTasks } = item;

            if (prepTasks.length === 0) {
                setInitialItemAssignee(item);
            } else {
                prepTasks.forEach((prepTask) => {
                    setInitialItemAssignee(prepTask);

                    const { id, units_data: unitsData } = prepTask;
                    const { prep_list_ordering_unit: orderingUnit } = unitsData;

                    const prepTaskUnitStep = getBatchUnitQtyStep(unitsData, orderingUnit);

                    itemsAssigneeState.data[id].qty = prepTask.ordered_BY_qty / prepTaskUnitStep;
                    itemsAssigneeState.data[id].qtyStep = item.ordered_BY_qty / prepTaskUnitStep;
                    itemsAssigneeState.data[id].unitStep = prepTaskUnitStep;
                    itemsAssigneeState.data[id].orderingUnitText = orderingUnit?.value || 'batches';
                    itemsAssigneeState.data[id].unitsData = unitsData;
                });
            }

            return item;
        });
    };

    const setAssignee = ({ item, value }) => {
        const { ordered_BY_qty: orderedBYQty } = item;

        const assigneeData = itemsAssigneeState.data[item.id];

        if (typeof value === 'number') {
            assigneeData.distribution_type = DISTRIBUTION_TYPES.DIRECT;
            assigneeData.distribution = [{ assignee_id: value, qty: orderedBYQty }];
        } else {
            assigneeData.distribution_type = value;
            assigneeData.distribution = [{ qty: orderedBYQty }];
        }
    };

    const setDistribution = ({ id, distributionType, distribution }) => {
        const assigneeData = itemsAssigneeState.data[id];

        assigneeData.distribution_type = distributionType;
        assigneeData.distribution = distribution;
    };

    const setQty = ({ prepTask, value }) => {
        const assigneeData = itemsAssigneeState.data[prepTask.id];

        assigneeData.qty = value;
        assigneeData.distribution_type = null;
        assigneeData.distribution = [];
    };

    const isInvalid = () => orderItemsState.isDataLoading || itemsAssigneeState.isSubmitting;

    const getPayload = () => {
        const payload = {};

        for (const assigneeId in itemsAssigneeState.data) {
            const assigneeData = itemsAssigneeState.data[assigneeId];

            if (assigneeData.distribution_type === DISTRIBUTION_TYPES.MIXED) {
                continue;
            }

            const { qty } = assigneeData;

            payload[assigneeId] = {
                item_id: assigneeData.item_id,
                distribution_type: qty === 0 ? null : assigneeData.distribution_type || DISTRIBUTION_TYPES.OPEN_ITEMS,
                distribution: qty === 0 ? [] : assigneeData.distribution,
            };

            if (qty !== undefined) {
                const { unitsData } = assigneeData;

                payload[assigneeId].qty = getBatchYieldQtyAmount(unitsData, unitsData.prep_list_ordering_unit, qty);
            }
        }

        return Object.values(payload);
    };

    const submit = (nextRoute) => {
        if (!isInvalid()) {
            itemsAssigneeState.isSubmitting = true;

            model
                .setAssignees(orgId, orderId, { items: getPayload() })
                .then(() => router.push(nextRoute))
                .catch(catchOrderPrepDateGoneError)
                .finally(() => (itemsAssigneeState.isSubmitting = false));
        }
    };

    return {
        selectableOrderItemsState: orderItemsState,
        selectableItemsAssigneeState: itemsAssigneeState,
        selectableItemsAssignee: {
            setAssignee,
            setDistribution,
            setQty,
            isInvalid,
            submit,
        },
    };
};

export default useSelectableItemsAssignee;
