<script>
export default {
    name: 'ReceivedNotifications',
};
</script>

<script setup>
import { computed, onBeforeMount, onBeforeUnmount, reactive } from 'vue';
import { useStore } from 'vuex';
import dayjs from 'dayjs';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import NotificationModel from '@/models/Notification';
import useAbortableRequest from '@/composition/useAbortableRequest';
import InfiniteScrollList from './partials/InfiniteScrollList';
import NotificationCard from './partials/NotificationCard';

const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

/*------------------------------------------------------------------------
                            Notifications state
------------------------------------------------------------------------*/

const notificationsState = reactive({
    data: [],
    links: {},
    isDataLoading: true,
    isSubmitting: false,
});

const loadNotifications = () => {
    sendAbortableRequest(NotificationModel.all())
        .then((response) => {
            if (response !== null) {
                notificationsState.data = response.data;
                notificationsState.links = response.links;

                store.commit('setUnreadNotificationsCount', response.unread_notifications_count);
            }
        })
        .finally(() => (notificationsState.isDataLoading = false));
};

const markAllNotificationsAsRead = () => {
    notificationsState.isSubmitting = true;

    NotificationModel.markAllAsRead()
        .then((response) => {
            notificationsState.data = response.notifications;

            store.commit('setUnreadNotificationsCount', response.unread_notifications_count);

            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: 'All notifications have been successfully read!',
            });
        })
        .finally(() => (notificationsState.isSubmitting = false));
};

const setNextNotificationsPage = (response) => {
    notificationsState.data.push(...NotificationModel.make(response.data));

    notificationsState.links = response.links;
};

const setUpdatedNotificationData = (response) => {
    const { notification: updatedNotification } = response;

    notificationsState.data = notificationsState.data.map((notification) => {
        if (notification.id === updatedNotification.id) {
            return updatedNotification;
        }

        return notification;
    });

    store.commit('setUnreadNotificationsCount', response.unread_notifications_count);

    EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
        type: ALERT_TYPES.SUCCESS,
        message: 'The notification has been successfully read!',
    });
};

const unreadNotificationsCount = computed(() => store.state.auth.currentAccount.unread_notifications_count);

onBeforeMount(() => {
    loadNotifications();

    EventBus.on(EVENT_BUS_EVENTS.NOTIFICATION_RECEIVED, (notification) => {
        notificationsState.data.unshift(
            NotificationModel.make({
                ...notification,
                data: notification,
                read_at: null,
                created_at: dayjs(),
            })
        );
    });

    EventBus.on(EVENT_BUS_EVENTS.NOTIFICATION_READ, (response) => {
        const touchedNotifications = response.touched_notifications.reduce((acc, notification) => {
            acc[notification.id] = notification;

            return acc;
        }, {});

        notificationsState.data = notificationsState.data.map((notification) => {
            const touchedNotification = touchedNotifications[notification.id];

            if (touchedNotification) {
                return NotificationModel.make({
                    ...notification,
                    ...touchedNotification,
                });
            }

            return notification;
        });
    });
});

onBeforeUnmount(() => {
    EventBus.off(EVENT_BUS_EVENTS.NOTIFICATION_RECEIVED);
    EventBus.off(EVENT_BUS_EVENTS.NOTIFICATION_READ);
});
</script>

<template>
    <div class="pl-received-notifications">
        <OverlayLoader v-if="notificationsState.isSubmitting" />

        <InPlaceLoader v-if="notificationsState.isDataLoading" />

        <small v-else-if="notificationsState.data.length === 0">You do not have any notifications yet.</small>

        <template v-else>
            <InfiniteScrollList
                :data="notificationsState.data"
                :links="notificationsState.links"
                @set-next-page-data="setNextNotificationsPage"
            >
                <template #item="{ item: notification }">
                    <NotificationCard
                        :notification="notification"
                        @set-updated-data="setUpdatedNotificationData"
                    />
                </template>
            </InfiniteScrollList>

            <div
                v-if="unreadNotificationsCount > 0"
                class="pl-received-notifications__actions"
            >
                <BtnUI
                    is-filled
                    size="sm"
                    :disabled="notificationsState.isSubmitting"
                    @click.stop="markAllNotificationsAsRead"
                >
                    Mark all read
                </BtnUI>
            </div>
        </template>
    </div>
</template>

<style lang="scss" scoped>
.pl-received-notifications {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: custom-space(18);
    min-height: custom-space(5);
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    overflow: hidden;

    & > :deep(.pl-in-place-loader) {
        height: custom-space(5);
    }

    & > small {
        text-align: center;
    }

    &__actions {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: custom-space(0.5) 0;
        border-top: 1px solid $gray-100;
    }
}
</style>
