<script>
export default {
    name: 'QFOrdersIndexView',
};
</script>

<script setup>
import OrdersIndexViewHOC from '@/components/model-specific/orders/OrdersIndexViewHOC';
import TimelineView from './partials/TimelineView';
</script>

<template>
    <OrdersIndexViewHOC>
        <template #view-as-manager>
            <TimelineView />
        </template>
    </OrdersIndexViewHOC>
</template>
