import { TASK_REMINDER_ROUTE_NAME } from '@/enums/routesNameEnums';
import TaskReminderView from '@/views/dashboard/task-reminder';
import quickFillOrdersRoutes from './quick-fill-orders';
import prepListsRoutes from './prep-lists';
import reportsRoutes from './reports';
import settingsRoutes from './settings';

export default [
    {
        path: '/dashboard',
        children: [
            ...quickFillOrdersRoutes,
            ...prepListsRoutes,
            ...reportsRoutes,
            ...settingsRoutes,
            {
                path: 'setup-suggestions',
                name: TASK_REMINDER_ROUTE_NAME,
                component: TaskReminderView,
            },
        ],
    },
];
