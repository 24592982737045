<script>
export default {
    name: 'PARTemplatesIndexView',
};
</script>

<script setup>
import { inject, onBeforeMount, reactive, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { PAR_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import { ORDER_TYPES } from '@/enums/orderEnums';
import PARTemplateModel from '@/models/PARTemplate';
import QFPARTemplateModel from '@/models/QFPARTemplate';
import useAbortableRequest from '@/composition/useAbortableRequest';
import Alert from '@/components/Alert';
import Tabs from '@/components/Tabs';
import ListDisplay from '@/components/ListDisplay';
import CreateTemplateFab from './partials/CreateTemplateFab';
import PARTemplateCard from './partials/PARTemplateCard';

const $acl = inject('$acl');

const router = useRouter();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(PAR_RESTRICTIONS.VIEW, currentAccount)) {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to PAR templates.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                            PAR templates state
------------------------------------------------------------------------*/

const templatesState = reactive({
    data: {
        [ORDER_TYPES.PREP_LIST]: [],
        [ORDER_TYPES.QUICK_FILL]: [],
    },
    isDataLoading: true,
    hasError: false,
});

const loadPARTemplates = () => {
    Promise.all([
        sendAbortableRequest(PARTemplateModel.all(orgId)),
        sendAbortableRequest(QFPARTemplateModel.all(orgId)),
    ])
        .then(([PLPARTemplateModels, QFPARTemplateModels]) => {
            templatesState.data[ORDER_TYPES.PREP_LIST] = PLPARTemplateModels;
            templatesState.data[ORDER_TYPES.QUICK_FILL] = QFPARTemplateModels;
        })
        .catch(() => (templatesState.hasError = true))
        .finally(() => (templatesState.isDataLoading = false));
};

onBeforeMount(loadPARTemplates);

/*------------------------------------------------------------------------
                                   Tabs
------------------------------------------------------------------------*/

const tabOptions = computed(() => {
    if (templatesState.isDataLoading) {
        return [];
    }

    return Object.entries(templatesState.data).map(([key, values]) => ({
        value: key,
        text: `${key.replace('_', ' ')} (${values.length})`,
    }));
});
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.INDEX">
        <template #title>
            <h1>PAR</h1>
        </template>

        <InPlaceLoader v-if="templatesState.isDataLoading" />

        <Alert
            v-else-if="templatesState.hasError"
            :type="ALERT_TYPES.FAIL"
        >
            PAR could not be loaded for some reason. Please try again later.
        </Alert>

        <template v-else>
            <Tabs
                :model-value="ORDER_TYPES.PREP_LIST"
                :tab-options="tabOptions"
            >
                <template #content="{ activeTab }">
                    <ListDisplay
                        v-if="templatesState.data[activeTab].length > 0"
                        has-borders
                        :items="templatesState.data[activeTab]"
                    >
                        <template #item="{ item: template }">
                            <PARTemplateCard
                                :current-account="currentAccount"
                                :template="template"
                                :template-type="activeTab"
                            />
                        </template>
                    </ListDisplay>

                    <p v-else>
                        The list is empty.
                    </p>
                </template>
            </Tabs>

            <CreateTemplateFab v-if="$acl.can(PAR_RESTRICTIONS.CREATE, currentAccount)" />
        </template>
    </TopBarLayout>
</template>

<style lang="scss" scoped>
:deep(.pl-tabs__content) > p {
    text-align: center;
}
</style>
