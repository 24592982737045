import { LS } from '@/instances/localStorage';
import { ORDER_DEFAULT_GROUP_ITEMS_BY } from '@/enums/orderEnums';

export default {
    namespaced: true,

    state: () => ({
        groupItemsBy: LS.get('pl_order.view_settings.group_items_by', ORDER_DEFAULT_GROUP_ITEMS_BY),
    }),

    mutations: {
        setGroupItemsBy(state, value) {
            state.groupItemsBy = value;

            LS.set('pl_order.view_settings.group_items_by', value);
        },
    },
};
