<script>
export default {
    name: 'OrderItemsWrapper',
};
</script>

<script setup>
import { computed } from 'vue';

import { isOrderCompleted, isOrderDateGone } from '@/utils/orderUtils';
import { ORDER_TYPES } from '@/enums/orderEnums';
import Tabs from '@/components/Tabs';
import OrderDetails from '@/components/model-specific/orders/OrderDetails';
import OrderSummaryCard from '@/components/model-specific/orders/order-summary-card';
import OrderItemControls from './OrderItemsControls';

const props = defineProps({
    order: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['handle-refresh-order']);

/*------------------------------------------------------------------------
                                   Tabs
------------------------------------------------------------------------*/

const departmentOptions = computed(() =>
    Object.keys(props.order.items.data.data).map((department) => ({ value: department, text: department }))
);
</script>

<template>
    <div
        v-if="isOrderCompleted(order) || isOrderDateGone(order)"
        class="container"
    >
        <OrderSummaryCard
            show-item-progress
            :order="order"
            :order-type="ORDER_TYPES.QUICK_FILL"
        />
    </div>

    <template v-else>
        <OrderDetails
            include-content-container
            :order="order"
        />

        <Tabs
            enable-slider-mode
            include-content-container
            :tab-options="departmentOptions"
        >
            <template #content="{ activeTab }">
                <OrderItemControls
                    :order="order"
                    :items="order.items.data.data[activeTab]"
                    @handle-refresh-order="(args) => emit('handle-refresh-order', args)"
                />
            </template>
        </Tabs>
    </template>
</template>

<style lang="scss" scoped>
.pl-tabs {
    margin-top: 0;
}
</style>
