<script>
export default {
    name: 'PLOrdersIndexMainView',
};
</script>

<script setup>
import OrdersIndexViewHOC from '@/components/model-specific/orders/OrdersIndexViewHOC';
import TimelineMainCook from './partials/timeline-main-cook';
import TimelineMainManager from './partials/TimelineMainManager';
</script>

<template>
    <OrdersIndexViewHOC>
        <template #view-as-cook>
            <TimelineMainCook />
        </template>

        <template #view-as-manager>
            <TimelineMainManager />
        </template>
    </OrdersIndexViewHOC>
</template>
