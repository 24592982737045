<script>
export default {
    name: 'PrepTasksIndexView',
};
</script>

<script setup>
import { watch, inject, computed, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { filterLeafs } from '@/utils/treeUtils';
import { getFilteredQueryParams } from '@/utils/routerUtils';
import { PREP_TASKS_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { PREP_TASKS_FILTER_SETTINGS_CONFIG } from '@/enums/prepTaskEnums';
import PrepTaskModel from '@/models/PrepTask';
import useAbortableRequest from '@/composition/useAbortableRequest';
import ListDisplay from '@/components/ListDisplay';
import TreeDisplay from '@/components/TreeDisplay';
import Fab from '@/components/Fab';
import FilterSettingsTemplate from '@/components/templates/FilterSettingsTemplate';
import SearchForm from '@/components/forms/SearchForm';
import AddItemsIcon from '@/components/icons/AddItemsIcon';
import PrepTaskCard from './partials/PrepTaskCard';

const $acl = inject('$acl');

const router = useRouter();
const route = useRoute();
const store = useStore();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

const { sendAbortableRequest } = useAbortableRequest();

/*------------------------------------------------------------------------
                                  Filters
------------------------------------------------------------------------*/

const filters = reactive({
    group_by: route.query.group_by ?? null,
});

const query = ref('');

const updateFilters = (updatedFilters) => {
    filters.group_by = updatedFilters.group_by;
};

/*------------------------------------------------------------------------
                             Prep tasks state
------------------------------------------------------------------------*/

const prepTasksState = reactive({
    data: null,
    isDataLoading: true,
});

const permissions = computed(() => ({
    create: $acl.can(PREP_TASKS_RESTRICTIONS.CREATE, currentAccount),
    update: $acl.can(PREP_TASKS_RESTRICTIONS.UPDATE, currentAccount),
}));

const loadPrepTasks = () => {
    prepTasksState.isDataLoading = true;

    sendAbortableRequest(PrepTaskModel.all(orgId, filters))
        .then((models) => (prepTasksState.data = models))
        .finally(() => (prepTasksState.isDataLoading = false));
};

const initEditItem = ({ id }) => {
    if (permissions.value.update) {
        router.push({
            name: SETTINGS_ROUTE_NAMES.PREP_TASKS.EDIT,
            params: { prepTaskId: id },
            query: route.query,
        });
    }
};

const prepTaskList = computed(() => {
    const { data } = prepTasksState;

    if (query.value.trim().length === 0) {
        return data;
    }

    return filterLeafs(data, ({ name }) => name.toLowerCase().includes(query.value.toLowerCase()));
});

const arePrepTasksPresent = computed(() => {
    const data = prepTaskList.value;

    if (Array.isArray(data)) {
        return data.length > 0;
    }

    return Object.entries(data).length > 0;
});

const emptyListMessage = computed(() => {
    if (query.value.length > 0) {
        return 'There is no prep tasks according to your request.';
    }

    if (permissions.value.create) {
        return 'The list is empty. Please press the plus icon below to create prep task.';
    }

    return 'No prep tasks here! Please wait until an administrator creates some prep tasks to work with.';
});

watch(
    filters,
    () => {
        router.replace({ query: getFilteredQueryParams(filters) });

        loadPrepTasks();
    },
    { immediate: true }
);
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.INDEX">
        <template #title>
            <h1>Prep Tasks</h1>
        </template>

        <template
            v-if="!prepTasksState.isDataLoading"
            #actions
        >
            <FilterSettingsTemplate
                :initial-filters="filters"
                :filters-config="PREP_TASKS_FILTER_SETTINGS_CONFIG"
                @update-filters="updateFilters"
            />

            <SearchForm
                v-model="query"
                data-testid="prep_tasks_search_form"
            />
        </template>

        <InPlaceLoader v-if="prepTasksState.isDataLoading" />

        <template v-else-if="arePrepTasksPresent">
            <transition
                appear
                type="transition"
                name="down"
            >
                <TreeDisplay
                    :data="prepTaskList"
                    class="pl-prep-tasks-tree"
                >
                    <template #node="{ key }">
                        <div class="pl-prep-tasks-tree__title">
                            {{ key }}
                        </div>
                    </template>

                    <template #leaf="{ leaf }">
                        <ListDisplay
                            has-borders
                            data-testid="prep_tasks_list"
                            :items="leaf"
                            @tap="initEditItem"
                        >
                            <template #item="{ item: prepTask }">
                                <PrepTaskCard
                                    :prep-task="prepTask"
                                    :class="{
                                        clickable: permissions.update,
                                    }"
                                />
                            </template>
                        </ListDisplay>
                    </template>
                </TreeDisplay>
            </transition>

            <Fab
                v-if="permissions.create"
                data-testid="create_prep_task_fab"
            >
                <router-link :to="{ name: SETTINGS_ROUTE_NAMES.PREP_TASKS.CREATE, query: route.query }">
                    <PlusIcon />
                </router-link>
            </Fab>
        </template>

        <template v-else>
            <transition
                appear
                type="transition"
                name="down"
            >
                <div class="pl-prep-tasks__empty-note">
                    <span data-testid="prep_tasks_list_empty_note">{{ emptyListMessage }}</span>

                    <router-link
                        v-if="permissions.create && query.length === 0"
                        :to="{ name: SETTINGS_ROUTE_NAMES.PREP_TASKS.CREATE, query: route.query }"
                        data-testid="create_prep_task_fab"
                    >
                        <AddItemsIcon />
                    </router-link>
                </div>
            </transition>
        </template>
    </TopBarLayout>
</template>

<style lang="scss" scoped>
.pl-prep-tasks__empty-note {
    display: flex;
    flex-direction: column;
    padding-top: custom-space(1.5);
    text-align: center;
}

.pl-prep-tasks-tree {
    @media screen and (max-width: 800px) {
        padding-bottom: custom-space(4.5);
    }

    & > :deep(.pl-tree-display__node:not(:last-child)) {
        padding-bottom: custom-space(1.5);
    }

    & > :deep(.pl-tree-display__node > .pl-prep-tasks-tree__title) {
        font-size: $font-size-base * 1.35;
        font-weight: $font-weight-bolder;
        padding-bottom: custom-space(0.5);
    }
}
</style>
