<!--suppress ALL -->
<template>
    <div
        ref="$root"
        class="pl-select"
        :class="computedRootStyles"
    >
        <div
            v-if="searchable && size !== 'sm'"
            class="pl-select-searchable-field pl-form-control-input-wrapper"
            :class="computedSelectedOptionClasses"
        >
            <input
                ref="searchInputRef"
                type="text"
                class="form-control form-select form-select-wrapper"
                :class="{ 'is-invalid': hasError && optionQuery.length === 0 }"
                :disabled="disabled"
                :value="currentDisplayValue"
                :data-testid="dataTestId"
                @click="toggle"
                @input="onChangeOptionQuery"
                @blur="onBlur"
            />

            <CaretDownIcon
                v-if="displayIcon"
                class="pl-select-searchable-field-icon clickable"
                @click="toggle"
            />

            <label
                v-if="label"
                class="form-label"
                :class="{
                    'form-label--required': includeAsterisk,
                }"
            >
                {{ label }}
            </label>
        </div>

        <div
            v-else-if="searchable && size === 'sm'"
            class="pl-select-searchable-field form-select form-select-wrapper"
            :class="computedSelectedOptionClasses"
            :data-testid="dataTestId"
            @click="toggle"
        >
            <label
                v-if="label"
                class="form-label"
                :class="{
                    'form-label--required': includeAsterisk,
                }"
            >
                {{ label }}
            </label>

            <input
                v-if="isOpened"
                ref="searchInputRef"
                type="text"
                :disabled="disabled"
                :value="currentDisplayValue"
                :data-testid="dataTestId"
                @input="onChangeOptionQuery"
                @blur="onBlur"
            />

            <span
                v-else
                :class="computedSelectedOptionTextClasses"
                @click.stop="toggle"
            >
                {{ currentDisplayValue }}
            </span>

            <CaretDownIcon
                v-if="displayIcon"
                class="pl-select-searchable-field-icon"
            />
        </div>

        <div
            v-else
            class="pl-select-selected-option form-select form-select-wrapper"
            :class="computedSelectedOptionClasses"
            :data-testid="dataTestId"
            @click="toggle"
        >
            <slot
                name="selected-option-template"
                :selected-option="selectedOption"
            >
                <span
                    :data-testid="`${dataTestId}_selected_option`"
                    :class="computedSelectedOptionTextClasses"
                >
                    {{
                        (selectedOption &&
                            (selectedOption.selectedText !== undefined
                                ? selectedOption.selectedText
                                : selectedOption.text)) ||
                            `&#x200b;`
                    }}
                </span>

                <label
                    v-if="label"
                    class="form-label"
                    :class="{
                        'form-label--required': includeAsterisk,
                    }"
                >
                    {{ label }}
                </label>
            </slot>

            <CaretDownIcon
                v-if="displayIcon"
                class="pl-select-selected-option-icon"
            />
        </div>

        <transition
            appear
            name="down"
            type="transition"
            @after-leave="afterLeave"
        >
            <div
                v-if="!(filteredOptions.length === 0 && !displayEmptyOptionsText)"
                v-show="isOpened"
                ref="selectOptionsList"
                class="pl-select-option-list-wrapper"
                :class="computedListOptionsStyles"
            >
                <ul
                    class="pl-select-options-list list-unstyled mb-0"
                    :data-testid="`${dataTestId}_options_list`"
                    :class="{
                        'pl-select-options-list--group-mode': enableGroupMode,
                    }"
                >
                    <template v-if="filteredOptions.length">
                        <li
                            v-for="(option, index) in filteredOptions"
                            :key="`option-${index}`"
                            class="pl-select-options-list-item"
                            :class="{
                                'pl-select-options-list-item--has-icon': option.icon,
                                ['pl-select-options-list-item--group-' + option.groupLevel]: option.groupLevel,
                            }"
                            :data-testid="`${dataTestId}_option_${option.text.replaceAll(' ', '_').toLowerCase()}`"
                            @click="select(option)"
                        >
                            <slot
                                name="options-list-item-template"
                                :option="option"
                            >
                                <span>{{ option.text }}</span>
                            </slot>

                            <component
                                :is="option.icon"
                                v-if="option.icon"
                                class="pl-select-options-list-item-icon"
                            />
                        </li>
                    </template>
                    <li
                        v-else
                        class="p-2 text-center"
                    >
                        <span>No options found</span>
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'SelectInput',

    props: {
        modelValue: {
            type: [String, Number],
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        options: {
            type: Array,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        displayIcon: {
            type: Boolean,
            default: true,
        },
        displayEmptyOptionsText: {
            type: Boolean,
            default: true,
        },
        hasError: {
            type: Boolean,
            default: false,
        },
        dropdownWidthAuto: {
            type: Boolean,
            default: false,
        },
        searchable: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'md',
            validator: (value) => ['sm', 'md'].includes(value),
        },
        selectedOptionPosition: {
            type: String,
            default: 'start',
            validator: (value) => ['start', 'end'].includes(value),
        },
        enableGroupMode: {
            type: Boolean,
            default: false,
        },
        includeAsterisk: {
            type: Boolean,
            default: false,
        },
        isLastOptionMarked: {
            type: Boolean,
            default: false,
        },
        dataTestId: {
            type: String,
            default: null,
        },
    },

    emits: ['update:modelValue', 'blur'],

    data() {
        return {
            isOpened: false,
            isOver: false,
            onTop: false,
            optionQuery: '',
        };
    },

    computed: {
        filteredOptions() {
            let maxGroupLevel = 0;

            const filteredOptions = this.options.filter(({ value, text, selectedText, groupLevel }) => {
                if (groupLevel !== undefined) {
                    maxGroupLevel = Math.max(maxGroupLevel, groupLevel);
                }

                return (
                    text.toLowerCase().includes(this.optionQuery.toLowerCase()) ||
                    (selectedText === '' && value !== null) ||
                    groupLevel !== undefined
                );
            });

            if (maxGroupLevel === 0) {
                return filteredOptions;
            }

            return filteredOptions.filter((item, index) => {
                if (item.groupLevel === undefined) {
                    return true;
                }

                for (let i = 1; i <= maxGroupLevel; i++) {
                    const { groupLevel } = filteredOptions[index + i] || { groupLevel: item.groupLevel };

                    if (groupLevel === undefined) {
                        return true;
                    }

                    if (item.groupLevel === groupLevel) {
                        return false;
                    }
                }

                return false;
            });
        },

        selectedOption() {
            return this.options.find((option) => option.value === this.modelValue);
        },

        isOptionSelected() {
            const current = this.selectedOption;

            if (!current) {
                return false;
            }

            return current.value !== null || current.selectedText !== '';
        },

        currentDisplayValue() {
            if (this.searchable && this.isOpened) {
                return this.optionQuery;
            }

            if (this.loading) {
                return '';
            }

            if (!this.selectedOption) {
                return this.modelValue;
            }

            return this.selectedOption.selectedText ?? this.selectedOption.text;
        },

        computedRootStyles() {
            return {
                'pl-select--opened': this.isOpened,
                'pl-select--over': this.isOver,
                'pl-select--disabled': this.disabled,
                'pl-select--icon-hidden': !this.displayIcon,
                'pl-select--invalid': this.hasError && this.optionQuery.length === 0,
                'pl-select--selected': this.isOptionSelected && !this.loading,
                'pl-select--has-label': !!this.label,
                'pl-select--loading': this.loading,
                'pl-select--last-option-marked': this.isLastOptionMarked,
                [`pl-select--${this.size}`]: true,
            };
        },

        computedSelectedOptionClasses() {
            return {
                'is-invalid': this.hasError && this.optionQuery.length === 0,
            };
        },

        computedSelectedOptionTextClasses() {
            return {
                'text-end': this.selectedOptionPosition === 'end',
            };
        },

        computedListOptionsStyles() {
            return {
                'pl-select-option-list-wrapper--auto-width': this.dropdownWidthAuto,
                'pl-select-option-list-wrapper--on-top': this.onTop,
            };
        },
    },

    created() {
        window.addEventListener('keydown', this.escape);
        window.document.addEventListener('click', this.blurTracking);
    },

    unmounted() {
        window.removeEventListener('keydown', this.escape);
        window.document.removeEventListener('click', this.blurTracking);

        this.close();
    },

    methods: {
        escape(event) {
            if (event.key === 'Escape') {
                if (this.isOpened) {
                    this.$emit('blur');
                }
                this.close();
            }
        },

        toggle() {
            if (this.disabled || (this.options.length === 0 && !this.displayEmptyOptionsText)) {
                return false;
            }

            this.isOpened = !this.isOpened;

            if (this.isOpened) {
                this.isOver = true;

                this.handleOptionsListPosition();

                if (this.searchable) {
                    this.$nextTick(() => this.$refs.searchInputRef.focus());
                }
            } else {
                this.close();
            }
        },

        close() {
            this.isOpened = false;
        },

        select(option) {
            if (!this.enableGroupMode || !option.groupLevel) {
                this.$emit('update:modelValue', option.value);

                this.close();
            }
        },

        afterLeave() {
            this.isOver = false;
            this.optionQuery = '';
        },

        onClickOutside(event) {
            let el = event.target;

            while (el !== null) {
                if (el === this.$refs.$root) {
                    return;
                }
                el = el.parentNode;
            }

            this.close();
        },

        handleOptionsListPosition() {
            if (this.isOpened) {
                this.onTop = false;
                const rootRect = this.$root.$el.getBoundingClientRect();

                let appHeight = rootRect.bottom;

                if (document.body.classList.contains('pl-body--no-scroll')) {
                    appHeight = document.body.clientHeight;
                }

                this.$nextTick(() => {
                    const { bottom } = this.$refs.selectOptionsList.getBoundingClientRect();

                    this.onTop = bottom + 50 >= appHeight;
                });
            }
        },

        onChangeOptionQuery($event) {
            this.optionQuery = $event.target.value;

            if (!this.isOpened) {
                this.isOpened = true;
                this.isOver = true;

                this.handleOptionsListPosition();
            }
        },

        blurTracking($event) {
            if (this.isOpened) {
                let parent = $event.target;

                while (parent !== null) {
                    if (parent === this.$refs.$root) {
                        return;
                    }
                    parent = parent.parentElement;
                }

                this.close();
                this.$emit('blur');
            }
        },

        onBlur($event) {
            const selectedOptionByQuery = this.options.find(({ text }) => {
                const processedQuery = this.optionQuery.toLowerCase().trim();

                if (processedQuery.length > 0) {
                    return text.toLowerCase().trim() === processedQuery;
                }

                return false;
            });

            if (selectedOptionByQuery) {
                this.$emit('update:modelValue', selectedOptionByQuery.value);
            }

            if ($event.target === $event.srcElement) {
                return;
            }

            this.$emit('blur');
            close();
        },
    },
};
</script>

<style scoped lang="scss">
.pl-select {
    position: relative;
    z-index: 0;

    &-selected-option {
        cursor: pointer;
        background-color: $white;
        border-width: $form-select-border-width;
        border-style: solid;
        border-color: $form-select-border-color;
        min-height: $form-select-font-size * $form-select-line-height + $form-select-padding-y * 2;
        border-radius: $form-select-border-radius;
        outline: none;
        width: 100%;
        font-weight: $form-select-font-weight;
        font-size: $form-select-font-size;
        line-height: $form-select-line-height;
        color: $form-select-color;
        padding-right: rem(45px);

        @include pl-transition(border-color);
    }

    &-selected-option,
    &-searchable-field {
        position: relative;
        z-index: 11;
        height: 100%;
        word-break: break-word;

        &-icon {
            fill: $gray-600;
            position: absolute;
            right: rem(20px);
            top: 50%;
            transform: translate3d(0, -50%, 0);
        }

        .form-label {
            position: absolute;
            top: $form-select-border-width;
            left: 0;
            text-align: left;
            line-height: $form-select-font-size * $form-select-line-height + $form-select-padding-y * 2;
            pointer-events: none;
            padding-left: $form-select-padding-x;
            padding-right: rem(45px);
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            @include pl-transition(all, true);

            &--required::after {
                content: '*';
                position: absolute;
                top: 50%;
                color: $red-light;
                font-size: $font-size-base;
                line-height: $font-size-base * 0.75;
                padding-left: custom-space(0.1);
                transform: translateY(-50%);
            }
        }
    }

    &:not(&--sm):is(&--selected) .pl-select-searchable-field > .form-control,
    &:not(&--sm) .pl-select-searchable-field > .form-control:focus {
        padding-top: $form-select-padding-y * 1.4;
        padding-bottom: $form-select-padding-y * 0.6;
    }

    &--disabled {
        .pl-select-selected-option,
        .pl-select-searchable-field {
            opacity: $input-disabled-opacity;
        }
    }

    &--selected {
        .pl-select-selected-option,
        .pl-select-searchable-field {
            &-icon {
                fill: $black;
            }
        }
    }

    &--has-label {
        .pl-select-selected-option,
        .pl-select-searchable-field {
            .form-label {
                font-size: rem(12px);
                transform: translate3d(0, -22%, 0);
            }

            & > span {
                display: block;
                transform: translate3d(-0, rem(7px), 0);
            }
        }
    }

    &--over {
        z-index: 32;
    }

    &--opened,
    &--selected {
        .pl-select-selected-option,
        .pl-select-searchable-field > .form-control,
        .pl-select-searchable-field.form-select {
            border-color: $form-select-focus-border-color;
        }
    }

    &--opened {
        .pl-select-selected-option,
        .pl-select-searchable-field {
            &-icon {
                fill: $primary;
            }
        }
    }

    &--invalid {
        .pl-select-selected-option {
            border-color: map-get(map-get($form-validation-states, 'invalid'), 'color');
        }
    }

    .pl-select-option-list-wrapper {
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        background-color: $white;
        padding-top: rem(30px);
        padding-bottom: custom-space(0.325);
        margin-top: rem(-20px);
        z-index: 10;
        box-shadow: 0 4px 15px rgba($black, 0.25);
        border-radius: 0 0 $border-radius $border-radius;

        &--on-top {
            top: 50%;
            padding: custom-space(0.5) 0 custom-space(2) 0;
            margin-top: 0;
            border-radius: $border-radius $border-radius 0 0;
            transform: translateY(-100%);
        }

        .pl-select-options-list {
            max-height: $spacer * 10;
            overflow-y: auto;

            &-item {
                text-align: initial;
                cursor: pointer;
                font-weight: $font-weight-normal;
                font-size: rem(18px);
                line-height: rem(23px);
                padding: rem(8px) $form-select-padding-x;
                word-break: break-word;

                &:hover {
                    background-color: $gray-100;
                    color: $black;
                }

                &--has-icon {
                    position: relative;
                    padding-right: custom-space(3.5);
                }

                &-icon {
                    position: absolute;
                    top: 50%;
                    right: $form-select-padding-x;
                    transform: translate3d(0, -50%, 0);
                }
            }

            &--group-mode {
                .pl-select-options-list-item {
                    color: $gray-800;
                    padding-left: calc($form-select-padding-x * 1.75);

                    &:hover {
                        color: $black;
                    }

                    &--group-1 {
                        $space: custom-space(0.25);

                        color: $black;
                        cursor: initial;
                        pointer-events: none;
                        padding-top: $space;
                        padding-bottom: $space;
                    }

                    &--group-1 {
                        font-weight: $font-weight-bolder;
                        padding-left: $form-select-padding-x;
                    }
                }
            }
        }
    }

    &--sm {
        max-width: custom-space(12.5);

        .pl-select-selected-option,
        .pl-select-searchable-field {
            display: flex;
            align-items: center;
            font-size: $form-select-font-size-sm;
            line-height: 1.15;
            padding: $form-select-padding-y-sm $form-select-indicator-padding $form-select-padding-y-sm
                $form-select-padding-x-sm;
            min-height: $form-select-font-size * $form-select-line-height + $form-select-padding-y-sm * 2;

            .pl-select-selected-option-icon,
            .pl-select-searchable-field-icon {
                right: custom-space(0.875);
            }

            .form-label {
                font-size: inherit;
                position: static;
                padding: 0;
                width: auto;
                height: auto;
                line-height: inherit;
                order: 1;
                flex-shrink: 0;
                padding-right: space(2);
            }

            > span {
                order: 2;
                flex-grow: 1;
                padding-right: space(1);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .pl-select-searchable-field {
            cursor: pointer;

            & > input {
                flex: 1;
                order: 2;
                padding: 0;
                border: none;
                outline: none;
            }
        }

        &.pl-select--has-label {
            .form-label {
                font-size: inherit;
                transform: none;
            }

            span {
                transform: none;
            }
        }
    }

    &--loading .pl-select {
        &-selected-option,
        &-searchable-field {
            &-icon {
                visibility: hidden;
            }

            background-size: custom-space(1) custom-space(1);
            background-image: url('~@/assets/images/loader.gif');
            background-position: calc(100% - custom-space(1)) 50%;
            background-repeat: no-repeat;
        }
    }

    &--last-option-marked .pl-select-options-list-item:last-child {
        border-top: 1px solid $gray-200;
    }
}
</style>
