/*------------------------------------------------------------------------
                              General routes
------------------------------------------------------------------------*/

export const LOGIN_ROUTE_NAME = 'login';

export const JOIN_ROUTE_NAME = 'join';

export const SELECT_ACCOUNT_ROUTE_NAME = 'select-account';

const ONBOARDING_ROUTE_ROOT_NAME = 'onboarding';

export const ONBOARDING_ROUTE_NAMES = {
    INDEX: ONBOARDING_ROUTE_ROOT_NAME + '.index',
    CREATE_COMPANY: ONBOARDING_ROUTE_ROOT_NAME + '.crete-company',
    CREATE_LOCATIONS: ONBOARDING_ROUTE_ROOT_NAME + '.create-locations',
    INVITE_EMPLOYEES: ONBOARDING_ROUTE_ROOT_NAME + '.invite-employees',
    CREATE_DEPARTMENTS: ONBOARDING_ROUTE_ROOT_NAME + '.create-departments',
    CREATE_ITEMS: ONBOARDING_ROUTE_ROOT_NAME + '.create-items',
};

export const TASK_REMINDER_ROUTE_NAME = 'task-reminder';

export const NOT_FOUND_ROUTE_NAME = 'not-found';

/*------------------------------------------------------------------------
                          Quick Fill Orders (QFO)
------------------------------------------------------------------------*/

const QFO_ROUTE_ROOT_NAME = 'dashboard.quick-fill-orders';

export const QFO_ROUTE_NAMES = {
    INDEX: QFO_ROUTE_ROOT_NAME + '.index',

    SHOW: QFO_ROUTE_ROOT_NAME + '.show',

    CREATE_FROM_TEMPLATE: QFO_ROUTE_ROOT_NAME + '.create-from-template',

    CREATE: {
        INDEX: QFO_ROUTE_ROOT_NAME + '.create',
        ADD_ITEMS: QFO_ROUTE_ROOT_NAME + '.create.add-items',
        REVIEW_AND_CONFIRM: QFO_ROUTE_ROOT_NAME + '.create.review-and-confirm',
    },

    EDIT: {
        INDEX: QFO_ROUTE_ROOT_NAME + '.edit',
        ADD_ITEMS: QFO_ROUTE_ROOT_NAME + '.edit.add-items',
        REVIEW_AND_CONFIRM: QFO_ROUTE_ROOT_NAME + '.edit.review-and-confirm',
    },
};

/*------------------------------------------------------------------------
                              Prep Lists (PL)
------------------------------------------------------------------------*/

const PL_ROUTE_ROOT_NAME = 'dashboard.prep-lists';

export const PL_ROUTE_NAMES = {
    INDEX: PL_ROUTE_ROOT_NAME + '.index',
    SECONDARY_VIEW: PL_ROUTE_ROOT_NAME + '.index.secondary-view',

    SHOW: PL_ROUTE_ROOT_NAME + '.show',
    OVERPRODUCTION: PL_ROUTE_ROOT_NAME + '.overproduction',

    CREATE_FROM_TEMPLATE: PL_ROUTE_ROOT_NAME + '.create-from-template',

    CREATE: {
        INDEX: PL_ROUTE_ROOT_NAME + '.create',
        ADD_ITEMS: PL_ROUTE_ROOT_NAME + '.create.add-items',
        SET_ASSIGNEES: PL_ROUTE_ROOT_NAME + '.create.set-assignees',
        REVIEW_AND_CONFIRM: PL_ROUTE_ROOT_NAME + '.create.review-and-confirm',
    },

    EDIT: {
        INDEX: PL_ROUTE_ROOT_NAME + '.edit',
        ADD_ITEMS: PL_ROUTE_ROOT_NAME + '.edit.add-items',
        SET_ASSIGNEES: PL_ROUTE_ROOT_NAME + '.edit.set-assignees',
        REVIEW_AND_CONFIRM: PL_ROUTE_ROOT_NAME + '.edit.review-and-confirm',
    },
};

/*------------------------------------------------------------------------
                                  Reports
------------------------------------------------------------------------*/

const REPORTS_ROUTE_ROOT_NAME = 'dashboard.reports';

export const REPORTS_ROUTE_NAMES = {
    INDEX: REPORTS_ROUTE_ROOT_NAME + '.index',

    SHOW: {
        PRODUCTION: REPORTS_ROUTE_ROOT_NAME + '.show.production',
        EMPLOYEE_PERFORMANCE: REPORTS_ROUTE_ROOT_NAME + '.show.employee-performance',
        FULFILLMENT: REPORTS_ROUTE_ROOT_NAME + '.show.fulfillment',
    },
};

/*------------------------------------------------------------------------
                                 Settings
------------------------------------------------------------------------*/

const SETTINGS_ROUTE_ROOT_NAME = 'dashboard.settings';

export const SETTINGS_ROUTE_NAMES = {
    INDEX: SETTINGS_ROUTE_ROOT_NAME + '.index',

    PEOPLE: {
        INDEX: SETTINGS_ROUTE_ROOT_NAME + '.people.index',
        CREATE: SETTINGS_ROUTE_ROOT_NAME + '.people.create',
        EDIT: SETTINGS_ROUTE_ROOT_NAME + '.people.edit',
    },

    ITEMS: {
        INDEX: SETTINGS_ROUTE_ROOT_NAME + '.items.index',
        CREATE: SETTINGS_ROUTE_ROOT_NAME + '.items.create',
        EDIT: SETTINGS_ROUTE_ROOT_NAME + '.items.edit',
    },

    PREP_TASKS: {
        INDEX: SETTINGS_ROUTE_ROOT_NAME + '.prep-tasks.index',
        CREATE: SETTINGS_ROUTE_ROOT_NAME + '.prep-tasks.create',
        EDIT: SETTINGS_ROUTE_ROOT_NAME + '.prep-tasks.edit',
    },

    DEPARTMENTS: {
        INDEX: SETTINGS_ROUTE_ROOT_NAME + '.departments.index',
        CREATE: SETTINGS_ROUTE_ROOT_NAME + '.departments.create',
        EDIT: SETTINGS_ROUTE_ROOT_NAME + '.departments.edit',
    },

    LOCATIONS: {
        INDEX: SETTINGS_ROUTE_ROOT_NAME + '.locations.index',
        CREATE: SETTINGS_ROUTE_ROOT_NAME + '.locations.create',
        EDIT: SETTINGS_ROUTE_ROOT_NAME + '.locations.edit',
    },

    SUPPLIERS: {
        INDEX: SETTINGS_ROUTE_ROOT_NAME + '.suppliers.index',
        CREATE: SETTINGS_ROUTE_ROOT_NAME + '.suppliers.create',
        EDIT: SETTINGS_ROUTE_ROOT_NAME + '.suppliers.edit',
    },

    NO_PREP_ACTIONS: SETTINGS_ROUTE_ROOT_NAME + '.no-prep-actions.index',

    CONFIGURATION_OF_ITEM: SETTINGS_ROUTE_ROOT_NAME + '.configuration-of-item.index',

    PAR: {
        INDEX: SETTINGS_ROUTE_ROOT_NAME + '.par.index',

        SHOW: {
            PL: SETTINGS_ROUTE_ROOT_NAME + '.par.pl.show',
            QF: SETTINGS_ROUTE_ROOT_NAME + '.par.qf.show',
        },

        CREATE: {
            PL: {
                INDEX: SETTINGS_ROUTE_ROOT_NAME + '.par.pl.create',
                ADD_ITEMS: SETTINGS_ROUTE_ROOT_NAME + '.par.pl.create.add-items',
                SET_ASSIGNEES: SETTINGS_ROUTE_ROOT_NAME + '.par.pl.create.set-assignees',
                REVIEW_AND_CONFIRM: SETTINGS_ROUTE_ROOT_NAME + '.par.pl.create.review-and-confirm',
            },
            QF: {
                INDEX: SETTINGS_ROUTE_ROOT_NAME + '.par.qf.create',
                ADD_ITEMS: SETTINGS_ROUTE_ROOT_NAME + '.par.qf.create.add-items',
                REVIEW_AND_CONFIRM: SETTINGS_ROUTE_ROOT_NAME + '.par.qf.create.review-and-confirm',
            },
        },

        EDIT: {
            PL: {
                INDEX: SETTINGS_ROUTE_ROOT_NAME + '.par.pl.edit',
                ADD_ITEMS: SETTINGS_ROUTE_ROOT_NAME + '.par.pl.edit.add-items',
                SET_ASSIGNEES: SETTINGS_ROUTE_ROOT_NAME + '.par.pl.edit.set-assignees',
                REVIEW_AND_CONFIRM: SETTINGS_ROUTE_ROOT_NAME + '.par.pl.edit.review-and-confirm',
            },
            QF: {
                INDEX: SETTINGS_ROUTE_ROOT_NAME + '.par.qf.edit',
                ADD_ITEMS: SETTINGS_ROUTE_ROOT_NAME + '.par.qf.edit.add-items',
                REVIEW_AND_CONFIRM: SETTINGS_ROUTE_ROOT_NAME + '.par.qf.edit.review-and-confirm',
            },
        },
    },

    MY_PROFILE: SETTINGS_ROUTE_ROOT_NAME + '.my-profile.index',

    MY_ACCOUNTS: SETTINGS_ROUTE_ROOT_NAME + '.my-accounts.index',

    COMPANY: SETTINGS_ROUTE_ROOT_NAME + '.company.edit',
};
