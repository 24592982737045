<script>
export default {
    name: 'ItemsIndexView',
};
</script>

<script setup>
import { watch, inject, computed, reactive, ref, onBeforeMount } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { filterLeafs } from '@/utils/treeUtils';
import { getFilteredQueryParams } from '@/utils/routerUtils';
import { ITEMS_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES, IMPORT_ENTITIES } from '@/enums/componentsEnums';
import { ITEMS_GROUP_BY, ITEMS_DEFAULT_GROUP_BY, ITEMS_FILTER_SETTINGS_CONFIG } from '@/enums/itemEnums';
import ItemModel from '@/models/Item';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useImportEntityModal from '@/composition/useImportEntityModal';
import ListDisplay from '@/components/ListDisplay';
import TreeDisplay from '@/components/TreeDisplay';
import Alert from '@/components/Alert';
import ImportEntityModal from '@/components/ImportEntityModal';
import FilterSettingsTemplate from '@/components/templates/FilterSettingsTemplate';
import SearchForm from '@/components/forms/SearchForm';
import ItemCard from '@/components/model-specific/item/ItemCard';
import CreateItemFab from './partials/CreateItemFab';

const $acl = inject('$acl');

const router = useRouter();
const route = useRoute();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(ITEMS_RESTRICTIONS.VIEW, currentAccount)) {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to items.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                                  Filters
------------------------------------------------------------------------*/

const filters = reactive({
    group_by: route.query.group_by ?? ITEMS_DEFAULT_GROUP_BY,
    order_type: route.query.order_type || null,
});

const query = ref('');

const getCurrentFilters = () => ({
    group_by: filters.group_by,
    type: filters.order_type,
});

const updateFilters = (updatedFilters) => {
    filters.group_by = updatedFilters.group_by;
    filters.order_type = updatedFilters.order_type;
};

/*------------------------------------------------------------------------
                                Items state
------------------------------------------------------------------------*/

const itemsState = reactive({
    data: null,
    isDataLoading: true,
});

const permissions = computed(() => ({
    create: $acl.can(ITEMS_RESTRICTIONS.CREATE, currentAccount),
    update: $acl.can(ITEMS_RESTRICTIONS.CREATE, currentAccount),
}));

const loadItems = () =>
    sendAbortableRequest(ItemModel.all(orgId, getCurrentFilters()))
        .then((models) => (itemsState.data = models))
        .finally(() => (itemsState.isDataLoading = false));

const initEditItem = ({ id }) => {
    if (permissions.value.update) {
        router.push({
            name: SETTINGS_ROUTE_NAMES.ITEMS.EDIT,
            params: { itemId: id },
            query: route.query,
        });
    }
};

const getTitleTestId = (nestingLevel) => {
    const { group_by: groupBy } = filters;

    let configurationName = groupBy;

    if (groupBy === ITEMS_GROUP_BY.DEPARTMENT_N_CATEGORY) {
        configurationName = nestingLevel === 0 ? ITEMS_GROUP_BY.DEPARTMENT : ITEMS_GROUP_BY.CATEGORY;
    }

    return `items_list_title_${configurationName}}`;
};

const itemList = computed(() => {
    const { data } = itemsState;

    if (query.value.trim().length === 0) {
        return data;
    }

    return filterLeafs(data, ({ name }) => name.toLowerCase().includes(query.value.toLowerCase()));
});

const areItemsPresent = computed(() => {
    const data = itemList.value;

    if (Array.isArray(data)) {
        return data.length > 0;
    }

    return Object.entries(data).length > 0;
});

const emptyListMessage = computed(() => {
    if (query.value.length > 0) {
        return 'There is no items according to your request.';
    }

    if (permissions.value.create) {
        return 'The list is empty.';
    }

    return 'No items here! Please wait until an administrator creates some items to work with.';
});

watch(
    filters,
    () => {
        router.replace({ query: getFilteredQueryParams(filters) });

        itemsState.isDataLoading = true;

        loadItems();
    },
    { immediate: true }
);

/*------------------------------------------------------------------------
                         Import entity modal state
------------------------------------------------------------------------*/

const { importEntityState, importEntityModal } = useImportEntityModal(ItemModel, 'items');

const submitImportItems = (payload) => {
    importEntityModal.submit(payload, (isSpinnerVisible) => {
        itemsState.isDataLoading = isSpinnerVisible;

        loadItems().then(() => store.commit('itemBatchMeta/setMeta', null));
    });
};
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.INDEX">
        <template #title>
            <h1>Items</h1>
        </template>

        <template
            v-if="!itemsState.isDataLoading"
            #actions
        >
            <FilterSettingsTemplate
                :initial-filters="filters"
                :filters-config="ITEMS_FILTER_SETTINGS_CONFIG"
                @update-filters="updateFilters"
            />

            <SearchForm
                v-model="query"
                data-testid="items_search_form"
            />
        </template>

        <InPlaceLoader v-if="itemsState.isDataLoading" />

        <Alert
            v-else-if="itemsState.data === null"
            :type="ALERT_TYPES.FAIL"
        >
            Items could not be loaded for some reason. Please try again later.
        </Alert>

        <template v-else>
            <transition
                appear
                name="down"
                type="transition"
            >
                <TreeDisplay
                    v-if="areItemsPresent"
                    :data="itemList"
                    class="pl-items-tree"
                >
                    <template #node="{ key, nestingLevel }">
                        <div
                            class="pl-items-tree__title"
                            :data-testid="getTitleTestId(nestingLevel)"
                        >
                            {{ key }}
                        </div>
                    </template>

                    <template #leaf="{ leaf }">
                        <ListDisplay
                            has-borders
                            data-testid="items_list"
                            :items="leaf"
                            @tap="initEditItem"
                        >
                            <template #item="{ item }">
                                <ItemCard
                                    include-info
                                    :item="item"
                                    :class="{
                                        clickable: permissions.update,
                                    }"
                                />
                            </template>
                        </ListDisplay>
                    </template>
                </TreeDisplay>

                <span
                    v-else
                    class="pl-items__empty-note"
                    data-testid="items_list_empty_note"
                >
                    {{ emptyListMessage }}
                </span>
            </transition>
        </template>

        <CreateItemFab
            v-if="permissions.create"
            @open-import="importEntityModal.open"
        />

        <ImportEntityModal
            v-if="importEntityModal.isOpened"
            :entity="IMPORT_ENTITIES.ITEM"
            :data="importEntityState.data"
            :progress="importEntityState.progress"
            :is-action-processing="importEntityState.isActionProcessing"
            @download-template="importEntityModal.downloadTemplate"
            @parse-file="importEntityModal.parseFile"
            @close="importEntityModal.close"
            @submit="submitImportItems"
        >
            <template #title="{ data, importEntitiesSize }">
                {{ data === null ? 'Import new Items' : `Items to create (${importEntitiesSize})` }}
            </template>
        </ImportEntityModal>
    </TopBarLayout>
</template>

<style lang="scss" scoped>
.pl-items__empty-note {
    padding-top: custom-space(1.5);
    text-align: center;
}

.pl-items-tree {
    @media screen and (max-width: 800px) {
        padding-bottom: custom-space(4.5);
    }

    & > :deep(.pl-tree-display__node:not(:last-child)) {
        padding-bottom: custom-space(1.5);
    }

    & > :deep(.pl-tree-display__node > .pl-items-tree__title) {
        font-size: $font-size-base * 1.35;
        font-weight: $font-weight-bolder;
        padding-bottom: custom-space(0.5);
    }

    & > :deep(.pl-tree-display__node > .pl-tree-display > .pl-tree-display__node):not(:last-child) {
        margin-bottom: custom-space(1);
    }

    & > :deep(.pl-tree-display__node > .pl-tree-display > .pl-tree-display__node > .pl-items-tree__title) {
        font-weight: $font-weight-bolder;
        padding-bottom: custom-space(0.25);
        padding-left: custom-space(0.75);
    }
}
</style>
