<script>
export default {
    name: 'EditQFPARTemplateStep1View',
};
</script>

<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { mapGetters } from '@/utils/vuexUtils';
import { catchUnprocessableEntity } from '@/utils/httpUtils';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import useOrderSettingsForm from '@/composition/useOrderSettingsForm';
import OrderStepTemplate from '@/components/model-specific/orders/OrderStepTemplate';
import PARTemplateSettingsForm from '@/components/forms/PARTemplateSettingsForm';

const props = defineProps({
    templateId: {
        type: [String, Number],
        required: true,
    },
});

const router = useRouter();
const store = useStore();

const { currentQFPARTemplate } = mapGetters(['currentQFPARTemplate']);

/*------------------------------------------------------------------------
                              Template state
------------------------------------------------------------------------*/

const cancelTemplateDraft = () => {
    currentQFPARTemplate.value.cancelDraft().finally(() => {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.SUCCESS,
            message: 'Template editing process has been successfully cancelled',
        });

        router.push({ name: SETTINGS_ROUTE_NAMES.PAR.INDEX });

        store.commit('setCurrentQFPARTemplate', null);
    });
};

/*------------------------------------------------------------------------
                       Template settings form state
------------------------------------------------------------------------*/

const { orderSettingsFormState, orderSettingsForm } = useOrderSettingsForm();

const navigateToNextStep = () => {
    router.push({
        name: SETTINGS_ROUTE_NAMES.PAR.EDIT.QF.ADD_ITEMS,
        params: { templateId: props.templateId },
    });
};

const submitForm = (payload) => {
    if (!orderSettingsFormState.hasChangesMade) {
        navigateToNextStep();
    } else {
        orderSettingsFormState.isSubmitting = true;

        currentQFPARTemplate.value
            .update(payload)
            .then((model) => {
                EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                    type: ALERT_TYPES.SUCCESS,
                    message: 'Template settings has been successfully updated',
                });

                store.commit('setCurrentQFPARTemplate', model);

                navigateToNextStep();
            })
            .catch((error) => catchUnprocessableEntity(error, orderSettingsForm.setValidationErrors))
            .finally(() => (orderSettingsFormState.isSubmitting = false));
    }
};

const templateSettingsFormProps = computed(() => {
    const { name, par_template_settings: settings } = currentQFPARTemplate.value;

    return {
        name,
        days: settings.days,
        dates: settings.dates,
        startDate: settings.start_date,
        endDate: settings.end_date,
    };
});
</script>

<template>
    <OrderStepTemplate
        is-template
        is-edit-mode
        :is-step-submitting="orderSettingsFormState.isSubmitting"
        @cancel-process="cancelTemplateDraft"
    >
        <template #step-content>
            <PARTemplateSettingsForm
                id="pl-edit-qf-template-step-1-form"
                :name="templateSettingsFormProps.name"
                :days="templateSettingsFormProps.days"
                :dates="templateSettingsFormProps.dates"
                :start-date="templateSettingsFormProps.startDate"
                :end-date="templateSettingsFormProps.endDate"
                :validation-errors="orderSettingsFormState.validationErrors"
                @update-status="orderSettingsForm.update"
                @submit="submitForm"
            />
        </template>

        <template #next-step-action>
            <BtnUI
                is-filled
                type="submit"
                form="pl-edit-qf-template-step-1-form"
                :disabled="orderSettingsForm.isSubmittingDisabled()"
            >
                {{ orderSettingsForm.getSubmitBtnText(currentQFPARTemplate) }}
            </BtnUI>
        </template>
    </OrderStepTemplate>
</template>
