<script>
export default {
    name: 'CreatePLOrderStep4View',
};
</script>

<script setup>
import { watch, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { catchOrderPrepDateGoneError } from '@/utils/httpUtils';
import { mapGetters } from '@/utils/vuexUtils';
import { PL_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import PLOrderModel from '@/models/PLOrder';
import useAbortableRequest from '@/composition/useAbortableRequest';
import OrderStepTemplate from '@/components/model-specific/orders/OrderStepTemplate';
import OrderSummaryCard from '@/components/model-specific/orders/order-summary-card';

const router = useRouter();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentPLOrder } = mapGetters(['currentPLOrder']);

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                                Order state
------------------------------------------------------------------------*/

const orderState = reactive({
    data: null,
    isDataLoading: true,
    isActionProcessing: false,
});

const loadOrder = (groupItemsBy) => {
    orderState.isDataLoading = true;

    sendAbortableRequest(PLOrderModel.find(orgId, currentPLOrder.value.id, { group_items_by: groupItemsBy }))
        .then((model) => (orderState.data = model))
        .finally(() => (orderState.isDataLoading = false));
};

const confirmOrder = () => {
    orderState.isActionProcessing = true;

    orderState.data
        .confirm()
        .then(() => {
            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: 'Order has been successfully confirmed',
            });

            router.push({
                name: PL_ROUTE_NAMES.INDEX,
                query: { date: orderState.data.prep_date },
            });

            store.commit('setCurrentPLOrder', null);
        })
        .catch(catchOrderPrepDateGoneError)
        .finally(() => (orderState.isActionProcessing = false));
};

watch(
    () => store.state.PLOrderViewSettings.groupItemsBy,
    (groupItemsBy) => {
        if (currentPLOrder.value !== null) {
            loadOrder(groupItemsBy);
        }
    },
    { immediate: true }
);
</script>

<template>
    <OrderStepTemplate :is-step-submitting="orderState.isActionProcessing">
        <template #step-content>
            <InPlaceLoader v-if="orderState.isDataLoading" />

            <OrderSummaryCard
                v-else-if="orderState.data !== null"
                :order="orderState.data"
            />
        </template>

        <template #prev-step-link>
            <router-link
                class="pl-btn"
                :to="{ name: PL_ROUTE_NAMES.CREATE.SET_ASSIGNEES }"
            >
                Set Assignees
            </router-link>
        </template>

        <template #next-step-action>
            <BtnUI
                is-filled
                :disabled="orderState.isDataLoading || orderState.isActionProcessing"
                @click="confirmOrder"
            >
                Confirm
            </BtnUI>
        </template>
    </OrderStepTemplate>
</template>
