<script>
export default {
    name: 'SearchForm',
};
</script>

<script setup>
import { ref, nextTick, onMounted, onBeforeUnmount } from 'vue';

import SearchIcon from '@/components/icons/SearchIcon';

defineProps({
    modelValue: {
        type: String,
        default: null,
    },
});

const emit = defineEmits(['update:modelValue']);

/*------------------------------------------------------------------------
                               Search state
------------------------------------------------------------------------*/

const inputRef = ref(null);
const isSearchFormOpened = ref(false);

const search = () => {
    emit('update:modelValue', inputRef.value.value);
};

const showSearchForm = () => {
    isSearchFormOpened.value = true;

    nextTick(() => inputRef.value.focus());
};

const closeSearchForm = () => {
    if (isSearchFormOpened.value) {
        inputRef.value.value = '';
        isSearchFormOpened.value = false;

        search();
    }
};

const toggleSearchForm = () => {
    if (isSearchFormOpened.value) {
        closeSearchForm();
    } else {
        showSearchForm();
    }
};

/*------------------------------------------------------------------------
                               Close on Esc
------------------------------------------------------------------------*/

const closeSearchOnEscape = (event) => {
    if (event.key === 'Escape') {
        closeSearchForm();
    }
};

onMounted(() => window.addEventListener('keydown', closeSearchOnEscape));
onBeforeUnmount(() => window.removeEventListener('keydown', closeSearchOnEscape));
</script>

<template>
    <div
        class="pl-search-form"
        :class="{
            'pl-search-form--opened': isSearchFormOpened,
        }"
    >
        <transition
            appear
            name="scale"
            type="transition"
        >
            <form
                v-if="isSearchFormOpened"
                novalidate
                class="pl-search-form__overlay"
                @submit.prevent="search"
            >
                <input
                    ref="inputRef"
                    type="search"
                    class="form-control"
                    data-testid="search_form_input"
                    name="search"
                    :value="modelValue"
                    @input="search"
                />

                <button
                    type="button"
                    data-testid="search_form_close_btn"
                    @click="closeSearchForm"
                >
                    <CrossIcon />
                </button>
            </form>
        </transition>

        <button
            type="button"
            data-testid="search_form_open_btn"
            @click="toggleSearchForm"
        >
            <SearchIcon
                :width="21"
                :height="21"
            />
        </button>
    </div>
</template>

<style lang="scss">
/**
    To use this component put it inside an HTML element with the "relative" position.
*/
.pl-search-form {
    &__overlay {
        position: absolute;
        top: 50%;
        left: calc($grid-gutter-width / 2);
        right: calc($grid-gutter-width / 2);
        width: auto;
        z-index: 24;
        transform: translateY(-50%);

        .form-control {
            border-width: 0;
            padding-top: space(2);
            padding-bottom: space(2);
            padding-right: $form-select-indicator-padding * 1.5;
        }

        & > button {
            display: flex;
            position: absolute;
            top: 50%;
            right: custom-space(1);
            transform: translateY(-50%);
        }
    }

    & > button {
        display: flex;

        @include pl-transition(all);
    }

    &--opened > button {
        opacity: 0;
        transform: translateX(50%);
    }
}
</style>
