<script>
export default {
    name: 'ShowPLOrderView',
};
</script>

<script setup>
import { inject, watch, reactive, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { mapGetters } from '@/utils/vuexUtils';
import { catchNotFoundError } from '@/utils/httpUtils';
import { isOrderEditing } from '@/utils/orderUtils';
import { PL_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { PL_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { ORDER_GROUP_ITEMS_BY_OPTIONS } from '@/enums/orderEnums';
import { ALERT_TYPES, BADGE_TYPES } from '@/enums/componentsEnums';
import PLOrderModel from '@/models/PLOrder';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useConfirmationModal from '@/composition/useConfirmationModal';
import Alert from '@/components/Alert';
import DropDown from '@/components/DropDown';
import Badge from '@/components/Badge';
import ConfirmationModal from '@/components/ConfirmationModal';
import GroupedSettings from '@/components/constructions/grouped-settings';
import OrderSummaryCard from '@/components/model-specific/orders/order-summary-card';

const props = defineProps({
    orderId: {
        type: [Number, String],
        required: true,
    },
});

const $acl = inject('$acl');

const router = useRouter();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentPLOrder } = mapGetters(['currentPLOrder']);

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                                Order state
------------------------------------------------------------------------*/

const orderState = reactive({
    data: null,
    isDataLoading: true,
    isActionProcessing: false,
});

const loadOrder = (groupItemsBy) => {
    orderState.isDataLoading = true;

    sendAbortableRequest(PLOrderModel.find(orgId, props.orderId, { group_items_by: groupItemsBy }))
        .then((model) => (orderState.data = model))
        .finally(() => (orderState.isDataLoading = false));
};

const refreshOrder = (freshOrder) => (orderState.data = freshOrder);

const initEditOrder = () => {
    const order = orderState.data;

    if (currentPLOrder.value !== null) {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.WARNING,
            message: 'You currently have a pending order. Please finalize or cancel it, before editing a new one.',
        });

        if (isOrderEditing(currentPLOrder.value)) {
            router.push({
                name: PL_ROUTE_NAMES.EDIT.INDEX,
                params: { orderId: currentPLOrder.value.parent_id },
            });
        } else {
            router.replace({ name: PL_ROUTE_NAMES.CREATE.INDEX });
        }
    } else if (order.has_batches_done) {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.FAIL,
            message: 'You are not allowed to edit the order, because some items are reported.',
        });
    } else {
        orderState.isActionProcessing = true;

        order
            .createDraft()
            .then(({ data }) => {
                store.commit('setCurrentPLOrder', data);

                router.push({
                    name: PL_ROUTE_NAMES.EDIT.INDEX,
                    params: { orderId: order.id },
                });
            })
            .catch((error) => {
                catchNotFoundError(error, () => {
                    EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                        type: ALERT_TYPES.FAIL,
                        message: 'The order has been deleted',
                    });

                    router.replace({ name: PL_ROUTE_NAMES.INDEX });
                });
            })
            .finally(() => (orderState.isActionProcessing = false));
    }
};

const permissions = computed(() => ({
    update: $acl.can(PL_RESTRICTIONS.UPDATE, currentAccount, orderState.data),
    delete: $acl.can(PL_RESTRICTIONS.DELETE, currentAccount, orderState.data),
}));

watch(() => store.state.PLOrderViewSettings.groupItemsBy, loadOrder, { immediate: true });

/*------------------------------------------------------------------------
                         Confirmation modal state
------------------------------------------------------------------------*/

const { confirmationState, confirmationModal } = useConfirmationModal();

const openDeletionModal = () => {
    if (orderState.data.has_batches_done) {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.FAIL,
            message: 'You are not allowed to delete the order, because some items are fulfilled.',
        });
    } else {
        confirmationModal.open(orderState.data);
    }
};

const handleSuccessfulDeletion = () => {
    EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
        type: ALERT_TYPES.SUCCESS,
        message: 'Order has been successfully deleted',
    });

    router.push({ name: PL_ROUTE_NAMES.INDEX });
};

const submitDeleteOrder = () => {
    confirmationModal
        .submit((model) => model.delete())
        .then(handleSuccessfulDeletion)
        .catch((error) => catchNotFoundError(error, handleSuccessfulDeletion));
};
</script>

<template>
    <TopBarLayout :back-route="PL_ROUTE_NAMES.INDEX">
        <template #title>
            <h1>
                <span>
                    Order Details

                    <Badge
                        v-if="orderState.data?.is_par"
                        is-absolute
                        size="xs"
                        :type="BADGE_TYPES.PAR"
                    >
                        PAR
                    </Badge>
                </span>
            </h1>
        </template>

        <template
            v-if="orderState.data !== null"
            #actions
        >
            <DropDown>
                <template #content>
                    <GroupedSettings.Root>
                        <GroupedSettings.List title="Group items by">
                            <GroupedSettings.ListItem
                                v-for="(text, value) in ORDER_GROUP_ITEMS_BY_OPTIONS"
                                :key="`group items by option: ${value}`"
                                :is-selected="store.state.PLOrderViewSettings.groupItemsBy === value"
                                @click="store.commit('PLOrderViewSettings/setGroupItemsBy', value)"
                            >
                                {{ text }}
                            </GroupedSettings.ListItem>
                        </GroupedSettings.List>

                        <GroupedSettings.List
                            v-if="permissions.update || permissions.delete"
                            title="Actions"
                        >
                            <GroupedSettings.ListItem
                                v-if="permissions.update"
                                @click="initEditOrder"
                            >
                                {{ orderState.data.draft !== null ? ' Continue editing' : 'Edit order' }}
                            </GroupedSettings.ListItem>

                            <GroupedSettings.ListItem
                                v-if="permissions.delete"
                                @click="openDeletionModal"
                            >
                                Delete order
                            </GroupedSettings.ListItem>
                        </GroupedSettings.List>
                    </GroupedSettings.Root>
                </template>
            </DropDown>
        </template>

        <InPlaceLoader v-if="orderState.isDataLoading" />

        <Alert
            v-else-if="orderState.data === null"
            :type="ALERT_TYPES.FAIL"
        >
            Order could not be loaded for some reason. Please try again later.
        </Alert>

        <template v-else>
            <OverlayLoader
                v-if="orderState.isActionProcessing"
                enable-sticky
            />

            <OrderSummaryCard
                show-item-progress
                enable-reassign
                :order="orderState.data"
                @refresh-order="refreshOrder"
            />

            <ConfirmationModal
                v-if="confirmationState.isOpened"
                qa-prefix="delete_order_modal"
                :is-pending="confirmationState.isPending"
                @close="confirmationModal.close"
                @submit-delete="submitDeleteOrder"
            >
                Are you sure you want to delete the order "{{ confirmationState.model.name }}"?
            </ConfirmationModal>
        </template>
    </TopBarLayout>
</template>
