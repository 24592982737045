import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';

import SuppliersIndexView from '@/views/dashboard/settings/suppliers/index';
import CreateSupplierView from '@/views/dashboard/settings/suppliers/create';
import EditSupplierView from '@/views/dashboard/settings/suppliers/edit';

export default [
    {
        path: 'settings/suppliers',
        name: SETTINGS_ROUTE_NAMES.SUPPLIERS.INDEX,
        component: SuppliersIndexView,
    },

    {
        path: 'settings/suppliers/create',
        name: SETTINGS_ROUTE_NAMES.SUPPLIERS.CREATE,
        component: CreateSupplierView,
    },
    {
        path: 'settings/suppliers/:supplierId/edit',
        name: SETTINGS_ROUTE_NAMES.SUPPLIERS.EDIT,
        component: EditSupplierView,
        props: true,
    },
];
