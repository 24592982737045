<script>
export default {
    name: 'PrepTaskCard',
};
</script>

<script setup>
import { PREP_TASK_TIME_DELAY } from '@/enums/prepTaskEnums';
import { BADGE_TYPES } from '@/enums/componentsEnums';
import Badge from '@/components/Badge';
import ItemCardUI from '@/components/model-specific/item/ItemCardUI';

defineProps({
    prepTask: {
        type: Object,
        required: true,
    },
});

/*------------------------------------------------------------------------
                                    QA
------------------------------------------------------------------------*/

const qaPrefix = 'prep-task';
</script>

<template>
    <ItemCardUI>
        <template #content="{ classNames }">
            <Badge
                v-if="prepTask.parent_items_count > 0"
                is-absolute
                size="sm"
                :type="BADGE_TYPES.ITEM_CARD"
            >
                Associated items ({{ prepTask.parent_items_count }})
            </Badge>

            <b :data-testid="`${qaPrefix}_name`">
                {{ prepTask.name }}
            </b>

            <ul :class="classNames.infoList">
                <li>
                    <span :data-testid="`${qaPrefix}_batch_size_amount`">
                        {{ prepTask.batch_size_amount }}
                    </span>

                    <span :data-testid="`${qaPrefix}_batch_size_unit`">
                        {{ prepTask.batch_size_unit.text }}
                    </span>
                </li>

                <li>
                    <span :data-testid="`${qaPrefix}_batch_yield_amount`">
                        {{ prepTask.batch_yield_amount }}
                    </span>

                    <span :data-testid="`${qaPrefix}_batch_yield_unit`">
                        {{ prepTask.batch_yield_unit.text }}
                    </span>
                </li>

                <li v-if="prepTask.batch_weight_amount">
                    <span :data-testid="`${qaPrefix}_batch_weight_amount`">
                        {{ prepTask.batch_weight_amount }}
                    </span>

                    <span :data-testid="`${qaPrefix}_batch_weight_unit`">
                        {{ prepTask.batch_weight_unit.text }}
                    </span>
                </li>

                <li v-if="prepTask.batch_labor_time">
                    <span :data-testid="`${qaPrefix}_labor_time`">{{ prepTask.batch_labor_time }}m</span>
                </li>

                <li>
                    <span :data-testid="`${qaPrefix}_time_delay`">
                        {{ PREP_TASK_TIME_DELAY[prepTask.time_delay].toLowerCase() }}
                    </span>
                </li>
            </ul>

            <ul :class="classNames.infoList">
                <li
                    v-for="department in prepTask.departments"
                    :key="`prep task department: ${department.id}`"
                >
                    <span :data-testid="`${qaPrefix}_department`">
                        {{ department.name }}
                    </span>
                </li>
            </ul>
        </template>
    </ItemCardUI>
</template>
