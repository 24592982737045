<script>
export default {
    name: 'FulfillmentReportView',
};
</script>

<script setup>
import {
    REPORTS,
    FULFILLMENT_REPORT_DEFAULT_BREAK_DOWN_BY,
    FULFILLMENT_REPORT_DEFAULT_ORDER_BY,
    FULFILLMENT_REPORT_FILTER_SETTINGS_CONFIG,
    REPORT_BREAK_DOWN_BY,
} from '@/enums/reportsEnums';
import useReportData from '@/composition/useReportData';
import FilterSettingsTemplate from '@/components/templates/FilterSettingsTemplate';
import ReportViewTemplate from '../partials/ReportViewTemplate';
import ReportDatePicker from '../partials/ReportDatePicker';
import FulfillmentSummaryCard from './partials/FulfillmentSummaryCard';

/*------------------------------------------------------------------------
                                Report data
------------------------------------------------------------------------*/

const initialFiltersConfig = {
    break_down_by: FULFILLMENT_REPORT_DEFAULT_BREAK_DOWN_BY,
    group_by: null,
    order_by: FULFILLMENT_REPORT_DEFAULT_ORDER_BY,
    direction: null,
};

const { filters, dateSettings, reportState, updateFilters, updateDateSettings, downloadReport } = useReportData(
    REPORTS.FULFILLMENT.NAME,
    initialFiltersConfig
);
</script>

<template>
    <ReportViewTemplate
        :report-title="REPORTS.FULFILLMENT.TITLE"
        :is-report-loading="reportState.isDataLoading"
        :summaries="reportState.data"
        @download="downloadReport"
    >
        <template #report-actions>
            <FilterSettingsTemplate
                :initial-filters="filters"
                :filters-config="FULFILLMENT_REPORT_FILTER_SETTINGS_CONFIG"
                @update-filters="updateFilters"
            />
        </template>

        <template #top-section>
            <h4>Found ({{ reportState.data.count }})</h4>

            <ReportDatePicker
                :initial-start-date="dateSettings.start_date"
                :initial-end-date="dateSettings.end_date"
                @update-date="updateDateSettings"
            />
        </template>

        <template #summary-card="{ item }">
            <FulfillmentSummaryCard
                :item="item"
                :is-break-down-by-item="filters.break_down_by === REPORT_BREAK_DOWN_BY.ITEM"
            />
        </template>
    </ReportViewTemplate>
</template>