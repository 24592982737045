<script>
export default {
    name: 'OnboardingStep4View',
};
</script>

<script setup>
import { onBeforeMount, reactive } from 'vue';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { catchUnprocessableEntity } from '@/utils/httpUtils';
import { RESPONSE_DATA_TYPES } from '@/enums/httpEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import DepartmentModel from '@/models/Department';
import useAbortableRequest from '@/composition/useAbortableRequest';
import OnboardingLayout from '@/components/layouts/OnboardingLayout';
import OnboardingStepLayout from '@/components/layouts/OnboardingStepLayout';
import DepartmentForm from '@/components/forms/DepartmentForm';
import DepartmentCard from '@/components/model-specific/department/DepartmentCard';

const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                             Departments state
------------------------------------------------------------------------*/

const departmentsState = reactive({
    data: [],
    isDataLoading: true,
});

const loadDepartments = () => {
    sendAbortableRequest(DepartmentModel.all(orgId))
        .then((models) => (departmentsState.data = models))
        .finally(() => (departmentsState.isDataLoading = false));
};

const updateOnboardingStatus = (models) => {
    store.commit('onboarding/updateSettings', {
        has_departments: models.length > 0,
    });
};

const handleDeleteSuccess = (models) => {
    departmentsState.data = models;

    updateOnboardingStatus(models);

    EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
        type: ALERT_TYPES.SUCCESS,
        message: 'The department has been successfully deleted!',
    });
};

onBeforeMount(loadDepartments);

/*------------------------------------------------------------------------
                           Department form state
------------------------------------------------------------------------*/

const departmentFormState = reactive({
    validationErrors: null,
    isSubmitting: false,
    isResetTriggered: false,
});

const submitCreateDepartment = (attributes) => {
    departmentFormState.isSubmitting = true;

    DepartmentModel.create(orgId, attributes, { response_data: RESPONSE_DATA_TYPES.LIST })
        .then((models) => {
            departmentsState.data = models;
            departmentFormState.isResetTriggered = true;

            updateOnboardingStatus(models);

            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: 'Department has been successfully created!',
            });
        })
        .catch((error) =>
            catchUnprocessableEntity(error, (errors) => {
                departmentFormState.validationErrors = errors;
            })
        )
        .finally(() => (departmentFormState.isSubmitting = false));
};
</script>

<template>
    <OnboardingLayout
        title="Departments"
        :is-data-loading="departmentsState.isDataLoading"
        :is-submitting="departmentFormState.isSubmitting"
    >
        <OnboardingStepLayout
            form-title="Create new Department"
            list-title="Departments added"
            :list-items="departmentsState.data"
            :is-data-loading="departmentsState.isDataLoading"
            @delete-success="handleDeleteSuccess"
        >
            <template #confirmation-message="{ model }">
                <p>Are you sure you want to delete "{{ model.name }}" from the company?</p>
                <b>All the department items will be deleted as well.</b>
            </template>

            <template #list-item="{ item: department, openDelete }">
                <DepartmentCard :department="department">
                    <template #actions="{ qaPrefix }">
                        <button
                            type="button"
                            :data-testid="`${qaPrefix}_delete_btn`"
                            @click="openDelete(department)"
                        >
                            <DeleteIcon />
                        </button>
                    </template>
                </DepartmentCard>
            </template>

            <DepartmentForm
                disable-animation
                submit-btn-text="create department"
                :validation-errors="departmentFormState.validationErrors"
                :is-submitting="departmentFormState.isSubmitting"
                :is-reset-triggered="departmentFormState.isResetTriggered"
                @reset-success="departmentFormState.isResetTriggered = false"
                @submit="submitCreateDepartment"
            />
        </OnboardingStepLayout>
    </OnboardingLayout>
</template>

<style lang="scss" scoped>
:deep(.pl-employees-list-wrapper) {
    max-height: custom-space(14);
    height: auto;
}
</style>
