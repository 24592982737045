<script>
export default {
    name: 'OnboardingLayout',
};
</script>

<script setup>
import { computed, onBeforeMount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { SELECT_ACCOUNT_ROUTE_NAME, ONBOARDING_ROUTE_NAMES } from '@/enums/routesNameEnums';

defineProps({
    title: {
        type: String,
        required: true,
    },
    subtitle: {
        type: String,
        default: null,
    },
    note: {
        type: String,
        default: null,
    },
    isDataLoading: {
        type: Boolean,
        default: false,
    },
    isSubmitting: {
        type: Boolean,
        default: false,
    },
});

const router = useRouter();
const route = useRoute();
const store = useStore();

/*------------------------------------------------------------------------
                               General state
------------------------------------------------------------------------*/

const onClickBackBtn = () => {
    switch (route.name) {
        case ONBOARDING_ROUTE_NAMES.CREATE_COMPANY: {
            if (store.state.accounts.length > 0) {
                store.commit('auth/setCurrentAccount', null);

                router.replace({ name: SELECT_ACCOUNT_ROUTE_NAME });
            } else {
                store.dispatch('auth/logout').then(() => (window.location.href = '/'));
            }

            break;
        }

        case ONBOARDING_ROUTE_NAMES.INDEX:
            router.push({ name: ONBOARDING_ROUTE_NAMES.CREATE_COMPANY });

            break;

        default:
            router.push({ name: ONBOARDING_ROUTE_NAMES.INDEX });
    }
};

const areOnboardingSettingsLoading = computed(
    () => store.state.auth.currentAccount !== null && !store.getters['onboarding/areSettingsLoaded']
);

onBeforeMount(() => {
    const { currentAccount } = store.state.auth;

    if (currentAccount !== null) {
        store.dispatch('onboarding/loadSettings', currentAccount.organization.id);
    }
});
</script>

<template>
    <section class="pl-onboarding-layout">
        <header>
            <div class="container">
                <transition
                    name="fade"
                    type="transition"
                    mode="out-in"
                >
                    <button
                        v-if="!isSubmitting && !areOnboardingSettingsLoading"
                        type="button"
                        data-testid="page_arrow_back"
                        @click="onClickBackBtn"
                    >
                        <ArrowDownIcon :height="10" />
                    </button>
                </transition>

                <h1>
                    {{ title }}
                </h1>
            </div>
        </header>

        <transition
            appear
            name="down"
            type="transition"
            mode="out-in"
        >
            <InPlaceLoader v-if="areOnboardingSettingsLoading" />

            <div
                v-else
                class="pl-onboarding-layout__content container"
                :class="{
                    'pl-onboarding-layout__content--loading': isDataLoading,
                }"
            >
                <OverlayLoader v-if="isDataLoading" />

                <h5 v-if="subtitle !== null">
                    {{ subtitle }}
                </h5>

                <small v-if="note !== null">
                    {{ note }}
                </small>

                <slot />
            </div>
        </transition>
    </section>
</template>

<style lang="scss" scoped>
.pl-onboarding-layout {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;

    > header {
        background-color: $primary;
        padding: custom-space(0.5) 0;
        color: $white;
        text-align: center;

        & > .container {
            position: relative;
            display: flex;
            align-items: center;
            gap: custom-space(1);

            & > button {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                position: absolute;
                top: 50%;
                left: calc($grid-gutter-width / 2);
                padding: $click-space;
                margin-left: -($click-space + custom-space(0.2));
                transform: translateY(-50%);

                svg {
                    transform: rotate(90deg);

                    :deep(path) {
                        fill: $white;
                    }
                }
            }

            & > h1 {
                flex: 1 1 auto;
                margin-bottom: 0;
                font-size: calc(1.25rem + 0.75vw);
                color: inherit;
            }
        }
    }

    .container {
        max-width: 400px;

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        margin-top: custom-space(1);
        padding-bottom: custom-space(1);

        > span {
            margin-bottom: custom-space(0.75);
            padding: custom-space(0.125) custom-space(1);
            border: 2px solid $primary;
            border-radius: custom-space(10);
            color: $primary;
            font-size: $font-size-base * 0.95;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
        }

        > h5 {
            max-width: 325px;
            margin: 0 0 custom-space(1.25) 0;
            text-align: center;
        }

        > small {
            max-width: 300px;
            margin: custom-space(-0.5) 0 custom-space(1.25) 0;
            text-align: center;
        }

        &--loading :deep(.pl-form) > .pl-overlay-spinner {
            display: none;
        }
    }
}
</style>
