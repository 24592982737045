<script>
export default {
    name: 'CreatePLPARTemplateStep1View',
};
</script>

<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { mapGetters } from '@/utils/vuexUtils';
import { catchUnprocessableEntity } from '@/utils/httpUtils';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import PARTemplateModel from '@/models/PARTemplate';
import useOrderSettingsForm from '@/composition/useOrderSettingsForm';
import PARTemplateSettingsForm from '@/components/forms/PARTemplateSettingsForm';
import OrderStepTemplate from '@/components/model-specific/orders/OrderStepTemplate';

const router = useRouter();
const store = useStore();

const { currentPARTemplate } = mapGetters(['currentPARTemplate']);

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Template state
------------------------------------------------------------------------*/

const checkTemplateStatus = () => {
    if (currentPARTemplate.value === null) {
        router.push({ name: SETTINGS_ROUTE_NAMES.PAR.INDEX });
    }
};

const cancelTemplate = () => {
    currentPARTemplate.value.cancel().finally(() => {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.SUCCESS,
            message: 'Template has been successfully cancelled',
        });

        router.push({ name: SETTINGS_ROUTE_NAMES.PAR.INDEX });

        store.commit('setCurrentPARTemplate', null);
    });
};

/*------------------------------------------------------------------------
                       Template settings form state
------------------------------------------------------------------------*/

const { orderSettingsFormState, orderSettingsForm } = useOrderSettingsForm();

const navigateToNextStep = () => {
    router.push({ name: SETTINGS_ROUTE_NAMES.PAR.CREATE.PL.ADD_ITEMS });
};

const submitForm = (payload) => {
    let request = null;

    if (currentPARTemplate.value !== null) {
        if (!orderSettingsFormState.hasChangesMade) {
            navigateToNextStep();
        } else {
            orderSettingsFormState.isSubmitting = true;

            request = currentPARTemplate.value.update(payload).then((model) => {
                EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                    type: ALERT_TYPES.SUCCESS,
                    message: 'Template settings has been successfully updated',
                });

                store.commit('setCurrentPARTemplate', model);

                navigateToNextStep();
            });
        }
    } else {
        orderSettingsFormState.isSubmitting = true;

        request = PARTemplateModel.create(orgId, payload).then((model) => {
            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: 'New pending PAR template has been successfully created',
            });

            store.commit('setCurrentPARTemplate', model);

            navigateToNextStep();
        });
    }

    if (request !== null) {
        request
            .catch((error) => catchUnprocessableEntity(error, orderSettingsForm.update))
            .finally(() => (orderSettingsFormState.isSubmitting = false));
    }
};

const templateSettingsFormProps = computed(() => {
    const template = currentPARTemplate.value;

    if (template === null) {
        return {};
    }

    const { par_template_settings: settings } = template;

    return {
        name: template.name,
        days: settings.days,
        dates: settings.dates,
        startDate: settings.start_date,
        endDate: settings.end_date,
    };
});
</script>

<template>
    <OrderStepTemplate
        is-template
        :is-step-submitting="orderSettingsFormState.isSubmitting"
        @before-init-cancel-process="checkTemplateStatus"
        @cancel-process="cancelTemplate"
    >
        <template #step-content>
            <PARTemplateSettingsForm
                id="pl-create-template-step-1-form"
                :name="templateSettingsFormProps.name"
                :days="templateSettingsFormProps.days"
                :dates="templateSettingsFormProps.dates"
                :start-date="templateSettingsFormProps.startDate"
                :end-date="templateSettingsFormProps.endDate"
                :validation-errors="orderSettingsFormState.validationErrors"
                @update-status="orderSettingsForm.update"
                @submit="submitForm"
            />
        </template>

        <template #next-step-action>
            <BtnUI
                is-filled
                type="submit"
                form="pl-create-template-step-1-form"
                :disabled="orderSettingsForm.isSubmittingDisabled()"
            >
                {{ orderSettingsForm.getSubmitBtnText(currentPARTemplate) }}
            </BtnUI>
        </template>
    </OrderStepTemplate>
</template>
