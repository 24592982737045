<script>
export default {
    name: 'EditPrepTaskView',
};
</script>

<script setup>
import { onBeforeMount, computed, reactive, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { catchUnprocessableEntity, catchNotFoundError } from '@/utils/httpUtils';
import { PREP_TASKS_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import PrepTaskModel from '@/models/PrepTask';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useConfirmationModal from '@/composition/useConfirmationModal';
import Alert from '@/components/Alert';
import ConfirmationModal from '@/components/ConfirmationModal';
import PrepTaskForm from '@/components/forms/PrepTaskForm';

const props = defineProps({
    prepTaskId: {
        type: [Number, String],
        required: true,
    },
});

const $acl = inject('$acl');

const router = useRouter();
const route = useRoute();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(PREP_TASKS_RESTRICTIONS.UPDATE, currentAccount)) {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to edit prep tasks.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                           Prep task form state
------------------------------------------------------------------------*/

const prepTaskFormState = reactive({
    data: null,
    validationErrors: null,
    isDataLoading: true,
    isSubmitting: false,
});

const loadPrepTaskData = () => {
    sendAbortableRequest(PrepTaskModel.find(orgId, props.prepTaskId, { 'on_demand[]': 'location_specific_settings' }))
        .then((model) => (prepTaskFormState.data = model))
        .finally(() => (prepTaskFormState.isDataLoading = false));
};

const submitUpdatePrepTask = (attributes) => {
    prepTaskFormState.isSubmitting = true;

    prepTaskFormState.data
        .update(attributes)
        .then(() => {
            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: 'Prep task settings has been successfully updated',
            });

            router.push({ name: SETTINGS_ROUTE_NAMES.PREP_TASKS.INDEX, query: route.query });
        })
        .catch((error) =>
            catchUnprocessableEntity(error, (errors) => {
                prepTaskFormState.validationErrors = errors;
            })
        )
        .finally(() => (prepTaskFormState.isSubmitting = false));
};

const prepTaskFormProps = computed(() => {
    const { data } = prepTaskFormState;

    if (!data) {
        return {};
    }

    return {
        name: data.name,
        departments: data.departments,
        timeDelay: data.time_delay.toString(),
        locationSpecificSettings: data.location_specific_settings,
        batchSizeAmount: data.batch_size_amount,
        batchSizeUnitId: data.batch_size_unit?.id,
        batchYieldAmount: data.batch_yield_amount,
        batchYieldUnitId: data.batch_yield_unit?.id,
        batchWeightAmount: data.batch_weight_amount,
        batchWeightUnit: data.batch_weight_unit?.value,
        batchCost: data.batch_cost,
        batchLaborTime: data.batch_labor_time,
        laborTimeSettings: data.labor_time_settings,
        orderingUnitId: data.ordering_unit?.id,
        defaultReportingUnitId: data.default_reporting_unit?.id,
        isChangeableReportingUnit: data.is_changeable_reporting_unit,
    };
});

onBeforeMount(loadPrepTaskData);

/*------------------------------------------------------------------------
                         Confirmation modal state
------------------------------------------------------------------------*/

const { confirmationState, confirmationModal } = useConfirmationModal();

const openDeletePrepTaskModal = () => {
    if (prepTaskFormState.data.parent_items_count > 0) {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.FAIL,
            message: 'You are not allowed to delete the prep task, because prep task is associated with some items.',
        });

        confirmationModal.close();
    } else {
        confirmationModal.open(prepTaskFormState.data);
    }
};

const handleSuccessfulDeletion = () => {
    EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
        type: ALERT_TYPES.SUCCESS,
        message: 'Prep task has been successfully deleted',
    });

    router.push({ name: SETTINGS_ROUTE_NAMES.PREP_TASKS.INDEX, query: route.query });
};

const submitDeletePrepTask = () => {
    confirmationModal
        .submit((model) => model.delete())
        .then(handleSuccessfulDeletion)
        .catch((error) => catchNotFoundError(error, handleSuccessfulDeletion));
};
</script>

<template>
    <TopBarLayout
        include-back-route-query
        :back-route="SETTINGS_ROUTE_NAMES.PREP_TASKS.INDEX"
    >
        <template #title>
            <h1>Edit Prep Task</h1>
        </template>

        <Alert
            v-if="prepTaskFormState.data === null && !prepTaskFormState.isDataLoading"
            :type="ALERT_TYPES.FAIL"
        >
            Prep task could not be loaded for some reason. Please try again later.
        </Alert>

        <template v-else>
            <PrepTaskForm
                is-edit-mode
                :name="prepTaskFormProps.name"
                :departments="prepTaskFormProps.departments"
                :time-delay="prepTaskFormProps.timeDelay"
                :location-specific-settings="prepTaskFormProps.locationSpecificSettings"
                :batch-size-amount="prepTaskFormProps.batchSizeAmount"
                :batch-size-unit-id="prepTaskFormProps.batchSizeUnitId"
                :batch-yield-amount="prepTaskFormProps.batchYieldAmount"
                :batch-yield-unit-id="prepTaskFormProps.batchYieldUnitId"
                :batch-weight-amount="prepTaskFormProps.batchWeightAmount"
                :batch-weight-unit="prepTaskFormProps.batchWeightUnit"
                :batch-cost="prepTaskFormProps.batchCost"
                :batch-labor-time="prepTaskFormProps.batchLaborTime"
                :labor-time-settings="prepTaskFormProps.laborTimeSettings"
                :ordering-unit-id="prepTaskFormProps.orderingUnitId"
                :default-reporting-unit-id="prepTaskFormProps.defaultReportingUnitId"
                :is-changeable-reporting-unit="prepTaskFormProps.isChangeableReportingUnit"
                :validation-errors="prepTaskFormState.validationErrors"
                :is-data-loading="prepTaskFormState.isDataLoading"
                :is-submitting="prepTaskFormState.isSubmitting"
                @submit="submitUpdatePrepTask"
            >
                <template #delete-btn="{ isLoading: isPrepTaskLoading }">
                    <BtnUI
                        data-testid="delete_prep_task_btn"
                        :disabled="isPrepTaskLoading"
                        @click="openDeletePrepTaskModal"
                    >
                        Delete prep task
                    </BtnUI>
                </template>
            </PrepTaskForm>

            <ConfirmationModal
                v-if="confirmationState.isOpened"
                qa-prefix="delete_prep_task_modal"
                :is-pending="confirmationState.isPending"
                @close="confirmationModal.close"
                @submit-delete="submitDeletePrepTask"
            >
                Are you sure you want to delete the prep task "{{ confirmationState.model.name }}" from the company?
            </ConfirmationModal>
        </template>
    </TopBarLayout>
</template>
