import { cutFloatNumber } from '@/utils/numberUtils';
import { ADVANCED_BATCH_TIME_CHART_TYPES } from '@/enums/itemEnums';

/*------------------------------------------------------------------------
                             Default assignees
------------------------------------------------------------------------*/

export const getLocationAssigneeOptions = (assignees, locationId) => {
    if (assignees.length === 0) {
        return [];
    }

    const options = assignees.reduce((acc, assignee) => {
        const isAssigneeInLocation = assignee.available_locations.some(({ id }) => locationId === id);

        if (isAssigneeInLocation) {
            acc.push({ value: assignee.id, text: assignee.name });
        }

        return acc;
    }, []);

    if (options.length > 0) {
        options.unshift({
            text: 'No employee',
            selectedText: '',
            value: null,
        });
    }

    return options;
};

/*------------------------------------------------------------------------
                              Batch Settings
------------------------------------------------------------------------*/

export const validateBatchSizeAmount = (form, handleValidationError) => {
    const { batch_yield_unit_id: batchYieldUnit, batch_size_unit_id: batchSizeUnit } = form;

    const batchYieldAmount = +form.batch_yield_amount;
    const batchSizeAmount = +form.batch_size_amount;

    const setValidationError = (errorMessage) => {
        handleValidationError(errorMessage);

        return false;
    };

    if (batchYieldUnit === batchSizeUnit) {
        if (batchSizeAmount !== batchYieldAmount) {
            return setValidationError(
                'Production output amount must be equal to batch yield when the same unit is used for both.'
            );
        }

        return true;
    }

    if (batchSizeAmount > batchYieldAmount) {
        return setValidationError('Production output amount must be smaller or equal to batch yield.');
    }

    if (batchYieldAmount % batchSizeAmount !== 0) {
        return setValidationError('Batch yield amount must be multiple of production output amount.');
    }

    return true;
};

export const getBatchSettingsPayload = (form) => {
    const getProcessedAmount = (amount) => {
        if (amount === null || amount === '') {
            return null;
        }

        return +amount;
    };

    return {
        batch_yield_amount: getProcessedAmount(form.batch_yield_amount),
        batch_size_amount: getProcessedAmount(form.batch_size_amount),
        batch_weight_amount: getProcessedAmount(form.batch_weight_amount),
    };
};

/*------------------------------------------------------------------------
                       Advanced batch time settings
------------------------------------------------------------------------*/

export const getBatchTimeSettingsPayload = (
    batchLaborTime,
    timeSpeedup,
    timeInitial,
    timeFinal,
    chartType,
    isAdvancedModeActivated
) => {
    if (isAdvancedModeActivated) {
        return {
            labor_time_settings: {
                period: +timeSpeedup,
                init_time: +timeInitial,
                final_time: +timeFinal,
                strategy: chartType,
            },
        };
    }

    return { batch_labor_time: batchLaborTime };
};

export const calculateAdvancedBatchLaborTime = (
    batchYieldValue,
    timeSpeedup,
    timeInitial,
    timeFinal,
    chartType,
    batchYieldAmount = 1
) => {
    if (batchYieldValue <= batchYieldAmount) {
        return timeInitial;
    }

    if (batchYieldValue >= timeSpeedup) {
        return timeFinal;
    }

    if (chartType === ADVANCED_BATCH_TIME_CHART_TYPES.LINEAR) {
        const coefficient = (timeFinal - timeInitial) / (timeSpeedup - batchYieldAmount);

        return timeInitial + coefficient * (batchYieldValue - batchYieldAmount);
    }

    const coefficient = Math.abs(timeFinal - timeInitial) / Math.pow(timeSpeedup - batchYieldAmount, 2);

    if (chartType === ADVANCED_BATCH_TIME_CHART_TYPES.EASE_IN) {
        return timeFinal > timeInitial
            ? timeInitial + coefficient * Math.pow(batchYieldAmount - batchYieldValue, 2)
            : timeFinal + coefficient * Math.pow(timeSpeedup - batchYieldValue, 2);
    }

    // ease out
    return timeFinal > timeInitial
        ? timeFinal - coefficient * Math.pow(timeSpeedup - batchYieldValue, 2)
        : timeInitial - coefficient * Math.pow(batchYieldAmount - batchYieldValue, 2);
};

export const calculateTotalAdvancedBatchLaborTime = (
    batchYieldValue,
    timeSpeedup,
    timeInitial,
    timeFinal,
    chartType,
    batchYieldAmount = 1,
    initialQty = 1
) => {
    let batchLaborTime = 0;

    for (let qty = initialQty; qty <= batchYieldValue; qty++) {
        const batchesQty = Math.ceil(qty / batchYieldAmount);

        const instant = calculateAdvancedBatchLaborTime(
            batchesQty * batchYieldAmount,
            timeSpeedup,
            timeInitial,
            timeFinal,
            chartType,
            batchYieldAmount
        );

        batchLaborTime += instant / batchYieldAmount;
    }

    return batchLaborTime;
};

export const getBatchTimeChartData = (form, chartType, showTotal, prepTasks = []) => {
    const labels = [];
    const datasetsData = [];

    if (prepTasks.length === 0) {
        const batchYieldAmount = +form.batch_yield_amount || 1;
        const timeSpeedup = +form.batch_time_speedup;
        const timeInitial = +form.batch_time_initial;
        const timeFinal = +form.batch_time_final;

        if (timeSpeedup && timeInitial && timeFinal) {
            for (let i = 1; i <= timeSpeedup; i++) {
                labels.push(`${i} batches`);

                let batchLaborTime = 0;

                const callback = showTotal ? calculateTotalAdvancedBatchLaborTime : calculateAdvancedBatchLaborTime;

                batchLaborTime = callback(
                    i * batchYieldAmount,
                    timeSpeedup * batchYieldAmount,
                    timeInitial,
                    timeFinal,
                    chartType,
                    batchYieldAmount
                );

                datasetsData.push(showTotal ? Math.ceil(batchLaborTime) : cutFloatNumber(batchLaborTime));
            }
        }
    } else {
        const maxPeriod = prepTasks.reduce((prevPeriod, prepTask) => {
            const { labor_time_settings: laborTimeSettings } = prepTask;

            if (laborTimeSettings === null) {
                return Math.min(Math.max(prevPeriod, 3), 10);
            }

            const calculatedPeriod = Math.ceil(
                (laborTimeSettings.period * prepTask.batch_yield_amount) / prepTask.BYQty
            );

            return Math.min(Math.max(prevPeriod, calculatedPeriod, 3), 10);
        }, 3);

        for (let i = 1; i <= maxPeriod; i++) {
            labels.push(`${i} batches`);

            let laborTime = 0;

            for (let j = 0; j < prepTasks.length; j++) {
                const {
                    BYQty,
                    batch_yield_amount: batchYieldAmount,
                    batch_labor_time: batchLaborTime,
                    labor_time_settings: laborTimeSettings,
                } = prepTasks[j];

                if (laborTimeSettings === null) {
                    const qtyLaborTime = batchLaborTime * (BYQty / batchYieldAmount);

                    laborTime += showTotal ? i * qtyLaborTime : qtyLaborTime;
                } else {
                    const initialQty = showTotal ? 1 : (i - 1) * BYQty + 1;

                    laborTime += calculateTotalAdvancedBatchLaborTime(
                        i * BYQty,
                        +laborTimeSettings.period * batchYieldAmount,
                        +laborTimeSettings.init_time,
                        +laborTimeSettings.final_time,
                        laborTimeSettings.strategy,
                        batchYieldAmount,
                        initialQty
                    );
                }
            }

            datasetsData.push(showTotal ? Math.ceil(laborTime) : cutFloatNumber(laborTime));
        }
    }

    return {
        labels,
        datasets: [
            {
                label: null,
                borderColor: '#cf382f',
                pointBackgroundColor: '#cf382f',
                borderWidth: 1,
                pointBorderColor: 'white',
                tension: 0.2,
                fill: false,
                data: datasetsData,
            },
        ],
    };
};

export const getBatchTimeChartOptions = (isChartInvalid) => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            displayColors: false,
            titleMarginBottom: 2,
            padding: 8,
            mode: 'index',
            position: 'nearest',
            intersect: false,
            yAlign: 'bottom',
            bodyAlign: 'center',
            callbacks: {
                label: (context) => context.parsed.y + 'm',
            },
        },
    },
    scales: {
        y: {
            beginAtZero: true,
            ticks: {
                callback: (value) => {
                    if (isChartInvalid) {
                        return '';
                    }

                    return value + 'm';
                },
            },
        },
    },
});

/*------------------------------------------------------------------------
                                 Ordering
------------------------------------------------------------------------*/

export const getSelectedUnitsOptions = (form, batchUnitsState) => {
    const { batch_yield_unit_id: batchYieldUnitId, batch_size_unit_id: batchSizeUnitId } = form;

    const findUnitText = (unitId, options, defaultText = 'batches') =>
        options.find(({ value }) => unitId === value)?.text || defaultText;

    const options = [
        {
            value: null,
            text: 'batches',
        },
    ];

    if (batchYieldUnitId !== null) {
        options.push({
            value: batchYieldUnitId,
            text: findUnitText(batchYieldUnitId, batchUnitsState.yieldOptions),
        });
    }

    if (batchSizeUnitId !== null && batchSizeUnitId !== batchYieldUnitId) {
        options.push({
            value: batchSizeUnitId,
            text: findUnitText(batchSizeUnitId, batchUnitsState.sizeOptions),
        });
    }

    return options;
};

/*------------------------------------------------------------------------
                        Item configuration entities
------------------------------------------------------------------------*/

export const getInitCreateNewEntityOptionValue = (entityName) => `option--create-new-${entityName}`;

export const getItemEntityOptions = (
    data,
    entityName,
    includeCreateNewEntityOption = true,
    includeEmptyOption = false
) => {
    if (!data) {
        return [];
    }

    const getInitialOptions = () => {
        if (Array.isArray(data)) {
            return data.map(({ id, ...option }) => ({ value: id, text: option.value || option.text || option.name }));
        }

        return Object.entries(data).map(([value, text]) => ({
            value,
            text,
        }));
    };

    let options = getInitialOptions();

    if (includeEmptyOption) {
        options.unshift({
            selectedText: '',
            text: 'Empty',
            value: null,
        });
    }

    if (includeCreateNewEntityOption) {
        options.push({
            selectedText: `Creating...`,
            text: `Create new ${entityName}...`,
            value: getInitCreateNewEntityOptionValue(entityName),
        });
    }

    return options;
};
