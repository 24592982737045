import { onBeforeMount, reactive, ref } from 'vue';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { catchUnprocessableEntity } from '@/utils/httpUtils';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import { ITEM_CONFIGURATION_ENTITIES_NAME } from '@/enums/itemEnums';
import BatchUnitModel from '@/models/BatchUnit';
import CategoryModel from '@/models/Category';
import DepartmentModel from '@/models/Department';
import StationModel from '@/models/Station';
import useAbortableRequest from '@/composition/useAbortableRequest';

const useItemConfiguration = (entitiesQueryParams = {}, isItemConfigurationRoute = false) => {
    const store = useStore();

    const { sendAbortableRequest } = useAbortableRequest();

    const { currentAccount } = store.state.auth;
    const orgId = currentAccount.organization.id;

    /*------------------------------------------------------------------------
                             Item configuration state
    ------------------------------------------------------------------------*/

    const itemConfigurationState = reactive({
        departments: [],
        batchUnits: [],
        stations: [],
        isDataLoading: false,
        hasError: false,
    });

    const loadItemConfigurationState = () => {
        itemConfigurationState.isDataLoading = true;

        return Promise.all([
            sendAbortableRequest(DepartmentModel.all(orgId, { with_categories: true })),
            sendAbortableRequest(BatchUnitModel.all(orgId, { 'on_demand[]': 'can_be_deleted' })),
            sendAbortableRequest(StationModel.all(orgId)),
        ])
            .then(([departmentModels, batchUnitModels, stationModels]) => {
                itemConfigurationState.departments = departmentModels;
                itemConfigurationState.batchUnits = batchUnitModels;
                itemConfigurationState.stations = stationModels;
            })
            .catch(() => (itemConfigurationState.hasError = true))
            .finally(() => (itemConfigurationState.isDataLoading = false));
    };

    onBeforeMount(() => {
        if (isItemConfigurationRoute) {
            loadItemConfigurationState();
        }
    });

    /*------------------------------------------------------------------------
                         Active entity configuration state
    ------------------------------------------------------------------------*/

    const entitiesDataKeysByName = {
        [ITEM_CONFIGURATION_ENTITIES_NAME.CATEGORY]: 'departments',
        [ITEM_CONFIGURATION_ENTITIES_NAME.UNIT]: 'batchUnits',
        [ITEM_CONFIGURATION_ENTITIES_NAME.STATION]: 'stations',
    };

    const activeConfigurationEntity = ref(null);

    const setActiveEntityConfiguration = (entityName) => (activeConfigurationEntity.value = entityName);
    const resetActiveEntityConfiguration = () => (activeConfigurationEntity.value = null);

    const updateItemEntityState = (modelData) => {
        const entityName = activeConfigurationEntity.value;
        const entityDataKey = entitiesDataKeysByName[entityName];

        if (Array.isArray(modelData)) {
            itemConfigurationState[entityDataKey] = modelData;
        } else {
            itemConfigurationState[entityDataKey] = itemConfigurationState[entityDataKey].map((entity) => {
                if (modelData.id === entity.id) {
                    return modelData;
                }

                return entity;
            });
        }

        if (entityName === ITEM_CONFIGURATION_ENTITIES_NAME.UNIT && store.getters['itemBatchMeta/isMetaLoaded']) {
            store.commit('itemBatchMeta/setMeta', {
                ...store.state.itemBatchMeta.meta,
                batch_size_units: modelData,
                batch_yield_units: modelData,
            });
        }

        resetActiveEntityConfiguration();
    };

    /*------------------------------------------------------------------------
                              Item entity form state
    ------------------------------------------------------------------------*/

    const entityModelsByName = {
        [ITEM_CONFIGURATION_ENTITIES_NAME.CATEGORY]: CategoryModel,
        [ITEM_CONFIGURATION_ENTITIES_NAME.UNIT]: BatchUnitModel,
        [ITEM_CONFIGURATION_ENTITIES_NAME.STATION]: StationModel,
    };

    const itemEntityFormState = reactive({
        entityData: null,
        validationErrors: null,
        isSubmitting: false,
        isModalOpened: false,
    });

    const openItemEntityForm = (entityName, entityData = null) => {
        setActiveEntityConfiguration(entityName);

        itemEntityFormState.entityData = entityData;
        itemEntityFormState.isModalOpened = true;
    };

    const closeItemEntityForm = () => {
        itemEntityFormState.entityData = null;
        itemEntityFormState.validationErrors = null;
        itemEntityFormState.isSubmitting = false;
        itemEntityFormState.isModalOpened = false;
    };

    const submitItemEntityForm = (attributes, resolveCallback) => {
        const { entityData } = itemEntityFormState;

        const entityName = activeConfigurationEntity.value;

        const isEditMode = entityData !== null && isItemConfigurationRoute;
        const entityModel = entityModelsByName[entityName];

        itemEntityFormState.isSubmitting = true;

        const queryParams = {
            ...(entitiesQueryParams[entityName] || {}),
            ...(isItemConfigurationRoute ? { 'on_demand[]': 'can_be_deleted' } : {}),
        };

        const request = isEditMode
            ? entityModel.update(orgId, entityData.id, attributes, queryParams)
            : entityModel.create(orgId, attributes, queryParams);

        request
            .then((modelData) => {
                EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                    type: ALERT_TYPES.SUCCESS,
                    message: `The ${entityName} has been successfully ${isEditMode ? 'updated' : 'created'}`,
                });

                const entityData = Array.isArray(modelData) ? modelData[modelData.length - 1] : modelData;

                resolveCallback?.(entityData);

                updateItemEntityState(modelData);
                closeItemEntityForm();
            })
            .catch((error) =>
                catchUnprocessableEntity(error, (errors) => {
                    itemEntityFormState.validationErrors = { name: errors.name || errors.value };
                })
            )
            .finally(() => (itemEntityFormState.isSubmitting = false));
    };

    return {
        itemConfigurationState,
        loadItemConfigurationState,

        activeConfigurationEntity,
        setActiveEntityConfiguration,
        updateItemEntityState,

        itemEntityFormState,
        openItemEntityForm,
        closeItemEntityForm,
        submitItemEntityForm,
    };
};

export default useItemConfiguration;
