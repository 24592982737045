<script>
export default {
    name: 'UnfinishedOrderRibbon',
};
</script>

<script setup>
import RibbonUI from '@/components/UI/RibbonUI';

defineProps({
    navigateTo: {
        type: Object,
        required: true,
    },
});
</script>

<template>
    <RibbonUI
        text="You haven't finished your order. Back to order"
        :navigate-to="navigateTo"
    />
</template>
