<script>
export default {
    name: 'ShowPLPARTemplateView',
};
</script>

<script setup>
import { watch, reactive, computed, inject, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { mapGetters } from '@/utils/vuexUtils';
import { catchNotFoundError } from '@/utils/httpUtils';
import { isOrderEditing } from '@/utils/orderUtils';
import { PAR_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES, BADGE_TYPES } from '@/enums/componentsEnums';
import { ORDER_GROUP_ITEMS_BY_OPTIONS } from '@/enums/orderEnums';
import PARTemplateModel from '@/models/PARTemplate';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useConfirmationModal from '@/composition/useConfirmationModal';
import Alert from '@/components/Alert';
import Badge from '@/components/Badge';
import DropDown from '@/components/DropDown';
import ConfirmationModal from '@/components/ConfirmationModal';
import GroupedSettings from '@/components/constructions/grouped-settings';
import OrderSummaryCard from '@/components/model-specific/orders/order-summary-card';

const props = defineProps({
    templateId: {
        type: [String, Number],
        required: true,
    },
});

const $acl = inject('$acl');

const router = useRouter();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentPARTemplate } = mapGetters(['currentPARTemplate']);

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(PAR_RESTRICTIONS.VIEW, currentAccount)) {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to PAR templates.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                              Template state
------------------------------------------------------------------------*/

const templateState = reactive({
    data: null,
    isDataLoading: true,
    isActionProcessing: false,
});

const loadTemplate = () => {
    templateState.isDataLoading = true;

    sendAbortableRequest(
        PARTemplateModel.find(orgId, props.templateId, {
            group_items_by: store.state.PLOrderViewSettings.groupItemsBy,
        })
    )
        .then((model) => (templateState.data = model))
        .finally(() => (templateState.isDataLoading = false));
};

const redirectToPendingTemplate = () => {
    const template = currentPARTemplate.value;

    EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
        type: ALERT_TYPES.WARNING,
        message: 'You currently have a pending template. Please finalize or cancel it, before editing a new one.',
    });

    if (isOrderEditing(template)) {
        router.replace({
            name: SETTINGS_ROUTE_NAMES.PAR.EDIT.PL.INDEX,
            params: { templateId: template.id },
        });
    } else {
        router.replace({ name: SETTINGS_ROUTE_NAMES.PAR.CREATE.PL.INDEX });
    }
};

const initEditTemplate = () => {
    if (currentPARTemplate.value !== null) {
        return redirectToPendingTemplate();
    }

    templateState.isActionProcessing = true;

    templateState.data
        .createDraft()
        .then((templateModel) => {
            store.commit('setCurrentPARTemplate', templateModel);

            router.push({
                name: SETTINGS_ROUTE_NAMES.PAR.EDIT.PL.INDEX,
                params: { templateId: props.templateId },
            });
        })
        .finally(() => (templateState.isActionProcessing = false));
};

const permissions = computed(() => ({
    update: $acl.can(PAR_RESTRICTIONS.UPDATE, currentAccount, templateState.data),
    delete: $acl.can(PAR_RESTRICTIONS.DELETE, currentAccount, templateState.data),
}));

watch(() => store.state.PLOrderViewSettings.groupItemsBy, loadTemplate, { immediate: true });

/*------------------------------------------------------------------------
                         Confirmation modal state
------------------------------------------------------------------------*/

const { confirmationState, confirmationModal } = useConfirmationModal();

const handleSuccessfulDeletion = () => {
    EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
        type: ALERT_TYPES.SUCCESS,
        message: 'Template has been successfully deleted',
    });

    router.push({ name: SETTINGS_ROUTE_NAMES.PAR.INDEX });
};

const submitDeleteTemplate = () => {
    confirmationModal
        .submit((model) => model.delete())
        .then(handleSuccessfulDeletion)
        .catch((error) => catchNotFoundError(error, handleSuccessfulDeletion));
};
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.PAR.INDEX">
        <template #title>
            <h1>
                <span>
                    PAR Details

                    <Badge
                        is-absolute
                        size="xs"
                        :type="BADGE_TYPES.PAR"
                    >
                        Prep List
                    </Badge>
                </span>
            </h1>
        </template>

        <template
            v-if="templateState.data !== null"
            #actions
        >
            <DropDown>
                <template #content>
                    <GroupedSettings.Root>
                        <GroupedSettings.List title="Group items by">
                            <GroupedSettings.ListItem
                                v-for="(text, value) in ORDER_GROUP_ITEMS_BY_OPTIONS"
                                :key="`group items by option: ${value}`"
                                :is-selected="store.state.PLOrderViewSettings.groupItemsBy === value"
                                @click="store.commit('PLOrderViewSettings/setGroupItemsBy', value)"
                            >
                                {{ text }}
                            </GroupedSettings.ListItem>
                        </GroupedSettings.List>

                        <GroupedSettings.List
                            v-if="permissions.update || permissions.delete"
                            title="Actions"
                        >
                            <GroupedSettings.ListItem
                                v-if="permissions.update"
                                @click="initEditTemplate"
                            >
                                {{ templateState.data.draft === null ? 'Edit template' : 'Continue editing' }}
                            </GroupedSettings.ListItem>

                            <GroupedSettings.ListItem
                                v-if="permissions.delete"
                                @click="confirmationModal.open(templateState.data)"
                            >
                                Delete template
                            </GroupedSettings.ListItem>
                        </GroupedSettings.List>
                    </GroupedSettings.Root>
                </template>
            </DropDown>
        </template>

        <OverlayLoader
            v-if="templateState.isActionProcessing"
            enable-sticky
        />

        <InPlaceLoader v-if="templateState.isDataLoading" />

        <Alert
            v-else-if="templateState.data === null"
            :type="ALERT_TYPES.FAIL"
        >
            Template could not be loaded for some reason. Please try again later.
        </Alert>

        <OrderSummaryCard
            v-else
            :order="templateState.data"
        />

        <ConfirmationModal
            v-if="confirmationState.isOpened"
            qa-prefix="delete_template_modal"
            :is-pending="confirmationState.isPending"
            @close="confirmationModal.close"
            @submit-delete="submitDeleteTemplate"
        >
            Are you sure you want to delete the template "{{ confirmationState.model.name }}"?
        </ConfirmationModal>
    </TopBarLayout>
</template>
