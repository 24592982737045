import ApiClient from '@/plugins/ApiClient';
import Model from './Model';

export default class Organization extends Model {
    static find(orgId) {
        return this.withAbort(ApiClient.get(`organizations/${orgId}`), ({ data }) => this.make(data));
    }

    async update(attributes) {
        const { data } = await ApiClient.put(`organizations/${this.id}`, attributes);

        return this.fill(data);
    }
}
