<script>
export default {
    name: 'DepartmentsIndexView',
};
</script>

<script setup>
import { onBeforeMount, inject, reactive, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { catchNotFoundError } from '@/utils/httpUtils';
import { RESPONSE_DATA_TYPES } from '@/enums/httpEnums';
import { DEPARTMENTS_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import DepartmentModel from '@/models/Department';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useConfirmationModal from '@/composition/useConfirmationModal';
import Alert from '@/components/Alert';
import ListDisplay from '@/components/ListDisplay';
import ConfirmationModal from '@/components/ConfirmationModal';
import DepartmentCard from '@/components/model-specific/department/DepartmentCard';

const $acl = inject('$acl');

const router = useRouter();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(DEPARTMENTS_RESTRICTIONS.VIEW, currentAccount)) {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to departments.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                             Departments state
------------------------------------------------------------------------*/

const departmentsState = reactive({
    data: null,
    isDataLoading: true,
    hasBeenJustUpdated: false,
});

const loadDepartments = () => {
    sendAbortableRequest(DepartmentModel.all(orgId, { with_members_count: true }))
        .then((models) => (departmentsState.data = models))
        .finally(() => (departmentsState.isDataLoading = false));
};

const updateDepartmentsState = (models) => {
    departmentsState.data = models;
    departmentsState.hasBeenJustUpdated = true;

    setTimeout(() => (departmentsState.hasBeenJustUpdated = false), 250);
};

const permissions = computed(() => ({
    create: $acl.can(DEPARTMENTS_RESTRICTIONS.CREATE, currentAccount),
    update: $acl.can(DEPARTMENTS_RESTRICTIONS.UPDATE, currentAccount),
    delete: $acl.can(DEPARTMENTS_RESTRICTIONS.DELETE, currentAccount),
}));

onBeforeMount(loadDepartments);

/*------------------------------------------------------------------------
                         Confirmation modal state
------------------------------------------------------------------------*/

const { confirmationState, confirmationModal } = useConfirmationModal();

const handleSuccessfulDeletion = (models) => {
    updateDepartmentsState(models);

    EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
        type: ALERT_TYPES.SUCCESS,
        message: 'The department has been successfully deleted',
    });
};

const submitDeleteDepartment = () => {
    const { model } = confirmationState;

    confirmationModal
        .submit((model) =>
            model.delete({
                response_data: RESPONSE_DATA_TYPES.LIST,
                with_members_count: true,
            })
        )
        .then(handleSuccessfulDeletion)
        .catch((error) => {
            catchNotFoundError(error, () => {
                const filteredModels = departmentsState.data.filter(({ id }) => model.id !== id);

                handleSuccessfulDeletion(filteredModels);
            });
        });
};
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.INDEX">
        <template #title>
            <h1>Departments</h1>
        </template>

        <InPlaceLoader v-if="departmentsState.isDataLoading" />

        <Alert
            v-else-if="departmentsState.data === null"
            :type="ALERT_TYPES.FAIL"
        >
            Departments list could not be loaded for some reason. Please try again later.
        </Alert>

        <template v-else>
            <transition
                appear
                name="down"
                type="transition"
                mode="out-in"
            >
                <template v-if="!departmentsState.hasBeenJustUpdated">
                    <ListDisplay
                        v-if="departmentsState.data.length > 0"
                        has-borders
                        data-testid="departments_list"
                        :items="departmentsState.data"
                    >
                        <template #item="{ item: department }">
                            <DepartmentCard
                                :department="department"
                                :members-count="department.members_count"
                            >
                                <template
                                    v-if="permissions.update || permissions.delete"
                                    #actions="{ qaPrefix }"
                                >
                                    <router-link
                                        v-if="permissions.update"
                                        :data-testid="`${qaPrefix}_edit_btn`"
                                        :to="{
                                            name: SETTINGS_ROUTE_NAMES.DEPARTMENTS.EDIT,
                                            params: { departmentId: department.id },
                                        }"
                                    >
                                        <EditIcon />
                                    </router-link>

                                    <button
                                        v-if="permissions.delete"
                                        type="button"
                                        data-testid="delete_department_btn"
                                        @click.stop="confirmationModal.open(department)"
                                    >
                                        <DeleteIcon />
                                    </button>
                                </template>
                            </DepartmentCard>
                        </template>
                    </ListDisplay>

                    <p
                        v-else
                        class="pl-departments__message"
                        data-testid="departments_list_empty_note"
                    >
                        Departments list is empty.
                    </p>
                </template>
            </transition>

            <transition
                appear
                name="down"
                type="transition"
            >
                <div
                    v-if="permissions.create"
                    class="pl-departments__action"
                    style="transition-delay: 50ms"
                >
                    <router-link
                        :to="{ name: SETTINGS_ROUTE_NAMES.DEPARTMENTS.CREATE }"
                        class="pl-btn pl-btn--filled"
                        data-testid="create_department_btn"
                    >
                        Create department
                    </router-link>
                </div>
            </transition>

            <ConfirmationModal
                v-if="confirmationState.isOpened"
                qa-prefix="delete_department_modal"
                :is-pending="confirmationState.isPending"
                @close="confirmationModal.close"
                @submit-delete="submitDeleteDepartment"
            >
                <p>Are you sure you want to delete "{{ confirmationState.model.name }}" from the company?</p>
                <b>All the department items will be deleted as well.</b>
            </ConfirmationModal>
        </template>
    </TopBarLayout>
</template>

<style lang="scss" scoped>
.pl-departments {
    &__message {
        text-align: center;
        padding-top: custom-space(1);
    }

    &__action {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        margin-top: auto;
        padding-top: custom-space(1);
        width: 50%;

        @include media-breakpoint-down(xl) {
            width: 75%;
        }

        @include media-breakpoint-down(lg) {
            width: 100%;
        }

        & > a {
            width: 100%;
        }
    }
}
</style>
