<script>
export default {
    name: 'OnboardingStep3View',
};
</script>

<script setup>
import { onBeforeMount, reactive } from 'vue';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { catchUnprocessableEntity } from '@/utils/httpUtils';
import { RESPONSE_DATA_TYPES } from '@/enums/httpEnums';
import { ALERT_TYPES, IMPORT_ENTITIES } from '@/enums/componentsEnums';
import AccountModel from '@/models/Account';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useImportEntityModal from '@/composition/useImportEntityModal';
import ImportEntityModal from '@/components/ImportEntityModal';
import OnboardingLayout from '@/components/layouts/OnboardingLayout';
import OnboardingStepLayout from '@/components/layouts/OnboardingStepLayout';
import EmployeeForm from '@/components/forms/EmployeeForm';
import EmployeeCard from '@/components/model-specific/employee/EmployeeCard';

const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Employees state
------------------------------------------------------------------------*/

const employeesState = reactive({
    data: [],
    isDataLoading: true,
});

const updateOnboardingStatus = (models) => {
    store.commit('onboarding/updateSettings', {
        has_employees: models.length > 0,
    });
};

const loadEmployees = () => {
    sendAbortableRequest(AccountModel.all(orgId, { exclude_owner: true }))
        .then((models) => {
            employeesState.data = models || [];

            updateOnboardingStatus(employeesState.data);
        })
        .finally(() => (employeesState.isDataLoading = false));
};

const handleDeleteSuccess = (models) => {
    employeesState.data = models;

    updateOnboardingStatus(models);

    EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
        type: ALERT_TYPES.SUCCESS,
        message: 'The invitation has been successfully removed!',
    });
};

onBeforeMount(loadEmployees);

/*------------------------------------------------------------------------
                            Employee form state
------------------------------------------------------------------------*/

const employeeFormState = reactive({
    validationErrors: null,
    isSubmitting: false,
    isResetTriggered: false,
});

const submitSendInvitation = (attributes) => {
    employeeFormState.isSubmitting = true;

    AccountModel.create(orgId, attributes, {
        response_data: RESPONSE_DATA_TYPES.LIST,
        exclude_owner: true,
    })
        .then((models) => {
            employeesState.data = models;
            employeeFormState.isResetTriggered = true;

            updateOnboardingStatus(models);

            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: 'Employee has been successfully invited',
            });
        })
        .catch((error) =>
            catchUnprocessableEntity(error, (errors) => {
                employeeFormState.validationErrors = errors;
            })
        )
        .finally(() => (employeeFormState.isSubmitting = false));
};

/*------------------------------------------------------------------------
                         Import entity modal state
------------------------------------------------------------------------*/

const { importEntityState, importEntityModal } = useImportEntityModal(AccountModel, 'employees');

const submitImportEmployees = (payload) => {
    importEntityModal.submit(payload, (isSpinnerVisible) => {
        employeesState.isDataLoading = isSpinnerVisible;

        loadEmployees();
    });
};
</script>

<template>
    <OnboardingLayout
        title="Employees"
        :is-data-loading="employeesState.isDataLoading"
        :is-submitting="employeeFormState.isSubmitting"
    >
        <OnboardingStepLayout
            form-title="Invite new Employee"
            list-title="Employees to invite"
            import-btn-text="import new employees"
            :list-items="employeesState.data"
            :is-data-loading="employeesState.isDataLoading"
            :delete-request-params="{ exclude_owner: true }"
            @open-import="importEntityModal.open"
            @delete-success="handleDeleteSuccess"
        >
            <template #confirmation-message="{ model }">
                {{ `Are you sure you want to remove the invitation of employee "${model.name}"?` }}
            </template>

            <template #list-item="{ item: employee, openDelete }">
                <EmployeeCard
                    exclude-avatar
                    :employee="employee"
                >
                    <template #actions="{ qaPrefix }">
                        <button
                            type="button"
                            :data-testid="`${qaPrefix}_delete_btn`"
                            @click="openDelete(employee)"
                        >
                            <DeleteIcon />
                        </button>
                    </template>
                </EmployeeCard>
            </template>

            <EmployeeForm
                disable-animation
                submit-btn-text="invite employee"
                :validation-errors="employeeFormState.validationErrors"
                :is-data-loading="employeesState.isDataLoading"
                :is-submitting="employeeFormState.isSubmitting"
                :is-reset-triggered="employeeFormState.isResetTriggered"
                @reset-success="employeeFormState.isResetTriggered = false"
                @submit="submitSendInvitation"
            />

            <ImportEntityModal
                v-if="importEntityModal.isOpened"
                :entity="IMPORT_ENTITIES.EMPLOYEE"
                :data="importEntityState.data"
                :progress="importEntityState.progress"
                :is-action-processing="importEntityState.isActionProcessing"
                @download-template="importEntityModal.downloadTemplate"
                @parse-file="importEntityModal.parseFile"
                @close="importEntityModal.close"
                @submit="submitImportEmployees"
            >
                <template #title="{ data, importEntitiesSize }">
                    {{ data === null ? 'Import new Employees' : `Employees to invite (${importEntitiesSize})` }}
                </template>
            </ImportEntityModal>
        </OnboardingStepLayout>
    </OnboardingLayout>
</template>

<style lang="scss" scoped>
:deep(.pl-form__content) > .pl-form__spacer--sm:last-child {
    margin-bottom: 0;
}
</style>
