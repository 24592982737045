<script>
export default {
    name: 'OrderItemsControls',
};
</script>

<script setup>
import { computed, reactive, watch, ref } from 'vue';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { catchUnprocessableEntity } from '@/utils/httpUtils';
import { required } from '@/utils/formValidators';
import { hasAccessToConfirmOrder } from '@/utils/orderUtils';
import { getBatchUnitQtyStep, getBatchUnitQtyAmount, getBatchYieldQtyAmount } from '@/utils/batchUnitsUtils';
import { getOrderItemBatchUnitsDisplay, getQFOrderItemOrderedQtyDisplay } from '@/utils/orderItemUtils';
import { getClosestAllowedQty, getQtyPrecision } from '@/utils/componentsUtils';
import { QF_ORDER_ITEM_CONTROLS } from '@/enums/orderItemEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import QFOrderModel from '@/models/QFOrder';
import QFSelectableItemModel from '@/models/QFSelectableItem';
import useForm from '@/composition/useForm';
import Modal from '@/components/Modal';
import TreeDisplay from '@/components/TreeDisplay';
import ListDisplay from '@/components/ListDisplay';
import QtyInput from '@/components/form-controls/QtyInput';
import OrderItemControlsCard from './OrderItemControlsCard';

const props = defineProps({
    order: {
        type: Object,
        required: true,
    },
    items: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['handle-refresh-order']);

const store = useStore();

const { currentAccount } = store.state.auth;
const orgId = currentAccount?.organization.id;

/*-----------------------------------------------------------------------------------
            Check if user has access to fulfill or to confirm order item
-----------------------------------------------------------------------------------*/

const hasAccessToConfirm = computed(() => hasAccessToConfirmOrder(props.order, currentAccount));

/*-----------------------------------------------------------------------------------
                                    Items loading state
-----------------------------------------------------------------------------------*/

const itemsLoadingState = reactive({});

const isAnyItemLoading = computed(() => Object.values(itemsLoadingState).some((value) => value));

/*-----------------------------------------------------------------------------------
                                        Setup form
-----------------------------------------------------------------------------------*/

const { formState, ...form } = useForm(
    {
        qty: 0,
    },
    {
        qty: {
            required,
        },
    }
);

/*-----------------------------------------------------------------------------------
                                        Qty
-----------------------------------------------------------------------------------*/

const qtyStep = computed(() => {
    if (orderItemControlsModalState.currentItem !== null) {
        const { units_data: unitsData } = orderItemControlsModalState.currentItem;

        const unitStep = getBatchUnitQtyStep(unitsData, unitsData.quick_fill_ordering_unit);

        return 1 / unitStep;
    }

    return 1;
});

const handleQtyBlurEvent = () => {
    if (formState.qty !== 0) {
        const { units_data: unitsData } = orderItemControlsModalState.currentItem;

        formState.qty = getClosestAllowedQty(unitsData, unitsData.quick_fill_ordering_unit, formState.qty);
    }
};

/*-----------------------------------------------------------------------------------
                           Order item controls modal state
-----------------------------------------------------------------------------------*/

const orderItemControlsModalState = reactive({
    isOpened: false,
    currentItem: null,
    validationErrors: null,
});

const handleOpenOrderItemControlsModal = (item) => {
    orderItemControlsModalState.isOpened = true;
    orderItemControlsModalState.currentItem = item;
};

const handleCloseOrderItemControlsModal = () => {
    orderItemControlsModalState.isOpened = false;
    orderItemControlsModalState.currentItem = null;
    orderItemControlsModalState.validationErrors = null;
};

watch(
    () => orderItemControlsModalState.currentItem,
    (item) => {
        if (item !== null) {
            const { units_data: unitsData } = item;

            const BYQty = hasAccessToConfirm.value ? item.confirmed_BY_qty : item.fulfilled_BY_qty;

            formState.qty = getBatchUnitQtyAmount(
                unitsData,
                unitsData.batch_yield_unit,
                unitsData.quick_fill_ordering_unit,
                BYQty,
                true
            );
        }
    }
);

/*-----------------------------------------------------------------------------------
                                Order items controls actions
-----------------------------------------------------------------------------------*/

const isOrderActionProcessing = ref(false);

const handleMarkOrderAction = () => {
    isOrderActionProcessing.value = true;

    QFOrderModel.markAsConfirmed(orgId, props.order.id)
        .then((data) => {
            emit('handle-refresh-order', data);

            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: 'Order have been successfully confirmed!',
            });
        })
        .finally(() => (isOrderActionProcessing.value = false));
};

/*-----------------------------------------------------------------------------------
                                Fulfill/confirm order item
-----------------------------------------------------------------------------------*/

const handleReportAction = (item, type, qty) => {
    if (form.validate()) {
        const { id: orderId } = props.order;
        const { prototype_id: prototypeId } = item;

        itemsLoadingState[prototypeId] = true;

        let request;

        if (hasAccessToConfirm.value) {
            request = QFSelectableItemModel.confirmDelivery(orgId, orderId, prototypeId, { qty }).then((data) => {
                emit('handle-refresh-order', data);

                EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                    type: ALERT_TYPES.SUCCESS,
                    message: 'Item have been successfully confirmed',
                });

                return Promise.resolve();
            });
        } else {
            request = QFSelectableItemModel.fulfill(orgId, orderId, prototypeId, {
                qty,
                fulfilled_type: type,
            }).then((data) => {
                emit('handle-refresh-order', data);

                EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                    type: ALERT_TYPES.SUCCESS,
                    message: 'Item have been successfully fulfilled',
                });

                return Promise.resolve();
            });
        }

        request
            .catch((error) =>
                catchUnprocessableEntity(error, (errors) => {
                    orderItemControlsModalState.validationErrors = errors;
                })
            )
            .finally(() => (itemsLoadingState[prototypeId] = false));

        handleCloseOrderItemControlsModal();
    }
};

const handleReportOrderItem = (item, type, qty) => {
    if (type === QF_ORDER_ITEM_CONTROLS.PARTIAL.TYPE) {
        handleOpenOrderItemControlsModal(item);
    } else {
        handleReportAction(item, type, qty);
    }
};

const reportPartialQty = () => {
    const { currentItem } = orderItemControlsModalState;
    const { units_data: unitsData } = currentItem;

    const initialBYQty = hasAccessToConfirm.value ? currentItem.fulfilled_BY_qty : currentItem.ordered_BY_qty;

    const BYQty = getBatchYieldQtyAmount(unitsData, unitsData.quick_fill_ordering_unit, formState.qty);

    const type = BYQty === +initialBYQty ? QF_ORDER_ITEM_CONTROLS.DONE.TYPE : QF_ORDER_ITEM_CONTROLS.PARTIAL.TYPE;

    handleReportAction(currentItem, type, BYQty);
};

/*-----------------------------------------------------------------------------------
                            Order item controls modal content
-----------------------------------------------------------------------------------*/

const isSaveDisabled = computed(() => {
    const { qty } = formState;

    if (hasAccessToConfirm.value) {
        return qty < 0;
    }

    return qty <= 0;
});

const modalContent = computed(() => {
    const { currentItem: item } = orderItemControlsModalState;

    if (item === null) {
        return {};
    }

    const { units_data: unitsData } = item;
    const { quick_fill_ordering_unit: quickFillOrderingUnit } = unitsData;

    return {
        orderedQtyDisplay: getQFOrderItemOrderedQtyDisplay(item),
        qtyTitle: `Select quantity to ${hasAccessToConfirm.value ? 'confirm' : 'fulfill'}:`,
        qtyUnit: quickFillOrderingUnit?.value ?? 'batches',
        qtyBatchUnitsDisplay: getOrderItemBatchUnitsDisplay(
            quickFillOrderingUnit,
            quickFillOrderingUnit,
            unitsData,
            formState.qty
        ),
    };
});
</script>

<template>
    <OverlayLoader
        v-if="isOrderActionProcessing"
        enable-sticky
    />

    <TreeDisplay
        node-source-column="data"
        :data="items"
    >
        <template #leaf="{ leaf }">
            <ListDisplay :items="leaf.data">
                <template #item="{ item }">
                    <OrderItemControlsCard
                        :key="`item-${item.prototype_id}`"
                        :item="item"
                        :category-name="leaf.name"
                        :is-fulfill-controls="!hasAccessToConfirm"
                        :is-loading="itemsLoadingState[item.prototype_id]"
                        @handle-report-order-item="handleReportOrderItem"
                    />
                </template>
            </ListDisplay>
        </template>
    </TreeDisplay>

    <div
        v-if="hasAccessToConfirm"
        class="pl-order-item-controls-actions"
    >
        <BtnUI
            is-filled
            :disabled="!order.all_items_confirmed || isAnyItemLoading || isOrderActionProcessing"
            @click="handleMarkOrderAction"
        >
            Confirm and submit
        </BtnUI>

        <small
            v-if="!order.all_items_confirmed && !isAnyItemLoading"
            class="d-block mt-2 mb-1 text-info"
        >
            Please confirm all items
        </small>
    </div>

    <Modal
        v-if="orderItemControlsModalState.isOpened"
        class="pl-report-partial-qty-modal"
        @close="handleCloseOrderItemControlsModal"
    >
        <template #title>
            {{ orderItemControlsModalState.currentItem.name }}

            <span>{{ modalContent.orderedQtyDisplay }}</span>
        </template>

        <template #content>
            <form
                id="pl-report-action-order-item-form"
                class="pl-report-action-order-item-form"
                novalidate
                @submit.prevent="reportPartialQty"
            >
                <b>
                    {{ modalContent.qtyTitle }}
                </b>

                <QtyInput
                    v-model="formState.qty"
                    is-input-always-allowed
                    is-increment-mode
                    size="lg"
                    :min="0"
                    :max="9999"
                    :precision="getQtyPrecision(qtyStep)"
                    :step="qtyStep"
                    @blur="handleQtyBlurEvent"
                    @update:modelValue="form.clearErrors('qty')"
                />

                <ValidationErrors
                    v-if="form.hasErrors('qty')"
                    :errors="form.getErrors('qty')"
                />

                <p>
                    <span>{{ modalContent.qtyUnit }}</span>
                    <span>{{ modalContent.qtyBatchUnitsDisplay }}</span>
                </p>
            </form>
        </template>

        <template #actions>
            <BtnUI @click="handleCloseOrderItemControlsModal">
                Cancel
            </BtnUI>

            <BtnUI
                is-filled
                type="submit"
                form="pl-report-action-order-item-form"
                :disabled="isSaveDisabled"
            >
                Save
            </BtnUI>
        </template>
    </Modal>
</template>

<style lang="scss" scoped>
.pl-order-item-controls-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto auto 0 auto;
    text-align: center;
    width: fit-content;

    @media screen and (max-width: 450px) {
        width: 100%;
    }

    > button {
        min-width: custom-space(15);
        text-align: center;
        margin-top: 10px;

        @media screen and (max-width: 450px) {
            min-width: auto;
        }
    }
}

.pl-report-partial-qty-modal :deep(.pl-modal__wrapper) > section > h2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: $font-size-base * 1.5;

    & > span {
        font-size: $font-size-base * 0.9;
        font-weight: $font-weight-normal;
    }
}

.pl-report-action-order-item-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: custom-space(0.25);
    text-align: center;

    & > b {
        font-weight: $font-weight-normal;
    }

    & > p {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;
        line-height: 1.25;

        & > span:first-child {
            color: $gray-800;
            font-size: $font-size-base * 0.9;
        }

        & > span:last-child {
            font-size: $font-size-base * 0.8;
            color: $gray-600;
        }
    }
}

:deep(.pl-validation-errors) {
    text-align: center;
}
</style>
