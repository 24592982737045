import { createStore } from 'vuex';
import countries from './modules/countries';
import auth from './modules/auth';
import itemBatchMeta from './modules/itemBatchMeta';
import onboarding from './modules/onboarding';
import PLOrderViewSettings from './modules/PLOrderViewSettings';
import QFOrderViewSettings from './modules/QFOrderViewSettings';

import ApiClient from '@/plugins/ApiClient';
import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';

import { LS } from '@/instances/localStorage';
import { Document } from '@/instances/document';

import PLOrderModel from '@/models/PLOrder';
import QFOrderModel from '@/models/QFOrder';
import PARTemplateModel from '@/models/PARTemplate';
import QFPARTemplateModel from '@/models/QFPARTemplate';
import AccountModel from '@/models/Account';

export default createStore({
    state: () => ({
        accounts: [], // accounts the current authenticated user has
    }),
    getters: {
        joinedAccounts(state) {
            return state.accounts.filter((account) => account.joined_at !== null);
        },
        invitedAccounts(state) {
            return state.accounts.filter((account) => account.joined_at === null);
        },
        currentPLOrder(state) {
            const currentPLOrder = state.auth.currentAccount.pending_prep_list_order;

            if (!currentPLOrder) {
                return null;
            }

            return PLOrderModel.make(currentPLOrder);
        },
        currentQFOrder(state) {
            const currentQFOrder = state.auth.currentAccount?.pending_quick_fill_order;

            if (!currentQFOrder) {
                return null;
            }

            return QFOrderModel.make(currentQFOrder);
        },
        currentPARTemplate(state) {
            const { pending_par_template: currentPARTemplate } = state.auth.currentAccount;

            if (!currentPARTemplate) {
                return null;
            }

            return PARTemplateModel.make(currentPARTemplate);
        },
        currentQFPARTemplate(state) {
            const { pending_quick_fill_par_template: currentPARTemplate } = state.auth.currentAccount;

            if (!currentPARTemplate) {
                return null;
            }

            return QFPARTemplateModel.make(currentPARTemplate);
        },
    },
    mutations: {
        setAccounts(state, accounts) {
            state.accounts = accounts.map((account) => {
                return AccountModel.make(account);
            });
        },
        setCurrentPLOrder: (state, currentPLOrder) => {
            if (state.auth.currentAccount === null) {
                return;
            }

            state.auth.currentAccount.pending_prep_list_order = currentPLOrder;
        },
        setCurrentQFOrder: (state, currentQFOrder) => {
            if (state.auth.currentAccount === null) {
                return;
            }

            state.auth.currentAccount.pending_quick_fill_order = currentQFOrder;
        },
        setCurrentPARTemplate: (state, template) => {
            state.auth.currentAccount.pending_par_template = template !== null ? PARTemplateModel.make(template) : null;
        },
        setCurrentQFPARTemplate: (state, template) => {
            state.auth.currentAccount.pending_quick_fill_par_template =
                template !== null ? QFPARTemplateModel.make(template) : null;
        },
        setUnreadNotificationsCount: (state, count) => {
            if (state.auth.currentAccount === null) {
                return;
            }

            state.auth.currentAccount.unread_notifications_count = count;
            Document.setUnfinishedActionsInTitle(count);
        },
    },
    actions: {
        loadAppState({ commit, state, getters }) {
            return ApiClient.get('appstate', {
                headers: {
                    'X-ACTOR-ACCOUNT-ID': LS.get('actor.account.id'),
                },
            })
                .then(({ data }) => {
                    commit('auth/setUser', data.user);
                    commit('setAccounts', data.accounts);

                    /*---------------------------------------------------------------------
                                        Determine actor account
                    --------------------------------------------------------------------*/

                    let actorAccount;

                    /**
                     * 1. In case there is the correspondent record in the cache (actor.account.id),
                     * that means the account had been determined before and we should just proceed
                     * with it.
                     */
                    actorAccount = getters.joinedAccounts.find((account) => account.id === LS.get('actor.account.id'));

                    /**
                     * 2. If the cache does not contain previously determined account, we should
                     * try to automatically determine it right now. This is only allowed in case
                     * there are no invited accounts.
                     */
                    if (!actorAccount && getters.invitedAccounts.length === 0) {
                        if (getters.joinedAccounts.length === 1) {
                            /**
                             * 2.1. The first possible way is to check if there is only one joined account.
                             * If so, just proceed with this account.
                             */
                            actorAccount = getters.joinedAccounts[0];
                        } else if (getters.joinedAccounts.length > 1) {
                            /**
                             * 2.2. The second possible way belongs to the situation where:
                             *
                             *  - there are more than one joined account
                             *  - user had made a decision about which account to use before
                             *
                             * If so, try to find the joined account basing on the user's previous
                             * decision.
                             */
                            actorAccount = getters.joinedAccounts.find(
                                (account) => account.id === LS.get('actor.account.chosen.id')
                            );
                        }
                    }

                    if (actorAccount) {
                        commit('auth/setCurrentAccount', actorAccount);
                    }

                    /*---------------------------------------------------------------------
                            Tell the waiting modules the app state has been loaded
                            (see router/index.js)
                    --------------------------------------------------------------------*/

                    data.authState = state.auth;
                    EventBus.emit(EVENT_BUS_EVENTS.APP_STATE_LOADING_SUCCESS, data);
                    return Promise.resolve(actorAccount);
                })
                .catch((error) => {
                    EventBus.emit(EVENT_BUS_EVENTS.APP_STATE_LOADING_FAILED, error);
                    return Promise.reject(error);
                });
        },

        reinitializeStore: ({ commit }, account) => {
            commit('auth/setCurrentAccount', account);
            commit('itemBatchMeta/setMeta', null);
            commit('onboarding/setSettings', null);
        },
    },
    modules: {
        auth,
        countries,
        itemBatchMeta,
        onboarding,
        PLOrderViewSettings,
        QFOrderViewSettings,
    },
});
