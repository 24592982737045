import { reactive } from 'vue';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { downloadFile } from '@/utils/fileUtils';
import { ALERT_TYPES, IMPORT_ENTITY_CHUNK_SIZE } from '@/enums/componentsEnums';

const useImportEntityModal = (entityModel, entityLabel) => {
    const store = useStore();

    const { currentAccount } = store.state.auth;
    const orgId = currentAccount.organization.id;

    /*------------------------------------------------------------------------
                                   Import state
    ------------------------------------------------------------------------*/

    const state = reactive({
        data: null,
        progress: 0,
        isActionProcessing: false,
    });

    const downloadTemplate = () => {
        state.isActionProcessing = true;

        entityModel
            .downloadTemplate(orgId)
            .then((data) => {
                EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                    type: ALERT_TYPES.SUCCESS,
                    message: 'The template has been successfully downloaded!',
                });

                downloadFile(data, `${entityLabel}-template`);
            })
            .finally(() => (state.isActionProcessing = false));
    };

    const parseFile = (formData) => {
        state.isActionProcessing = true;

        entityModel
            .parseImportFile(orgId, formData)
            .then((data) => {
                state.data = data;

                EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                    type: ALERT_TYPES.SUCCESS,
                    message: 'The file has been successfully processed!',
                });
            })
            .finally(() => (state.isActionProcessing = false));
    };

    const getPayloadToApply = (payload) => {
        const { progress } = state;

        const remainingLength = payload.length - progress;

        if (remainingLength > IMPORT_ENTITY_CHUNK_SIZE * 1.5) {
            return payload.slice(progress, progress + IMPORT_ENTITY_CHUNK_SIZE);
        }

        return payload.slice(progress);
    };

    const submit = (payload, successCallback) => {
        state.isActionProcessing = true;

        const payloadToApply = getPayloadToApply(payload);

        return entityModel
            .applyImport(orgId, payloadToApply)
            .then((data) => {
                if (!Array.isArray(data)) {
                    if (state.progress > 0) {
                        successCallback(false);
                    }

                    state.data.rows.splice(0, state.progress + IMPORT_ENTITY_CHUNK_SIZE, ...data.rows);
                    state.progress = 0;
                    state.isActionProcessing = false;

                    return;
                }

                state.progress += payloadToApply.length;

                if (state.progress < payload.length) {
                    return submit(payload, successCallback);
                }

                state.isActionProcessing = false;

                closeModal();

                successCallback(true);

                EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                    type: ALERT_TYPES.SUCCESS,
                    message: `The ${entityLabel} import has been successfully completed!`,
                });
            })
            .catch(() => (state.isActionProcessing = false));
    };

    /*------------------------------------------------------------------------
                                    Modal state
    ------------------------------------------------------------------------*/

    const modalState = reactive({
        isOpened: false,
    });

    const openModal = () => {
        modalState.isOpened = true;
    };

    const closeModal = () => {
        modalState.isOpened = false;

        state.data = null;
        state.progress = 0;
    };

    return {
        importEntityState: state,
        importEntityModal: Object.assign(modalState, {
            open: openModal,
            close: closeModal,
            downloadTemplate,
            parseFile,
            submit,
        }),
    };
};

export default useImportEntityModal;
