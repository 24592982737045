<script>
export default {
    name: 'CreateTemplateFab',
};
</script>

<script setup>
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import Fab from '@/components/Fab';
import DropDown from '@/components/DropDown';
import GroupedSettings from '@/components/constructions/grouped-settings';

/*------------------------------------------------------------------------
                                    QA
------------------------------------------------------------------------*/

const qaPrefix = 'create_new_par_template';
</script>

<template>
    <Fab>
        <DropDown
            pos-y="top"
            :data-test-id="qaPrefix"
        >
            <template #invoker>
                <PlusIcon />
            </template>

            <template #content>
                <GroupedSettings.Root>
                    <GroupedSettings.List>
                        <GroupedSettings.ListItem>
                            <router-link
                                :data-testid="`${qaPrefix}_prep_list`"
                                :to="{
                                    name: SETTINGS_ROUTE_NAMES.PAR.CREATE.PL.INDEX,
                                    query: { action: 'new-template' },
                                }"
                            >
                                New Prep List PAR
                            </router-link>
                        </GroupedSettings.ListItem>

                        <GroupedSettings.ListItem>
                            <router-link
                                :data-testid="`${qaPrefix}_quick_fill`"
                                :to="{
                                    name: SETTINGS_ROUTE_NAMES.PAR.CREATE.QF.INDEX,
                                    query: { action: 'new-template' },
                                }"
                            >
                                New Quick Fill PAR
                            </router-link>
                        </GroupedSettings.ListItem>
                    </GroupedSettings.List>
                </GroupedSettings.Root>
            </template>
        </DropDown>
    </Fab>
</template>
