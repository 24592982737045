/*------------------------------------------------------------------------
                                  General
------------------------------------------------------------------------*/

export const CALENDAR_DAY_OPTIONS = [
    { value: 0, text: 'Sunday' },
    { value: 1, text: 'Monday' },
    { value: 2, text: 'Tuesday' },
    { value: 3, text: 'Wednesday' },
    { value: 4, text: 'Thursday' },
    { value: 5, text: 'Friday' },
    { value: 6, text: 'Saturday' },
];

/*------------------------------------------------------------------------
                                   Modes
------------------------------------------------------------------------*/

export const CALENDAR_MODES = {
    DEFAULT: 'default',
    MULTIPLE: 'multiple',
    RANGE: 'range',
};

export const CALENDAR_MODE_OPTIONS = [
    { value: CALENDAR_MODES.DEFAULT, text: 'All' },
    { value: CALENDAR_MODES.MULTIPLE, text: 'Multiple' },
    { value: CALENDAR_MODES.RANGE, text: 'Range' },
];

export const CALENDAR_ANY_DAY_OPTION = 'any';
