<script>
export default {
    name: 'AddPrepTaskModal',
};
</script>

<script setup>
import { watch, computed } from 'vue';

import { required } from '@/utils/formValidators';
import { getBatchUnitQtyStep, getBatchYieldQtyAmount } from '@/utils/batchUnitsUtils';
import { getClosestAllowedQty, getQtyPrecision } from '@/utils/componentsUtils';
import { CREATE_NEW_PREP_TASK_OPTION_VALUE } from '@/enums/itemEnums';
import useForm from '@/composition/useForm';
import Modal from '@/components/Modal';
import SelectInput from '@/components/form-controls/SelectInput';
import QtyInput from '@/components/form-controls/QtyInput';
import FormUI from '@/components/UI/FormUI';

const props = defineProps({
    itemBatchYieldAmount: {
        type: [Number, String],
        default: 0,
    },
    prepTasks: {
        type: Array,
        required: true,
    },
    prepTaskId: {
        type: Number,
        default: null,
    },
    prepTaskBatchYieldQty: {
        type: Number,
        default: 0,
    },
});

const emit = defineEmits(['open-create-prep-task-modal', 'add-prep-task', 'close']);

/*------------------------------------------------------------------------
                                Setup form
------------------------------------------------------------------------*/

const { formState, ...form } = useForm(
    {
        prep_task_id: null,
        qty: 0,
    },
    {
        prep_task_id: {
            required,
        },
        qty: {
            required,
        },
    },
    {
        prep_task_id: 'Prep task',
    }
);

const submitForm = () => {
    if (form.validate()) {
        const { qty, prep_task_id: prepTaskId } = formState;

        const batchYieldQtyAmount = getBatchYieldQtyAmount(
            selectedPrepTask.value,
            selectedPrepTask.value.ordering_unit,
            qty
        );

        emit('add-prep-task', {
            id: prepTaskId,
            BYQty: batchYieldQtyAmount,
            times: batchYieldQtyAmount / props.itemBatchYieldAmount,
        });
    }
};

/*------------------------------------------------------------------------
                               Prep task id
------------------------------------------------------------------------*/

const handlePrepTaskIdUpdated = (optionValue) => {
    if (optionValue === CREATE_NEW_PREP_TASK_OPTION_VALUE) {
        emit('open-create-prep-task-modal');
    }

    form.clearErrors('prep_task_id');
};

const prepTaskOptions = computed(() => {
    const options = props.prepTasks.map(({ id, name }) => ({ value: id, text: name }));

    options.push({
        selectedText: '',
        text: 'Create new prep task...',
        value: CREATE_NEW_PREP_TASK_OPTION_VALUE,
    });

    return options;
});

const selectedPrepTask = computed(() => props.prepTasks.find(({ id }) => +formState.prep_task_id === id) || null);

watch(
    () => props.prepTaskId,
    (prepTaskId) => (formState.prep_task_id = prepTaskId),
    { immediate: true }
);

/*------------------------------------------------------------------------
                                    Qty
------------------------------------------------------------------------*/

const unitStep = computed(() => {
    if (selectedPrepTask.value === null) {
        return 1;
    }

    return getBatchUnitQtyStep(selectedPrepTask.value, selectedPrepTask.value.ordering_unit);
});

const qtyStep = computed(() => props.itemBatchYieldAmount / unitStep.value);

const onBlurQty = () => {
    formState.qty = getClosestAllowedQty(selectedPrepTask.value, selectedPrepTask.value.ordering_unit, formState.qty);
};

watch(
    () => formState.prep_task_id,
    () => (formState.qty = 0)
);

watch(
    () => props.prepTaskBatchYieldQty,
    (BYQty) => {
        if (selectedPrepTask.value !== null) {
            formState.qty = BYQty / unitStep.value;
        }
    },
    { immediate: true }
);

/*------------------------------------------------------------------------
                                    QA
------------------------------------------------------------------------*/

const qaPrefix = 'add_prep_task_form';
</script>

<template>
    <Modal @close="emit('close')">
        <template #title>
            Add Prep Task to Item
        </template>

        <template #content>
            <FormUI
                id="pl-add-prep-task-form"
                disable-animation
                class="pl-add-prep-task-form"
                :data-test-id="qaPrefix"
                @submit="submitForm"
            >
                <template #content="{ classNames }">
                    <div :class="classNames.spacerLg">
                        <SelectInput
                            v-model="formState.prep_task_id"
                            include-asterisk
                            searchable
                            is-last-option-marked
                            label="Select Prep Task"
                            :data-test-id="`${qaPrefix}_prep_task_select`"
                            :options="prepTaskOptions"
                            :has-error="form.hasErrors('prep_task_id')"
                            @blur="form.validate('prep_task_id')"
                            @update:modelValue="handlePrepTaskIdUpdated"
                        />

                        <ValidationErrors
                            v-if="form.hasErrors('prep_task_id')"
                            :data-test-id="`${qaPrefix}_prep_task_errors`"
                            :errors="form.getErrors('prep_task_id')"
                        />
                    </div>

                    <div class="pl-add-prep-task-form__qty">
                        <p v-if="selectedPrepTask !== null">
                            Select quantity
                        </p>

                        <QtyInput
                            v-model="formState.qty"
                            is-input-always-allowed
                            is-increment-mode
                            size="lg"
                            :min="0"
                            :max="9999"
                            :step="qtyStep"
                            :precision="getQtyPrecision(qtyStep)"
                            :disabled="formState.prep_task_id === null"
                            :qty-data-test-id="`${qaPrefix}_qty`"
                            @blur="onBlurQty"
                            @update:modelValue="form.clearErrors('qty')"
                        />

                        <ValidationErrors
                            v-if="form.hasErrors('qty')"
                            :errors="form.getErrors('qty')"
                        />

                        <small
                            v-if="selectedPrepTask === null"
                            :class="classNames.ribbon"
                        >
                            Please select a prep task.
                        </small>

                        <span
                            v-else
                            :data-testid="`${qaPrefix}_unit`"
                        >
                            {{ selectedPrepTask.ordering_unit?.text || 'batches' }}
                        </span>
                    </div>
                </template>
            </FormUI>
        </template>

        <template #actions>
            <BtnUI @click="emit('close')">
                Cancel
            </BtnUI>

            <BtnUI
                is-filled
                type="submit"
                form="pl-add-prep-task-form"
                :data-testid="`${qaPrefix}_submit_btn`"
                :disabled="formState.qty === 0 || form.isDisabled()"
            >
                Add to Item
            </BtnUI>
        </template>
    </Modal>
</template>

<style lang="scss" scoped>
.pl-add-prep-task-form {
    &__qty {
        text-align: center;

        p {
            font-size: $font-size-base * 0.9;
            font-weight: $font-weight-normal;
            margin: 0 0 custom-space(0.25) 0;
        }
    }

    :deep(.pl-qty-input) {
        justify-content: center;
    }
}
</style>
