import ApiClient from '@/plugins/ApiClient';

export default {
    namespaced: true,

    state: () => ({
        countries: null,
        currentRequest: null,
    }),

    getters: {
        areCountriesLoaded: (state) => state.countries !== null,
    },

    mutations: {
        setCountries(state, countries) {
            state.countries = countries;
        },
        setCurrentRequest(state, request) {
            state.currentRequest = request;
        },
    },

    actions: {
        loadCountries({ state, commit, getters }) {
            if (state.currentRequest !== null) {
                return state.currentRequest;
            }

            if (!getters.areCountriesLoaded) {
                const request = ApiClient.get('countries?is_active=1');

                commit('setCurrentRequest', request);

                return request
                    .then(({ data }) => {
                        commit('setCountries', data);

                        return data;
                    })
                    .catch(() => commit('setCountries', null))
                    .finally(() => commit('setCurrentRequest', null));
            }

            return Promise.resolve(state.countries);
        },
    },
};
