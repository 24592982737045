export const convertArbitraryListToOptions = (arbitraryList = null) => {
    const isArrayEmpty = Array.isArray(arbitraryList) && arbitraryList.length === 0;

    if (arbitraryList === null || isArrayEmpty) {
        return [];
    }

    if (Array.isArray(arbitraryList)) {
        const actionsList = arbitraryList.map(({ id, name }) => ({
            value: id,
            text: name,
        }));

        return [{ text: 'Actions', groupLevel: 1 }, ...actionsList];
    }

    const result = [];

    for (const department in arbitraryList) {
        result.push({
            text: department,
            groupLevel: 1,
        });

        const data = arbitraryList[department] || [];

        for (const item of data) {
            result.push({
                text: item.name,
                value: item.id,
            });
        }
    }

    return result;
};
