import { reactive } from 'vue';

const useOrderSettingsForm = () => {
    const state = reactive({
        validationErrors: null,
        isSubmittingAllowed: false,
        hasChangesMade: false,
        isSubmitting: false,
    });

    const update = (updatedState) => Object.assign(state, updatedState);

    const setValidationErrors = (errors) => (state.validationErrors = errors);

    const isSubmittingDisabled = () => !state.isSubmittingAllowed || state.isSubmitting;

    const getSubmitBtnText = (order = null) => {
        if (order !== null) {
            return state.hasChangesMade ? 'apply changes' : 'select items';
        }

        return 'next';
    };

    return {
        orderSettingsFormState: state,
        orderSettingsForm: {
            update,
            setValidationErrors,
            isSubmittingDisabled,
            getSubmitBtnText,
        },
    };
};

export default useOrderSettingsForm;
