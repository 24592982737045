<script>
export default {
    name: 'TaskReminderRibbon',
};
</script>

<script setup>
import { TASK_REMINDER_ROUTE_NAME } from '@/enums/routesNameEnums';
import RibbonUI from '@/components/UI/RibbonUI';
</script>

<template>
    <RibbonUI
        class="pl-task-reminder-ribbon"
        text="Make Prep List more useful by adding information about your business."
        :navigate-to="{ name: TASK_REMINDER_ROUTE_NAME }"
    />
</template>
