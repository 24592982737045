<script>
export default {
    name: 'ImageCropper',
};
</script>

<script setup>
import { ref } from 'vue';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

import Modal from '@/components/Modal';

defineProps({
    imgSrc: {
        type: String,
        required: true,
    },
});

const emit = defineEmits(['close', 'crop']);

/*------------------------------------------------------------------------
                               Cropper state
------------------------------------------------------------------------*/

const cropperRef = ref(null);
const isPending = ref(false);

const cropImage = () => {
    isPending.value = true;

    cropperRef.value.getCroppedCanvas({ width: 300, height: 300 }).toBlob((file) => {
        isPending.value = false;

        const formData = new FormData();

        formData.append('file', file, 'cropped-image.png');

        emit('crop', formData);
    });
};
</script>

<template>
    <Modal
        disable-click-outside-mechanism
        @close="emit('close')"
    >
        <template #title>
            Crop Image
        </template>

        <template #content>
            <VueCropper
                ref="cropperRef"
                alt="Image"
                :view-mode="1"
                :auto-crop-area="1"
                :aspect-ratio="1"
                :src="imgSrc"
            />
        </template>

        <template #actions>
            <BtnUI
                :disabled="isPending"
                @click="emit('close')"
            >
                Cancel
            </BtnUI>

            <BtnUI
                is-filled
                :is-loading="isPending"
                @click="cropImage"
            >
                Apply
            </BtnUI>
        </template>
    </Modal>
</template>

<style lang="scss" scoped>
:deep(.pl-modal__wrapper) > section {
    align-items: center;
    max-width: custom-space(50);
}

:deep(.pl-modal__actions) {
    width: 100%;
    max-width: custom-space(25);
}

:deep(.cropper-container) {
    overflow: hidden;
}

:deep(.cropper-view-box),
:deep(.cropper-face) {
    border-radius: 50%;
}
</style>
