<script>
export default {
    name: 'TagListSelect',
};
</script>

<script setup>
import { computed, ref } from 'vue';

import { TAG_LIST_SELECT_EVENTS } from '@/enums/componentsEnums';
import SelectInput from '@/components/form-controls/SelectInput';

const props = defineProps({
    tags: {
        type: Array,
        required: true,
    },
    options: {
        type: Array,
        required: true,
    },
    errors: {
        type: Array,
        default: () => [],
    },
    hasError: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    includeAsterisk: {
        type: Boolean,
        default: false,
    },
    label: {
        type: String,
        default: '',
    },
    dataFormControlTestId: {
        type: String,
        required: true,
    },
    dataFormControlTestIdError: {
        type: String,
        required: true,
    },
    isLastOptionMarked: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update-tags-list', 'blur']);

/*-----------------------------------------------------------------------------------
                                    Selected option
-----------------------------------------------------------------------------------*/

const selectedOption = ref(null);

const handleAddTag = (value) => {
    emit('update-tags-list', { value, type: TAG_LIST_SELECT_EVENTS.ADD });

    selectedOption.value = null;
};

const handleRemoveTag = (value) => {
    emit('update-tags-list', { value, type: TAG_LIST_SELECT_EVENTS.REMOVE });
};

const filteredOptions = computed(() => props.options.filter(({ value }) => !props.tags.includes(value)));

const mappedTags = computed(() => {
    const optionsMap = new Map(props.options.map((option) => [option.value, option]));

    return props.tags.map((tagValue) => optionsMap.get(tagValue));
});
</script>

<template>
    <div class="pl-tag-list-select">
        <div class="pl-tag-list-select__input-wrapper">
            <SelectInput
                v-model="selectedOption"
                searchable
                :options="filteredOptions"
                :data-test-id="dataFormControlTestId"
                :has-error="hasError"
                :disabled="disabled"
                :include-asterisk="includeAsterisk"
                :is-last-option-marked="isLastOptionMarked"
                :label="label"
                @blur="emit('blur')"
                @update:modelValue="handleAddTag"
            />
        </div>

        <ValidationErrors
            v-if="hasError"
            :data-testid="dataFormControlTestIdError"
            :errors="errors"
        />

        <div
            v-if="mappedTags.length > 0"
            class="pl-tag-list-select__tags"
        >
            <div
                v-for="{ value, text, isLocked } in mappedTags"
                :key="value"
                class="pl-tag-list-select__tag"
            >
                <span>{{ text }}</span>

                <button
                    v-if="!isLocked"
                    type="button"
                    class="pl-tag-list-select__remove-tag-btn"
                    data-testid="remove_tag_btn"
                    @click="handleRemoveTag(value)"
                >
                    <CrossIcon />
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.pl-tag-list-select {
    &__input-wrapper {
        position: relative;
        z-index: 2;
    }

    &__remove-tag-btn {
        display: flex;
        align-items: center;
        margin-left: 15px;
    }

    &__tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
    }

    &__tag {
        display: flex;
        align-items: center;
        width: fit-content;
        max-width: 100%;
        padding: 10px 20px;
        margin-top: 5px;
        background-color: $gray-400;
        border-radius: 50px;
        transition: 0.5s linear;

        span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &:not(:last-child) {
            margin-right: 5px;
        }
    }
}
</style>
