<script>
export default {
    name: 'GroupedSettingsList',
};
</script>

<script setup>
defineProps({
    title: {
        type: String,
        default: null,
    },
});
</script>

<template>
    <div class="pl-grouped-settings__list">
        <h6 v-if="title !== null">
            {{ title }}
        </h6>

        <ul>
            <slot />
        </ul>
    </div>
</template>

<style lang="scss" scoped>
.pl-grouped-settings__list {
    & > h6 {
        margin: 0 0 custom-space(0.5) 0;
    }

    & > ul {
        display: flex;
        flex-direction: column;
        gap: custom-space(0.375);
        padding: 0;
        margin: 0;
        list-style: none;
    }

    &:not(:last-child) {
        padding-bottom: custom-space(0.75);
        margin-bottom: custom-space(0.75);
        border-bottom: 1px solid $gray-100;
    }
}
</style>
