<script>
export default {
    name: 'LayoutHeader',
};
</script>

<script setup>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { isRouteLinkActive } from '@/utils/navigationUtils';
import { BADGE_TYPES } from '@/enums/componentsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import Badge from '@/components/Badge';
import ReceivedNotifications from '@/components/received-notifications';
import DropDown from '@/components/DropDown';
import BellIcon from '@/components/icons/BellIcon';
import HamburgerIcon from '@/components/icons/HamburgerIcon';
import HeaderNavigation from './partials/HeaderNavigation';
import ChangeLocationModal from './partials/ChangeLocationModal';

const route = useRoute();
const store = useStore();

const { currentAccount } = store.state.auth;

/*------------------------------------------------------------------------
                               Account state
------------------------------------------------------------------------*/

const unreadNotificationsCount = computed(() => {
    const { unread_notifications_count: count } = currentAccount;

    if (count > 99) {
        return '99+';
    }

    return count;
});

/*------------------------------------------------------------------------
                           Change location state
------------------------------------------------------------------------*/

const isChangeLocationModalOpened = ref(false);

const openChangeLocationModal = () => (isChangeLocationModalOpened.value = true);
const closeChangeLocationModal = () => (isChangeLocationModalOpened.value = false);
</script>

<template>
    <header class="pl-layout-header">
        <div class="container">
            <slot name="ribbon" />

            <div class="pl-layout-header__top">
                <router-link :to="{ name: SETTINGS_ROUTE_NAMES.INDEX }">
                    <template #default="{ route: linkRoute }">
                        <button
                            type="button"
                            data-testid="hamburger_btn"
                            class="pl-layout-header__hamburger"
                            :class="{
                                'pl-layout-header__hamburger--active': isRouteLinkActive(route, linkRoute.path),
                            }"
                        >
                            <HamburgerIcon />
                        </button>
                    </template>
                </router-link>

                <BtnUI
                    size="sm"
                    @click="openChangeLocationModal"
                >
                    <span data-testid="current_location">
                        {{ currentAccount.location.name }}
                    </span>

                    <CaretDownIcon
                        :width="8"
                        :height="7"
                    />
                </BtnUI>

                <DropDown data-test-id="open_received_notifications">
                    <template #invoker>
                        <transition
                            name="scale"
                            type="transition"
                        >
                            <Badge
                                v-if="unreadNotificationsCount"
                                :key="`unread notifications count: ${unreadNotificationsCount}`"
                                is-absolute
                                size="sm"
                                :type="BADGE_TYPES.ROUNDED"
                            >
                                {{ unreadNotificationsCount }}
                            </Badge>
                        </transition>

                        <BellIcon />
                    </template>

                    <template #content>
                        <ReceivedNotifications />
                    </template>
                </DropDown>
            </div>

            <HeaderNavigation />
        </div>
    </header>

    <ChangeLocationModal
        v-if="isChangeLocationModalOpened"
        @close="closeChangeLocationModal"
    />
</template>

<style lang="scss" scoped>
.pl-layout-header {
    background-color: $white;

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: custom-space(1);
        padding: custom-space(0.75) 0;

        & > button {
            display: flex;
            align-items: center;
            gap: custom-space(0.75);
            max-width: 275px;

            &,
            & > span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            & > svg {
                flex-shrink: 0;
                fill: $primary;
            }
        }

        :deep(.pl-dropdown) > button .pl-badge {
            top: custom-space(-0.4);
            right: custom-space(-0.4);
            line-height: 1;
            z-index: 1;
        }
    }

    &__hamburger {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $click-space;
        border-radius: 50%;

        &,
        svg :deep(path) {
            @include pl-transition(all);
        }

        &:not(&--active) {
            margin-left: -$click-space;

            &:hover {
                background-color: $gray-100;

                svg :deep(path) {
                    stroke: $black;
                }
            }
        }

        &--active {
            background-color: $primary;

            svg :deep(path) {
                stroke: $white;
            }
        }
    }
}
</style>
