export default class Rule {
    constructor(name, callback) {
        this.name = name;
        this.callback = callback;
    }

    execute(args) {
        return this.callback.apply(this, args);
    }
}
