<script>
export default {
    name: 'CreateItemView',
};
</script>

<script setup>
import { inject, reactive, onBeforeMount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { catchUnprocessableEntity } from '@/utils/httpUtils';
import { ITEMS_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import ItemModel from '@/models/Item';
import ItemForm from '@/components/forms/item-form';

const $acl = inject('$acl');

const router = useRouter();
const route = useRoute();
const store = useStore();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(ITEMS_RESTRICTIONS.CREATE, currentAccount)) {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to create items.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                              Item form state
------------------------------------------------------------------------*/

const itemFormState = reactive({
    isSubmitting: false,
    validationErrors: null,
});

const submitCreateItem = (attributes) => {
    itemFormState.isSubmitting = true;

    ItemModel.create(orgId, attributes)
        .then(() => {
            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: 'Item has been successfully created',
            });

            router.push({ name: SETTINGS_ROUTE_NAMES.ITEMS.INDEX, query: route.query });
        })
        .catch((error) =>
            catchUnprocessableEntity(error, (errors) => {
                itemFormState.validationErrors = errors;
            })
        )
        .finally(() => (itemFormState.isSubmitting = false));
};
</script>

<template>
    <TopBarLayout
        include-back-route-query
        :back-route="SETTINGS_ROUTE_NAMES.ITEMS.INDEX"
    >
        <template #title>
            <h1>Create Item</h1>
        </template>

        <ItemForm
            :validation-errors="itemFormState.validationErrors"
            :is-submitting="itemFormState.isSubmitting"
            @submit="submitCreateItem"
        />
    </TopBarLayout>
</template>
