import Echo from '@/plugins/Echo';
import ApiClient from '@/plugins/ApiClient';
import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { Document } from '@/instances/document';
import { LS } from '@/instances/localStorage';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import AuthModel from '@/models/Auth';

const state = () => ({
    user: null,
    currentAccount: null,
    isLogOutPending: false,
});

const mutations = {
    setUser(state, user) {
        state.user = user;
    },
    setCurrentAccount(state, account) {
        // leave previously set private notifications channel anyway
        Echo.leave(`App.Models.OrganizationAccount.${state.currentAccount?.id}`);

        if (!account) {
            state.currentAccount = null;
            LS.remove('actor.account.id');
            LS.remove('actor.account.chosen.id');
            ApiClient.setHeaders({ 'X-ACTOR-ACCOUNT-ID': null });
            return;
        }

        state.currentAccount = account;

        const setUnreadNotificationCount = ({ unread_notifications_count: count }) => {
            state.currentAccount.unread_notifications_count = count;

            Document.setUnfinishedActionsInTitle(count);
        };

        setUnreadNotificationCount(account);

        // subscribe to private notifications channel
        Echo.private(`App.Models.OrganizationAccount.${account.id}`)
            .listen('.notification.created', (response) => {
                EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_RECEIVED, response);

                EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                    type: ALERT_TYPES.INFO,
                    state: response,
                });

                setUnreadNotificationCount(response);
            })
            .notification((response) => {
                EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_READ, response);

                setUnreadNotificationCount(response);
            });

        LS.set('actor.account.id', account.id);
        LS.set('actor.account.chosen.id', account.id);
        ApiClient.setHeaders({ 'X-ACTOR-ACCOUNT-ID': account.id });
    },
    setOnboardingCompleted(state) {
        state.currentAccount.organization.onboarding_completed = true;
    },
    setAccountAvatar(state, avatar) {
        state.currentAccount.avatar_path = avatar;
    },
    setOrganizationName(state, organizationName) {
        state.currentAccount.organization.name = organizationName;
    },
    setIsLogOutPending(state, isLogOutPending) {
        state.isLogOutPending = isLogOutPending;
    },
};

const actions = {
    logout({ state, commit }) {
        commit('setIsLogOutPending', true);

        return AuthModel.logout()
            .then(() => {
                Echo.leave(`App.Models.OrganizationAccount.${state.currentAccount?.id}`);
                LS.remove('actor.account.id');
                ApiClient.setHeaders({ 'X-ACTOR-ACCOUNT-ID': null });
            })
            .catch(() => commit('setIsLogOutPending', false));
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
