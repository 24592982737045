import { isDateBefore } from '@/utils/dateUtils';
import { ORDER_STATUSES } from '@/enums/orderEnums';

/*------------------------------------------------------------------------
                                  General
------------------------------------------------------------------------*/

export const isOrderDateGone = (order) => isDateBefore(order.prep_date);

export const isOrderCreator = (account, order) => {
    if (!order || !account) {
        return false;
    }

    const { id } = account;

    return order.creator?.id === id || order.creator_id === id;
};

export const hasOrderDraft = (order) => order.draft !== null || order.parent_id;

/*------------------------------------------------------------------------
                                  Status
------------------------------------------------------------------------*/

const hasStatus = (order, status) => order.status === status;

export const isOrderCreating = (order) => hasStatus(order, ORDER_STATUSES.CREATING);
export const isOrderInProgress = (order) => hasStatus(order, ORDER_STATUSES.IN_PROGRESS);
export const isOrderEditing = (order) => hasStatus(order, ORDER_STATUSES.EDITING);
export const isOrderCompleted = (order) => hasStatus(order, ORDER_STATUSES.COMPLETED) || order.is_completed;

/*------------------------------------------------------------------------
                                Quick Fill
------------------------------------------------------------------------*/

export const hasAccessToConfirmOrder = (order, account) =>
    !isOrderDateGone(order) && account.location.id === order.destination?.id;
