<script>
export default {
    name: 'ListDisplay',
};
</script>

<script setup>
defineProps({
    items: {
        type: Array,
        required: true,
    },
    hasBorders: {
        type: Boolean,
        default: false,
    },
    animationDataKey: {
        type: String,
        default: null,
    },
});

const emit = defineEmits(['tap']);
</script>

<template>
    <ul
        class="pl-list-display"
        :class="{
            'pl-list-display--has-borders': hasBorders,
        }"
    >
        <template v-if="animationDataKey !== null">
            <transition-group
                name="down"
                type="transition"
            >
                <li
                    v-for="(item, index) in items"
                    :key="`list item: ${item[animationDataKey]}`"
                    class="pl-list-display__item"
                    @click="emit('tap', item)"
                >
                    <slot
                        name="item"
                        :item="item"
                        :index="index"
                    />
                </li>
            </transition-group>
        </template>

        <template v-else>
            <li
                v-for="(item, index) in items"
                :key="`list item: ${index}`"
                class="pl-list-display__item"
                @click="emit('tap', item)"
            >
                <slot
                    name="item"
                    :item="item"
                    :index="index"
                />
            </li>
        </template>
    </ul>
</template>

<style lang="scss" scoped>
.pl-list-display {
    padding: 0;
    margin: 0;
    word-break: break-word;
    list-style: none;

    .pl-badge {
        white-space: initial;
    }

    &--has-borders {
        $space: calc($grid-gutter-width / 2);

        background-color: $white;
        padding-left: $space;
        padding-right: $space;
        border: 1px solid $gray-200;
        border-radius: $border-radius;

        > .pl-list-display__item {
            padding-top: $space;
            padding-bottom: $space;

            &:not(:last-child) {
                border-bottom: 1px solid $gray-200;
            }
        }
    }
}
</style>
