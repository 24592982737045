<script>
export default {
    name: 'VerticalNavigation',
};
</script>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import { formatToQA } from '@/utils/qaUtils';
import ListDisplay from '@/components/ListDisplay';

const props = defineProps({
    navigationConfig: {
        type: Array,
        required: true,
    },
    dataTestId: {
        type: String,
        required: true,
    },
});

const store = useStore();

/*------------------------------------------------------------------------
                             Navigation state
------------------------------------------------------------------------*/

const filteredNavigationLinks = computed(() =>
    props.navigationConfig.filter(({ guards }) => (guards || []).every((guard) => guard(store)))
);
</script>

<template>
    <transition
        appear
        name="down"
        type="transition"
    >
        <nav class="pl-vertical-navigation">
            <ListDisplay
                has-borders
                :items="filteredNavigationLinks"
                :data-testid="dataTestId"
            >
                <template #item="{ item: link }">
                    <router-link
                        :to="{ name: link.route, query: link.query }"
                        custom
                    >
                        <template #default="{ href, navigate }">
                            <a
                                class="pl-vertical-navigation-link"
                                :class="{
                                    'pl-vertical-navigation-link--has-icon': !!link.icon,
                                }"
                                :href="href"
                                :data-testid="`${dataTestId}_link_${formatToQA(link.name)}`"
                                @click="navigate"
                            >
                                <component
                                    :is="link.icon"
                                    v-if="link.icon"
                                    class="pl-vertical-navigation-link__icon"
                                />

                                {{ link.name }}

                                <ArrowDownIcon
                                    class="pl-vertical-navigation-link__arrow"
                                    :width="13"
                                    :height="8"
                                />
                            </a>
                        </template>
                    </router-link>
                </template>
            </ListDisplay>
        </nav>
    </transition>
</template>

<style lang="scss" scoped>
.pl-vertical-navigation :deep(.pl-list-display > .pl-list-display__item) {
    padding-top: 0;
    padding-bottom: 0;
}

.pl-vertical-navigation-link {
    $space: $grid-gutter-width * 0.5;

    position: relative;
    display: block;
    padding-top: $space;
    padding-bottom: $space;
    color: $gray-900;
    font-weight: $font-weight-normal;
    padding-right: custom-space(2);

    &__icon,
    &__arrow {
        position: absolute;
        top: 50%;
    }

    &__icon {
        left: 0;
        transform: translateY(-50%);
    }

    &__arrow {
        right: 0;
        transform: translateY(-50%) rotate(-90deg);
    }

    &--has-icon {
        padding-left: custom-space(2);
    }
}
</style>
