<script>
export default {
    name: 'ReassignBtn',
};
</script>

<script setup>
import { ORDER_ITEM_REASSIGN_TYPES_OPTIONS } from '@/enums/orderItemEnums';
import GroupedSettings from '@/components/constructions/grouped-settings';
import DropDown from '@/components/DropDown';

const emit = defineEmits(['open-reassign-modal']);
</script>

<template>
    <DropDown>
        <template #invoker="{ isOpened }">
            <div
                class="pl-order-summary__reassign-btn"
                :class="{
                    'pl-order-summary__reassign-btn--opened': isOpened,
                }"
            >
                <span>Reassign</span>

                <CaretDownIcon
                    :width="8"
                    :height="6"
                />
            </div>
        </template>

        <template #content>
            <GroupedSettings.Root>
                <GroupedSettings.List>
                    <GroupedSettings.ListItem
                        v-for="{ value, text } in ORDER_ITEM_REASSIGN_TYPES_OPTIONS"
                        :key="`reassign-option-${value}`"
                        @click="emit('open-reassign-modal', value)"
                    >
                        {{ text }}
                    </GroupedSettings.ListItem>
                </GroupedSettings.List>
            </GroupedSettings.Root>
        </template>
    </DropDown>
</template>

<style lang="scss" scoped>
.pl-order-summary__reassign-btn {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    padding: custom-space(0.4) custom-space(0.75);
    border: 2px solid $primary;
    border-radius: custom-space(2);

    &--opened svg {
        transform: rotate(180deg);
    }

    span {
        color: $primary;
        text-transform: uppercase;
        font-weight: $font-weight-bolder;
        font-size: $font-size-base * 0.75;
        line-height: 1;
    }

    svg {
        fill: $primary;
        margin-left: custom-space(0.5);
    }
}
</style>
