<script>
export default {
    name: 'EditSupplierView',
};
</script>

<script setup>
import { reactive, computed, onBeforeMount, inject } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { catchUnprocessableEntity } from '@/utils/httpUtils';
import { SUPPLIERS_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import SupplierModel from '@/models/Supplier';
import useAbortableRequest from '@/composition/useAbortableRequest';
import Alert from '@/components/Alert';
import SupplierForm from '@/components/forms/SupplierForm';

const props = defineProps({
    supplierId: {
        type: [Number, String],
        required: true,
    },
});

const $acl = inject('$acl');

const router = useRouter();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(SUPPLIERS_RESTRICTIONS.UPDATE, currentAccount)) {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to edit supplier.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                            Supplier form state
------------------------------------------------------------------------*/

const supplierFormState = reactive({
    data: null,
    validationErrors: null,
    isDataLoading: true,
    isSubmitting: false,
});

const loadSupplierData = () => {
    sendAbortableRequest(SupplierModel.find(orgId, props.supplierId))
        .then((model) => (supplierFormState.data = model))
        .finally(() => (supplierFormState.isDataLoading = false));
};

const submitUpdateSupplier = (attributes) => {
    supplierFormState.isSubmitting = true;

    supplierFormState.data
        .update(orgId, attributes)
        .then(() => {
            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: 'The supplier has been successfully updated!',
            });

            router.push({ name: SETTINGS_ROUTE_NAMES.SUPPLIERS.INDEX });
        })
        .catch((error) =>
            catchUnprocessableEntity(error, (errors) => {
                supplierFormState.validationErrors = errors;
            })
        )
        .finally(() => (supplierFormState.isSubmitting = false));
};

const supplierFormProps = computed(() => {
    const { data } = supplierFormState;

    if (data === null) {
        return {};
    }

    return {
        name: data.name,
        email: data.email,
    };
});

onBeforeMount(loadSupplierData);
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.SUPPLIERS.INDEX">
        <template #title>
            <h1>Edit supplier</h1>
        </template>

        <Alert
            v-if="supplierFormState.data === null && !supplierFormState.isDataLoading"
            :type="ALERT_TYPES.FAIL"
        >
            Supplier could not be loaded for some reason. Please try again later.
        </Alert>

        <SupplierForm
            v-else
            :name="supplierFormProps.name"
            :email="supplierFormProps.email"
            :validation-errors="supplierFormState.validationErrors"
            :is-data-loading="supplierFormState.isDataLoading"
            :is-submitting="supplierFormState.isSubmitting"
            @submit="submitUpdateSupplier"
        />
    </TopBarLayout>
</template>
