<script>
export default {
    name: 'OrderFilters',
};
</script>

<script setup>
import { computed, reactive, watch } from 'vue';
import { useStore } from 'vuex';

import { FILTER_ORDERS_BY_STATUS_OPTIONS } from '@/enums/timelineEnums';
import DepartmentModel from '@/models/Department';
import SelectInput from '@/components/form-controls/SelectInput';
import CheckboxInput from '@/components/form-controls/CheckboxInput';
import FilterSettingsUI from '@/components/UI/FilterSettingsUI';

const props = defineProps({
    initialFilters: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['update-filters']);

const store = useStore();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              General state
------------------------------------------------------------------------*/

const generalState = reactive({
    departments: null,
    isDataLoading: false,
});

const loadDepartments = () => {
    generalState.isDataLoading = true;

    DepartmentModel.all(orgId, { with_default: true, with_categories: true })
        .then((models) => (generalState.departments = models || []))
        .finally(() => (generalState.isDataLoading = false));
};

/*------------------------------------------------------------------------
                              Settings state
------------------------------------------------------------------------*/

const settingsState = reactive({
    isModalOpened: false,
    filters: {},
});

const openSettingsModal = () => {
    const { initialFilters } = props;

    settingsState.isModalOpened = true;

    settingsState.filters = {
        ...initialFilters,
        department_id: +initialFilters.department_id || null,
        category_id: +initialFilters.category_id || null,
        only_my: initialFilters.only_my === '1' ? ['1'] : [],
    };

    if (generalState.departments === null && !generalState.isDataLoading) {
        loadDepartments();
    }
};

const closeSettingsModal = () => {
    settingsState.isModalOpened = false;
    settingsState.filters = {};
};

const applyChanges = () => {
    const { filters } = settingsState;

    emit('update-filters', { ...filters, only_my: filters.only_my[0] ?? '0' });

    closeSettingsModal();
};

const isAnyFilterActive = computed(() => {
    const { initialFilters } = props;

    return (
        initialFilters.department_id !== null ||
        initialFilters.category_id !== null ||
        initialFilters.status !== null ||
        initialFilters.only_my !== '0'
    );
});

const isApplyBtnDisabled = computed(() => {
    if (generalState.isDataLoading) {
        return true;
    }

    const { initialFilters } = props;
    const { filters } = settingsState;

    return (
        +filters.department_id === +initialFilters.department_id &&
        +filters.category_id === +initialFilters.category_id &&
        filters.status === initialFilters.status &&
        (filters.only_my[0] ?? '0') === initialFilters.only_my
    );
});

/*------------------------------------------------------------------------
                                Department
------------------------------------------------------------------------*/

const departmentOptions = computed(() => {
    if (generalState.departments === null) {
        return [];
    }

    const options = [{ value: null, text: 'All departments' }];

    generalState.departments.forEach(({ id, name }) => {
        options.push({ value: id, text: name });
    });

    return options;
});

watch(
    () => settingsState.filters.department_id,
    (departmentId) => {
        if (generalState.departments !== null) {
            const { categories = [] } = generalState.departments.find(({ id }) => departmentId === id) || {};

            if (!categories.some(({ id }) => settingsState.filters.category_id === id)) {
                settingsState.filters.category_id = null;
            }
        }
    }
);

/*------------------------------------------------------------------------
                                 Category
------------------------------------------------------------------------*/

const categoryOptions = computed(() => {
    if (generalState.isDataLoading) {
        return [];
    }

    const departmentId = settingsState.filters.department_id;

    const options = [{ value: null, text: 'All categories' }];

    if (departmentId === null) {
        return options;
    }

    const { categories } = generalState.departments.find(({ id }) => departmentId === id);

    categories.forEach(({ id, name }) => {
        options.push({ value: id, text: name });
    });

    return options;
});

/*------------------------------------------------------------------------
                                  Status
------------------------------------------------------------------------*/

const statusOptions = [{ value: null, text: 'All statuses' }, ...FILTER_ORDERS_BY_STATUS_OPTIONS];

/*------------------------------------------------------------------------
                                  Only my
------------------------------------------------------------------------*/

const onlyMyOptions = [{ text: 'Show only my orders', value: '1' }];

/*------------------------------------------------------------------------
                                    QA
------------------------------------------------------------------------*/

const qaPrefix = 'order_filters';
</script>

<template>
    <FilterSettingsUI
        :is-modal-opened="settingsState.isModalOpened"
        :is-any-setting-active="isAnyFilterActive"
        :is-apply-btn-disabled="isApplyBtnDisabled"
        @open="openSettingsModal"
        @close="closeSettingsModal"
        @apply-changes="applyChanges"
    >
        <template #settings="{ classNames }">
            <div :class="classNames.selectBlock">
                <SelectInput
                    v-model="settingsState.filters.department_id"
                    searchable
                    label="Department"
                    size="sm"
                    selected-option-position="end"
                    :data-testid="`${qaPrefix}_department_select`"
                    :options="departmentOptions"
                    :loading="generalState.isDataLoading"
                    :disabled="generalState.isDataLoading"
                />
            </div>

            <div :class="classNames.selectBlock">
                <SelectInput
                    v-model="settingsState.filters.category_id"
                    searchable
                    label="Category"
                    size="sm"
                    selected-option-position="end"
                    :data-testid="`${qaPrefix}_category_select`"
                    :options="categoryOptions"
                    :loading="generalState.isDataLoading"
                    :disabled="generalState.isDataLoading"
                />
            </div>

            <div :class="classNames.selectBlock">
                <SelectInput
                    v-model="settingsState.filters.status"
                    searchable
                    label="Status"
                    size="sm"
                    selected-option-position="end"
                    :data-testid="`${qaPrefix}_status_select`"
                    :options="statusOptions"
                />
            </div>

            <CheckboxInput
                v-model="settingsState.filters.only_my"
                name="only_my"
                :data-testid="`${qaPrefix}_only_my_checkbox`"
                :options="onlyMyOptions"
            />
        </template>
    </FilterSettingsUI>
</template>
