<script>
export default {
    name: 'OverlayLoader',
};
</script>

<script setup>
import { useSlots } from 'vue';

import Badge from '@/components/Badge';

defineProps({
    enableSticky: {
        type: Boolean,
        default: false,
    },
});

const slots = useSlots();
</script>

<template>
    <div
        class="pl-overlay-spinner"
        :class="{
            'pl-overlay-spinner--sticky': enableSticky,
        }"
    >
        <img
            src="~@/assets/images/loader.gif"
            :width="28"
            :height="28"
            alt="Loading..."
        />

        <Badge v-if="slots['progress']">
            <slot name="progress" />
        </Badge>
    </div>
</template>

<style lang="scss" scoped>
.pl-overlay-spinner {
    position: absolute;
    inset: 0;
    background-color: rgba($white, 0.6);
    z-index: 99;

    &:not(&--sticky) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: custom-space(1);
    }

    &--sticky img {
        position: sticky;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>
