<script>
export default {
    name: 'TimelineOrderItem',
};
</script>

<script setup>
import { computed } from 'vue';
import { Pie } from 'vue-chartjs';

import { getBatchUnitQtyAmount } from '@/utils/batchUnitsUtils';
import {
    getPLOrderItemOrderedQtyDisplay,
    getPLOrderItemProducedQtyDisplay,
    getPLOrderItemProducedPercentage,
    getQFOrderItemOrderedQtyDisplay,
} from '@/utils/orderItemUtils';

const props = defineProps({
    orderItem: {
        type: Object,
        required: true,
    },
    hasAccessToConfirm: {
        type: Boolean,
        default: false,
    },
    isQuickFill: {
        type: Boolean,
        default: false,
    },
    isOverproduction: {
        type: Boolean,
        default: false,
    },
    isPrepTask: {
        type: Boolean,
        default: false,
    },
});

/*-----------------------------------------------------------------------------------
                                Order item state
-----------------------------------------------------------------------------------*/

const orderItemState = computed(() => {
    const { orderItem, hasAccessToConfirm, isQuickFill, isPrepTask } = props;

    const getOrderedQtyDisplay = () => {
        if (isQuickFill) {
            return getQFOrderItemOrderedQtyDisplay(orderItem);
        }

        if (isPrepTask) {
            return getPLOrderItemOrderedQtyDisplay(orderItem);
        }

        return getPLOrderItemOrderedQtyDisplay(orderItem);
    };

    const getProducedValue = () => {
        if (isQuickFill) {
            const { fulfilled_qty: fulfilledQty, confirmed_qty: confirmedQty, units_data: unitsData } = orderItem;

            const BYQty = hasAccessToConfirm ? confirmedQty : fulfilledQty;

            if (BYQty === null) {
                return '?';
            }

            return getBatchUnitQtyAmount(
                unitsData,
                unitsData.batch_yield_unit,
                unitsData.quick_fill_ordering_unit,
                BYQty,
                true
            );
        }

        if (isPrepTask) {
            return getPLOrderItemProducedQtyDisplay(orderItem);
        }

        return getPLOrderItemProducedPercentage(orderItem, true);
    };

    return {
        orderedQtyDisplay: getOrderedQtyDisplay(),
        producedValue: getProducedValue(),
    };
});

/*-----------------------------------------------------------------------------------
                                        Pie Chart
-----------------------------------------------------------------------------------*/

const chartPieGlobalStyles = {
    borderWidth: '2px',
    borderColor: '#fff',
    borderStyle: 'solid',
    borderRadius: '50%',
    backgroundColor: '#fff',
    height: '21px',
    width: '21px',
};

const chartPieOptions = {
    responsive: false,
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            enabled: false,
        },
    },
};

const chartData = computed(() => {
    const { orderItem, hasAccessToConfirm, isQuickFill } = props;

    const getPercentage = () => {
        if (!isQuickFill) {
            return getPLOrderItemProducedPercentage(orderItem);
        }

        const { ordered_BY_qty: orderedQty, fulfilled_qty: fulfilledQty, confirmed_qty: confirmedQty } = orderItem;

        const numerator = hasAccessToConfirm ? confirmedQty : fulfilledQty;
        const denominator = hasAccessToConfirm ? fulfilledQty : orderedQty;

        return (numerator / denominator) * 100 || 0;
    };

    const percentage = getPercentage();

    return {
        datasets: [
            {
                borderWidth: 0,
                hoverBackgroundColor: ['#f1ae7c', '#fff'],
                backgroundColor: ['#f1ae7c', '#fff'],
                data: [Math.min(percentage, 100), Math.max(100 - percentage, 0)],
            },
        ],
    };
});

/*-----------------------------------------------------------------------------------
                                        QA
-----------------------------------------------------------------------------------*/

const qaPrefix = 'order-item';
</script>

<template>
    <div class="pl-timeline-order-item">
        <div class="pl-timeline-order-item__name">
            <b :data-testid="`${qaPrefix}_name`">
                {{ orderItem.name }}
            </b>

            <span :data-testid="`${qaPrefix}_ordered_qty`">({{ orderItemState.orderedQtyDisplay }})</span>
        </div>

        <div
            v-if="!isOverproduction"
            class="pl-timeline-order-item__progress-wrapper"
        >
            <div class="pl-timeline-order-item__progress">
                <Pie
                    :data="chartData"
                    :options="chartPieOptions"
                    :style="chartPieGlobalStyles"
                />

                <span :data-testid="`${qaPrefix}_produced_qty`">
                    {{ orderItemState.producedValue }}
                </span>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.pl-timeline-order-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: custom-space(0.5);
    padding: custom-space(0.25) 0;
    border-bottom: 1px solid $gray-400;

    &__name {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: custom-space(1);
        line-height: $line-height-base * 0.75;

        > b {
            font-weight: $font-weight-normal;
        }

        > span {
            flex-shrink: 0;
            font-size: $font-size-base * 0.875;
        }
    }

    &__progress-wrapper {
        flex-shrink: 0;
        max-width: 100px;
        width: 100%;
    }

    &__progress {
        display: flex;
        align-items: center;
        background-color: $primary;
        padding: custom-space(0.2) custom-space(0.5) custom-space(0.2) custom-space(0.2);
        border-radius: 1.5rem;
        font-weight: $font-weight-bolder;
        font-size: $font-size-base * 0.75;
        line-height: $font-size-base * 0.75;
        color: $white;
        gap: custom-space(0.25);

        & > div {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        & > span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex-grow: 1;
            text-align: center;
        }
    }
}
</style>
