import { QFO_ROUTE_NAMES } from '@/enums/routesNameEnums';

import QFOrdersIndexView from '@/views/dashboard/quick-fill-orders/index';

import ShowQFOrderView from '@/views/dashboard/quick-fill-orders/show';
import CreateQFOrderFromTemplateView from '@/views/dashboard/quick-fill-orders/create-from-template';

import CreateQFOrderIndexView from '@/views/dashboard/quick-fill-orders/create';
import CreateQFOrderStep1View from '@/views/dashboard/quick-fill-orders/create/step-1';
import CreateQFOrderStep2View from '@/views/dashboard/quick-fill-orders/create/step-2';
import CreateQFOrderStep3View from '@/views/dashboard/quick-fill-orders/create/step-3';

import EditQFOrderIndexView from '@/views/dashboard/quick-fill-orders/edit';
import EditQFOrderStep1View from '@/views/dashboard/quick-fill-orders/edit/step-1';
import EditQFOrderStep2View from '@/views/dashboard/quick-fill-orders/edit/step-2';
import EditQFOrderStep3View from '@/views/dashboard/quick-fill-orders/edit/step-3';

export default [
    {
        path: 'quick-fill-orders',
        name: QFO_ROUTE_NAMES.INDEX,
        component: QFOrdersIndexView,
    },

    {
        path: 'quick-fill-orders/:orderId',
        name: QFO_ROUTE_NAMES.SHOW,
        component: ShowQFOrderView,
        props: true,
    },

    {
        path: 'quick-fill-orders/create-from-template',
        name: QFO_ROUTE_NAMES.CREATE_FROM_TEMPLATE,
        component: CreateQFOrderFromTemplateView,
    },

    {
        path: 'quick-fill-orders/create',
        component: CreateQFOrderIndexView,
        children: [
            {
                path: '',
                name: QFO_ROUTE_NAMES.CREATE.INDEX,
                component: CreateQFOrderStep1View,
            },
            {
                path: 'add-items',
                name: QFO_ROUTE_NAMES.CREATE.ADD_ITEMS,
                component: CreateQFOrderStep2View,
            },
            {
                path: 'review-and-confirm',
                name: QFO_ROUTE_NAMES.CREATE.REVIEW_AND_CONFIRM,
                component: CreateQFOrderStep3View,
            },
        ],
    },

    {
        path: 'quick-fill-orders/:orderId/edit',
        component: EditQFOrderIndexView,
        props: true,
        children: [
            {
                path: '',
                name: QFO_ROUTE_NAMES.EDIT.INDEX,
                component: EditQFOrderStep1View,
                props: true,
            },
            {
                path: 'add-items',
                name: QFO_ROUTE_NAMES.EDIT.ADD_ITEMS,
                component: EditQFOrderStep2View,
                props: true,
            },
            {
                path: 'review-and-confirm',
                name: QFO_ROUTE_NAMES.EDIT.REVIEW_AND_CONFIRM,
                component: EditQFOrderStep3View,
                props: true,
            },
        ],
    },
];
