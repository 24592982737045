<script>
export default {
    name: 'MyProfileIndexView',
};
</script>

<script setup>
import { computed, onBeforeMount, reactive } from 'vue';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { capitalizeFirstLetter } from '@/utils/stringUtils';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import AccountModel from '@/models/Account';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useConfirmationModal from '@/composition/useConfirmationModal';
import ConfirmationModal from '@/components/ConfirmationModal';
import Alert from '@/components/Alert';
import Accordion from '@/components/Accordion';
import EmployeeAvatar from '@/components/model-specific/employee/EmployeeAvatar';

const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                               Profile state
------------------------------------------------------------------------*/

const profileState = reactive({
    data: null,
    isDataLoading: true,
    isActionProcessing: false,
});

const loadProfileData = () => {
    Promise.all([
        sendAbortableRequest(AccountModel.getProfile(orgId, currentAccount.id)),
        store.dispatch('countries/loadCountries'),
    ])
        .then(([accountModel]) => (profileState.data = accountModel))
        .finally(() => (profileState.isDataLoading = false));
};

const saveProfileAvatar = (formData) => {
    profileState.isActionProcessing = true;

    currentAccount
        .uploadAvatar({ avatar: formData.get('file') })
        .then(({ avatar_path: avatarPath }) => {
            profileState.data.avatar_path = avatarPath;

            store.commit('auth/setAccountAvatar', avatarPath);

            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: 'The profile avatar has been successfully uploaded!',
            });
        })
        .finally(() => (profileState.isActionProcessing = false));
};

const profileData = computed(() => {
    const { data } = profileState;

    if (data === null) {
        return {};
    }

    const { countries } = store.state.countries;

    const country = countries.find(({ id }) => data.phone.phone_country_id === id);

    return {
        ...data,
        phone_number: `+${country.phone_code}${data.phone.phone}`,
    };
});

const profileInfoConfig = computed(() => {
    if (!profileState.data) {
        return [];
    }

    const { is_owner: isOwner } = profileState.data;

    const config = [
        { label: 'Name', dataKey: 'name' },
        { label: 'Phone Number', dataKey: 'phone_number' },
        { label: 'Email', dataKey: 'email' },
    ];

    if (!isOwner) {
        config.push({ label: 'Hourly Rate', dataKey: 'hourly_rate' });
    }

    config.push({ label: isOwner ? 'Company created' : 'Member Since', dataKey: 'joined_at_display' });

    return config;
});

onBeforeMount(loadProfileData);

/*------------------------------------------------------------------------
                         Confirmation modal state
------------------------------------------------------------------------*/

const { confirmationState, confirmationModal } = useConfirmationModal();

const submitDeleteAvatar = () => {
    confirmationModal
        .submit(() => currentAccount.deleteAvatar())
        .then(() => {
            profileState.data.avatar_path = null;

            store.commit('auth/setAccountAvatar', null);

            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: 'The profile avatar has been successfully deleted!',
            });
        });
};

/*------------------------------------------------------------------------
                                    QA
------------------------------------------------------------------------*/

const qaPrefix = 'profile';
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.INDEX">
        <template #title>
            <h1>My Profile</h1>
        </template>

        <InPlaceLoader v-if="profileState.isDataLoading" />

        <Alert
            v-else-if="profileState.data === null"
            :type="ALERT_TYPES.FAIL"
        >
            Profile information could not be loaded for some reason. Please try again later.
        </Alert>

        <div
            v-else
            class="pl-profile"
        >
            <EmployeeAvatar
                include-controls
                :size="120"
                :url="profileState.data.avatar_path"
                :is-loading="profileState.isActionProcessing"
                @save="saveProfileAvatar"
                @delete="confirmationModal.open"
            />

            <Accordion
                title="General Information"
                :data-test-id="`${qaPrefix}_general_information`"
            >
                <div
                    v-for="{ label, dataKey } in profileInfoConfig"
                    :key="`profile field: ${label}`"
                    class="pl-profile__field"
                >
                    <small>{{ label }}</small>

                    <span>{{ profileData[dataKey] ?? '-' }}</span>
                </div>
            </Accordion>

            <Accordion
                v-if="!profileState.data.is_owner"
                :title="`Locations (${profileData.locations.length})`"
                :data-test-id="`${qaPrefix}_locations`"
            >
                <div
                    v-for="{ name, role } in profileData.locations"
                    :key="`profile location: ${name}`"
                    class="pl-profile__field"
                >
                    <small>{{ `${capitalizeFirstLetter(role)} in:` }}</small>

                    <span>{{ name }}</span>
                </div>
            </Accordion>

            <Accordion
                v-if="!profileState.data.is_owner"
                :title="`Departments (${profileData.departments.length})`"
                :data-test-id="`${qaPrefix}_departments`"
            >
                <template v-if="profileData.departments.length > 0">
                    <div
                        v-for="{ name } in profileData.departments"
                        :key="`profile department: ${name}`"
                        class="pl-profile__field"
                    >
                        <small>Assigned to:</small>

                        <span>{{ name }}</span>
                    </div>
                </template>

                <Alert
                    v-else
                    :type="ALERT_TYPES.WARNING"
                >
                    You don't belong to any department
                </Alert>
            </Accordion>
        </div>

        <ConfirmationModal
            v-if="confirmationState.isOpened"
            qa-prefix="delete_avatar_modal"
            :is-pending="confirmationState.isPending"
            @close="confirmationModal.close"
            @submit-delete="submitDeleteAvatar"
        >
            Are you sure you want to delete profile avatar?
        </ConfirmationModal>
    </TopBarLayout>
</template>

<style lang="scss" scoped>
.pl-profile {
    display: flex;
    flex-direction: column;
    gap: custom-space(0.25);
    max-width: 400px;
    width: 100%;
    margin: 0 auto;

    :deep(.pl-employee-avatar) {
        margin: 0 auto custom-space(1.5) auto;
    }

    &__field {
        display: flex;
        flex-direction: column;
        gap: custom-space(0.25);
        padding-bottom: custom-space(0.25);
        border-bottom: 1px solid $gray-200;
        line-height: 1;

        &:not(:last-of-type) {
            margin-bottom: custom-space(0.75);
        }

        & > small {
            font-size: $font-size-base * 0.85;
        }

        & > span {
            font-weight: $font-weight-normal;
        }
    }
}
</style>
