<script>
export default {
    name: 'FulfillmentSummaryCard',
};
</script>

<script setup>
import { computed } from 'vue';

import { getBatchYieldUnitQtyDisplay } from '@/utils/batchUnitsUtils';
import ItemSummary from '@/components/model-specific/item/ItemSummary';

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    isBreakDownByItem: {
        type: Boolean,
        default: false,
    },
});

/*------------------------------------------------------------------------
                                Item details
------------------------------------------------------------------------*/

const itemOrderedQtyDisplay = computed(() => {
    const { units_data: unitsData, ordered_BY_qty: orderedBYQty } = props.item;

    return getBatchYieldUnitQtyDisplay(unitsData, unitsData.batch_yield_unit, orderedBYQty, true);
});
</script>

<template>
    <article class="pl-report-summary">
        <div class="pl-report-summary__wrapper">
            <div
                v-if="isBreakDownByItem"
                class="pl-report-summary__orders-qty"
            >
                Orders: {{ item.orders }}
            </div>

            <h2
                v-else
                class="pl-report-summary__name"
            >
                {{ item.name }}
            </h2>

            <span>
                {{ itemOrderedQtyDisplay }}
            </span>
        </div>

        <ItemSummary
            :labor-time="item.display_labor_time"
            :food-cost="item.display_food_cost"
            :labor-cost="item.display_labor_cost"
            :total-cost="item.display_total_cost"
        />

        <div class="pl-report-summary__statuses">
            <p>
                <span>Ordered:</span>

                <b>{{ item.ordered_BY_qty }}</b>
            </p>

            <p>
                <span>Fulfilled:</span>

                <b>{{ item.fulfilled_BY_qty }}</b>
            </p>

            <p>
                <span>Confirmed:</span>

                <b>{{ item.confirmed_BY_qty }}</b>
            </p>
        </div>
    </article>
</template>

<style lang="scss" scoped>
.pl-report-summary {
    &__wrapper {
        align-items: center;
        justify-content: space-between;

        h2 {
            margin-bottom: 0;
        }
    }

    &__name {
        font-size: $font-size-base;
        color: $primary;
        font-weight: $font-weight-bolder;
        margin-bottom: custom-space(0.5);
    }

    &__orders-qty {
        font-size: $font-size-base * 0.875;
        font-weight: $font-weight-normal;
    }

    &__statuses {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 custom-space(0.875);
        font-size: $font-size-base * 0.85;

        & > p {
            display: flex;
            gap: custom-space(0.375);
            margin: 0;
        }
    }
}

:deep(.pl-item-summary) {
    margin-bottom: custom-space(0.375);
}
</style>
