<script>
export default {
    name: 'EditPLOrderStep1View',
};
</script>

<script setup>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { mapGetters } from '@/utils/vuexUtils';
import { catchUnprocessableEntity } from '@/utils/httpUtils';
import { PL_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import useOrderSettingsForm from '@/composition/useOrderSettingsForm';
import PrepDateForm from '@/components/forms/PrepDateForm';
import OrderStepTemplate from '@/components/model-specific/orders/OrderStepTemplate';

const props = defineProps({
    orderId: {
        type: [Number, String],
        required: true,
    },
});

const router = useRouter();
const store = useStore();

const { currentPLOrder } = mapGetters(['currentPLOrder']);

/*------------------------------------------------------------------------
                                Order state
------------------------------------------------------------------------*/

const cancelOrderDraft = () => {
    currentPLOrder.value.cancel().finally(() => {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.SUCCESS,
            message: 'Order editing process has been successfully cancelled',
        });

        router.push({ name: PL_ROUTE_NAMES.INDEX });

        store.commit('setCurrentPLOrder', null);
    });
};

/*------------------------------------------------------------------------
                         Order settings form state
------------------------------------------------------------------------*/

const { orderSettingsFormState, orderSettingsForm } = useOrderSettingsForm();

const navigateToNextStep = () => {
    router.push({
        name: PL_ROUTE_NAMES.EDIT.ADD_ITEMS,
        params: { orderId: props.orderId },
    });
};

const submitForm = (payload) => {
    if (!orderSettingsFormState.hasChangesMade) {
        navigateToNextStep();
    } else {
        orderSettingsFormState.isSubmitting = true;

        currentPLOrder.value
            .update(payload)
            .then((model) => {
                EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                    type: ALERT_TYPES.SUCCESS,
                    message: 'Order settings have been successfully updated',
                });

                store.commit('setCurrentPLOrder', model);

                navigateToNextStep();
            })
            .catch((error) => catchUnprocessableEntity(error, orderSettingsForm.update))
            .finally(() => (orderSettingsFormState.isSubmitting = false));
    }
};
</script>

<template>
    <OrderStepTemplate
        is-edit-mode
        :is-step-submitting="orderSettingsFormState.isSubmitting"
        @cancel-process="cancelOrderDraft"
    >
        <template #step-content>
            <PrepDateForm
                id="pl-edit-order-step-1-form"
                :prep-date="currentPLOrder.prep_date"
                :validation-errors="orderSettingsFormState.validationErrors"
                @update-status="orderSettingsForm.update"
                @submit="submitForm"
            />
        </template>

        <template #next-step-action>
            <BtnUI
                is-filled
                type="submit"
                form="pl-edit-order-step-1-form"
                :disabled="orderSettingsForm.isSubmittingDisabled()"
            >
                {{ orderSettingsForm.getSubmitBtnText(currentPLOrder) }}
            </BtnUI>
        </template>
    </OrderStepTemplate>
</template>
