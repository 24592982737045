<script>
export default {
    name: 'OrdersIndexViewHOC',
};
</script>

<script setup>
import { computed, useSlots } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { isCook, isManager } from '@/utils/accountUtils';
import { TIMELINE_VIEW_MODES } from '@/enums/timelineEnums';

const route = useRoute();
const store = useStore();
const slots = useSlots();

const { currentAccount } = store.state.auth;

const isViewAsCook = computed(() => {
    const isManagerAsCook = isManager(currentAccount) && route.query.viewmode === TIMELINE_VIEW_MODES.AS_COOK;

    return isCook(currentAccount) || isManagerAsCook;
});
</script>

<template>
    <slot
        v-if="isViewAsCook && slots['view-as-cook']"
        name="view-as-cook"
    />

    <slot
        v-else
        name="view-as-manager"
    />
</template>

<style lang="scss" scoped>
:slotted(.pl-layout) .pl-tabs {
    margin-top: custom-space(-0.5);

    &__content {
        margin-top: custom-space(1);
    }
}
</style>
