import { createRouter, createWebHistory } from 'vue-router';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import {
    LOGIN_ROUTE_NAME,
    JOIN_ROUTE_NAME,
    SELECT_ACCOUNT_ROUTE_NAME,
    ONBOARDING_ROUTE_NAMES,
    NOT_FOUND_ROUTE_NAME,
    PL_ROUTE_NAMES,
} from '@/enums/routesNameEnums';

import NotFoundView from '@/views/not-found';

import authRoutes from './auth';
import dashboardRoutes from './dashboard';

const routes = [
    ...authRoutes,
    ...dashboardRoutes,
    {
        path: '/:pathMatch(.*)*',
        name: NOT_FOUND_ROUTE_NAME,
        component: NotFoundView,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

const loadAppStatePromise = new Promise((resolve, reject) => {
    EventBus.once(EVENT_BUS_EVENTS.APP_STATE_LOADING_SUCCESS, resolve);
    EventBus.once(EVENT_BUS_EVENTS.APP_STATE_LOADING_FAILED, reject);
});

router.beforeEach(async (to, from, next) => {
    await loadAppStatePromise
        .then(({ user, authState }) => {
            if (user === null && ![LOGIN_ROUTE_NAME, JOIN_ROUTE_NAME].includes(to.name)) {
                return next({
                    name: LOGIN_ROUTE_NAME,
                    query: { come_from: window.location.href },
                });
            }

            if (user === null || to.name === JOIN_ROUTE_NAME) {
                return next();
            }

            const { currentAccount } = authState;

            if (to.name === LOGIN_ROUTE_NAME) {
                if (currentAccount !== null) {
                    return next({ name: PL_ROUTE_NAMES.INDEX });
                }

                return next({ name: SELECT_ACCOUNT_ROUTE_NAME });
            }

            if (
                currentAccount === null &&
                ![SELECT_ACCOUNT_ROUTE_NAME, ONBOARDING_ROUTE_NAMES.CREATE_COMPANY].includes(to.name)
            ) {
                return next({ name: SELECT_ACCOUNT_ROUTE_NAME });
            }

            if (currentAccount !== null) {
                const { onboarding_completed: isOnboardingCompleted } = currentAccount.organization;

                const isOnboardingRoute = Object.values(ONBOARDING_ROUTE_NAMES).includes(to.name);

                if (isOnboardingCompleted && isOnboardingRoute) {
                    return next({ name: PL_ROUTE_NAMES.INDEX });
                }

                if (!isOnboardingCompleted && !isOnboardingRoute) {
                    return next({ name: ONBOARDING_ROUTE_NAMES.CREATE_COMPANY });
                }
            }

            next();
        })
        .catch(() => next());
});

export default router;
