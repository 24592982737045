import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';

import SettingsIndexView from '@/views/dashboard/settings';

import companyRoutes from './company';
import configurationOfItemRoutes from './configuration-of-item';
import departmentsRoutes from './departments';
import itemsRoutes from './items';
import locationsRoutes from './locations';
import suppliersRoutes from './suppliers';
import myAccountsRoutes from './my-accounts';
import myProfileRoutes from './my-profile';
import noPrepActionsRoutes from './no-prep-actions';
import PARRoutes from './PAR';
import peopleRoutes from './people';
import prepTasksRoutes from './prep-tasks';

export default [
    {
        path: 'settings',
        name: SETTINGS_ROUTE_NAMES.INDEX,
        component: SettingsIndexView,
    },
    ...companyRoutes,
    ...configurationOfItemRoutes,
    ...departmentsRoutes,
    ...itemsRoutes,
    ...locationsRoutes,
    ...suppliersRoutes,
    ...myAccountsRoutes,
    ...myProfileRoutes,
    ...noPrepActionsRoutes,
    ...PARRoutes,
    ...peopleRoutes,
    ...prepTasksRoutes,
];
