import ApiClient from '@/plugins/ApiClient';
import Model from './Model';
import DepartmentModel from './Department';

export default class Category extends Model {
    static async create(orgId, attributes, query = {}) {
        const { department_id: departmentId, ...payload } = attributes;

        const { data } = await ApiClient.post(
            `organizations/${orgId}/departments/${departmentId}/categories`,
            payload,
            { params: query }
        );

        return DepartmentModel.make(data);
    }

    static async update(orgId, categoryId, attributes, query = {}) {
        const { department_id: departmentId, ...payload } = attributes;

        const { data } = await ApiClient.put(
            `organizations/${orgId}/departments/${departmentId}/categories/${categoryId}`,
            payload,
            { params: query }
        );

        return DepartmentModel.make(data);
    }

    async delete(orgId, query = {}) {
        const { data } = await ApiClient.delete(
            `organizations/${orgId}/departments/${this.department_id}/categories/${this.id}`,
            { params: query }
        );

        return DepartmentModel.make(data);
    }
}
