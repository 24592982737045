<script>
export default {
    name: 'ReportDatePicker',
};
</script>

<script setup>
import { computed, reactive, watch } from 'vue';
import dayjs from 'dayjs';

import { getCurrentDate, formatDate } from '@/utils/dateUtils';
import { REPORT_PERIODS } from '@/enums/reportsEnums';
import DatePickerHOC from '@/components/HOC/DatePickerHOC';
import FilterSettingsUI from '@/components/UI/FilterSettingsUI';
import CalendarIcon from '@/components/icons/CalendarIcon';

const props = defineProps({
    initialStartDate: {
        type: String,
        required: true,
    },
    initialEndDate: {
        type: String,
        required: true,
    },
});

const emit = defineEmits(['update-date']);

/*------------------------------------------------------------------------
                              Settings state
------------------------------------------------------------------------*/

const settingsState = reactive({
    isModalOpened: false,
    date: null,
    period: null,
    dateToFocus: null,
});

const openSettingsModal = () => (settingsState.isModalOpened = true);

const closeSettingsModal = () => {
    settingsState.isModalOpened = false;
    settingsState.date = null;
    settingsState.period = null;
    settingsState.dateToFocus = null;
};

const datePickerKeyToReRender = computed(() => {
    const { period, dateToFocus } = settingsState;

    return `date picker: ${period} ${dateToFocus}`;
});

/*------------------------------------------------------------------------
                                Range mode
------------------------------------------------------------------------*/

const onRangeStart = (date) => {
    const { period } = settingsState;

    if (period === REPORT_PERIODS.MONTH || period === REPORT_PERIODS.WEEK) {
        const endDate = dayjs(date);
        const startDate = endDate.subtract(1, period);

        settingsState.date = [formatDate(startDate), formatDate(endDate)];
    }
};

const isRangeEnabled = computed(() => {
    const { isModalOpened, date, period } = settingsState;

    if (!isModalOpened) {
        return props.initialStartDate !== props.initialEndDate;
    }

    if (period !== null && period !== REPORT_PERIODS.DAY) {
        return true;
    }

    return Array.isArray(date) && date[0] !== date[1];
});

/*------------------------------------------------------------------------
                                   Date
------------------------------------------------------------------------*/

const setDateToFocus = (isEndDate) => {
    const { date } = settingsState;

    if (date.length === 0) return;

    if (isRangeEnabled.value) {
        settingsState.dateToFocus = date[+isEndDate];
    } else {
        settingsState.dateToFocus = date;
    }
};

const selectedDatesDisplay = computed(() => {
    const { date, isModalOpened } = settingsState;

    let startDate = '';
    let endDate = '';

    if (!isModalOpened) {
        startDate = props.initialStartDate;
        endDate = props.initialEndDate;
    } else if (date.length > 0) {
        if (isRangeEnabled.value) {
            startDate = date[0];
            endDate = date[1];
        } else {
            startDate = date;
            endDate = date;
        }
    }

    if (startDate && endDate) {
        startDate = formatDate(startDate, 'D MMM YYYY');
        endDate = formatDate(endDate, 'D MMM YYYY');
    }

    const fullDate = startDate && endDate ? `${startDate} - ${endDate}` : '';

    return {
        startDate,
        endDate,
        fullDate,
    };
});

watch(
    () => [props.initialStartDate, props.initialEndDate, settingsState.isModalOpened],
    ([startDate, endDate, isModalOpened]) => {
        if (!isModalOpened) return;

        if (startDate !== endDate) {
            settingsState.date = [startDate, endDate];
            settingsState.period = REPORT_PERIODS.CUSTOM;
        } else {
            settingsState.date = startDate;
            settingsState.period = REPORT_PERIODS.DAY;
        }
    },
    { immediate: true }
);

watch(
    () => settingsState.date,
    (date) => {
        if (settingsState.isModalOpened && isRangeEnabled.value && date.length > 0 && date[0] === date[1]) {
            settingsState.date = date[0];
            settingsState.period = REPORT_PERIODS.DAY;
        }

        settingsState.dateToFocus = null;
    }
);

/*------------------------------------------------------------------------
                                  Period
------------------------------------------------------------------------*/

const setPeriod = (period) => {
    if (settingsState.period !== period) {
        settingsState.period = period;

        const currentDate = getCurrentDate();
        const endDate = formatDate(currentDate);

        switch (period) {
            case REPORT_PERIODS.DAY:
                settingsState.date = endDate;

                break;

            case REPORT_PERIODS.WEEK:
            case REPORT_PERIODS.MONTH: {
                const startDate = currentDate.subtract(1, period);

                settingsState.date = [formatDate(startDate), endDate];

                break;
            }

            case REPORT_PERIODS.CUSTOM:
                settingsState.date = [];

                break;
        }
    }
};

/*------------------------------------------------------------------------
                               Apply changes
------------------------------------------------------------------------*/

const getDateToApply = () => {
    const { date } = settingsState;

    if (isRangeEnabled.value) {
        return { startDate: date[0], endDate: date[1] };
    }

    return { startDate: date, endDate: date };
};

const applyChanges = () => {
    emit('update-date', getDateToApply());

    closeSettingsModal();
};

const isApplyBtnDisabled = computed(() => {
    const { initialStartDate, initialEndDate } = props;
    const { isModalOpened, date } = settingsState;

    if (!isModalOpened) {
        return true;
    }

    if (isRangeEnabled.value) {
        if (date.length === 0) {
            return true;
        }

        return date[0] === initialStartDate && date[1] === initialEndDate;
    }

    return date === initialStartDate && date === initialEndDate;
});
</script>

<template>
    <FilterSettingsUI
        class="pl-calendar-settings"
        :is-modal-opened="settingsState.isModalOpened"
        :is-apply-btn-disabled="isApplyBtnDisabled"
        @open="openSettingsModal"
        @close="closeSettingsModal"
        @apply-changes="applyChanges"
    >
        <template #invoker-content>
            <span v-if="selectedDatesDisplay.fullDate">{{ selectedDatesDisplay.fullDate }}</span>

            <CalendarIcon />
        </template>

        <template #settings="{ classNames }">
            <div :class="classNames.block">
                <div :class="classNames.title">
                    Show report for this
                </div>

                <div :class="classNames.options">
                    <button
                        v-for="period in REPORT_PERIODS"
                        :key="`setting period: ${period}`"
                        type="button"
                        :class="{
                            [classNames.option]: true,
                            [classNames.optionActive]: settingsState.period === period,
                        }"
                        @click="setPeriod(period)"
                    >
                        {{ period }}
                    </button>
                </div>
            </div>

            <div :class="classNames.title">
                Select your date range
            </div>

            <div class="pl-calendar-settings__selected-dates">
                <button
                    type="button"
                    @click="setDateToFocus(false)"
                >
                    <CalendarIcon
                        :height="22"
                        :width="22"
                    />

                    {{ selectedDatesDisplay.startDate }}
                </button>

                <button
                    type="button"
                    @click="setDateToFocus(true)"
                >
                    <CalendarIcon
                        :height="22"
                        :width="22"
                    />

                    {{ selectedDatesDisplay.endDate }}
                </button>
            </div>

            <DatePickerHOC
                :key="datePickerKeyToReRender"
                v-model="settingsState.date"
                inline
                focus-start-date
                class="dp__mode--range"
                :max-date="new Date()"
                :range="isRangeEnabled"
                :start-date="settingsState.dateToFocus"
                @range-start="onRangeStart"
            />
        </template>
    </FilterSettingsUI>
</template>

<style lang="scss" scoped>
.pl-calendar-settings {
    margin-left: auto;

    :deep(.pl-filter-settings__invoker) {
        background: $gray-400;
        color: $black;
        font-size: $font-size-base * 0.8;
        line-height: 1;
        font-weight: $font-weight-light;
        padding: custom-space(0.35) custom-space(0.6);
        gap: custom-space(0.75);
        border-radius: custom-space(0.4);

        svg path {
            fill: $black;
        }
    }

    &__selected-dates {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: custom-space(0.5);
        gap: custom-space(0.5);

        button {
            display: flex;
            align-items: center;
            min-height: 38px;
            flex: 1 1 50%;
            padding: 0 custom-space(0.75);
            text-align: left;
            font-weight: $font-weight-light;
            font-size: $font-size-base * 0.875;
            line-height: 1;
            color: $black;
            border: 1px solid $gray-400;
            border-radius: custom-space(1.5);

            svg {
                margin-right: custom-space(0.25);

                :deep(path) {
                    fill: $gray-500;
                }
            }
        }
    }
}
</style>
