<template>
    <div
        class="pl-phone-input"
        :class="dynamicRootClasses"
    >
        <div class="pl-phone-input-country-code">
            <SelectInput
                v-model="phoneData.phone_country_id"
                :options="countryOptions"
                :display-icon="false"
                :has-error="hasError"
                :disabled="disabled"
                data-test-id="country_code"
                @update:modelValue="$emit('country-changed', phoneData.phone_country_id)"
            >
                <template #selected-option-template="{ selectedOption }">
                    <!-- Selected option -->
                    <template v-if="selectedOption && selectedOption.name">
                        <div
                            class="pl-phone-input-option-country"
                            data-testid="country_flag_selected_parametrized"
                        >
                            <img
                                class="pl-phone-input-option-country-flag"
                                :src="selectedOption.flag_url"
                                :alt="selectedOption.shortCode"
                            />

                            <div class="pl-phone-input-option-country-code">
                                +{{ selectedOption.phone_code }}
                            </div>
                        </div>
                    </template>
                    <!-- / Selected option -->

                    <!-- Placeholder -->
                    <template v-else-if="defaultCountryOption">
                        <div
                            class="pl-phone-input-option-country"
                            data-testid="country_flag_selected_parametrized"
                        >
                            <img
                                class="pl-phone-input-option-country-flag"
                                :src="defaultCountryOption.flag_url"
                                :alt="defaultCountryOption.shortCode"
                            />

                            <div class="pl-phone-input-option-country-code">
                                +{{ defaultCountryOption.phone_code }}
                            </div>
                        </div>
                    </template>
                    <!-- / Placeholder -->
                </template>

                <!-- Options list item -->
                <template #options-list-item-template="{ option }">
                    <div
                        class="pl-phone-input-option-country"
                        data-testid="country_flag_selected_parametrized"
                    >
                        <img
                            class="pl-phone-input-option-country-flag"
                            :src="option.flag_url"
                            :alt="option.shortCode"
                        />

                        <div class="pl-phone-input-option-country-code">
                            (+{{ option.phone_code }}) {{ option.name }}
                        </div>
                    </div>
                </template>
                <!-- / Options list item -->
            </SelectInput>
        </div>

        <div
            class="pl-phone-input-number"
            :class="{ 'pl-form-control-input-wrapper': label }"
        >
            <input
                :id="id"
                ref="input"
                v-model="phoneData.phone"
                v-digits-only
                v-phone-spacing
                type="tel"
                inputmode="tel"
                autocomplete="tel-national"
                :autofocus="autofocus"
                name="phone"
                class="form-control"
                :class="{
                    'is-invalid': hasError,
                    'pl-form-control-input pl-form-control-input--phone': label,
                }"
                :placeholder="placeholder"
                :disabled="disabled"
                data-testid="phone_number_field"
                @focus="onFocus"
                @blur="onBlur"
                @input="$emit('update:modelValue', phoneData)"
            />

            <button
                v-if="sendButton && !hasError"
                type="button"
                class="p-2 pl-phone-input-number-send-btn"
                data-testid="phone_number_send_btn"
                @click="$emit('send')"
            >
                <PlaneIcon />
            </button>

            <label
                v-if="label"
                class="form-label"
                :class="{
                    'form-label--required': includeAsterisk,
                }"
            >
                {{ label }}
            </label>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import PlaneIcon from '@/components/icons/PlaneIcon';
import SelectInput from './SelectInput';

export default {
    name: 'PhoneInput',
    components: {
        PlaneIcon,
        SelectInput,
    },
    props: {
        modelValue: {
            type: Object,
            required: true,
            validator(object) {
                return 'phone' in object && 'phone_country_id' in object;
            },
        },
        id: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        hasError: {
            type: Boolean,
            default: false,
        },
        defaultCountryIso2: {
            type: String,
            default: 'US',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        sendButton: {
            type: Boolean,
            default: true,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        includeAsterisk: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue', 'country-changed', 'blur', 'send'],
    data() {
        return {
            phoneData: {
                phone_country_id: null,
                phone: null,
            },
            isFocused: false,
        };
    },

    computed: {
        ...mapState('countries', ['countries']),
        dynamicRootClasses() {
            return {
                'pl-phone-input--filled': !!this.modelValue.phone,
                'pl-phone-input--focused': this.isFocused,
                'pl-phone-input--has-label': this.label,
            };
        },
        countryOptions() {
            return (
                (this.countries &&
                    this.countries.map((country) => {
                        const { name, flag_url, id, phone_code, iso2 } = country;

                        return {
                            name,
                            phone_code,
                            flag_url,
                            value: id,
                            text: name,
                            shortCode: iso2,
                        };
                    })) ||
                []
            );
        },
        defaultCountryOption() {
            return this.countries?.find((country) => country.iso2 === this.defaultCountryIso2);
        },
    },

    watch: {
        countryOptions(options) {
            if (options.length && !this.modelValue.phone_country_id && this.defaultCountryOption) {
                this.chooseDefaultCountry();
            }
        },
        modelValue: {
            handler(newValue) {
                this.phoneData.phone_country_id = newValue?.phone_country_id;
                this.phoneData.phone = newValue?.phone;
            },
            immediate: true,
        },
    },

    created() {
        this.loadCountries();
    },
    mounted() {
        const event = document.createEvent('Event');
        event.initEvent('input');

        this.$refs.input.dispatchEvent(event);

        if (this.countryOptions.length && !this.modelValue.phone_country_id && this.defaultCountryOption) {
            this.chooseDefaultCountry();
        }
    },

    methods: {
        ...mapActions('countries', ['loadCountries']),
        chooseDefaultCountry() {
            this.phoneData.phone_country_id = this.defaultCountryOption.id;
        },
        onFocus() {
            this.isFocused = true;
        },
        onBlur($event) {
            this.isFocused = false;
            this.$emit('blur', $event);
        },
    },
};
</script>

<style scoped lang="scss">
.pl-phone-input {
    display: flex;
    position: relative;

    * {
        word-break: initial;
    }

    &-number {
        position: relative;
        z-index: 11;
        flex-grow: 1;

        > .form-control {
            height: 100%;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            padding-right: $spacer * 2.6;
        }

        > .form-label {
            position: absolute;
            top: $input-border-width;
            left: 0;
            width: 100%;
            text-align: left;
            line-height: $input-font-size * $input-line-height + $input-padding-y * 2;
            pointer-events: none;
            padding-left: $input-padding-x;
            padding-right: rem(45px);
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            @include pl-transition(all, true);

            &--required::after {
                content: '*';
                position: absolute;
                top: 50%;
                color: $red-light;
                font-size: $font-size-base;
                line-height: $font-size-base * 0.75;
                padding-left: custom-space(0.1);
                transform: translateY(-50%);
            }
        }

        &-send-btn {
            position: absolute;
            top: 50%;
            right: $spacer * 0.625;
            transform: translate3d(0, -50%, 0);
        }
    }

    &--has-label {
        .pl-phone-input-number {
            > .form-control {
                padding-top: $input-padding-y * 1.4;
                padding-bottom: $input-padding-y * 0.6;
            }

            > .form-label {
                font-size: rem(12px);
                transform: translate3d(0, -22%, 0);
            }
        }
    }

    :deep(.pl-select) {
        height: 100%;
        position: static;

        &.pl-select--selected:not(.pl-select--invalid) .pl-select-selected-option {
            border-color: $input-border-color;
        }
    }

    &--filled,
    &--focused {
        .pl-phone-input-number > .form-control:not(.is-invalid),
        :deep(.pl-select:not(.pl-select--invalid)) .pl-select-selected-option,
        :deep(.pl-select.pl-select--selected:not(.pl-select--invalid)) .pl-select-selected-option {
            border-color: $input-focus-border-color;
        }
    }

    :deep(.pl-select-selected-option) {
        padding-right: $input-padding-x * 0.7;
        padding-left: $input-padding-x;
        border-right-width: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        display: flex;
        flex-direction: column;

        &.is-invalid {
            background-image: none !important;
        }

        .pl-phone-input-option-country {
            flex-grow: 1;
            font-weight: $font-weight-light;
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    :deep(.was-validated) .form-select:invalid:not([multiple]):not([size]),
    :deep(.was-validated) .form-select:invalid:not([multiple])[size='1'],
    :deep(.form-select.is-invalid:not([multiple]):not([size])),
    :deep(.form-select.is-invalid:not([multiple])[size='1']) {
        padding-right: custom-space(1.375);
    }
}

.pl-phone-input-option-country {
    display: flex;
    align-items: center;

    &-flag {
        display: block;
        width: $spacer * 1.5;
        height: $spacer * 1.0625;
        margin-right: $spacer * 0.35;
    }
}
</style>