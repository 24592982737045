<script>
export default {
    name: 'SelectableItemQtyCard',
};
</script>

<script setup>
import { computed, ref } from 'vue';

import { cutFloatNumber } from '@/utils/numberUtils';
import { getBatchUnitQtyAmount } from '@/utils/batchUnitsUtils';
import { getOrderItemBatchUnitsDisplay } from '@/utils/orderItemUtils';
import { ALERT_TYPES, BADGE_TYPES } from '@/enums/componentsEnums';
import { SELECTABLE_ITEM_STATUSES } from '@/enums/selectableItemsEnums';
import Alert from '@/components/Alert';
import Badge from '@/components/Badge';
import QtyInput from '@/components/form-controls/QtyInput';

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    isTemplate: {
        type: Boolean,
        default: false,
    },
    isQuickFill: {
        type: Boolean,
        default: false,
    },
    statuses: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['update-qty']);

/*------------------------------------------------------------------------
                                Item state
------------------------------------------------------------------------*/

const itemOrderingUnit = computed(() => {
    const { units_data: unitsData } = props.item;

    return props.isQuickFill ? unitsData.quick_fill_ordering_unit : unitsData.prep_list_ordering_unit;
});

const itemBatchUnitsDisplay = computed(() => {
    const { units_data: unitsData, qty } = props.item;

    return getOrderItemBatchUnitsDisplay(unitsData.batch_yield_unit, itemOrderingUnit.value, unitsData, qty, true);
});

const isItemFooterVisible = computed(() => {
    const { item, isQuickFill, isTemplate, statuses } = props;

    return (
        statuses[SELECTABLE_ITEM_STATUSES.IS_BEING_SAVED] ||
        statuses[SELECTABLE_ITEM_STATUSES.HAS_BEEN_JUST_SAVED] ||
        (!isQuickFill && !item.has_available_assignees) ||
        (!isQuickFill && item.has_prep_tasks_out_of_time) ||
        (!isTemplate && !!item.par_default_qty)
    );
});

/*------------------------------------------------------------------------
                                    Qty
------------------------------------------------------------------------*/

const isQtyTypeOnHand = ref(!props.isTemplate && !!props.item.par_default_qty);

const qtyTypeOptions = [
    { value: true, text: 'On hand' },
    { value: false, text: 'To order' },
];

const onQtyUpdated = (...args) => emit('update-qty', props.item, isQtyTypeOnHand.value, ...args);

const onQtySaved = (isQtyOnHand) => {
    if (isQtyOnHand) {
        isQtyTypeOnHand.value = false;
    }
};

const getOrderingQtyAmount = (qty) => {
    const { units_data: unitsData } = props.item;

    return getBatchUnitQtyAmount(unitsData, unitsData.batch_yield_unit, itemOrderingUnit.value, qty ?? 0);
};

const itemQty = computed(() => getOrderingQtyAmount(props.item.qty));
const itemOnHandQty = computed(() => getOrderingQtyAmount(props.item.par_on_hand_qty));
</script>

<template>
    <article
        class="pl-selectable-item-qty-card"
        :class="{
            'pl-selectable-item-qty-card--added': item.is_overlaid,
        }"
    >
        <Badge
            v-if="!isQuickFill && item.prep_tasks_count > 0"
            is-absolute
            size="sm"
            :type="BADGE_TYPES.ORDER_CARD"
        >
            Prep Tasks ({{ item.prep_tasks_count }})
        </Badge>

        <div class="pl-selectable-item-qty-card__wrapper">
            <div class="pl-selectable-item-qty-card__content">
                <b>{{ item.category }}</b>

                <h6>{{ item.name }}</h6>

                <span>{{ itemBatchUnitsDisplay }}</span>
            </div>

            <div class="pl-selectable-item-qty-card__controls">
                <div
                    v-if="!isTemplate && !!item.par_default_qty"
                    class="pl-switch-btn"
                >
                    <button
                        v-for="{ value, text } in qtyTypeOptions"
                        :key="`qty type option: ${text}`"
                        type="button"
                        class="pl-switch-btn__option"
                        :class="{
                            'pl-switch-btn__option--active': isQtyTypeOnHand === value,
                        }"
                        @click="isQtyTypeOnHand = value"
                    >
                        {{ text }}
                    </button>
                </div>

                <QtyInput
                    custom-on-success-handling
                    ask-for-saving-changes-on-blur
                    is-increment-mode
                    :min="0"
                    :max="9999"
                    :model-value="isQtyTypeOnHand ? itemOnHandQty : itemQty"
                    :loading="statuses[SELECTABLE_ITEM_STATUSES.IS_CONTROL_LOADING]"
                    :disabled="statuses[SELECTABLE_ITEM_STATUSES.IS_CONTROL_LOADING]"
                    @on-success="onQtySaved"
                    @update:modelValue="onQtyUpdated"
                />

                <small>({{ itemOrderingUnit?.value ?? 'batches' }})</small>
            </div>
        </div>

        <footer v-if="isItemFooterVisible">
            <transition
                appear
                name="scale"
                type="transition"
                mode="out-in"
            >
                <span
                    v-if="statuses[SELECTABLE_ITEM_STATUSES.IS_BEING_SAVED]"
                    :key="`saving caption: ${item.prototype_id}`"
                >
                    Saving...
                </span>

                <span
                    v-else-if="statuses[SELECTABLE_ITEM_STATUSES.HAS_BEEN_JUST_SAVED]"
                    :key="`saved caption: ${item.prototype_id}`"
                >
                    Saved!
                </span>
            </transition>

            <div
                v-if="!isTemplate && !!item.par_default_qty"
                class="pl-selectable-item-qty-card__statuses"
            >
                <small>
                    Required:

                    <b>{{ cutFloatNumber(getOrderingQtyAmount(item.par_default_qty)) }}</b>
                </small>

                <small>
                    On hand:

                    <b>{{ itemOnHandQty }}</b>
                </small>

                <small>
                    To order:

                    <b>{{ cutFloatNumber(itemQty) }}</b>
                </small>
            </div>

            <template v-if="!isQuickFill">
                <Alert
                    v-if="!item.has_available_assignees"
                    :type="ALERT_TYPES.WARNING"
                >
                    There are no available assignees.
                </Alert>

                <Alert
                    v-else-if="item.has_prep_tasks_out_of_time"
                    :type="ALERT_TYPES.WARNING"
                >
                    Pay attention that this item contains some prep tasks with a time delay.
                </Alert>
            </template>
        </footer>
    </article>
</template>

<style lang="scss" scoped>
.pl-selectable-item-qty-card {
    position: relative;
    background-color: $white;
    margin-bottom: custom-space(0.5);
    padding: custom-space(0.5) custom-space(0.75);
    border-radius: $border-radius;
    border: 1px solid $gray-200;
    @include pl-transition((background-color, border-color));

    > .pl-badge.pl-badge--absolute {
        left: 50%;
        transform: translateX(-50%);
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: custom-space(1);
    }

    &__content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;

        > b {
            font-weight: $font-weight-normal;
            line-height: 1.25;
        }

        > h6 {
            margin: custom-space(0.75) 0 0 0;
            color: $primary;
        }

        > b,
        > span {
            font-size: $font-size-base * 0.85;
        }
    }

    &__controls {
        flex: 0 0 custom-space(7);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: custom-space(0.375) 0;

        .pl-switch-btn {
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: custom-space(0.75);
            border: 1px solid $primary;
            border-radius: custom-space(0.25);

            &__option {
                flex: 1 1 50%;
                padding: custom-space(0.25);
                text-align: center;
                font-size: $font-size-base * 0.7;
                line-height: 1;

                &--active {
                    color: $white;
                    background-color: $primary;
                }
            }
        }

        :deep(.pl-qty-input) {
            margin-bottom: custom-space(0.25);
        }
    }

    > footer {
        margin-top: custom-space(0.25);

        > span {
            font-weight: $font-weight-normal;
            font-size: $font-size-base * 0.85;
        }

        :deep(.pl-alert) {
            margin-top: custom-space(0.5);
        }
    }

    &__statuses {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid $gray-200;
        padding-top: custom-space(0.5);
    }

    &--added {
        background-color: $yellow-light;
    }
}
</style>
