<script>
export default {
    name: 'TimelineMainCook',
};
</script>

<script setup>
import { computed, reactive, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { catchUnprocessableEntity } from '@/utils/httpUtils';
import { isToday } from '@/utils/dateUtils';
import { convertArbitraryListToOptions } from '@/utils/timelineUtils';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import {
    TIMELINE_VIEW_MODES,
    TIMELINE_ARBITRARY_REPORT_TYPES,
    TIMELINE_ARBITRARY_REPORT_TYPE_OPTIONS,
} from '@/enums/timelineEnums';
import ItemModel from '@/models/Item';
import SelectInput from '@/components/form-controls/SelectInput';
import TimelineViewTemplate from '../../../partials/timeline-view-template';
import TimelineCookItemCard from './partials/TimelineCookItemCard';
import ReportItemFormModal from './partials/ReportItemFormModal';

const route = useRoute();
const store = useStore();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                             Timeline settings
------------------------------------------------------------------------*/

const factory = 'cook_by_item';

const initialFiltersConfig = {
    viewmode: null,
};

const freshTimeline = ref(null);
const selectedOrderList = ref([]);

const setSelectedOrderList = (orderList) => (selectedOrderList.value = orderList);

/*------------------------------------------------------------------------
                            Report item form state
------------------------------------------------------------------------*/

const reportItemFormState = reactive({
    item: null,
    validationErrors: null,
    isModalOpened: false,
});

const loadingItemsState = reactive({
    ids: new Map(),
    count: 0,
});

const openReportItemFormModal = (item, initCompleteItem = false) => {
    reportItemFormState.item = item;

    if (initCompleteItem) {
        reportItem(item.ordered_BY_qty - item.produced_BY_qty);
    } else {
        reportItemFormState.isModalOpened = true;
    }
};

const closeReportItemFormModal = () => {
    reportItemFormState.isModalOpened = false;
    reportItemFormState.item = null;
};

const reportItem = (qty) => {
    const { item } = reportItemFormState;

    const payload = {
        qty,
        assignee_id: item.assignee_id,
        assignee_type: item.assignee_type,
        date: route.query.date,
    };

    if (item.status === 'open_item') {
        payload.is_open_item = true;
    }

    if (!reportItemFormState.isModalOpened) {
        loadingItemsState.ids.set(item.id, true);
    }

    loadingItemsState.count++;

    ItemModel.report(orgId, item.prototype_id, payload, { factory })
        .then((model) => {
            loadingItemsState.count--;

            if (loadingItemsState.count === 0) {
                closeReportItemFormModal();

                freshTimeline.value = model;

                EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                    type: ALERT_TYPES.SUCCESS,
                    message: 'Batches have been successfully reported',
                });
            }
        })
        .catch((error) =>
            catchUnprocessableEntity(error, (errors) => {
                reportItemFormState.validationErrors = errors;

                openReportArbitraryItem(item);
            })
        )
        .finally(() => {
            if (loadingItemsState.count === 0) {
                loadingItemsState.ids.clear();
            }
        });
};

const isReportingDisabled = computed(() => !isToday(route.query.date));

/*------------------------------------------------------------------------
                          Arbitrary report state
------------------------------------------------------------------------*/

const arbitraryReportState = reactive({
    type: TIMELINE_ARBITRARY_REPORT_TYPES.ALL,
    id: null,
});

const arbitraryTypeOptions = computed(() => {
    const { items_to_order: itemsToOrder = {} } = selectedOrderList.value;

    if (isReportingDisabled.value || Object.keys(itemsToOrder).length === 0) {
        return [];
    }

    return Object.entries(TIMELINE_ARBITRARY_REPORT_TYPE_OPTIONS).reduce((acc, [type, text]) => {
        if (Object.keys(itemsToOrder[type] || {}).length > 0 || type === TIMELINE_ARBITRARY_REPORT_TYPES.ALL) {
            acc.push({ value: type, text });
        }

        return acc;
    }, []);
});

const arbitraryItemOptions = computed(() => {
    const { items_to_order: itemsToOrder = {} } = selectedOrderList.value;

    if (isReportingDisabled.value || !itemsToOrder) {
        return [];
    }

    const { type } = arbitraryReportState;

    if (type === TIMELINE_ARBITRARY_REPORT_TYPES.ALL) {
        return Object.values(TIMELINE_ARBITRARY_REPORT_TYPES).flatMap((value) =>
            convertArbitraryListToOptions(itemsToOrder[value])
        );
    }

    return convertArbitraryListToOptions(itemsToOrder[type]);
});

const arbitrarySelectLabel = computed(() => {
    if (arbitraryReportState.type === TIMELINE_ARBITRARY_REPORT_TYPES.ALL) {
        return 'Select to report';
    }

    const entity = TIMELINE_ARBITRARY_REPORT_TYPE_OPTIONS[arbitraryReportState.type];

    return `Select ${entity} to report`;
});

const openReportArbitraryItem = () => {
    const { text, value } = arbitraryItemOptions.value.find((option) => arbitraryReportState.id === option.value);

    const payload = {
        assignee_type: 'account',
        name: text,
        prototype_id: value,
        assignee_id: currentAccount.id,
    };

    openReportItemFormModal(payload);

    arbitraryReportState.id = null;
};

watch(
    () => arbitraryReportState.type,
    () => (arbitraryReportState.id = null)
);

watch(
    () => arbitraryTypeOptions.value,
    (arbitraryTypes) => {
        if (!arbitraryTypes.some((type) => arbitraryReportState.type === type)) {
            arbitraryReportState.type = arbitraryTypes[0]?.value || null;
        }
    }
);
</script>

<template>
    <TimelineViewTemplate
        include-tabs
        regular-order-list-key="ordered"
        other-order-list-key="other"
        :view-mode="TIMELINE_VIEW_MODES.MAIN"
        :factory="factory"
        :initial-filters-config="initialFiltersConfig"
        :fresh-timeline="freshTimeline"
        @set-selected-order-list="setSelectedOrderList"
    >
        <template #order-list-item="{ item: orderItem }">
            <TimelineCookItemCard
                :order-item="orderItem"
                :is-reporting-disabled="isReportingDisabled || loadingItemsState.count > 0"
                :is-loading="loadingItemsState.ids.has(orderItem.id)"
                @init-report-item="openReportItemFormModal"
            />
        </template>

        <template
            v-if="arbitraryTypeOptions.length > 0"
            #arbitrary-section
        >
            <div class="pl-arbitrary-section">
                <small>Report any item or prep task not mentioned above</small>

                <div class="pl-arbitrary-section__wrapper">
                    <div class="pl-arbitrary-section__types">
                        <button
                            v-for="{ value, text } in arbitraryTypeOptions"
                            :key="`arbitrary type: ${value}`"
                            type="button"
                            class="pl-arbitrary-section__type-option"
                            :class="{
                                'pl-arbitrary-section__type-option--active': arbitraryReportState.type === value,
                            }"
                            @click="arbitraryReportState.type = value"
                        >
                            {{ text }}
                        </button>
                    </div>

                    <SelectInput
                        :key="`arbitrary select input: ${arbitraryReportState.type}`"
                        v-model="arbitraryReportState.id"
                        enable-group-mode
                        searchable
                        data-test-id="arbitrary_report_select"
                        :options="arbitraryItemOptions"
                        :label="arbitrarySelectLabel"
                    />

                    <BtnUI
                        size="sm"
                        :disabled="arbitraryReportState.id === null"
                        @click="openReportArbitraryItem"
                    >
                        Report
                    </BtnUI>
                </div>
            </div>
        </template>

        <template
            v-if="reportItemFormState.isModalOpened"
            #modal
        >
            <ReportItemFormModal
                :organization-id="orgId"
                :item="reportItemFormState.item"
                :validation-errors="reportItemFormState.validationErrors"
                @close-modal="closeReportItemFormModal"
                @submit="reportItem"
            />
        </template>
    </TimelineViewTemplate>
</template>

<style lang="scss" scoped>
.pl-arbitrary-section {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;

    & > small {
        color: $gray-800;
        text-align: center;
        margin-bottom: custom-space(1);
    }

    &__wrapper {
        display: flex;
        flex-direction: column;

        & > button {
            align-self: flex-end;
        }
    }

    &__types {
        display: flex;
        align-items: center;
        gap: custom-space(0.25);
    }

    &__type-option {
        background-color: $gray-400;
        padding: custom-space(0.2) custom-space(1);
        text-transform: capitalize;
        font-size: $font-size-base * 0.875;
        font-weight: $font-weight-normal;
        color: $black;
        border-radius: custom-space(2);

        &--active {
            background-color: $primary;
            color: $white;
        }
    }

    :deep(.pl-select) {
        max-width: 100%;
        margin: custom-space(0.3) 0;
    }
}

:deep(.pl-timeline-view__order-list--space) {
    padding-bottom: custom-space(1);
}

:deep(.pl-overlay-spinner) {
    border-radius: $border-radius;
}
</style>
