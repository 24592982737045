import { reactive } from 'vue';

import { isObject } from '@/utils/objectUtils';

const useForm = (initialFormState, validationRules = {}, validationNames = {}) => {
    /*------------------------------------------------------------------------
                                 Validation state
    ------------------------------------------------------------------------*/

    const validationState = reactive({
        rules: validationRules,
        names: {
            email: 'Email address',
            phone: 'Phone number',
            qty: 'Quantity',
            ...validationNames,
        },
        errors: new Map(),
    });

    const hasErrors = (fields) => {
        if (Array.isArray(fields)) {
            return fields.some(hasErrors);
        }

        return validationState.errors.has(fields);
    };

    const getErrors = (fields) => {
        if (Array.isArray(fields)) {
            for (const field of fields) {
                if (hasErrors(field)) {
                    return getErrors(field);
                }
            }
        }

        return validationState.errors.get(fields);
    };

    const setErrors = (field, errors) => {
        const fieldErrors = validationState.errors.get(field) || [];

        validationState.errors.set(field, [...fieldErrors, ...(Array.isArray(errors) ? errors : [errors])]);
    };

    const clearErrors = (fields) => {
        if (Array.isArray(fields)) {
            fields.forEach(clearErrors);
        } else if (validationState.errors.has(fields)) {
            validationState.errors.delete(fields);
        }
    };

    const clearAllErrors = () => validationState.errors.clear();

    const setValidationErrors = (errors) => {
        Object.entries(errors || []).forEach(([key, value]) => {
            validationState.errors.set(key, value);
        });
    };

    const reinitializeValidationRules = (rules) => (validationState.rules = rules);

    /*------------------------------------------------------------------------
                                    Form state
    ------------------------------------------------------------------------*/

    const formState = reactive({ ...initialFormState });

    const reinitializeFormState = (newFormState) => {
        const targetFields = new Set(Object.keys(newFormState));

        Object.keys(formState).forEach((key) => {
            if (!targetFields.has(key)) {
                delete formState[key];
            }
        });

        Object.entries(newFormState).forEach(([key, value]) => {
            formState[key] = value;
        });
    };

    const validateField = (field) => {
        clearErrors(field);

        const fieldState = formState[field];
        const fieldValidation = validationState.rules[field];
        const fieldValidationName = validationState.names[field];

        if (fieldValidation === undefined) {
            return true;
        }

        for (const key in fieldValidation) {
            const validationStatus = fieldValidation[key]({ state: formState, value: fieldState }, fieldValidationName);

            if (validationStatus !== null) {
                setErrors(field, validationStatus);

                return false;
            }
        }

        return true;
    };

    const validateForm = () => {
        let isValidated = true;

        for (const key in formState) {
            if (!validateField(key)) {
                isValidated = false;
            }
        }

        return isValidated;
    };

    const validate = (field = null) => (field === null ? validateForm() : validateField(field));

    const isFormFilled = (payload, rules) => {
        for (const field in payload) {
            const fieldValue = payload[field];
            const fieldRules = rules[field];

            if (isObject(fieldValue) && !isFormFilled(fieldValue, fieldRules)) {
                return false;
            }

            if (fieldRules === undefined) {
                continue;
            }

            const requiredRules = [
                fieldRules.required,
                fieldRules.requiredField,
                fieldRules.requiredIf,
                fieldRules.requiredWith,
            ];

            for (const rule of requiredRules) {
                if (rule && rule({ state: formState, value: fieldValue }, null) !== null) {
                    return false;
                }
            }
        }

        return true;
    };

    const isDisabled = () => validationState.errors.size > 0 || !isFormFilled(formState, validationState.rules);

    return {
        hasErrors,
        getErrors,
        setErrors,
        clearErrors,
        clearAllErrors,
        setValidationErrors,
        reinitializeValidationRules,

        formState,
        reinitializeFormState,
        validate,
        isDisabled,
    };
};

export default useForm;
