<script>
export default {
    name: 'ReportSummaryCard',
};
</script>

<script setup>
import { ref, computed } from 'vue';

import { getBatchYieldUnitQtyDisplay } from '@/utils/batchUnitsUtils';
import { ITEM_TYPES } from '@/enums/itemEnums';
import ItemSummary from '@/components/model-specific/item/ItemSummary';

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    includePrepTasks: {
        type: Boolean,
        default: false,
    },
});

/*------------------------------------------------------------------------
                              Prep task state
------------------------------------------------------------------------*/

const arePrepTasksVisible = ref(false);

const togglePrepTasksVisibility = () => (arePrepTasksVisible.value = !arePrepTasksVisible.value);

/*------------------------------------------------------------------------
                                Item state
------------------------------------------------------------------------*/

const itemState = computed(() => {
    const {
        type,
        units_data: unitsData,
        produced_BY_qty: producedBYQty,
        display_orders_count: ordersCount,
    } = props.item;

    return {
        label: type === ITEM_TYPES.PREP_ACTION ? 'No prep action' : `Orders: ${ordersCount}`,
        producedQtyDisplay: getBatchYieldUnitQtyDisplay(
            unitsData,
            unitsData.batch_yield_unit,
            producedBYQty,
            true,
            type === ITEM_TYPES.PREP_ACTION ? 'hours' : 'batches'
        ),
    };
});
</script>

<template>
    <article class="pl-report-summary">
        <div class="pl-report-summary__wrapper">
            <b>
                {{ itemState.label }}
            </b>

            <span>
                {{ itemState.producedQtyDisplay }}
            </span>
        </div>

        <ItemSummary
            :labor-time="item.display_labor_time"
            :food-cost="item.display_food_cost"
            :labor-cost="item.display_labor_cost"
            :total-cost="item.display_total_cost"
            :item-type="item.type"
        />

        <div
            v-if="includePrepTasks && item.prep_tasks.length > 0"
            class="pl-report-summary__prep-tasks"
            :class="{
                'pl-report-summary__prep-tasks--opened': arePrepTasksVisible,
            }"
        >
            <button
                type="button"
                @click="togglePrepTasksVisibility"
            >
                <span>View prep tasks ({{ item.prep_tasks.length }})</span>

                <CaretDownIcon
                    :width="8"
                    :height="6"
                />
            </button>

            <ul v-if="arePrepTasksVisible">
                <li
                    v-for="prepTask in item.prep_tasks"
                    :key="`prep task: ${prepTask.id}`"
                >
                    <b>{{ prepTask.name }}</b>

                    <span>{{ `${prepTask.produced_batches_display} / ${prepTask.produced_BY_display}` }}</span>
                </li>
            </ul>
        </div>
    </article>
</template>

<style lang="scss">
.pl-report-summary {
    margin-bottom: custom-space(0.5);
    padding-bottom: custom-space(0.5);

    &__wrapper {
        display: flex;
        align-items: flex-end;
        margin-top: custom-space(0.125);
    }

    &__wrapper > b,
    &__prep-tasks ul li b {
        flex-grow: 1;
        font-size: $font-size-base * 0.875;
        font-weight: $font-weight-normal;
    }

    &__wrapper > span,
    &__prep-tasks ul li span {
        flex-shrink: 0;
        text-align: right;
        font-size: $font-size-base * 0.75;
        font-weight: $font-weight-light;
        padding-left: custom-space(0.5);
    }

    &__prep-tasks {
        display: flex;
        flex-direction: column;
        margin-top: custom-space(0.5);
        margin-bottom: custom-space(0.25);

        & > button {
            display: flex;
            align-items: center;

            span {
                color: $primary;
                text-transform: uppercase;
                font-size: $font-size-base * 0.85;
                line-height: $font-size-base * 1.2;
                border-bottom: 1px solid $primary;
            }

            svg {
                fill: $primary;
                margin-left: custom-space(0.5);
            }
        }

        & > ul {
            display: flex;
            flex-direction: column;
            gap: custom-space(0.25);
            list-style: none;
            padding: 0;
            margin: custom-space(0.75) 0 0 0;

            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        &--opened > button svg {
            transform: rotate(180deg);
        }
    }
}
</style>
