<script>
export default {
    name: 'TimelineView',
};
</script>

<script setup>
import { computed, inject, onBeforeUnmount, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

import Echo from '@/plugins/Echo';
import { isCook } from '@/utils/accountUtils';
import { mapGetters } from '@/utils/vuexUtils';
import { isOrderEditing } from '@/utils/orderUtils';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import { QFO_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { QFO_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { QF_FILTER_ORDERS_BY, QF_DEFAULT_FILTER_ORDERS_BY } from '@/enums/timelineEnums';
import useTaskReminderRibbon from '@/composition/useTaskReminderRibbon';
import useTimeline from '@/composition/useTimeline';
import TaskReminderRibbon from '@/components/TaskReminderRibbon';
import Alert from '@/components/Alert';
import Fab from '@/components/Fab';
import DropDown from '@/components/DropDown';
import DatePickerHOC from '@/components/HOC/DatePickerHOC';
import ListDisplay from '@/components/ListDisplay';
import TimelineOrderCard from '@/components/model-specific/orders/TimelineOrderCard';
import TimelineDatesSlider from '@/components/model-specific/orders/TimelineDatesSlider';
import UnfinishedOrderRibbon from '@/components/model-specific/orders/UnfinishedOrderRibbon';
import GroupedSettings from '@/components/constructions/grouped-settings';
import FilterIcon from '@/components/icons/FilterIcon';

const $acl = inject('$acl');

const store = useStore();

const { currentQFOrder } = mapGetters(['currentQFOrder']);

const { isTaskReminderVisible } = useTaskReminderRibbon(currentQFOrder.value === null);

const { currentAccount } = store.state.auth;

/*------------------------------------------------------------------------
                                 Timeline
------------------------------------------------------------------------*/

const {
    timelineState,
    selectedOrderList,

    loadTimeline,
    loadTouchedDatesTimeline,
    setTimeline,
    timelineDateChanged,
    calendarDateChanged,
} = useTimeline(null);

const factory = computed(() => {
    if (isCook(currentAccount)) {
        return QF_FILTER_ORDERS_BY.ALL;
    }

    return store.state.QFOrderViewSettings.filterBy;
});

const unfinishedOrderLink = computed(() => {
    const order = currentQFOrder.value;

    if (isOrderEditing(order)) {
        return {
            name: QFO_ROUTE_NAMES.EDIT.INDEX,
            params: { orderId: order.parent_id },
        };
    }

    return { name: QFO_ROUTE_NAMES.CREATE.INDEX };
});

/*------------------------------------------------------------------------
                              Filter items by
------------------------------------------------------------------------*/

const filterOrderByOptions = computed(() => {
    const { name } = currentAccount.location;

    return {
        [QF_FILTER_ORDERS_BY.ALL]: 'All',
        [QF_FILTER_ORDERS_BY.CREATED_BY_ME]: 'Orders I created',
        [QF_FILTER_ORDERS_BY.LOCATION]: `Produced in ${name}`,
        [QF_FILTER_ORDERS_BY.DESTINATION]: `Shipped to ${name}`,
    };
});

const isOptionFilterBySelected = (optionValue) => store.state.QFOrderViewSettings.filterBy === optionValue;

const setOptionFilterBy = (optionValue) => store.commit('QFOrderViewSettings/setFilterBy', optionValue);

/*------------------------------------------------------------------------
                               Load timeline
------------------------------------------------------------------------*/

watch(
    () => store.state.QFOrderViewSettings.filterBy,
    () => {
        timelineState.data = {};
        timelineState.isDataLoading = true;

        loadTimeline(factory.value).then(setTimeline);
    },
    { deep: true, immediate: true }
);

/*------------------------------------------------------------------------
                              Echo listeners
------------------------------------------------------------------------*/

onMounted(() => {
    const channelName = `timeline.quick-fill.location.${currentAccount.location.id}`;

    const callback = (data) =>
        loadTouchedDatesTimeline(factory.value, data.touched_dates || data.quick_fill_orders_touched_dates || []);

    Echo.private(channelName)
        .listen('.quick-fill-orders.created', callback)
        .listen('.quick-fill-orders.deleted', callback)
        .listen('.quick-fill-orders.draft-created', callback)
        .listen('.quick-fill-orders.draft-canceled', callback)
        .listen('.quick-fill-orders.draft-completed', callback)
        .listen('.item.deleted', callback);

    onBeforeUnmount(() => Echo.leave(channelName));
});
</script>

<template>
    <TopBarLayout exclude-content-container>
        <template #title>
            <h1>Quick Fill Orders</h1>
        </template>

        <template #actions>
            <DatePickerHOC
                v-model="timelineState.selectedDate"
                menu-class-name="pl-timeline-view-calendar"
                @update:modelValue="calendarDateChanged(factory)"
            />

            <DropDown v-if="!isCook(currentAccount)">
                <template #content>
                    <GroupedSettings.Root class="pl-qf-timeline__grouped-settings">
                        <GroupedSettings.List title="Show">
                            <GroupedSettings.ListItem
                                v-for="(text, value) in filterOrderByOptions"
                                :key="`filter-order-by-option-${value}`"
                                :is-selected="isOptionFilterBySelected(value)"
                                @click="setOptionFilterBy(value)"
                            >
                                {{ text }}
                            </GroupedSettings.ListItem>
                        </GroupedSettings.List>

                        <GroupedSettings.List title="Orders">
                            <GroupedSettings.ListItem>
                                <router-link
                                    :to="{
                                        name: QFO_ROUTE_NAMES.CREATE.INDEX,
                                        query: { action: 'new-order' },
                                    }"
                                >
                                    Create new order
                                </router-link>
                            </GroupedSettings.ListItem>

                            <GroupedSettings.ListItem>
                                <router-link :to="{ name: QFO_ROUTE_NAMES.CREATE_FROM_TEMPLATE }">
                                    Create new PAR-guided order
                                </router-link>
                            </GroupedSettings.ListItem>
                        </GroupedSettings.List>
                    </GroupedSettings.Root>
                </template>
            </DropDown>
        </template>

        <template
            v-if="currentQFOrder !== null || isTaskReminderVisible"
            #ribbon
        >
            <UnfinishedOrderRibbon
                v-if="currentQFOrder !== null"
                :navigate-to="unfinishedOrderLink"
            />

            <TaskReminderRibbon v-else />
        </template>

        <div
            v-if="timelineState.hasError"
            class="container"
        >
            <Alert :type="ALERT_TYPES.FAIL">
                Order list could not be loaded for some reason. Please try again later.
            </Alert>
        </div>

        <template v-else>
            <TimelineDatesSlider
                v-model="timelineState.selectedDate"
                @slide-start="timelineState.hasBeenJustUpdated = true"
                @update:modelValue="timelineDateChanged(factory)"
            />

            <div class="container">
                <div
                    v-if="factory !== QF_DEFAULT_FILTER_ORDERS_BY"
                    class="pl-qf-timeline__active-filter"
                >
                    <p>
                        <FilterIcon />

                        <span>
                            {{ filterOrderByOptions[factory] }}
                        </span>
                    </p>

                    <button
                        type="button"
                        @click="setOptionFilterBy(QF_DEFAULT_FILTER_ORDERS_BY)"
                    >
                        <FilterIcon />

                        <span>
                            <CrossIcon />
                        </span>
                    </button>
                </div>

                <InPlaceLoader v-if="timelineState.isDataLoading" />

                <template v-else>
                    <transition
                        appear
                        name="down"
                        type="transition"
                        mode="out-in"
                    >
                        <template v-if="!timelineState.hasBeenJustUpdated">
                            <ListDisplay
                                v-if="selectedOrderList?.length > 0"
                                key="orders_list"
                                animation-data-key="id"
                                class="pl-qf-timeline__order-list"
                                data-testid="orders_list"
                                :items="selectedOrderList"
                            >
                                <template #item="{ item: order }">
                                    <TimelineOrderCard
                                        is-quick-fill
                                        :order="order"
                                    />
                                </template>
                            </ListDisplay>

                            <p
                                v-else
                                key="no_orders_message"
                                class="pl-qf-timeline__message"
                            >
                                There are no orders for the selected prep date.
                            </p>
                        </template>
                    </transition>
                </template>
            </div>

            <Fab
                v-if="$acl.can(QFO_RESTRICTIONS.CREATE, currentAccount)"
                data-testid="create_new_qfo_fab_btn"
            >
                <DropDown pos-y="top">
                    <template #invoker>
                        <PlusIcon />
                    </template>

                    <template #content>
                        <GroupedSettings.Root>
                            <GroupedSettings.List>
                                <GroupedSettings.ListItem>
                                    <router-link
                                        :to="{
                                            name: QFO_ROUTE_NAMES.CREATE.INDEX,
                                            query: { action: 'new-order' },
                                        }"
                                    >
                                        New Order
                                    </router-link>
                                </GroupedSettings.ListItem>

                                <GroupedSettings.ListItem>
                                    <router-link :to="{ name: QFO_ROUTE_NAMES.CREATE_FROM_TEMPLATE }">
                                        New PAR-Guided Order
                                    </router-link>
                                </GroupedSettings.ListItem>
                            </GroupedSettings.List>
                        </GroupedSettings.Root>
                    </template>
                </DropDown>
            </Fab>
        </template>
    </TopBarLayout>
</template>

<style lang="scss" scoped>
.pl-qf-timeline {
    &__active-filter {
        $space: $grid-gutter-width * 0.5;

        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: custom-space(0.5);
        background-color: $orange-300;
        margin-top: custom-space(-0.5);
        margin-bottom: custom-space(0.5);
        padding: custom-space(0.3) $space;
        border-radius: 0 0 $border-radius $border-radius;

        @include media-breakpoint-down(lg) {
            padding: custom-space(0.3) 0;
            border-radius: 0;
            box-shadow: $space 0 0 $orange-300, -$space 0 0 $orange-300;
        }

        & > p {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: custom-space(0.5);
            margin: 0;

            span {
                font-size: $font-size-base * 0.775;
                font-weight: $font-weight-normal;
                color: $orange-700;
                padding-top: 2px;
            }
        }

        & > button {
            position: relative;
            cursor: pointer;
            line-height: 1;

            svg :deep(path) {
                fill: $orange-800;
                stroke: $orange-800;
            }

            span {
                position: absolute;
                bottom: 0;
                right: -2px;
                display: flex;

                svg {
                    width: 6px;
                    height: 6px;
                }
            }
        }

        svg {
            width: 17px;
            height: 17px;
            transform: rotateY(180deg);
        }
    }

    &__order-list {
        padding: custom-space(0.5) 0 custom-space(3) 0;
    }

    &__message {
        text-align: center;
        padding: custom-space(3) 0;
    }
}

:deep(.pl-dropdown__content > .pl-grouped-settings) {
    width: custom-space(15);
}
</style>
