<script>
export default {
    name: 'InviteEmployeeFab',
};
</script>

<script setup>
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import Fab from '@/components/Fab';
import DropDown from '@/components/DropDown';
import GroupedSettings from '@/components/constructions/grouped-settings';

const emit = defineEmits(['open-import']);
</script>

<template>
    <Fab data-testid="invite_employee_fab_btn">
        <DropDown pos-y="top">
            <template #invoker>
                <PlusIcon />
            </template>

            <template #content>
                <GroupedSettings.Root>
                    <GroupedSettings.List>
                        <GroupedSettings.ListItem>
                            <router-link :to="{ name: SETTINGS_ROUTE_NAMES.PEOPLE.CREATE }">
                                Invite new employee
                            </router-link>
                        </GroupedSettings.ListItem>

                        <GroupedSettings.ListItem @click="emit('open-import')">
                            Import new employees
                        </GroupedSettings.ListItem>
                    </GroupedSettings.List>
                </GroupedSettings.Root>
            </template>
        </DropDown>
    </Fab>
</template>
