<script>
export default {
    name: 'SelectAccountView',
};
</script>

<script setup>
import { ref, onBeforeMount, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import { ONBOARDING_ROUTE_NAMES, PL_ROUTE_NAMES } from '@/enums/routesNameEnums';
import ListDisplay from '@/components/ListDisplay';
import AuthLayout from '@/components/layouts/AuthLayout';
import AccountCard from '@/components/model-specific/account/AccountCard';

const router = useRouter();
const store = useStore();

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (store.state.accounts.length === 0) {
        router.replace({ name: ONBOARDING_ROUTE_NAMES.CREATE_COMPANY });
    }
});

/*------------------------------------------------------------------------
                               General state
------------------------------------------------------------------------*/

const isActionProcessing = ref(false);

const accountLists = computed(() => ({
    joined: store.getters.joinedAccounts,
    invited: store.getters.invitedAccounts,
}));

const initCreateNewCompany = () => {
    store.commit('auth/setCurrentAccount', null);

    router.replace({ name: ONBOARDING_ROUTE_NAMES.CREATE_COMPANY });
};

const enterCompany = (account) => {
    store.dispatch('reinitializeStore', account);

    router.replace({ name: PL_ROUTE_NAMES.INDEX });
};

const acceptInvitation = (account) => {
    isActionProcessing.value = true;

    account
        .join()
        .then((model) => {
            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: "Congratulations! You've successfully accepted the invitation.",
            });

            enterCompany(model);
        })
        .finally(() => (isActionProcessing.value = false));
};

const logout = () => store.dispatch('auth/logout').then(() => (window.location.href = '/'));
</script>

<template>
    <AuthLayout>
        <OverlayLoader v-if="isActionProcessing" />

        <transition
            appear
            name="down"
            type="transition"
        >
            <div class="pl-select-account__wrapper">
                <h1>
                    How would you like
                    <br />
                    to proceed?
                </h1>

                <section v-if="accountLists.invited.length > 0">
                    <h6>Accept invitation to</h6>

                    <ListDisplay
                        has-borders
                        :items="accountLists.invited"
                    >
                        <template #item="{ item: account }">
                            <AccountCard
                                :account="account"
                                @accept-invitation="acceptInvitation(account)"
                            />
                        </template>
                    </ListDisplay>
                </section>

                <section v-if="accountLists.joined.length > 0">
                    <h6>
                        {{ accountLists.invited.length > 0 ? 'or continue to' : 'Choose the company' }}
                    </h6>

                    <ListDisplay
                        has-borders
                        :items="accountLists.joined"
                    >
                        <template #item="{ item: account }">
                            <AccountCard
                                is-joined-list
                                :account="account"
                                @enter-company="enterCompany(account)"
                            />
                        </template>
                    </ListDisplay>
                </section>

                <section>
                    <h6>Or</h6>

                    <BtnUI
                        is-filled
                        data-testid="auth_option_owner_btn"
                        @click="initCreateNewCompany"
                    >
                        Create a new company
                    </BtnUI>
                </section>

                <section>
                    <h6>Or</h6>

                    <BtnUI
                        is-filled
                        data-testid="logout_btn"
                        class="pl-select-account__logout-btn"
                        @click="logout"
                    >
                        Logout
                    </BtnUI>
                </section>
            </div>
        </transition>
    </AuthLayout>
</template>

<style lang="scss" scoped>
.pl-select-account {
    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: custom-space(1.5);

        > h1 {
            margin-bottom: custom-space(1.5);
            text-align: center;
        }

        > section {
            > h6 {
                text-align: center;
            }

            > .pl-btn {
                width: 100%;
            }
        }
    }

    &__logout-btn {
        max-width: 75%;
        margin: 0 auto;
    }
}
</style>
