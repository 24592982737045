<script>
export default {
    name: 'OrderSummaryCard',
};
</script>

<script setup>
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

import {
    getPLOrderItemOrderedQtyDisplay,
    getPLOrderItemProducedQtyDisplay,
    getQFOrderItemOrderedQtyDisplay,
} from '@/utils/orderItemUtils';
import { isOrderInProgress } from '@/utils/orderUtils';
import { getBatchUnitQtyAmount } from '@/utils/batchUnitsUtils';
import { ORDER_TYPES } from '@/enums/orderEnums';
import { ORDER_GROUP_ITEMS_BY } from '@/enums/orderEnums';
import { DISTRIBUTION_TYPES } from '@/enums/selectableItemsEnums';
import Progress from '@/components/Progress';
import ListDisplay from '@/components/ListDisplay';
import TreeDisplay from '@/components/TreeDisplay';
import OrderDetails from '@/components/model-specific/orders/OrderDetails';
import SummaryTotal from '@/components/model-specific/orders/SummaryTotal';
import ItemSummary from '@/components/model-specific/item/ItemSummary';
import ReassignBtn from './partials/ReassignBtn';
import ReassignModal from './partials/ReassignModal';

const props = defineProps({
    order: {
        type: Object,
        required: true,
    },
    localGroupItemsBy: {
        type: String,
        default: '',
    },
    showItemProgress: {
        type: Boolean,
        default: false,
    },
    enableReassign: {
        type: Boolean,
        default: false,
    },
    orderType: {
        type: String,
        default: ORDER_TYPES.PREP_LIST,
        validator: (value) => [ORDER_TYPES.PREP_LIST, ORDER_TYPES.QUICK_FILL].includes(value),
    },
});

const emit = defineEmits(['refresh-order']);

const store = useStore();

/*-----------------------------------------------------------------------------------
                                Order group items by
-----------------------------------------------------------------------------------*/

const currentGroupItemsBy = computed(() => props.localGroupItemsBy || store.state.PLOrderViewSettings.groupItemsBy);

/*-----------------------------------------------------------------------------------
                                    Items settings
-----------------------------------------------------------------------------------*/

const itemsEntries = computed(() => Object.entries(props.order.items.data));

const orderItemHelpers = computed(() => {
    const isPrepList = props.orderType === ORDER_TYPES.PREP_LIST;

    const gerOrderedQtyDisplay = (orderItem) => {
        if (isPrepList) {
            return getPLOrderItemOrderedQtyDisplay(orderItem, true, true);
        }

        return getQFOrderItemOrderedQtyDisplay(orderItem, true, true);
    };

    const getProducedUnitsQtyDisplay = (orderItem) => {
        if (orderItem.has_prep_tasks) {
            return `${orderItem.produced_percentage}%`;
        }

        return getPLOrderItemProducedQtyDisplay(orderItem);
    };

    const getProgressValue = (orderItem) => {
        if (isPrepList) {
            return orderItem.produced_BY_qty;
        }

        return orderItem.fulfilled_BY_qty ?? 0;
    };

    const getFulfilledQty = (orderItem) => {
        const { units_data: unitsData } = orderItem;

        return getBatchUnitQtyAmount(
            unitsData,
            unitsData.batch_yield_unit,
            unitsData.quick_fill_ordering_unit,
            orderItem.fulfilled_BY_qty
        );
    };

    const getConfirmedQty = (orderItem) => {
        const { units_data: unitsData } = orderItem;

        return getBatchUnitQtyAmount(
            unitsData,
            unitsData.batch_yield_unit,
            unitsData.quick_fill_ordering_unit,
            orderItem.confirmed_BY_qty
        );
    };

    return {
        gerOrderedQtyDisplay,
        getProducedUnitsQtyDisplay,
        getProgressValue,
        getFulfilledQty,
        getConfirmedQty,
    };
});

/*-----------------------------------------------------------------------------------
                                Reassign modal state
-----------------------------------------------------------------------------------*/

const reassignModalState = reactive({
    type: null,
    data: null,
    isOpened: false,
});

const isReassignBtnVisible = (node) => {
    const isSomeItemNotCompleted = node.data.some((item) => +item.produced_percentage !== 100);

    return (
        props.enableReassign &&
        currentGroupItemsBy.value === ORDER_GROUP_ITEMS_BY.ASSIGNEE &&
        isOrderInProgress(props.order) &&
        isSomeItemNotCompleted
    );
};

const openReassignModal = (type, node) => {
    reassignModalState.isOpened = true;
    reassignModalState.type = type;

    const itemData = node.data[0];

    reassignModalState.data = {
        assigneeId: itemData.assignee.assignee_id || DISTRIBUTION_TYPES.OPEN_ITEMS,
        departmentId: itemData.department_id,
    };
};

const closeReassignModal = () => {
    reassignModalState.type = null;
    reassignModalState.data = null;
    reassignModalState.isOpened = false;
};

const refreshOrder = (data) => {
    emit('refresh-order', data);

    closeReassignModal();
};
</script>

<template>
    <article class="pl-order-summary">
        <OrderDetails :order="order" />

        <p
            v-if="!itemsEntries.length"
            class="text-center pt-4"
        >
            Items not found.
        </p>

        <TreeDisplay
            v-else
            :data="order.items.data"
            node-source-column="data"
            class="pl-order-summary-tree"
        >
            <template #node="{ key, node, nestingLevel }">
                <div
                    v-if="currentGroupItemsBy !== ORDER_GROUP_ITEMS_BY.DISTRIBUTION || nestingLevel === 0"
                    class="pl-order-summary-tree-node-name"
                >
                    <span>
                        {{ key }}

                        <template
                            v-if="
                                nestingLevel === 1 &&
                                    currentGroupItemsBy === ORDER_GROUP_ITEMS_BY.ASSIGNEE &&
                                    orderType !== ORDER_TYPES.QUICK_FILL
                            "
                        >
                            ({{ node.labor_time ?? '??h' }})
                        </template>
                    </span>

                    <ReassignBtn
                        v-if="nestingLevel === 1 && isReassignBtnVisible(node)"
                        @open-reassign-modal="(type) => openReassignModal(type, node)"
                    />
                </div>
            </template>

            <template #leaf="{ leaf }">
                <ListDisplay :items="leaf.data">
                    <template #item="{ item: orderItem }">
                        <div class="pl-order-summary-item">
                            <div
                                class="pl-order-summary-item-name-line"
                                :class="{
                                    'pl-order-summary-item-name-line--distribution':
                                        currentGroupItemsBy === ORDER_GROUP_ITEMS_BY.DISTRIBUTION,
                                }"
                            >
                                <div class="pl-order-summary-item-name-line-name">
                                    {{
                                        currentGroupItemsBy === ORDER_GROUP_ITEMS_BY.DISTRIBUTION
                                            ? leaf.name
                                            : orderItem.name
                                    }}
                                </div>

                                <div class="pl-order-summary-item-name-line-qty">
                                    {{ orderItemHelpers.gerOrderedQtyDisplay(orderItem) }}
                                </div>
                            </div>

                            <ItemSummary
                                :labor-time="orderItem.display_labor_time"
                                :food-cost="orderItem.display_food_cost"
                                :labor-cost="orderItem.display_labor_cost"
                                :total-cost="orderItem.display_total_cost"
                                :is-open-item="orderItem.is_open_item"
                            />

                            <template v-if="showItemProgress && !orderItem.is_open_item">
                                <Progress
                                    :min="0"
                                    :max="orderItem.ordered_BY_qty"
                                    :value="orderItemHelpers.getProgressValue(orderItem)"
                                />

                                <b
                                    v-if="orderType !== ORDER_TYPES.QUICK_FILL"
                                    class="mt-1 d-block text-center text-primary fw-normal pl-order-summary-item-progress-caption"
                                >
                                    {{ orderItemHelpers.getProducedUnitsQtyDisplay(orderItem) }} done
                                </b>

                                <div
                                    v-else
                                    class="pl-order-summary-item__progress-details"
                                >
                                    <p>
                                        <span>Fulfilled:</span>

                                        <b>{{ orderItemHelpers.getFulfilledQty(orderItem) }}</b>
                                    </p>

                                    <p>
                                        <span>Confirmed:</span>

                                        <b>{{ orderItemHelpers.getConfirmedQty(orderItem) }}</b>
                                    </p>
                                </div>
                            </template>
                        </div>
                    </template>
                </ListDisplay>
            </template>
        </TreeDisplay>

        <SummaryTotal
            :labor-time="order.items.data.labor_time"
            :food-cost="order.items.data.food_cost"
            :labor-cost="order.items.data.labor_cost"
            :total-cost="order.items.data.total_cost"
            :has-on-hand-qty="order.has_items_with_on_hand"
        />
    </article>

    <ReassignModal
        v-if="reassignModalState.isOpened"
        :type="reassignModalState.type"
        :order-id="order.id"
        :assignee-id="reassignModalState.data.assigneeId"
        :department-id="reassignModalState.data.departmentId"
        @refresh-order="refreshOrder"
        @close="closeReassignModal"
    />
</template>

<style lang="scss" scoped>
.pl-order-summary-tree {
    padding-top: custom-space(0.75);

    > .pl-tree-display__node {
        > .pl-order-summary-tree-node-name {
            padding-top: custom-space(0.5);

            > span {
                font-size: $font-size-base * 1.125;
                font-weight: $font-weight-bolder;
            }
        }

        > .pl-tree-display > .pl-tree-display__node > .pl-order-summary-tree-node-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: custom-space(1);
            margin-top: custom-space(0.5);

            > span {
                color: $primary;
                font-weight: $font-weight-bolder;
            }

            &:has(.pl-order-summary__reassign-btn) {
                margin-bottom: custom-space(0.5);
            }
        }
    }
}

.pl-order-summary-item {
    margin-bottom: space(2);

    &-name-line {
        display: flex;
        align-items: flex-end;
        gap: custom-space(0.5);
        margin-bottom: custom-space(0.25);

        &-name {
            font-size: $font-size-base * 0.875;
            line-height: 1.35;
            font-weight: $font-weight-normal;
            flex-grow: 1;
        }

        &-qty {
            text-align: right;
            font-size: $font-size-base * 0.75;
            line-height: 1.7;
            font-weight: $font-weight-light;
            flex-shrink: 0;
        }

        &--distribution {
            flex-wrap: wrap;
            margin-top: custom-space(0.4);

            .pl-order-summary-item-name-line-name {
                font-size: $font-size-base;
                color: $primary;
                font-weight: $font-weight-bolder;
            }
        }
    }

    &-progress {
        margin-top: custom-space(0.75);

        &-caption {
            font-size: $font-size-base * 0.875;
        }
    }

    &__progress-details {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: custom-space(5);
        font-size: $font-size-base * 0.875;
        margin-top: custom-space(0.25);

        > p {
            display: flex;
            align-items: center;
            gap: custom-space(0.25);
            margin: 0;
        }
    }
}

.pl-order-summary {
    width: 100%;
    z-index: 0;
}
</style>
