<template>
    <div
        class="pl-text-input"
        :class="computedRootClasses"
    >
        <input
            ref="$input"
            :type="type"
            :value="modelValue"
            :name="name"
            :autocomplete="autocomplete"
            class="form-control"
            :class="{ 'is-invalid': hasError }"
            :disabled="disabled"
            :data-testid="dataTestId"
            :placeholder="enableEmptyPlaceholder ? '' : null"
            @blur="emit('blur')"
            @focus="emit('focus')"
            @input="onInput"
        />

        <label
            v-if="label"
            class="form-label"
            :class="{
                'form-label--required': includeAsterisk,
            }"
        >
            {{ label }}
        </label>

        <slot name="after" />
    </div>
</template>

<script setup>
import { computed, ref, onMounted, nextTick } from 'vue';

const emit = defineEmits(['update:modelValue', 'blur', 'focus']);

const props = defineProps({
    label: {
        type: String,
        default: null,
    },
    modelValue: {
        type: [String, Number],
        default: null,
    },
    name: {
        type: String,
        default: null,
    },
    autocomplete: {
        type: String,
        default: null,
    },
    autofocus: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        default: 'text',
    },
    loading: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    hasError: {
        type: Boolean,
        default: false,
    },
    includeAsterisk: {
        type: Boolean,
        default: false,
    },
    dataTestId: {
        type: String,
        default: null,
    },
    // AddressInput.vue: Google Maps API sets a placeholder if none is provided
    enableEmptyPlaceholder: {
        type: Boolean,
        default: false,
    },
});

const $input = ref(null);

const onInput = (event) => {
    emit('update:modelValue', event.target.value);
};

const computedRootClasses = computed(() => ({
    'pl-text-input--filled': !!props.modelValue,
    'pl-text-input--loading': !!props.loading,
    'pl-text-input--disabled': props.disabled,
    'pl-text-input--has-label': !!props.label,
}));

onMounted(() => {
    if (props.autofocus) {
        nextTick(() => $input.value.focus());
    }
});
</script>

<style scoped lang="scss">
.pl-text-input {
    position: relative;

    > .form-label {
        position: absolute;
        top: $input-border-width;
        left: 0;
        width: 100%;
        text-align: left;
        line-height: $input-font-size * $input-line-height + $input-padding-y * 2;
        pointer-events: none;
        padding-left: $input-padding-x;
        padding-right: rem(45px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @include pl-transition(all, true);

        &--required::after {
            content: '*';
            position: absolute;
            top: 50%;
            color: $red-light;
            font-size: $font-size-base;
            line-height: $font-size-base * 0.75;
            padding-left: custom-space(0.1);
            transform: translateY(-50%);
        }
    }

    &--has-label > .form-control {
        padding-top: $input-padding-y * 1.4;
        padding-bottom: $input-padding-y * 0.6;

        ~ .form-label {
            font-size: rem(12px);
            transform: translate3d(0, -22%, 0);
        }
    }

    &--filled > .form-control,
    &--filled > .form-control:disabled,
    &--filled > .form-control[disabled] {
        border-color: $black;
    }

    &--loading .form-control {
        background-size: custom-space(1) custom-space(1);
        background-image: url('~@/assets/images/loader.gif');
        background-position: calc(100% - custom-space(1)) 50%;
        background-repeat: no-repeat;
    }

    &--disabled {
        opacity: $input-disabled-opacity;
    }
}
</style>
