import { SETTINGS_ROUTE_NAMES, ONBOARDING_ROUTE_NAMES } from '@/enums/routesNameEnums';

export const ONBOARDING_STEPS = [
    {
        name: ONBOARDING_ROUTE_NAMES.CREATE_LOCATIONS,
        title: 'Create locations',
    },
    {
        name: ONBOARDING_ROUTE_NAMES.INVITE_EMPLOYEES,
        title: 'Invite employees',
    },
    {
        name: ONBOARDING_ROUTE_NAMES.CREATE_DEPARTMENTS,
        title: 'Create departments',
    },
    {
        name: ONBOARDING_ROUTE_NAMES.CREATE_ITEMS,
        title: 'Create items',
    },
];

// DEV NOTE: multiplier in seconds
export const ONBOARDING_ESTIMATION_TIME_CONFIG = {
    [ONBOARDING_ROUTE_NAMES.CREATE_LOCATIONS]: {
        dataKey: 'estimated_locations_amount',
        multiplier: 25,
    },
    [ONBOARDING_ROUTE_NAMES.INVITE_EMPLOYEES]: {
        dataKey: 'estimated_employee_amount',
        multiplier: 20,
    },
    [ONBOARDING_ROUTE_NAMES.CREATE_DEPARTMENTS]: {
        dataKey: 'estimated_employee_amount',
        multiplier: 10,
    },
    [ONBOARDING_ROUTE_NAMES.CREATE_ITEMS]: {
        dataKey: 'estimated_items_amount',
        multiplier: 45,
    },
};

export const ONBOARDING_REMINDER_CONFIG = [
    {
        name: SETTINGS_ROUTE_NAMES.PEOPLE.INDEX,
        title: 'Employees',
        description: 'Add information about your team members to effectively assign tasks and distribute work.',
        ...ONBOARDING_ESTIMATION_TIME_CONFIG[ONBOARDING_ROUTE_NAMES.INVITE_EMPLOYEES],
    },
    {
        name: SETTINGS_ROUTE_NAMES.ITEMS.INDEX,
        title: 'Items',
        description: 'Organize the inventory of products.',
        ...ONBOARDING_ESTIMATION_TIME_CONFIG[ONBOARDING_ROUTE_NAMES.CREATE_ITEMS],
    },
    {
        name: SETTINGS_ROUTE_NAMES.PREP_TASKS.INDEX,
        title: 'Prep tasks',
        description: 'Obtain more granular control over production operations by splitting each item into tasks.',
        multiplier: 60 * 5,
    },
    {
        name: SETTINGS_ROUTE_NAMES.DEPARTMENTS.INDEX,
        title: 'Departments',
        description: 'Store information about your organizational structure.',
        ...ONBOARDING_ESTIMATION_TIME_CONFIG[ONBOARDING_ROUTE_NAMES.CREATE_DEPARTMENTS],
    },
    {
        name: SETTINGS_ROUTE_NAMES.LOCATIONS.INDEX,
        title: 'Locations',
        description: 'Organize work by location.',
        ...ONBOARDING_ESTIMATION_TIME_CONFIG[ONBOARDING_ROUTE_NAMES.CREATE_LOCATIONS],
    },
    {
        name: SETTINGS_ROUTE_NAMES.SUPPLIERS.INDEX,
        title: 'Suppliers',
        description: 'Control the supply chain.',
        multiplier: 10,
    },
];

export const ONBOARDING_REMINDER_KEY_BY_ROUTE = {
    [SETTINGS_ROUTE_NAMES.PEOPLE.INDEX]: 'employee_reminder',
    [SETTINGS_ROUTE_NAMES.ITEMS.INDEX]: 'items_reminder',
    [SETTINGS_ROUTE_NAMES.PREP_TASKS.INDEX]: 'prep_task_reminder',
    [SETTINGS_ROUTE_NAMES.DEPARTMENTS.INDEX]: 'departments_reminder',
    [SETTINGS_ROUTE_NAMES.LOCATIONS.INDEX]: 'locations_reminder',
    [SETTINGS_ROUTE_NAMES.SUPPLIERS.INDEX]: 'suppliers_reminder',
};

export const ONBOARDING_INCLUSION_KEY_BY_ROUTE = {
    [SETTINGS_ROUTE_NAMES.PEOPLE.INDEX]: 'has_employees',
    [SETTINGS_ROUTE_NAMES.ITEMS.INDEX]: 'has_items',
    [SETTINGS_ROUTE_NAMES.PREP_TASKS.INDEX]: 'has_prep_tasks',
    [SETTINGS_ROUTE_NAMES.DEPARTMENTS.INDEX]: 'has_departments',
    [SETTINGS_ROUTE_NAMES.LOCATIONS.INDEX]: 'has_locations',
    [SETTINGS_ROUTE_NAMES.SUPPLIERS.INDEX]: 'has_suppliers',
};
