<script>
export default {
    name: 'PeopleIndexView',
};
</script>

<script setup>
import { onBeforeMount, inject, reactive, computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { catchNotFoundError } from '@/utils/httpUtils';
import { RESPONSE_DATA_TYPES } from '@/enums/httpEnums';
import { PEOPLE_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES, IMPORT_ENTITIES } from '@/enums/componentsEnums';
import AccountModel from '@/models/Account';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useImportEntityModal from '@/composition/useImportEntityModal';
import useConfirmationModal from '@/composition/useConfirmationModal';
import ConfirmationModal from '@/components/ConfirmationModal';
import Alert from '@/components/Alert';
import Tabs from '@/components/Tabs';
import ListDisplay from '@/components/ListDisplay';
import ImportEntityModal from '@/components/ImportEntityModal';
import SearchForm from '@/components/forms/SearchForm';
import EmployeeCard from '@/components/model-specific/employee/EmployeeCard';
import ReportIcon from '@/components/icons/ReportIcon';
import InviteEmployeeFab from './partials/InviteEmployeeFab';
import GlobalReassignModal from './partials/GlobalReassignModal';

const $acl = inject('$acl');

const route = useRoute();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                                  Filters
------------------------------------------------------------------------*/

const query = ref('');

const isEmployeeMatching = (employee) => {
    const name = employee.name.toLowerCase();
    const phone = employee.phone_number.slice(1);

    return name.includes(query.value.toLowerCase()) || phone.includes(query.value);
};

/*------------------------------------------------------------------------
                               People state
------------------------------------------------------------------------*/

const peopleState = reactive({
    data: null,
    isDataLoading: true,
    hasBeenJustUpdated: false,
});

const loadPeople = () => {
    sendAbortableRequest(AccountModel.all(orgId))
        .then((models) => (peopleState.data = models))
        .finally(() => (peopleState.isDataLoading = false));
};

const updatePeopleState = (models) => {
    peopleState.data = models;
    peopleState.hasBeenJustUpdated = true;

    setTimeout(() => (peopleState.hasBeenJustUpdated = false), 250);
};

const employeesLists = computed(() => {
    const { data } = peopleState;

    if (data === null) {
        return null;
    }

    return data.reduce(
        (acc, employee) => {
            if (!isEmployeeMatching(employee)) {
                return acc;
            }

            if (employee.joined_at !== null) {
                acc.joined.push(employee);
            } else {
                acc.invited.push(employee);
            }

            return acc;
        },
        { joined: [], invited: [] }
    );
});

const permissions = computed(() => ({
    create: $acl.can(PEOPLE_RESTRICTIONS.CREATE, currentAccount),
    update: (employee) => $acl.can(PEOPLE_RESTRICTIONS.UPDATE, currentAccount, employee),
    delete: (employee) => $acl.can(PEOPLE_RESTRICTIONS.DELETE, currentAccount, employee),
    reassign: (employee) => $acl.can(PEOPLE_RESTRICTIONS.REASSIGN, currentAccount, employee),
}));

onBeforeMount(loadPeople);

/*------------------------------------------------------------------------
                                   Tabs
------------------------------------------------------------------------*/

const tabOptions = computed(() => {
    if (employeesLists.value === null) {
        return [];
    }

    return Object.entries(employeesLists.value).map(([key, values]) => ({
        value: key,
        text: `${key} employees (${values.length})`,
    }));
});

/*------------------------------------------------------------------------
                           Reassign modal state
------------------------------------------------------------------------*/

const reassignModalState = reactive({
    employee: null,
    isOpened: false,
    isDeletionProcessActive: false,
});

const openReassignEmployeeModal = (employee) => {
    reassignModalState.employee = employee;
    reassignModalState.isOpened = true;
};

const closeReassignEmployeeModal = () => {
    reassignModalState.employee = null;
    reassignModalState.isOpened = false;
    reassignModalState.isDeletionProcessActive = false;
};

/*------------------------------------------------------------------------
                         Import entity modal state
------------------------------------------------------------------------*/

const { importEntityState, importEntityModal } = useImportEntityModal(AccountModel, 'employees');

const submitImportEmployees = (payload) => {
    importEntityModal.submit(payload, (isSpinnerVisible) => {
        peopleState.isDataLoading = isSpinnerVisible;

        loadPeople();
    });
};

/*------------------------------------------------------------------------
                         Confirmation modal state
------------------------------------------------------------------------*/

const { confirmationState, confirmationModal } = useConfirmationModal();

const handleSuccessfulDeletion = (models, hasEmployeeJoined) => {
    updatePeopleState(models);

    EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
        type: ALERT_TYPES.SUCCESS,
        message: hasEmployeeJoined
            ? 'The employee has been successfully deleted'
            : 'The invitation has been successfully removed',
    });
};

const submitDeleteAccount = () => {
    const { model } = confirmationState;

    const hasEmployeeJoined = model.joined_at !== null;

    confirmationModal
        .submit((model) => model.delete({ response_data: RESPONSE_DATA_TYPES.LIST }))
        .then((models) => handleSuccessfulDeletion(models, hasEmployeeJoined))
        .catch((error) => {
            catchNotFoundError(error, () => {
                const filteredModels = peopleState.data.filter(({ id }) => model.id !== id);

                handleSuccessfulDeletion(filteredModels, hasEmployeeJoined);
            });
        });
};

const prepareDeleteAccount = () => {
    if (confirmationState.model.joined_at === null) {
        submitDeleteAccount();
    } else {
        reassignModalState.isDeletionProcessActive = true;
        confirmationState.isPending = true;

        openReassignEmployeeModal(confirmationState.model);
    }
};

const setHasEmployeeWork = (hasEmployeeWork) => {
    if (hasEmployeeWork) {
        confirmationState.isPending = false;

        confirmationModal.close();

        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.FAIL,
            message: "The employee has unfinished work. Reassign employee's work to complete the deletion.",
        });
    } else {
        reassignModalState.isDeletionProcessActive = false;

        closeReassignEmployeeModal();
        submitDeleteAccount();
    }
};

const completeDeletionProcess = () => {
    const { employee } = reassignModalState;

    closeReassignEmployeeModal();

    confirmationModal.open(employee);
    submitDeleteAccount();
};
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.INDEX">
        <template #title>
            <h1>People</h1>
        </template>

        <template
            v-if="!peopleState.isDataLoading"
            #actions
        >
            <SearchForm
                v-model="query"
                data-testid="people_search_form"
            />
        </template>

        <InPlaceLoader v-if="peopleState.isDataLoading" />

        <Alert
            v-else-if="peopleState.data === null"
            :type="ALERT_TYPES.FAIL"
        >
            People list could not be loaded for some reason. Please try again later.
        </Alert>

        <template v-else>
            <Tabs
                :model-value="route.query.initialTab || null"
                :tab-options="tabOptions"
                :has-been-content-just-updated="peopleState.hasBeenJustUpdated"
            >
                <template #content="{ activeTab }">
                    <ListDisplay
                        v-if="employeesLists[activeTab].length > 0"
                        has-borders
                        :data-testid="`${activeTab}_employees_list`"
                        :items="employeesLists[activeTab]"
                    >
                        <template #item="{ item: employee }">
                            <EmployeeCard :employee="employee">
                                <template
                                    v-if="
                                        permissions.update(employee) ||
                                            permissions.delete(employee) ||
                                            permissions.reassign(employee)
                                    "
                                    #actions="{ qaPrefix }"
                                >
                                    <button
                                        v-if="permissions.reassign(employee)"
                                        type="button"
                                        :data-testid="`${qaPrefix}_reassign_btn`"
                                        @click="openReassignEmployeeModal(employee)"
                                    >
                                        <ReportIcon />
                                    </button>

                                    <router-link
                                        v-if="permissions.update(employee)"
                                        :to="{
                                            name: SETTINGS_ROUTE_NAMES.PEOPLE.EDIT,
                                            params: { accountId: employee.id },
                                            query: { initialTab: activeTab },
                                        }"
                                        :data-testid="`${qaPrefix}_edit_btn`"
                                    >
                                        <EditIcon />
                                    </router-link>

                                    <button
                                        v-if="permissions.delete(employee)"
                                        type="button"
                                        data-testid="delete_employee_btn"
                                        @click="confirmationModal.open(employee)"
                                    >
                                        <DeleteIcon />
                                    </button>
                                </template>
                            </EmployeeCard>
                        </template>
                    </ListDisplay>

                    <p
                        v-else
                        :data-testid="`${activeTab}_empty_list_message`"
                    >
                        The list is empty
                    </p>
                </template>
            </Tabs>

            <InviteEmployeeFab @open-import="importEntityModal.open" />

            <ImportEntityModal
                v-if="importEntityModal.isOpened"
                :entity="IMPORT_ENTITIES.EMPLOYEE"
                :data="importEntityState.data"
                :progress="importEntityState.progress"
                :is-action-processing="importEntityState.isActionProcessing"
                @download-template="importEntityModal.downloadTemplate"
                @parse-file="importEntityModal.parseFile"
                @close="importEntityModal.close"
                @submit="submitImportEmployees"
            >
                <template #title="{ data, importEntitiesSize }">
                    {{ data === null ? 'Import new Employees' : `Employees to invite (${importEntitiesSize})` }}
                </template>
            </ImportEntityModal>

            <GlobalReassignModal
                v-if="reassignModalState.isOpened"
                :assignee-id="reassignModalState.employee.id"
                :assignee-name="reassignModalState.employee.name"
                :is-deletion-process="reassignModalState.isDeletionProcessActive"
                @close="closeReassignEmployeeModal"
                @set-has-employee-work="setHasEmployeeWork"
                @complete-deletion="completeDeletionProcess"
            />

            <ConfirmationModal
                v-if="confirmationState.isOpened"
                qa-prefix="delete_account_modal"
                :is-pending="confirmationState.isPending"
                :submit-btn-text="confirmationState.model.joined_at !== null ? 'delete' : 'remove'"
                @close="confirmationModal.close"
                @submit-delete="prepareDeleteAccount"
            >
                {{
                    confirmationState.model.joined_at !== null
                        ? `Are you sure you want to delete "${confirmationState.model.name}" from the company?`
                        : `Are you sure you want to remove the invitation of employee "${confirmationState.model.name}"?`
                }}
            </ConfirmationModal>
        </template>
    </TopBarLayout>
</template>

<style lang="scss" scoped>
:deep(.pl-tabs__content) > p {
    text-align: center;
}

.pl-employees__action {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    padding-top: custom-space(1);

    & > a {
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
}
</style>
