<script>
export default {
    name: 'GlobalReassignModal',
};
</script>

<script setup>
import { reactive, onBeforeMount, computed, ref } from 'vue';
import { useStore } from 'vuex';
import dayjs from 'dayjs';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { formatDate } from '@/utils/dateUtils';
import { constructAssigneeOptions } from '@/utils/selectableItemsUtils';
import { DISTRIBUTION_TYPES } from '@/enums/selectableItemsEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import AccountModel from '@/models/Account';
import useAbortableRequest from '@/composition/useAbortableRequest';
import Modal from '@/components/Modal';
import Accordion from '@/components/Accordion';
import Alert from '@/components/Alert';
import DatePickerHOC from '@/components/HOC/DatePickerHOC';
import SelectInput from '@/components/form-controls/SelectInput';
import CheckboxInput from '@/components/form-controls/CheckboxInput';

const props = defineProps({
    assigneeId: {
        type: Number,
        required: true,
    },
    assigneeName: {
        type: String,
        required: true,
    },
    isDeletionProcess: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['close', 'set-has-employee-work', 'complete-deletion']);

const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Reassign state
------------------------------------------------------------------------*/

const reassignState = reactive({
    data: [],
    selectedAssignees: null,
    dueDate: null,
    isDataLoading: true,
    isActionProcessing: false,
});

const isCalendarVisible = ref(false);

const toggleDueDateCalendar = () => {
    if (!isCalendarVisible.value) {
        reassignState.dueDate = null;
    }
};

const setDueDate = (value) => {
    if (!Array.isArray(reassignState.dueDate)) {
        reassignState.dueDate = [formatDate(), value];
    }
};

const getInitialAssigneeId = (availableAssignees) => {
    const { assigneeId, isDeletionProcess } = props;

    if (isDeletionProcess) {
        return null;
    }

    const isAssigneeAvailable = availableAssignees.some((assignee) => assignee.id === assigneeId);

    return isAssigneeAvailable ? assigneeId : null;
};

const setInitialSelectedAssignees = (data) => {
    reassignState.selectedAssignees = data.reduce((locationsAcc, { id: locationId, departments }) => {
        locationsAcc[locationId] = Object.values(departments).reduce(
            (departmentsAcc, { id: departmentId, available_assignees: availableAssignees }) => {
                departmentsAcc[departmentId] = getInitialAssigneeId(availableAssignees);

                return departmentsAcc;
            },
            {}
        );

        return locationsAcc;
    }, {});
};

const loadReassignStateData = () => {
    sendAbortableRequest(AccountModel.getReassignState(orgId, props.assigneeId))
        .then((data) => {
            if (data !== null) {
                reassignState.data = data ?? [];

                setInitialSelectedAssignees(data);

                if (props.isDeletionProcess) {
                    emit('set-has-employee-work', reassignState.data.length > 0);
                }
            }
        })
        .finally(() => (reassignState.isDataLoading = false));
};

const getPayload = () => {
    const locations = Object.entries(reassignState.selectedAssignees).map(([locationId, departments]) => ({
        id: +locationId,
        departments: Object.entries(departments).map(([departmentId, assigneeId]) => ({
            department_id: +departmentId,
            assignee_id: +assigneeId,
        })),
    }));

    return {
        locations,
        due_date: isCalendarVisible.value ? dayjs(reassignState.dueDate[1]).add(1, 'day').format('YYYY-MM-DD') : null,
    };
};

const submitReassign = () => {
    reassignState.isActionProcessing = true;

    AccountModel.setReassign(orgId, props.assigneeId, getPayload())
        .then(() => {
            if (props.isDeletionProcess) {
                emit('complete-deletion');
            } else {
                emit('close');
            }

            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: 'The reassign have been successfully completed!',
            });
        })
        .finally(() => (reassignState.isActionProcessing = false));
};

const getAssigneeOptions = (department) => {
    const options = constructAssigneeOptions(
        DISTRIBUTION_TYPES.DIRECT,
        [{ assignee_id: props.assigneeId }],
        department.available_assignees,
        false
    );

    if (props.isDeletionProcess) {
        return options.filter(({ value }) => props.assigneeId !== value);
    }

    return options;
};

const getSelectInputLabel = (location, department) => {
    const selectedAssigneeId = reassignState.selectedAssignees[location.id][department.id];

    return props.assigneeId === selectedAssigneeId ? 'Assigned to' : 'Reassign to';
};

const isAnyAssigneeChanged = computed(() => {
    if (reassignState.isDataLoading || reassignState.data.length === 0) {
        return true;
    }

    return Object.values(reassignState.selectedAssignees).some((departments) =>
        Object.values(departments).some((assigneeId) => assigneeId !== props.assigneeId)
    );
});

const hasUnselectedAssignees = computed(() => {
    if (reassignState.isDataLoading || reassignState.data.length === 0) {
        return false;
    }

    return Object.values(reassignState.selectedAssignees).some((departments) =>
        Object.values(departments).some((assigneeId) => assigneeId === null)
    );
});

const isDueDateValid = computed(() => {
    if (!isCalendarVisible.value) {
        return true;
    }

    return reassignState.dueDate !== null;
});

const ribbonText = computed(() => {
    if (hasUnselectedAssignees.value) {
        return 'Set all assignees to complete reassign';
    }

    if (!isAnyAssigneeChanged.value) {
        return 'There are no changes to reassign';
    }

    if (!isDueDateValid.value) {
        return 'Specify due date to complete reassign';
    }

    return null;
});

const isSubmitDisabled = computed(() => {
    if (reassignState.isDataLoading || reassignState.isActionProcessing || reassignState.data.length === 0) {
        return true;
    }

    return hasUnselectedAssignees.value || !isAnyAssigneeChanged.value || !isDueDateValid.value;
});

onBeforeMount(loadReassignStateData);
</script>

<template>
    <Modal
        v-if="!isDeletionProcess || !reassignState.isDataLoading"
        disable-click-outside-mechanism
        :is-close-disabled="reassignState.isActionProcessing"
        @close="emit('close')"
    >
        <template #title>
            {{ `Reassign ${assigneeName}` }}

            <Alert
                v-if="isDeletionProcess"
                :type="ALERT_TYPES.FAIL"
            >
                Reassign is required to complete the deletion.
            </Alert>
        </template>

        <template #content>
            <OverlayLoader v-if="reassignState.isActionProcessing" />

            <InPlaceLoader v-if="reassignState.isDataLoading" />

            <Alert
                v-else-if="reassignState.data.length === 0"
                :type="ALERT_TYPES.INFO"
            >
                No work to reassign.
            </Alert>

            <template v-else>
                <Accordion
                    v-for="location in reassignState.data"
                    :key="`employee location: ${location.id}`"
                    :title="location.name"
                    data-test-id="employee_location"
                >
                    <div
                        v-for="department in location.departments"
                        :key="`employee location: ${location.id}; department: ${department.id}`"
                        class="pl-reassign-card"
                    >
                        <span>{{ department.name }}</span>

                        <SelectInput
                            v-model="reassignState.selectedAssignees[location.id][department.id]"
                            searchable
                            is-last-option-marked
                            :label="getSelectInputLabel(location, department)"
                            :options="getAssigneeOptions(department)"
                        />
                    </div>
                </Accordion>

                <CheckboxInput
                    v-if="!isDeletionProcess"
                    v-model="isCalendarVisible"
                    enable-boolean-mode
                    name="temporary-reassign-checkbox"
                    :options="[{ value: true, text: 'Enable temporary reassign' }]"
                    @update:modelValue="toggleDueDateCalendar"
                />

                <div
                    v-if="isCalendarVisible"
                    class="pl-reassign-datepicker"
                    :class="{
                        'pl-reassign-datepicker--selected': isDueDateValid,
                    }"
                >
                    <h5>Due Date</h5>

                    <DatePickerHOC
                        v-model="reassignState.dueDate"
                        inline
                        class="dp__mode--range"
                        :min-date="new Date()"
                        :range="isDueDateValid"
                        :fixed-start="isDueDateValid"
                        @update:modelValue="setDueDate"
                    />
                </div>
            </template>
        </template>

        <template #actions>
            <BtnUI @click="emit('close')">
                Cancel
            </BtnUI>

            <BtnUI
                is-filled
                :disabled="isSubmitDisabled"
                @click="submitReassign"
            >
                Apply
            </BtnUI>

            <small v-if="ribbonText !== null">
                {{ ribbonText }}
            </small>
        </template>
    </Modal>
</template>

<style lang="scss" scoped>
:deep(.pl-modal__wrapper) > section {
    max-width: custom-space(28);
}

:deep(.pl-modal__content) {
    display: flex;
    flex-direction: column;
    gap: custom-space(0.25);
}

:deep(.pl-modal__actions) {
    > small {
        margin-bottom: custom-space(0.25);
    }

    @include media-breakpoint-up(md) {
        flex-direction: column-reverse;

        & > button {
            flex: auto;
            width: 100%;
            padding-top: custom-space(0.75);
            padding-bottom: custom-space(0.75);
        }
    }
}

:deep(.pl-accordion__content) {
    display: flex;
    flex-direction: column;
    gap: custom-space(0.75);
}

:deep(.pl-checkbox) {
    margin-top: custom-space(0.5);

    .form-check {
        margin: 0;
    }

    .form-check-label {
        font-size: $font-size-base * 0.875;
    }

    .form-check-input[type='checkbox'] {
        border-color: $black;
        border-radius: custom-space(0.25);
    }
}

.pl-reassign-card > span {
    color: $primary;
    font-size: $font-size-base * 0.95;
    font-weight: $font-weight-normal;
}

.pl-reassign-datepicker {
    background-color: $white;
    padding: custom-space(0.5);
    border: 1px solid $form-select-border-color;
    border-radius: $border-radius;

    & > h5 {
        text-align: center;
        margin-bottom: custom-space(0.5);
    }

    :deep(.dp__menu) {
        flex-grow: 1;
    }

    :deep(.dp__calendar_header),
    :deep(.dp__calendar) {
        width: 100%;
    }

    &--selected {
        border-color: $input-focus-border-color;
    }
}
</style>
