<script>
export default {
    name: 'ChangeLocationModal',
};
</script>

<script setup>
import { computed, onBeforeMount, reactive, ref } from 'vue';
import { useStore } from 'vuex';

import { catchUnprocessableEntity } from '@/utils/httpUtils';
import { required } from '@/utils/formValidators';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import AccountModel from '@/models/Account';
import LocationModel from '@/models/Location';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useForm from '@/composition/useForm';
import Modal from '@/components/Modal';
import Alert from '@/components/Alert';
import SelectInput from '@/components/form-controls/SelectInput';
import FormUI from '@/components/UI/FormUI';

const emit = defineEmits(['close']);

const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                                Setup form
------------------------------------------------------------------------*/

const { formState, ...form } = useForm(
    {
        location_id: null,
    },
    {
        location_id: {
            required,
        },
    },
    {
        location_id: 'Location',
    }
);

const isFormSubmitting = ref(false);

const submitForm = () => {
    if (form.validate()) {
        isFormSubmitting.value = true;

        AccountModel.changeLocation(orgId, currentAccount.id, formState)
            .then(() => (window.location.href = '/'))
            .catch((error) => {
                isFormSubmitting.value = false;

                catchUnprocessableEntity(error, form.setValidationErrors);
            });
    }
};

/*------------------------------------------------------------------------
                                 Locations
------------------------------------------------------------------------*/

const locationsState = reactive({
    data: [],
    isDataLoading: true,
});

const loadLocations = () => {
    sendAbortableRequest(LocationModel.all(orgId))
        .then((models) => (locationsState.data = models || []))
        .finally(() => (locationsState.isDataLoading = false));
};

const locationOptions = computed(() => {
    const { location, available_locations: availableLocations } = store.state.auth.currentAccount;

    const availableLocationIds = new Set(availableLocations.map(({ id }) => id));

    return locationsState.data.reduce((acc, { id, name }) => {
        if (availableLocationIds.has(id) && location.id !== id) {
            acc.push({
                value: id,
                text: name,
            });
        }

        return acc;
    }, []);
});

const isLoading = computed(() => locationsState.isDataLoading || isFormSubmitting.value);

const hasNoAvailableLocations = computed(() => !locationsState.isDataLoading && locationOptions.value.length === 0);

onBeforeMount(loadLocations);
</script>

<template>
    <Modal
        :is-close-disabled="isFormSubmitting"
        @close="emit('close')"
    >
        <template #title>
            Change location
        </template>

        <template #content>
            <FormUI
                id="pl-change-location-form"
                disable-animation
                data-test-id="change_location_form"
                :is-loading="isFormSubmitting"
                @submit="submitForm"
            >
                <template #content="{ classNames, qaPrefix }">
                    <SelectInput
                        v-model="formState.location_id"
                        searchable
                        include-asterisk
                        label="Location"
                        :data-test-id="`${qaPrefix}_location_select`"
                        :class="{
                            [classNames.spacerSm]: hasNoAvailableLocations,
                        }"
                        :options="locationOptions"
                        :loading="locationsState.isDataLoading"
                        :disabled="isLoading || locationOptions.length === 0"
                        :has-error="form.hasErrors('location_id')"
                        @blur="form.validate('location_id')"
                        @update:modelValue="form.clearErrors('location_id')"
                    />

                    <Alert
                        v-if="hasNoAvailableLocations"
                        :type="ALERT_TYPES.FAIL"
                    >
                        No available locations.
                    </Alert>

                    <ValidationErrors
                        v-if="form.hasErrors('location_id')"
                        :data-testid="`${qaPrefix}_location_errors`"
                        :errors="form.getErrors('location_id')"
                    />
                </template>
            </FormUI>
        </template>

        <template #actions>
            <BtnUI
                type="button"
                :disabled="isFormSubmitting"
                @click="emit('close')"
            >
                Cancel
            </BtnUI>

            <BtnUI
                is-filled
                type="submit"
                form="pl-change-location-form"
                data-test-id="change_location_form_submit_btn"
                :disabled="isLoading || form.isDisabled()"
            >
                Apply
            </BtnUI>
        </template>
    </Modal>
</template>

<style lang="scss" scoped>
:deep(.pl-overlay-spinner) {
    border-radius: $border-radius;
}

:deep(.pl-modal__actions) {
    @include media-breakpoint-up(md) {
        flex-direction: column-reverse;

        & > button {
            flex: auto;
            width: 100%;
            padding-top: custom-space(0.75);
            padding-bottom: custom-space(0.75);
        }
    }
}
</style>
