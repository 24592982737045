<script>
export default {
    name: 'OrganizationForm',
};
</script>

<script setup>
import { computed, watch } from 'vue';

import { required, maxLength, minLength, minValue } from '@/utils/formValidators';
import { NOTIFICATION_CHANNEL_OPTIONS } from '@/enums/organizationEnums';
import useForm from '@/composition/useForm';
import CheckboxInput from '@/components/form-controls/CheckboxInput';
import NumericInput from '@/components/form-controls/NumericInput';
import TextInput from '@/components/form-controls/TextInput';
import FormUI from '@/components/UI/FormUI';

const props = defineProps({
    name: {
        type: String,
        default: '',
    },
    defaultHourlyRate: {
        type: [Number, String],
        default: null,
    },
    notificationChannels: {
        type: Array,
        default: () => [],
    },
    isDataLoading: {
        type: Boolean,
        default: false,
    },
    isSubmitting: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['submit']);

/*------------------------------------------------------------------------
                                Setup form
------------------------------------------------------------------------*/

const { formState, ...form } = useForm(
    {
        name: '',
        default_hourly_rate: null,
        notification_channels: [],
    },
    {
        name: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(255),
        },
        default_hourly_rate: {
            required,
            minValue: minValue(1),
        },
    },
    {
        name: 'Company name',
        default_hourly_rate: 'Default hourly rate',
    }
);

const submitForm = () => {
    if (form.validate()) {
        const { default_hourly_rate: defaultHourlyRate, ...restPayload } = formState;

        emit('submit', {
            ...restPayload,
            default_hourly_rate: defaultHourlyRate * 100,
        });
    }
};

const isLoading = computed(() => props.isDataLoading || props.isSubmitting);

watch(
    () => props.isDataLoading,
    (isDataLoading) => {
        if (!isDataLoading) {
            formState.name = props.name;
            formState.default_hourly_rate = props.defaultHourlyRate;
            formState.notification_channels = props.notificationChannels;
        }
    },
    { immediate: true }
);
</script>

<template>
    <FormUI
        data-test-id="organization_form"
        :is-loading="isLoading"
        @submit="submitForm"
    >
        <template #content="{ classNames, qaPrefix }">
            <div :class="classNames.spacerLg">
                <TextInput
                    v-model="formState.name"
                    include-asterisk
                    label="Company name"
                    name="name"
                    autocomplete="name"
                    :data-test-id="`${qaPrefix}_name_input`"
                    :disabled="isLoading"
                    :has-error="form.hasErrors('name')"
                    @blur="form.validate('name')"
                    @update:modelValue="form.clearErrors('name')"
                />

                <ValidationErrors
                    v-if="form.hasErrors('name')"
                    :data-testid="`${qaPrefix}_name_errors`"
                    :errors="form.getErrors('name')"
                />
            </div>

            <div :class="classNames.spacerLg">
                <NumericInput
                    v-model="formState.default_hourly_rate"
                    only-positive
                    always-use-dot
                    include-asterisk
                    label="Default hourly rate"
                    name="default_hourly_rate"
                    :data-test-id="`${qaPrefix}_default_hourly_rate_input`"
                    :max-precision="2"
                    :max="9999"
                    :disabled="isLoading"
                    :has-error="form.hasErrors('default_hourly_rate')"
                    @blur="form.validate('default_hourly_rate')"
                    @update:modelValue="form.clearErrors('default_hourly_rate')"
                />

                <ValidationErrors
                    v-if="form.hasErrors('default_hourly_rate')"
                    :data-testid="`${qaPrefix}_default_hourly_rate_errors`"
                    :errors="form.getErrors('default_hourly_rate')"
                />

                <small
                    v-else-if="!isDataLoading"
                    :class="classNames.ribbon"
                >
                    Note that default hourly rate will be used only when there is no hourly rate specified.
                </small>
            </div>

            <CheckboxInput
                v-model="formState.notification_channels"
                label="Send notifications via:"
                name="notification_channels"
                :data-testid="`${qaPrefix}_notification_channels_input`"
                :options="NOTIFICATION_CHANNEL_OPTIONS"
            />
        </template>

        <template #actions="{ qaPrefix }">
            <BtnUI
                is-filled
                type="submit"
                :data-testid="`${qaPrefix}_submit_btn`"
                :disabled="isLoading || form.isDisabled()"
            >
                Save
            </BtnUI>
        </template>
    </FormUI>
</template>
