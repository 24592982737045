<script>
export default {
    name: 'SelectableItemAssigneeCard',
};
</script>

<script setup>
import { computed } from 'vue';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { cutFloatNumber } from '@/utils/numberUtils';
import { constructAssigneeOptions, constructAssigneeSelectedOption } from '@/utils/selectableItemsUtils';
import { getQtyPrecision } from '@/utils/componentsUtils';
import { getPLOrderItemOrderedQtyDisplay } from '@/utils/orderItemUtils';
import { ALERT_TYPES, BADGE_TYPES } from '@/enums/componentsEnums';
import { DISTRIBUTION_TYPES } from '@/enums/selectableItemsEnums';
import Alert from '@/components/Alert';
import Badge from '@/components/Badge';
import QtyInput from '@/components/form-controls/QtyInput';
import SelectInput from '@/components/form-controls/SelectInput';

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    itemsAssignee: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['set-assignee', 'set-qty']);

/*------------------------------------------------------------------------
                               General state
------------------------------------------------------------------------*/

const getPrepTaskQtyDisplay = (prepTask) => {
    const assigneeData = props.itemsAssignee[prepTask.id];

    return `(${cutFloatNumber(assigneeData.qty)} ${assigneeData.orderingUnitText})`;
};

const isItemAdded = computed(() => {
    const { item, itemsAssignee } = props;

    return item.prep_tasks.length === 0 && itemsAssignee[item.id].distribution_type !== null;
});

/*------------------------------------------------------------------------
                                 Assignee
------------------------------------------------------------------------*/

const getItemAssigneeData = (item) => {
    const assigneeData = props.itemsAssignee[item.id];

    return {
        distributionType: assigneeData.distribution_type,
        distribution: assigneeData.distribution,
        availableAssignees: item.available_assignees,
    };
};

const hasItemMixedDistribution = (item) => {
    const { distributionType } = getItemAssigneeData(item);

    return distributionType === DISTRIBUTION_TYPES.MIXED;
};

const getSelectedAssigneeId = (item) => {
    const { distributionType, distribution } = getItemAssigneeData(item);

    return constructAssigneeSelectedOption(distributionType, distribution);
};

const getAssigneeOptions = (item) => {
    const { distributionType, distribution, availableAssignees } = getItemAssigneeData(item);

    return constructAssigneeOptions(distributionType, distribution, availableAssignees);
};

const setAssignee = (item, value) => {
    emit('set-assignee', { item, value });
};

/*------------------------------------------------------------------------
                                    Qty
------------------------------------------------------------------------*/

const isTheLastActivePrepTask = () => {
    const { item, itemsAssignee } = props;

    const activePrepTasks = item.prep_tasks.filter(({ id }) => +itemsAssignee[id].qty > 0);

    return activePrepTasks.length === 1;
};

const getClosestAllowedPrepTaskQty = (qty, qtyStep) => {
    if (qty === 0) {
        return 0;
    }

    if (qty <= qtyStep) {
        return qtyStep;
    }

    return Math.round(qty / qtyStep) * qtyStep;
};

const setQty = (prepTask, qty) => {
    const { qtyStep } = props.itemsAssignee[prepTask.id];

    let value = getClosestAllowedPrepTaskQty(+qty, qtyStep);

    if (value === 0 && isTheLastActivePrepTask()) {
        value = qtyStep;

        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.FAIL,
            message: 'All prep tasks cannot be removed from the item.',
        });
    }

    emit('set-qty', { prepTask, value });
};
</script>

<template>
    <div
        class="pl-selectable-item-assignee-card"
        :class="{
            'pl-selectable-item-assignee-card--added': isItemAdded,
        }"
    >
        <Badge
            v-if="item.prep_tasks.length > 0"
            is-absolute
            size="sm"
            :type="BADGE_TYPES.ORDER_CARD"
        >
            Prep Tasks ({{ item.prep_tasks.length }})
        </Badge>

        <div class="pl-selectable-item-assignee-card__wrapper">
            <b>{{ item.category }}</b>

            <div class="pl-selectable-item-assignee-card__content">
                <h6>{{ item.name }}</h6>

                <span>{{ getPLOrderItemOrderedQtyDisplay(item) }}</span>
            </div>

            <div class="pl-selectable-item-assignee-card__controls">
                <template v-if="item.prep_tasks.length > 0">
                    <div
                        v-for="prepTask in item.prep_tasks"
                        :key="`selectable prep task: ${prepTask.id}`"
                        class="pl-selectable-prep-task"
                        :class="{
                            'pl-selectable-prep-task--added': itemsAssignee[prepTask.id].qty > 0,
                        }"
                    >
                        <div class="pl-selectable-prep-task__wrapper">
                            <div class="pl-selectable-prep-task__name">
                                <span>{{ prepTask.name }}</span>

                                <small>{{ getPrepTaskQtyDisplay(prepTask) }}</small>
                            </div>

                            <QtyInput
                                is-increment-mode
                                ask-for-saving-changes-on-blur
                                class="pl-selectable-prep-task__qty"
                                :max="9999"
                                :min="0"
                                :model-value="itemsAssignee[prepTask.id].qty"
                                :step="itemsAssignee[prepTask.id].qtyStep"
                                :precision="getQtyPrecision(itemsAssignee[prepTask.id].qtyStep)"
                                @update:modelValue="(value) => setQty(prepTask, value)"
                            />
                        </div>

                        <SelectInput
                            is-last-option-marked
                            searchable
                            label="Assign to"
                            size="sm"
                            :model-value="getSelectedAssigneeId(prepTask)"
                            :disabled="itemsAssignee[prepTask.id].qty === 0 || hasItemMixedDistribution(prepTask)"
                            :options="getAssigneeOptions(prepTask)"
                            @update:modelValue="(value) => setAssignee(prepTask, value)"
                        />

                        <Alert
                            v-if="hasItemMixedDistribution(prepTask)"
                            :type="ALERT_TYPES.WARNING"
                        >
                            You can't change this prep task because it was reassigned.
                        </Alert>
                    </div>
                </template>

                <template v-else>
                    <SelectInput
                        is-last-option-marked
                        searchable
                        label="Assign to"
                        size="sm"
                        :model-value="getSelectedAssigneeId(item)"
                        :options="getAssigneeOptions(item)"
                        :disabled="hasItemMixedDistribution(item)"
                        @update:modelValue="(value) => setAssignee(item, value)"
                    />

                    <Alert
                        v-if="hasItemMixedDistribution(item)"
                        :type="ALERT_TYPES.WARNING"
                    >
                        You can't change this item because it was reassigned.
                    </Alert>
                </template>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.pl-selectable-item-assignee-card {
    position: relative;
    background-color: $white;
    margin-bottom: custom-space(0.5);
    padding: custom-space(0.5) custom-space(0.75);
    border-radius: $border-radius;
    border: 1px solid $gray-200;

    > .pl-badge.pl-badge--absolute {
        left: 50%;
        transform: translateX(-50%);
    }

    &__wrapper {
        display: flex;
        flex-direction: column;

        > b {
            font-size: $font-size-base * 0.85;
            font-weight: $font-weight-normal;
            line-height: 1.25;
        }
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: custom-space(1);
        margin-top: custom-space(0.5);

        > h6 {
            margin: 0;
            color: $primary;
        }

        > span {
            font-size: $font-size-base * 0.85;
            font-weight: $font-weight-bold;
        }
    }

    &__controls {
        display: flex;
        flex-direction: column;
        gap: custom-space(0.25);

        .pl-select--sm {
            max-width: 100%;
            margin-top: custom-space(0.25);
        }
    }

    &--added {
        background-color: $yellow-light;
    }
}

.pl-selectable-prep-task {
    display: flex;
    flex-direction: column;
    padding: custom-space(0.25);
    border: 1px solid rgba($gray-200, 0.5);
    border-radius: custom-space(0.5);

    :deep(.pl-alert) {
        margin-top: custom-space(0.5);
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: custom-space(1);
    }

    &__name {
        display: flex;
        flex-direction: column;
        padding: custom-space(0.5);
        padding-right: 0;
        line-height: $font-size-base;

        span {
            font-size: $font-size-base * 0.875;
            font-weight: $font-weight-normal;
        }
    }

    &__qty {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        flex: 0 0 130px;

        :deep(.pl-qty-input) {
            width: 100%;
        }
    }

    &--added {
        background-color: $yellow-light;
    }
}
</style>
