<script>
export default {
    name: 'DistributionTabUI',
};
</script>

<template>
    <section class="pl-distribution-tab">
        <p>
            <slot name="ribbon-text" />
        </p>

        <div class="pl-distribution-tab__content">
            <slot name="content" />
        </div>

        <footer class="pl-distribution-tab__actions">
            <slot name="actions" />
        </footer>
    </section>
</template>

<style lang="scss">
.pl-distribution-tab {
    & > p {
        text-align: center;
    }

    &__content {
        margin-bottom: custom-space(1.25);

        .pl-checkbox .form-check:not(:last-child) {
            margin-bottom: custom-space(1);
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        gap: custom-space(0.5);

        & > button {
            width: 100%;
            padding-top: custom-space(0.75);
            padding-bottom: custom-space(0.75);
        }
    }
}
</style>
