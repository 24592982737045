import axios from 'axios';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';

class ApiClient {
    constructor(options) {
        this.instance = axios.create({
            ...options,
            withCredentials: true,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                Accept: 'application/json',
                ...options.headers,
            },
        });

        this._addResponseInterceptor();
    }

    request(method, url, data = {}, options = {}) {
        const controller = new AbortController();

        const request = this.instance.request({
            url,
            method,
            data,
            signal: controller.signal,
            ...options,
        });

        request.abort = () => controller.abort();

        return request;
    }

    get(url, options = {}) {
        return this.request('get', url, null, options);
    }

    post(url, data = {}, options = {}) {
        return this.request('post', url, data, options);
    }

    put(url, data = {}, options = {}) {
        data._method = 'put';

        if (data instanceof FormData) {
            data.append('_method', 'put');
        }

        return this.post(url, data, options);
    }

    delete(url, options = {}) {
        return this.request('delete', url, null, options);
    }

    setHeaders(headers) {
        Object.assign(this.instance.defaults.headers.common, headers);
    }

    _handleErrorResponse(error) {
        if (axios.isCancel(error)) {
            return Promise.reject(error);
        }

        switch (error?.response?.status) {
            /**
             * Not found errors along with conflict errors are
             * supposed to be handled by displaying the correspondent
             * alert component in the view that makes the request.
             */
            case HTTP_RESPONSES_CODE.NOT_FOUND:
            case HTTP_RESPONSES_CODE.CONFLICT:
                return Promise.reject(error);

            case HTTP_RESPONSES_CODE.FORBIDDEN:
            case HTTP_RESPONSES_CODE.BAD_REQUEST:
            case HTTP_RESPONSES_CODE.UNPROCESSABLE_ENTITY:
                EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                    type: ALERT_TYPES.FAIL,
                    message: error.response.data.message,
                });

                break;

            case HTTP_RESPONSES_CODE.UNAUTHORIZED:
                window.location.href = '/';

                break;

            default:
                EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                    type: ALERT_TYPES.FAIL,
                    message: 'Something went wrong during processing your request.',
                });
        }

        return Promise.reject(error);
    }

    _addResponseInterceptor() {
        this.instance.interceptors.response.use(
            (response) => response,
            (error) => this._handleErrorResponse(error)
        );
    }
}

const client = new ApiClient({
    baseURL: process.env.VUE_APP_API_URL_INTERNAL,
});

export default client;
