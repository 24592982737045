<script>
export default {
    name: 'LocationsIndexView',
};
</script>

<script setup>
import { onBeforeMount, inject, reactive, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { catchNotFoundError } from '@/utils/httpUtils';
import { RESPONSE_DATA_TYPES } from '@/enums/httpEnums';
import { LOCATIONS_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import LocationModel from '@/models/Location';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useConfirmationModal from '@/composition/useConfirmationModal';
import Alert from '@/components/Alert';
import ConfirmationModal from '@/components/ConfirmationModal';
import ListDisplay from '@/components/ListDisplay';
import Tooltip from '@/components/Tooltip';
import LocationCard from '@/components/model-specific/location/LocationCard';

const $acl = inject('$acl');

const router = useRouter();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(LOCATIONS_RESTRICTIONS.VIEW, currentAccount)) {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to locations.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                              Locations state
------------------------------------------------------------------------*/

const locationsState = reactive({
    data: [],
    isDataLoading: true,
    hasBeenJustUpdated: false,
});

const loadLocations = () => {
    sendAbortableRequest(LocationModel.all(orgId))
        .then((models) => (locationsState.data = models || []))
        .finally(() => (locationsState.isDataLoading = false));
};

const updateLocationsState = (models) => {
    locationsState.data = models;
    locationsState.hasBeenJustUpdated = true;

    setTimeout(() => (locationsState.hasBeenJustUpdated = false), 250);
};

const permissions = computed(() => ({
    create: $acl.can(LOCATIONS_RESTRICTIONS.CREATE, currentAccount),
    update: $acl.can(LOCATIONS_RESTRICTIONS.UPDATE, currentAccount),
    delete: $acl.can(LOCATIONS_RESTRICTIONS.DELETE, currentAccount),
}));

onBeforeMount(loadLocations);

/*------------------------------------------------------------------------
                         Confirmation modal state
------------------------------------------------------------------------*/

const { confirmationState, confirmationModal } = useConfirmationModal();

const handleSuccessfulDeletion = (models, enableCloseAfterComplete) => {
    if (enableCloseAfterComplete) {
        updateLocationsState(models);

        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.SUCCESS,
            message: 'The location has been successfully deleted',
        });
    } else {
        window.location.href = '/';
    }
};

const submitDeleteLocation = () => {
    const { model } = confirmationState;

    const enableCloseAfterComplete = currentAccount.location.id !== model.id;

    confirmationModal
        .submit((model) => model.delete({ response_data: RESPONSE_DATA_TYPES.LIST }), enableCloseAfterComplete)
        .then((models) => handleSuccessfulDeletion(models, enableCloseAfterComplete))
        .catch((error) => {
            catchNotFoundError(error, () => {
                const filteredModels = locationsState.data.filter(({ id }) => model.id !== id);

                handleSuccessfulDeletion(filteredModels, enableCloseAfterComplete);
            });
        });
};
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.INDEX">
        <template #title>
            <h1>Locations</h1>
        </template>

        <InPlaceLoader v-if="locationsState.isDataLoading" />

        <Alert
            v-else-if="locationsState.data === null"
            :type="ALERT_TYPES.FAIL"
        >
            Locations could not be loaded for some reason. Please try again later.
        </Alert>

        <template v-else>
            <transition
                appear
                name="down"
                type="transition"
                mode="out-in"
            >
                <template v-if="!locationsState.hasBeenJustUpdated">
                    <ListDisplay
                        v-if="locationsState.data.length > 0"
                        has-borders
                        data-testid="locations_list"
                        :items="locationsState.data"
                    >
                        <template #item="{ item: location }">
                            <LocationCard :location="location">
                                <template
                                    v-if="permissions.update || permissions.delete"
                                    #actions="{ qaPrefix }"
                                >
                                    <router-link
                                        v-if="permissions.update"
                                        :to="{
                                            name: SETTINGS_ROUTE_NAMES.LOCATIONS.EDIT,
                                            params: { locationId: location.id },
                                        }"
                                        :data-testid="`${qaPrefix}_edit_btn`"
                                    >
                                        <EditIcon />
                                    </router-link>

                                    <template v-if="permissions.delete">
                                        <button
                                            v-if="location.can_be_deleted"
                                            type="button"
                                            :data-testid="`${qaPrefix}_delete_btn`"
                                            @click.stop="confirmationModal.open(location)"
                                        >
                                            <DeleteIcon />
                                        </button>

                                        <Tooltip
                                            v-else
                                            enable-transparency
                                            text="You can't delete this location while some accounts are associated with only this location. Please move the accounts to other locations or delete them, before you can delete this location."
                                        >
                                            <DeleteIcon />
                                        </Tooltip>
                                    </template>
                                </template>
                            </LocationCard>
                        </template>
                    </ListDisplay>

                    <p
                        v-else
                        class="pl-locations__message"
                        data-testid="locations_empty_list_message"
                    >
                        Locations list is empty.
                    </p>
                </template>
            </transition>

            <transition
                appear
                name="down"
                type="transition"
            >
                <div
                    v-if="permissions.create"
                    class="pl-locations__action"
                    style="transition-delay: 50ms"
                >
                    <router-link
                        :to="{ name: SETTINGS_ROUTE_NAMES.LOCATIONS.CREATE }"
                        class="pl-btn pl-btn--filled"
                        data-testid="create_location_btn"
                    >
                        Create location
                    </router-link>
                </div>
            </transition>

            <ConfirmationModal
                v-if="confirmationState.isOpened"
                qa-prefix="delete_location_modal"
                :is-pending="confirmationState.isPending"
                @close="confirmationModal.close"
                @submit-delete="submitDeleteLocation"
            >
                Are you sure you want to delete "{{ confirmationState.model.name }}" from the company?
            </ConfirmationModal>
        </template>
    </TopBarLayout>
</template>

<style lang="scss" scoped>
.pl-locations {
    &__message {
        text-align: center;
        padding: custom-space(3) 0;
    }

    &__action {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        margin-top: auto;
        padding-top: custom-space(1);
        width: 50%;

        @include media-breakpoint-down(lg) {
            width: 100%;
        }

        & > a {
            width: 100%;
        }
    }
}
</style>
