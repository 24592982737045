<template>
    <div class="pl-confirmation-code-input">
        <div
            v-for="(segment, index) in segments"
            :key="`segment-${index}`"
            class="pl-confirmation-code-input-segment"
        >
            <input
                ref="$segments"
                v-model="segment.value"
                type="text"
                inputmode="numeric"
                autocomplete="one-time-code"
                name="one-time-code"
                class="form-control text-center px-2"
                :class="{
                    'is-invalid': hasError,
                    'pl-confirmation-code-input-segment--filled': segment.value !== '',
                }"
                :data-testid="`code_text_field_${index + 1}`"
                :disabled="disabled"
                @input="onInput"
                @blur="$emit('blur')"
                @focus="handleFocus"
                @keyup.delete="onDelete"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ConfirmationCodeInput',
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        digitsCount: {
            type: Number,
            default: 4,
        },
        hasError: {
            type: Boolean,
            default: false,
        },
        focused: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue', 'blur'],
    data() {
        return {
            segments:
                typeof this.modelValue === 'string'
                    ? this.modelValue
                          .split('')
                          .slice(0, this.digitsCount)
                          .map((segment) => Object.assign({ value: segment }))
                    : [],
            lastInputErased: false,
        };
    },
    watch: {
        segments: {
            handler: function (segments) {
                this.$emit('update:modelValue', segments.map((segment) => segment.value).join(''));
            },
            deep: true,
        },
        modelValue: function (value) {
            this.segments =
                typeof value === 'string'
                    ? value
                          .split('')
                          .slice(0, this.digitsCount)
                          .map((segment) => Object.assign({ value: segment }))
                    : [];
            this.addMissingFields();
        },
        focused: {
            handler: function (value) {
                this.$nextTick(() => {
                    const firstSegment = this.$refs.$segments[0];

                    if (!(firstSegment instanceof HTMLInputElement)) {
                        return;
                    }

                    if (value) {
                        this.handleFocus();
                    } else {
                        firstSegment.blur();
                    }
                });
            },
            immediate: true,
        },
    },
    created() {
        this.addMissingFields();
    },
    methods: {
        onInput($event) {
            const el = $event.target;
            const oldValue = el.value;
            const newValue = oldValue.replace(/\D/g, '');

            const focusIndex = this.$refs.$segments.indexOf($event.target);
            const isLastInput = focusIndex === this.$refs.$segments.length - 1;

            if (oldValue !== newValue) {
                el.value = newValue;
                el.dispatchEvent(new Event('input', { bubbles: true }));
            }

            if (!newValue && isLastInput) {
                this.lastInputErased = true;
            }

            if (el.value) {
                this.handleFocus();
            }
        },
        handleFocus() {
            for (let input of this.$refs.$segments) {
                if (!input.value) {
                    setTimeout(() => {
                        input.focus();
                        input.click();
                    });
                    
                    return;
                }
            }

            setTimeout(() => this.$refs.$segments[this.$refs.$segments.length - 1].focus(), 0);
        },
        onDelete($event) {
            if (this.lastInputErased) {
                this.lastInputErased = false;
                return;
            }

            if (!$event.target.value) {
                const currentSegmentIndex = this.$refs.$segments.indexOf($event.target);
                const prevSegment = this.$refs.$segments[currentSegmentIndex - 1];

                if (prevSegment) {
                    prevSegment.value = '';
                    prevSegment.dispatchEvent(new Event('input', { bubbles: true }));
                    prevSegment.focus();
                }
            }
        },
        addMissingFields() {
            for (let i = this.digitsCount - this.segments.length; i > 0; i--) {
                this.segments.push({ value: '' });
            }
        },
    },
};
</script>

<style scoped lang="scss">
$offset: calc($spacer / 2);

.pl-confirmation-code-input {
    display: flex;
    margin-left: $offset * -1;
    margin-right: $offset * -1;

    &-segment {
        flex-grow: 1;
        padding-left: $offset;
        padding-right: $offset;
    }

    input {
        text-align: center;
    }

    &-segment--filled {
        border-color: $black;
    }

    .form-control {
        font-size: $input-font-size * 2;
        line-height: 1;
        border-top-width: 0;
        border-right-width: 0;
        border-left-width: 0;
        border-radius: 0;
        padding-top: $input-padding-x * 0.2;
        padding-bottom: $input-padding-x * 0.2;
    }

    .was-validated .form-control:invalid,
    .form-control.is-invalid {
        background-image: none !important;
    }
}
</style>
