<script>
export default {
    name: 'OnboardingStepLayout',
};
</script>

<script setup>
import { computed } from 'vue';

import { catchNotFoundError } from '@/utils/httpUtils';
import { RESPONSE_DATA_TYPES } from '@/enums/httpEnums';
import useConfirmationModal from '@/composition/useConfirmationModal';
import Accordion from '@/components/Accordion';
import ListDisplay from '@/components/ListDisplay';
import ConfirmationModal from '@/components/ConfirmationModal';

const props = defineProps({
    formTitle: {
        type: String,
        required: true,
    },
    listTitle: {
        type: String,
        required: true,
    },
    importBtnText: {
        type: String,
        default: null,
    },
    listItems: {
        type: Array,
        required: true,
    },
    isDataLoading: {
        type: Boolean,
        required: true,
    },
    deleteRequestParams: {
        type: Object,
        default: () => ({}),
    },
});

const emit = defineEmits(['open-import', 'delete-success']);

/*------------------------------------------------------------------------
                               General state
------------------------------------------------------------------------*/

const currentListTitle = computed(() => {
    let title = props.listTitle;

    if (!props.isDataLoading) {
        title += ` (${props.listItems.length})`;
    }

    return title;
});

/*------------------------------------------------------------------------
                         Confirmation modal state
------------------------------------------------------------------------*/

const { confirmationState, confirmationModal } = useConfirmationModal();

const submitDeleteEntity = () => {
    const { model } = confirmationState;

    confirmationModal
        .submit((model) =>
            model.delete({
                ...props.deleteRequestParams,
                response_data: RESPONSE_DATA_TYPES.LIST,
            })
        )
        .then((models) => emit('delete-success', models))
        .catch((error) => {
            catchNotFoundError(error, () => {
                const filteredModels = props.listItems.filter(({ id }) => model.id !== id);

                emit('delete-success', filteredModels);
            });
        });
};
</script>

<template>
    <section class="pl-onboarding-step-layout">
        <BtnUI
            v-if="importBtnText !== null"
            size="sm"
            @click="emit('open-import')"
        >
            {{ importBtnText }}
        </BtnUI>

        <Accordion
            data-test-id="onboarding_list"
            :title="currentListTitle"
        >
            <ListDisplay
                v-if="listItems.length > 0"
                has-borders
                data-testid="onboarding_list"
                :items="listItems"
            >
                <template #item="{ item }">
                    <slot
                        name="list-item"
                        :item="item"
                        :open-delete="confirmationModal.open"
                    />
                </template>
            </ListDisplay>

            <p v-else>
                The list is empty
            </p>
        </Accordion>

        <div class="pl-onboarding-step-layout__wrapper">
            <h5>{{ formTitle }}</h5>

            <slot />
        </div>

        <ConfirmationModal
            v-if="confirmationState.isOpened"
            qa-prefix="delete_entity_modal"
            :is-pending="confirmationState.isPending"
            @close="confirmationModal.close"
            @submit-delete="submitDeleteEntity"
        >
            <slot
                name="confirmation-message"
                :model="confirmationState.model"
            >
                {{ `Are you sure you want to delete "${confirmationState.model.name}" from the company?` }}
            </slot>
        </ConfirmationModal>
    </section>
</template>

<style lang="scss" scoped>
.pl-onboarding-step-layout {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    > .pl-btn {
        width: 100%;
        margin-bottom: custom-space(1);
    }

    &__wrapper {
        margin-top: custom-space(1);
        padding-top: custom-space(1.5);
        border-top: 1px solid $gray-300;

        > h5 {
            text-align: center;
            margin-bottom: custom-space(1.5);
        }

        > :deep(.pl-form) {
            gap: custom-space(2);
            position: relative;
            max-width: 100%;

            .pl-overlay-spinner--sticky > img {
                position: absolute;
            }
        }
    }

    :deep(.pl-accordion) {
        width: 100%;

        .pl-accordion__content > p {
            margin: 0;
            text-align: center;
        }
    }
}
</style>
