export const isFieldEmpty = (value) => {
    if (Array.isArray(value)) {
        return value.length === 0;
    }

    if (typeof value === 'string') {
        return value.trim().length === 0;
    }

    return value === undefined || value === null;
};
