import ApiClient from '@/plugins/ApiClient';
import Model from './Model';

export default class Auth extends Model {
    static requestConfirmationCode(attributes) {
        return ApiClient.post('auth/request-phone-confirmation-code', attributes);
    }

    static login(attributes) {
        return ApiClient.post('auth/login', attributes);
    }

    static logout() {
        return ApiClient.post('auth/logout');
    }

    static acceptInvitationByToken(token) {
        return ApiClient.get('auth/login/invitation', { params: { join_token: token } });
    }
}
