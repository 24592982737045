<script>
export default {
    name: 'LocationForm',
};
</script>

<script setup>
import { ref, reactive, computed, watch, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { getCurrentTimezone } from '@/utils/dateUtils';
import { required, maxLength, minLength } from '@/utils/formValidators';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import AccountModel from '@/models/Account';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useForm from '@/composition/useForm';
import ListDisplay from '@/components/ListDisplay';
import Alert from '@/components/Alert';
import TextInput from '@/components/form-controls/TextInput';
import AddressInput from '@/components/form-controls/AddressInput';
import EmployeeAvatar from '@/components/model-specific/employee/EmployeeAvatar';
import EmployeeCard from '@/components/model-specific/employee/EmployeeCard';
import FormUI from '@/components/UI/FormUI';

const props = defineProps({
    name: {
        type: String,
        default: '',
    },
    address: {
        type: String,
        default: '',
    },
    members: {
        type: Array,
        default: () => [],
    },
    validationErrors: {
        type: Object,
        default: null,
    },
    isDataLoading: {
        type: Boolean,
        default: false,
    },
    isSubmitting: {
        type: Boolean,
        default: false,
    },
    submitBtnText: {
        type: String,
        default: 'save location',
    },
    disableAnimation: {
        type: Boolean,
        default: false,
    },
    excludeEmployees: {
        type: Boolean,
        default: false,
    },
    isResetTriggered: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['reset-success', 'submit']);

const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                               General state
------------------------------------------------------------------------*/

const generalState = reactive({
    accounts: [],
    isDataLoading: false,
    hasError: false,
});

const loadAccounts = () => {
    generalState.isDataLoading = true;

    sendAbortableRequest(AccountModel.all(orgId, { exclude_owner: true }))
        .then((models) => (generalState.accounts = models || []))
        .catch(() => (generalState.hasError = true))
        .finally(() => (generalState.isDataLoading = false));
};

const isLoading = computed(() => generalState.isDataLoading || props.isDataLoading || props.isSubmitting);

onBeforeMount(() => {
    if (!props.excludeEmployees) {
        loadAccounts();
    }
});

/*------------------------------------------------------------------------
                                Setup form
------------------------------------------------------------------------*/

const { formState, ...form } = useForm(
    {
        name: '',
        address: '',
        members: [],
    },
    {
        name: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(255),
        },
        address: {
            minLength: minLength(2),
            maxLength: maxLength(255),
        },
    },
    {
        name: 'Location name',
        address: 'Address',
    }
);

const protectedMemberIds = ref(new Set());
const locationTimezone = ref(null);

const setTimezone = (timezone) => (locationTimezone.value = timezone);

const getLocationTimezone = () => {
    if (locationTimezone.value !== null) {
        return locationTimezone.value;
    }

    return getCurrentTimezone()
};

const submitForm = () => {
    if (form.validate()) {
        emit('submit', {
            ...formState,
            timezone: getLocationTimezone(),
        });
    }
};

const setInitialFormState = () => {
    protectedMemberIds.value.clear();

    const memberIds = [];

    props.members.forEach((member) => {
        memberIds.push(member.id);

        if (!member.can_be_detached) {
            protectedMemberIds.value.add(member.id);
        }
    });

    formState.name = props.name;
    formState.address = props.address;
    formState.members = memberIds;
};

watch(
    () => props.isResetTriggered,
    (isResetTriggered) => {
        if (isResetTriggered) {
            setInitialFormState();

            emit('reset-success');
        }
    }
);

watch(
    () => [generalState.isDataLoading, props.isDataLoading],
    (loadingState) => {
        if (loadingState.every((isLoading) => !isLoading)) {
            setInitialFormState();
        }
    },
    { immediate: true }
);

watch(() => props.validationErrors, form.setValidationErrors);

/*------------------------------------------------------------------------
                               Members state
------------------------------------------------------------------------*/

const getSelectedMemberIndex = (member) => formState.members.findIndex((id) => member.id === id);

const isMemberSelected = (member) => getSelectedMemberIndex(member) !== -1;

const toggleMember = (member) => {
    if (protectedMemberIds.value.has(member.id)) {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.FAIL,
            message: `You can't remove ${member.name} because this employee is associated with only this location.`,
        });

        return;
    }

    const currentMemberIndex = getSelectedMemberIndex(member);

    if (currentMemberIndex !== -1) {
        formState.members.splice(currentMemberIndex, 1);
    } else {
        formState.members.push(member.id);
    }
};

const selectedMembers = computed(() => generalState.accounts.filter(isMemberSelected));

/*------------------------------------------------------------------------
                                  Swiper
------------------------------------------------------------------------*/

const swiperSettings = {
    modules: [Navigation],
    spaceBetween: 10,
    style: {
        width: '100%',
        height: '100%',
    },
    breakpoints: {
        1: {
            slidesPerView: 4,
        },
        400: {
            slidesPerView: 5,
        },
        480: {
            slidesPerView: 6,
        },
        580: {
            slidesPerView: 5,
        },
    },
};
</script>

<template>
    <Alert
        v-if="generalState.hasError"
        :type="ALERT_TYPES.FAIL"
    >
        The data could not be loaded for some reason. Please try again later.
    </Alert>

    <FormUI
        v-else
        data-test-id="location_form"
        :disable-animation="disableAnimation"
        :is-loading="isLoading"
        @submit="submitForm"
    >
        <template #content="{ classNames, qaPrefix }">
            <div :class="classNames.spacerMd">
                <TextInput
                    v-model="formState.name"
                    include-asterisk
                    label="Name of Location"
                    name="name"
                    autocomplete="name"
                    :data-test-id="`${qaPrefix}_name_input`"
                    :disabled="isLoading"
                    :has-error="form.hasErrors('name')"
                    @blur="form.validate('name')"
                    @update:modelValue="form.clearErrors('name')"
                />

                <ValidationErrors
                    v-if="form.hasErrors('name')"
                    :data-testid="`${qaPrefix}_name_error`"
                    :errors="form.getErrors('name')"
                />
            </div>

            <div :class="classNames.spacerLg">
                <AddressInput
                    v-model="formState.address"
                    :data-test-id="`${qaPrefix}_address_input`"
                    :disabled="isLoading"
                    :has-error="form.hasErrors('address')"
                    @set-timezone="setTimezone"
                    @input="form.clearErrors('address')"
                    @blur="form.clearErrors('address')"
                />

                <ValidationErrors
                    v-if="form.hasErrors('address')"
                    :data-testid="`${qaPrefix}_address_error`"
                    :errors="form.getErrors('address')"
                />
            </div>

            <transition-group
                appear
                name="down"
                type="transition"
            >
                <template v-if="!excludeEmployees && !generalState.isDataLoading && !isDataLoading">
                    <div
                        key="selected_employees"
                        :class="classNames.spacerLg"
                    >
                        <h5>Employees ({{ selectedMembers.length }})</h5>

                        <div
                            class="pl-selected-employees"
                            :class="{
                                'pl-selected-employees--empty': selectedMembers.length === 0,
                            }"
                        >
                            <small
                                v-if="selectedMembers.length === 0"
                                :data-testid="`${qaPrefix}_selected_employees_list_empty_note`"
                            >
                                Please select at least one employee.
                            </small>

                            <Swiper
                                v-else
                                navigation
                                :data-testid="`${qaPrefix}_selected_employees_list`"
                                :modules="swiperSettings.modules"
                                :space-between="swiperSettings.spaceBetween"
                                :style="swiperSettings.style"
                                :breakpoints="swiperSettings.breakpoints"
                            >
                                <SwiperSlide
                                    v-for="(employee, index) in selectedMembers"
                                    :key="`selected employee: ${index}`"
                                    class="pl-selected-employee-card"
                                >
                                    <div class="pl-selected-employee-card__avatar">
                                        <EmployeeAvatar :url="employee.avatar_path" />

                                        <button
                                            type="button"
                                            :data-testid="`${qaPrefix}_remove_employee_btn`"
                                            @click="toggleMember(employee)"
                                        >
                                            <CrossIcon
                                                :width="8"
                                                :height="8"
                                            />
                                        </button>
                                    </div>

                                    <small :data-testid="`${qaPrefix}_employee_name`">
                                        {{ employee.name }}
                                    </small>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>

                    <div
                        key="employees_list_wrapper"
                        class="pl-employees-list-wrapper"
                    >
                        <ListDisplay
                            v-if="generalState.accounts.length"
                            data-testid="employees_list"
                            :items="generalState.accounts"
                        >
                            <template #item="{ item: employee }">
                                <EmployeeCard
                                    :employee="employee"
                                    :data-qa-selected="isMemberSelected(employee) ? 'selected' : 'unselected'"
                                    @click="toggleMember(employee)"
                                >
                                    <template
                                        v-if="isMemberSelected(employee)"
                                        #avatar-badge
                                    >
                                        <div
                                            class="pl-employee-card__check-badge"
                                            :data-testid="`employee_check_badge`"
                                        >
                                            <CheckIcon
                                                :width="10"
                                                :height="10"
                                            />
                                        </div>
                                    </template>
                                </EmployeeCard>
                            </template>
                        </ListDisplay>

                        <p
                            v-else
                            :data-testid="`${qaPrefix}_employees_list_empty_note`"
                        >
                            Employees list is empty.
                        </p>
                    </div>
                </template>
            </transition-group>
        </template>

        <template #actions="{ qaPrefix }">
            <BtnUI
                is-filled
                type="submit"
                :data-testid="`${qaPrefix}_submit_btn`"
                :disabled="isLoading || form.isDisabled()"
            >
                {{ submitBtnText }}
            </BtnUI>
        </template>
    </FormUI>
</template>

<style lang="scss" scoped>
.pl-form__spacer--lg > h5 {
    margin-bottom: 0;
}

.pl-employees-list-wrapper {
    flex: 1 1 auto;
    height: custom-space(13.5);
    overflow-y: auto;

    > p {
        text-align: center;
        margin: 0;
    }

    :deep(.pl-list-display__item):not(:last-child) {
        margin-bottom: custom-space(0.5);
    }
}

.pl-selected-employees {
    position: relative;
    padding: custom-space(1) custom-space(2);
    border-bottom: 1px solid $gray-200;

    :deep(.swiper) {
        position: static;
    }

    &:has(.swiper-button-lock) {
        padding-left: 0;
        padding-right: 0;
    }

    &--empty {
        text-align: center;
        padding: custom-space(1.5) 0;
    }
}

.pl-selected-employee-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 100%;
    text-align: center;

    &__avatar {
        position: relative;
        display: flex;
        margin-bottom: custom-space(0.375);

        & > button {
            $size: 16px;

            position: absolute;
            bottom: 0;
            right: custom-space(-0.125);
            width: $size;
            height: $size;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $red-light;
            box-shadow: 0 0 0 1px $white;
            border: none;
            border-radius: 50%;
            line-height: 0;

            & > svg :deep(path) {
                fill: $white;
            }
        }
    }

    & > small {
        max-width: 90%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: $font-weight-normal;
        line-height: 1;
    }
}

:deep(.pl-employee-card) {
    cursor: pointer;

    .pl-employee-card__avatar {
        position: relative;
    }

    .pl-employee-card__check-badge {
        $size: 16px;

        position: absolute;
        bottom: 0;
        right: custom-space(-0.125);
        display: flex;
        align-items: center;
        justify-content: center;
        width: $size;
        height: $size;
        background-color: $teal;
        border-radius: 50%;
        box-shadow: 0 0 0 1px $white;
        border: none;
        line-height: 0;
    }

    .pl-employee-card__content {
        & > span {
            font-weight: $font-weight-bolder;
        }

        & > b {
            font-weight: $font-weight-light;
        }
    }
}
</style>
