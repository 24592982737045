<script>
export default {
    name: 'TimelineDatesSlider',
};
</script>

<script setup>
import { computed, ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import dayjs from 'dayjs';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

import { isToday, formatDate } from '@/utils/dateUtils';
import CarouselArrowIcon from '@/components/icons/CarouselArrowIcon';

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
    },
});

const emit = defineEmits(['slide-start', 'update:modelValue']);

const router = useRouter();
const route = useRoute();

/*------------------------------------------------------------------------
                                  Swiper
------------------------------------------------------------------------*/

const swiperSettings = {
    modules: [Navigation],
    slidesPerView: 1,
    speed: 350,
};

const swiperInstance = ref(null);

const onSwiperInit = (swiper) => (swiperInstance.value = swiper);

const dates = computed(() => {
    const startDate = dayjs(props.modelValue);

    return [startDate.subtract(1, 'day'), startDate, startDate.add(1, 'day')].map((date) => formatDate(date));
});

const onSlideChanged = () => {
    if (swiperInstance.value) {
        const { activeIndex } = swiperInstance.value;

        const activeDate = dates.value[activeIndex];

        router.push({
            query: {
                ...route.query,
                date: activeDate,
            },
        });

        emit('update:modelValue', activeDate);
    }
};

const onNextSlide = () => swiperInstance.value.slideNext();
const onPrevSlide = () => swiperInstance.value.slidePrev();

watch(
    () => props.modelValue,
    () => {
        if (swiperInstance.value) {
            swiperInstance.value.slideTo(1, 0, false);
        }
    }
);
</script>

<template>
    <div class="pl-timeline-dates-slider">
        <div class="container">
            <Swiper
                centered-slides
                :initial-slide="1"
                :modules="swiperSettings.modules"
                :slides-per-view="swiperSettings.slidesPerView"
                :speed="swiperSettings.speed"
                @slide-change-transition-start="emit('slide-start')"
                @slide-change-transition-end="onSlideChanged"
                @swiper="onSwiperInit"
            >
                <SwiperSlide
                    v-for="date in dates"
                    :key="`date: ${date}`"
                >
                    <div class="pl-timeline-dates-slider__date">
                        <span data-testid="timeline_current_date">
                            {{ formatDate(date, 'MMM D, YYYY') }}
                        </span>

                        <small v-if="isToday(date)">Today</small>
                    </div>
                </SwiperSlide>

                <button
                    type="button"
                    class="pl-timeline-dates-slider__prev-btn"
                    @click="onPrevSlide"
                >
                    <CarouselArrowIcon />
                </button>

                <button
                    type="button"
                    class="pl-timeline-dates-slider__next-btn"
                    @click="onNextSlide"
                >
                    <CarouselArrowIcon />
                </button>
            </Swiper>
        </div>
    </div>
</template>

<style scoped lang="scss">
.pl-timeline-dates-slider {
    display: flex;
    align-items: center;
    min-height: custom-space(3.75);
    padding-top: custom-space(0.5);
    padding-bottom: custom-space(0.5);
    background-color: $white;
    margin-top: custom-space(-1.5);
    margin-bottom: custom-space(0.5);
    border-bottom: 1px solid $gray-200;

    &__prev-btn,
    &__next-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;

        & > button {
            padding: custom-space(0.25) custom-space(0.5);
            margin: custom-space(-0.25) custom-space(-0.5);
        }
    }

    &__prev-btn {
        left: 0;
    }

    &__next-btn {
        right: 0;

        svg {
            transform: rotate(-180deg);
        }
    }

    &__date {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        user-select: none;
        font-weight: $font-weight-normal;

        > small {
            color: $primary;
        }
    }
}

:deep(.swiper-wrapper) {
    align-items: center;
}

:deep(.swiper-slide) {
    text-align: center;
}
</style>
