<script>
export default {
    name: 'DepartmentForm',
};
</script>

<script setup>
import { reactive, ref, computed, watch, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

import { required, maxLength, minLength } from '@/utils/formValidators';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import AccountModel from '@/models/Account';
import useForm from '@/composition/useForm';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useConfirmationModal from '@/composition/useConfirmationModal';
import Alert from '@/components/Alert';
import ListDisplay from '@/components/ListDisplay';
import ConfirmationModal from '@/components/ConfirmationModal';
import TextInput from '@/components/form-controls/TextInput';
import EmployeeAvatar from '@/components/model-specific/employee/EmployeeAvatar';
import EmployeeCard from '@/components/model-specific/employee/EmployeeCard';
import FormUI from '@/components/UI/FormUI';

const props = defineProps({
    name: {
        type: String,
        default: '',
    },
    members: {
        type: Array,
        default: () => [],
    },
    validationErrors: {
        type: Object,
        default: null,
    },
    isDataLoading: {
        type: Boolean,
        default: false,
    },
    isSubmitting: {
        type: Boolean,
        default: false,
    },
    submitBtnText: {
        type: String,
        default: 'save department',
    },
    disableAnimation: {
        type: Boolean,
        default: false,
    },
    isResetTriggered: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['reset-success', 'submit']);

const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                               General state
------------------------------------------------------------------------*/

const generalState = reactive({
    accounts: [],
    isDataLoading: true,
    hasError: false,
});

const loadAccounts = () => {
    sendAbortableRequest(AccountModel.all(orgId, { exclude_owner: true }))
        .then((models) => (generalState.accounts = models))
        .catch(() => (generalState.hasError = true))
        .finally(() => (generalState.isDataLoading = false));
};

const isLoading = computed(() => generalState.isDataLoading || props.isDataLoading || props.isSubmitting);

onBeforeMount(loadAccounts);

/*------------------------------------------------------------------------
                                Setup form
------------------------------------------------------------------------*/

const { formState, ...form } = useForm(
    {
        name: '',
        members: [],
    },
    {
        name: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(255),
        },
    },
    {
        name: 'Department name',
    }
);

const initialMemberIds = ref([]);

const submitForm = () => {
    if (form.validate()) {
        const { members, ...restPayload } = formState;

        emit('submit', {
            ...restPayload,
            members: members.map(({ id }) => id),
        });
    }
};

const setInitialFormState = () => {
    const { members } = props;

    formState.name = props.name;
    formState.members = [...members];

    initialMemberIds.value = members.map(({ id }) => id);
};

watch(
    () => props.isResetTriggered,
    (isResetTriggered) => {
        if (isResetTriggered) {
            setInitialFormState();

            emit('reset-success');
        }
    }
);

watch(
    () => [generalState.isDataLoading, props.isDataLoading],
    (loadingState) => {
        if (loadingState.every((isLoading) => !isLoading)) {
            setInitialFormState();
        }
    },
    { immediate: true }
);

watch(() => props.validationErrors, form.setValidationErrors);

/*------------------------------------------------------------------------
                                  Members
------------------------------------------------------------------------*/

const getSelectedMemberIndex = (member) =>
    formState.members.findIndex(
        (addedMember) =>
            addedMember.phone.phone_country_id === member.phone.phone_country_id &&
            addedMember.phone.phone === member.phone.phone
    );

const isMemberSelected = (member) => getSelectedMemberIndex(member) !== -1;

const toggleMember = (member) => {
    const currentMemberIndex = getSelectedMemberIndex(member);

    if (currentMemberIndex !== -1) {
        if (initialMemberIds.value.includes(member.id) && !!member.joined_at) {
            confirmationModal.open(member);
        } else {
            formState.members.splice(currentMemberIndex, 1);
        }
    } else {
        formState.members.push({
            id: member.id,
            phone: {
                phone: member.phone.phone,
                phone_country_id: member.phone.phone_country_id,
            },
        });
    }
};

const selectedMembers = computed(() =>
    generalState.accounts.filter((account) => getSelectedMemberIndex(account) !== -1)
);

/*------------------------------------------------------------------------
                                  Swiper
------------------------------------------------------------------------*/

const swiperSettings = {
    modules: [Navigation],
    spaceBetween: 10,
    style: {
        width: '100%',
        height: '100%',
    },
    breakpoints: {
        1: {
            slidesPerView: 4,
        },
        400: {
            slidesPerView: 5,
        },
        480: {
            slidesPerView: 6,
        },
        580: {
            slidesPerView: 5,
        },
    },
};

/*------------------------------------------------------------------------
                         Confirmation modal state
------------------------------------------------------------------------*/

const { confirmationState, confirmationModal } = useConfirmationModal();

const submitDeleteMember = () => {
    confirmationModal.submit((model) => {
        formState.members.splice(getSelectedMemberIndex(model), 1);

        return Promise.resolve();
    });
};
</script>

<template>
    <Alert
        v-if="generalState.hasError"
        :type="ALERT_TYPES.FAIL"
    >
        The data could not be loaded for some reason. Please try again later.
    </Alert>

    <FormUI
        v-else
        data-test-id="department_form"
        :disable-animation="disableAnimation"
        :is-loading="isLoading"
        @submit="submitForm"
    >
        <template #content="{ classNames, qaPrefix }">
            <div :class="classNames.spacerLg">
                <TextInput
                    v-model="formState.name"
                    include-asterisk
                    label="Name of Department"
                    name="name"
                    autocomplete="name"
                    :data-test-id="`${qaPrefix}_name_input`"
                    :disabled="isLoading"
                    :has-error="form.hasErrors('name')"
                    @blur="form.validate('name')"
                    @update:modelValue="form.clearErrors('name')"
                />

                <ValidationErrors
                    v-if="form.hasErrors('name')"
                    :data-testid="`${qaPrefix}_name_error`"
                    :errors="form.getErrors('name')"
                />
            </div>

            <transition-group
                appear
                name="down"
                type="transition"
            >
                <template v-if="!generalState.isDataLoading && !isDataLoading">
                    <div
                        key="selected_employees"
                        :class="classNames.spacerLg"
                    >
                        <h5>Employees ({{ formState.members.length }})</h5>

                        <div
                            class="pl-selected-employees"
                            :class="{
                                'pl-selected-employees--empty': selectedMembers.length === 0,
                            }"
                        >
                            <small
                                v-if="selectedMembers.length === 0"
                                :data-testid="`${qaPrefix}_selected_employees_list_empty_note`"
                            >
                                Please select at least one employee.
                            </small>

                            <Swiper
                                v-else
                                navigation
                                :data-testid="`${qaPrefix}_selected_employees_list`"
                                :modules="swiperSettings.modules"
                                :space-between="swiperSettings.spaceBetween"
                                :style="swiperSettings.style"
                                :breakpoints="swiperSettings.breakpoints"
                            >
                                <SwiperSlide
                                    v-for="(employee, index) in selectedMembers"
                                    :key="`selected employee: ${index}`"
                                    class="pl-selected-employee-card"
                                >
                                    <div class="pl-selected-employee-card__avatar">
                                        <EmployeeAvatar :url="employee.avatar_path" />

                                        <button
                                            type="button"
                                            :data-testid="`${qaPrefix}_remove_employee_btn`"
                                            @click="toggleMember(employee)"
                                        >
                                            <CrossIcon
                                                :width="8"
                                                :height="8"
                                            />
                                        </button>
                                    </div>

                                    <small :data-testid="`${qaPrefix}_employee_name`">
                                        {{ employee.name }}
                                    </small>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>

                    <div
                        key="employees_list_wrapper"
                        class="pl-employees-list-wrapper"
                    >
                        <ListDisplay
                            v-if="generalState.accounts.length > 0"
                            data-testid="employees_list"
                            :items="generalState.accounts"
                        >
                            <template #item="{ item: employee }">
                                <EmployeeCard
                                    :employee="employee"
                                    :data-qa-selected="isMemberSelected(employee) ? 'selected' : 'unselected'"
                                    @click="toggleMember(employee)"
                                >
                                    <template
                                        v-if="isMemberSelected(employee)"
                                        #avatar-badge
                                    >
                                        <div
                                            class="pl-employee-card__check-badge"
                                            data-testid="employee_check_badge"
                                        >
                                            <CheckIcon
                                                :width="10"
                                                :height="10"
                                            />
                                        </div>
                                    </template>
                                </EmployeeCard>
                            </template>
                        </ListDisplay>

                        <p
                            v-else
                            :data-testid="`${qaPrefix}_employees_list_empty_note`"
                        >
                            Employees list is empty.
                        </p>
                    </div>
                </template>
            </transition-group>
        </template>

        <template #actions="{ qaPrefix }">
            <BtnUI
                is-filled
                type="submit"
                :data-testid="`${qaPrefix}_submit_btn`"
                :disabled="isLoading || form.isDisabled()"
            >
                {{ submitBtnText }}
            </BtnUI>
        </template>
    </FormUI>

    <ConfirmationModal
        v-if="confirmationState.isOpened"
        qa-prefix="delete_department_member_modal"
        :is-pending="confirmationState.isPending"
        @close="confirmationModal.close"
        @submit-delete="submitDeleteMember"
    >
        <p>Are you sure you want to delete "{{ confirmationState.model.name }}" from the department?</p>

        <b v-if="confirmationState.model.joined_at !== null">
            Unfinished orders, that has an item distributed to the employee, may not be finished.
        </b>
    </ConfirmationModal>
</template>

<style lang="scss" scoped>
.pl-form__spacer--lg > h5 {
    margin-bottom: 0;
}

.pl-employees-list-wrapper {
    flex: 1 1 auto;
    height: custom-space(13.5);
    overflow-y: auto;

    > p {
        text-align: center;
        margin: 0;
    }

    :deep(.pl-list-display__item):not(:last-child) {
        margin-bottom: custom-space(0.5);
    }
}

.pl-selected-employees {
    position: relative;
    padding: custom-space(1) custom-space(2);
    border-bottom: 1px solid $gray-200;

    :deep(.swiper) {
        position: static;
    }

    &:has(.swiper-button-lock) {
        padding-left: 0;
        padding-right: 0;
    }

    &--empty {
        text-align: center;
        padding: custom-space(1.5) 0;
    }
}

.pl-selected-employee-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 100%;
    text-align: center;

    &__avatar {
        position: relative;
        display: flex;
        margin-bottom: custom-space(0.375);

        & > button {
            $size: 16px;

            position: absolute;
            bottom: 0;
            right: custom-space(-0.125);
            width: $size;
            height: $size;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $red-light;
            box-shadow: 0 0 0 1px $white;
            border: none;
            border-radius: 50%;
            line-height: 0;

            & > svg :deep(path) {
                fill: $white;
            }
        }
    }

    & > small {
        max-width: 90%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: $font-weight-normal;
        line-height: 1;
    }
}

:deep(.pl-employee-card) {
    cursor: pointer;

    .pl-employee-card__avatar {
        position: relative;
    }

    .pl-employee-card__check-badge {
        $size: 16px;

        position: absolute;
        bottom: 0;
        right: custom-space(-0.125);
        display: flex;
        align-items: center;
        justify-content: center;
        width: $size;
        height: $size;
        background-color: $teal;
        border-radius: 50%;
        box-shadow: 0 0 0 1px $white;
        border: none;
        line-height: 0;
    }

    .pl-employee-card__content {
        & > span {
            font-weight: $font-weight-bolder;
        }

        & > b {
            font-weight: $font-weight-light;
        }
    }
}
</style>
