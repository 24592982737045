<script>
export default {
    name: 'Accordion',
};
</script>

<script setup>
import { ref } from 'vue';

defineProps({
    title: {
        type: String,
        required: true,
    },
    contentModifierPrefix: {
        type: String,
        default: '',
    },
    hasError: {
        type: Boolean,
        default: false,
    },
    dataTestId: {
        type: String,
        required: true,
    },
});

/*------------------------------------------------------------------------
                              Accordion state
------------------------------------------------------------------------*/

const rootRef = ref(null);
const isAccordionOpened = ref(false);

const toggleAccordionContent = () => (isAccordionOpened.value = !isAccordionOpened.value);

/*------------------------------------------------------------------------
                            Accordion animation
------------------------------------------------------------------------*/

const transitionDuration = 400;

const shouldAnimate = () => window.innerWidth >= 580;

const beforeEnter = (el) => {
    if (!shouldAnimate()) {
        return;
    }

    el.style.height = '0';
    el.style.opacity = '0';
    el.style.overflow = 'hidden';
};

const enter = (el, done) => {
    if (!shouldAnimate()) {
        return done();
    }

    requestAnimationFrame(() => {
        el.style.transition = `height ${transitionDuration}ms ease`;
        el.style.height = `${el.scrollHeight}px`;
        el.style.padding = '0';

        setTimeout(() => {
            el.style.transition += `, opacity ${transitionDuration}ms ease`;
            el.style.opacity = '1';
            el.style.padding = '';
        }, transitionDuration / 8);

        const clearTransition = () => {
            el.style.transition = '';
            el.removeEventListener('transitionend', clearTransition);

            done();
        };

        el.addEventListener('transitionend', clearTransition);
    });
};

const afterEnter = (el) => {
    if (!shouldAnimate()) {
        return;
    }

    el.style.height = '';
    el.style.opacity = '';
    el.style.overflow = '';
};

const beforeLeave = (el) => {
    if (!shouldAnimate()) {
        return;
    }

    el.style.height = `${el.scrollHeight}px`;
    el.style.opacity = '1';
    el.style.overflow = 'hidden';
};

const leave = (el, done) => {
    if (!shouldAnimate()) {
        return done();
    }

    requestAnimationFrame(() => {
        el.style.transition = `height ${transitionDuration}ms ease, opacity ${transitionDuration}ms ease`;
        el.style.height = '0';
        el.style.opacity = '0';

        setTimeout(() => {
            el.style.transition = `height ${transitionDuration}ms ease`;
            el.style.padding = '0';
        }, transitionDuration / 3);

        const clearTransition = () => {
            el.style.transition = '';
            el.removeEventListener('transitionend', clearTransition);

            done();
        };

        el.addEventListener('transitionend', clearTransition);
    });
};

const afterLeave = (el) => {
    if (!shouldAnimate()) {
        return;
    }

    el.style.height = '';
    el.style.opacity = '';
    el.style.overflow = '';
};
</script>

<template>
    <div
        ref="rootRef"
        class="pl-accordion"
        :class="{
            'pl-accordion--opened': isAccordionOpened,
            'pl-accordion--has-error': hasError,
        }"
    >
        <div
            class="pl-accordion__top-section"
            :data-testid="`${dataTestId}_accordion`"
            @click="toggleAccordionContent"
        >
            <h6>{{ title }}</h6>

            <ArrowDownIcon
                :width="13"
                :height="8"
            />
        </div>

        <transition
            @before-enter="beforeEnter"
            @enter="enter"
            @after-enter="afterEnter"
            @before-leave="beforeLeave"
            @leave="leave"
            @after-leave="afterLeave"
        >
            <div
                v-if="isAccordionOpened"
                class="pl-accordion__content"
                :class="{
                    [`pl-accordion__content--${contentModifierPrefix}`]: contentModifierPrefix.length > 0,
                }"
            >
                <slot />
            </div>
        </transition>
    </div>
</template>

<style lang="scss">
.pl-accordion {
    background-color: $white;
    padding: custom-space(0.4) custom-space(0.75);
    border: 1px solid $gray-200;
    border-radius: $border-radius;
    @include pl-transition(border-color);

    &__top-section {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            left: custom-space(-0.75);
            right: custom-space(-0.75);
            bottom: custom-space(-0.25);
            height: 1px;
            background-color: transparent;

            @include media-breakpoint-up(xl) {
                @include pl-transition(background-color);
            }
        }

        h6 {
            flex-grow: 1;
            font-weight: $font-weight-normal;
            margin-bottom: 0;
            padding: custom-space(0.25) 0;
            user-select: none;
            @include pl-transition(color);
        }

        svg {
            flex-shrink: 0;
            @include pl-transition(transform);
        }
    }

    &__content {
        padding: custom-space(0.75) 0 custom-space(0.2) 0;
    }

    &--opened &__top-section {
        &::after {
            background-color: $gray-200;
        }

        svg {
            transform: rotate(-180deg);
        }
    }

    &--has-error {
        border-color: $red-light;

        .pl-accordion__top-section h6 {
            color: $danger;
        }
    }
}
</style>
