<script>
export default {
    name: 'TimelineCookItemCard',
};
</script>

<script setup>
import { computed } from 'vue';

import { getPLOrderItemOrderedQtyDisplay, getPLOrderItemProducedQtyDisplay } from '@/utils/orderItemUtils';
import { DISTRIBUTION_TYPES } from '@/enums/selectableItemsEnums';
import { BADGE_TYPES } from '@/enums/componentsEnums';
import Badge from '@/components/Badge';
import Progress from '@/components/Progress';
import TimelineCardUI from '@/components/model-specific/orders/TimelineCardUI';
import OrderItemDistributionDetails from '@/components/model-specific/prep-lists/OrderItemDistributionDetails';
import MultipleUsersIcon from '@/components/icons/MultipleUsersIcon';

const props = defineProps({
    orderItem: {
        type: Object,
        required: true,
    },
    isReportingDisabled: {
        type: Boolean,
        default: false,
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['init-report-item']);

/*------------------------------------------------------------------------
                             Order item state
------------------------------------------------------------------------*/

const orderItemState = computed(() => {
    const { orderItem } = props;
    const { status } = orderItem;

    const isOverproduction = status === 'overproduction';
    const isOther = isOverproduction || status === 'only_reported';

    const orderedQtyDisplay = getPLOrderItemOrderedQtyDisplay(orderItem);
    const producedQtyDisplay = getPLOrderItemProducedQtyDisplay(orderItem);

    const progressQtyDisplay = isOther
        ? producedQtyDisplay
        : `${producedQtyDisplay} / ${orderedQtyDisplay}`;

    return {
        isOverproduction,
        isOther,
        progressQtyDisplay,
    };
});
</script>

<template>
    <TimelineCardUI
        class="pl-timeline-report-item-card"
        :is-completed="orderItem.is_completed"
        :is-overproduction="orderItemState.isOther"
    >
        <template #header-content="{ classNames }">
            <OverlayLoader v-if="isLoading" />

            <Badge
                v-if="orderItemState.isOverproduction"
                is-absolute
                size="sm"
                :type="BADGE_TYPES.ORDER_CARD"
            >
                overproduction
            </Badge>

            <div :class="classNames.name">
                <h2>
                    {{ orderItem.name }}
                </h2>

                <div
                    v-if="!orderItem.is_completed"
                    class="pl-timeline-report-item-card__actions"
                >
                    <button
                        type="button"
                        :disabled="isReportingDisabled"
                        :class="classNames.actionBtn"
                        @click="emit('init-report-item', orderItem, true)"
                    >
                        <span>Done</span>
                    </button>

                    <button
                        type="button"
                        :disabled="isReportingDisabled"
                        :class="classNames.actionBtn"
                        @click="emit('init-report-item', orderItem)"
                    >
                        <SettingsIcon
                            :height="20"
                            :width="20"
                        />
                    </button>
                </div>

                <button
                    v-else
                    type="button"
                    :disabled="isReportingDisabled"
                    :class="classNames.actionBtn"
                    @click="emit('init-report-item', orderItem)"
                >
                    <CheckIcon
                        v-if="orderItem.is_completed && !orderItemState.isOther"
                        :width="16"
                        :height="16"
                    />

                    <span>
                        {{
                            orderItem.is_completed && !orderItemState.isOther
                                ? 'done'
                                : 'report'
                        }}
                    </span>
                </button>
            </div>
        </template>

        <template #main-content>
            <div class="pl-timeline-report-item-card__progress-wrapper">
                <Progress
                    v-if="!orderItemState.isOther"
                    :min="0"
                    :max="orderItem.ordered_BY_qty"
                    :value="orderItem.produced_BY_qty"
                />

                <span>{{ orderItemState.progressQtyDisplay }}</span>
            </div>
        </template>

        <template
            v-if="orderItem.distribution_type === DISTRIBUTION_TYPES.SHARE || orderItem.distribution.length > 0"
            #footer-content
        >
            <template v-if="orderItem.distribution_type === DISTRIBUTION_TYPES.SHARE">
                <MultipleUsersIcon />

                <small>Together with: {{ orderItem.with_display }}</small>
            </template>

            <OrderItemDistributionDetails
                v-else
                :order-item="orderItem"
            />
        </template>
    </TimelineCardUI>
</template>

<style lang="scss" scoped>
.pl-timeline-report-item-card {
    &__actions {
        display: flex;

        & > button:first-child {
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        & > button:last-child {
            position: relative;
            padding: custom-space(0.25) custom-space(0.575);
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            svg :deep(path) {
                fill: $primary;
            }

            &::after {
                $space: 5px;

                content: '';
                position: absolute;
                top: $space;
                bottom: $space;
                left: 0;
                background-color: $primary;
                width: 1px;
            }
        }
    }

    &__progress-wrapper {
        .pl-progress {
            margin-bottom: custom-space(0.3);
        }

        span {
            color: $primary;
            font-weight: $font-weight-normal;
        }
    }
}

.pl-timeline-card {
    padding-top: custom-space(0.5);

    :deep(.pl-timeline-card__name) {
        align-items: flex-end;
        justify-content: space-between;

        h2 {
            color: $black;
            line-height: $font-size-base * 1.3;
            padding-right: custom-space(1);
        }
    }

    :deep(.pl-timeline-card__action-btn) {
        &:disabled,
        &[disabled] {
            opacity: 0.5;
        }
    }

    :deep(.pl-timeline-card__footer) > svg {
        flex-shrink: 0;
        margin-right: custom-space(0.5);
    }

    &--completed,
    &--overproduction {
        .pl-timeline-report-item-card__progress-wrapper span,
        :deep(.pl-timeline-card__name) h2,
        :deep(.pl-timeline-card__footer) small {
            color: $white;
        }

        :deep(.pl-timeline-card__footer) > svg path {
            fill: $white;
        }

        :deep(.pl-order-item-distribution) {
            > button {
                span {
                    color: $white;
                    border-bottom-color: $white;
                }

                svg {
                    fill: $white;
                }
            }

            > ul {
                color: $white;
            }
        }
    }

    &--completed .pl-progress {
        border: 1px solid lighten($primary, 15%);
    }

    &--overproduction {
        :deep(.pl-timeline-card__header) {
            margin-bottom: custom-space(0.25);
        }

        :deep(.pl-timeline-card__name) h2 {
            margin-top: custom-space(1);
        }
    }
}
</style>
