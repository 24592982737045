<script>
export default {
    name: 'FormControlHint',
};
</script>

<script setup>
import Tooltip from '@/components/Tooltip';
import HintIcon from '@/components/icons/HintIcon';

defineProps({
    text: {
        type: String,
        required: true,
    },
});
</script>

<template>
    <Tooltip
        enable-wide-content
        class="pl-form-control-hint"
        :text="text"
    >
        <HintIcon
            :height="23"
            :width="23"
        />
    </Tooltip>
</template>

<style lang="scss" scoped>
.pl-form-control-hint {
    position: absolute;
    top: 0;
    right: custom-space(0.625);
    background-color: $white;
    border-radius: 50%;
    box-shadow: 0 0 0 1px $white;
    transform: translateY(-50%);
    z-index: 33;
}
</style>
