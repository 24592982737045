import ApiClient from '@/plugins/ApiClient';
import { mapLeafs } from '@/utils/treeUtils';
import { isObject } from '@/utils/objectUtils';
import Model from './Model';
import DepartmentModel from './Department';
import CategoryModel from './Category';

export default class Item extends Model {
    static make(attributes = {}) {
        if (Array.isArray(attributes)) {
            return attributes.map((nested) => new this(nested));
        } else if (isObject(attributes) && attributes.id !== undefined) {
            return new this(attributes);
        }

        return mapLeafs(attributes, (item) => new this(item));
    }

    static all(orgId, query = {}) {
        return this.withAbort(ApiClient.get(`organizations/${orgId}/items`, { params: query }), ({ data }) =>
            this.make(data.data)
        );
    }

    static find(orgId, itemId, query = {}) {
        return this.withAbort(ApiClient.get(`organizations/${orgId}/items/${itemId}`, { params: query }), ({ data }) =>
            this.make(data)
        );
    }

    static async create(orgId, attributes, query = {}) {
        const { data } = await ApiClient.post(`organizations/${orgId}/items`, attributes, { params: query });

        return this.make(data);
    }

    async update(attributes) {
        const { data } = await ApiClient.put(`organizations/${this.organization_id}/items/${this.id}`, attributes);

        return this.fill(data);
    }

    async delete(query = {}) {
        const { data } = await ApiClient.delete(`organizations/${this.organization_id}/items/${this.id}`, {
            params: query,
        });

        return Item.make(data);
    }

    static async downloadTemplate(orgId) {
        const { data } = await ApiClient.get(`organizations/${orgId}/items/import-get-template`, {
            responseType: 'blob',
        });

        return data;
    }

    static async parseImportFile(orgId, formData) {
        const { data } = await ApiClient.post(`organizations/${orgId}/items/import-file`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        return data;
    }

    static async applyImport(orgId, attributes) {
        const { data } = await ApiClient.post(`organizations/${orgId}/items/import`, { data: attributes });

        return data;
    }

    static getReportingUnits(orgId, itemId, query = {}) {
        return this.withAbort(
            ApiClient.get(`organizations/${orgId}/items/${itemId}/reporting-units`, { params: query }),
            ({ data }) => data
        );
    }

    static async report(orgId, itemId, attributes, query = {}) {
        const { data } = await ApiClient.post(`organizations/${orgId}/items/${itemId}/batches-done`, attributes, {
            params: query,
        });

        return data;
    }

    getRelationships() {
        return {
            department: DepartmentModel,
            category: CategoryModel,
        };
    }
}
