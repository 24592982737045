<script>
export default {
    name: 'CreateQFPARTemplateStep3View',
};
</script>

<script setup>
import { watch, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { mapGetters } from '@/utils/vuexUtils';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import QFPARTemplateModel from '@/models/QFPARTemplate';
import useAbortableRequest from '@/composition/useAbortableRequest';
import OrderStepTemplate from '@/components/model-specific/orders/OrderStepTemplate';
import OrderSummaryCard from '@/components/model-specific/orders/order-summary-card';

const router = useRouter();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentQFPARTemplate } = mapGetters(['currentQFPARTemplate']);

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Template state
------------------------------------------------------------------------*/

const templateState = reactive({
    data: null,
    isDataLoading: true,
    isActionProcessing: false,
});

const loadTemplate = (groupItemsBy) => {
    templateState.isDataLoading = true;

    sendAbortableRequest(
        QFPARTemplateModel.find(orgId, currentQFPARTemplate.value.id, {
            group_items_by: groupItemsBy,
        })
    )
        .then((model) => (templateState.data = model))
        .finally(() => (templateState.isDataLoading = false));
};

const confirmTemplate = () => {
    templateState.isActionProcessing = true;

    templateState.data
        .confirm()
        .then(() => {
            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: 'Template has been successfully confirmed',
            });

            router.push({ name: SETTINGS_ROUTE_NAMES.PAR.INDEX });

            store.commit('setCurrentQFPARTemplate', null);
        })
        .finally(() => (templateState.isActionProcessing = false));
};

watch(
    () => store.state.PLOrderViewSettings.groupItemsBy,
    (groupItemsBy) => {
        if (currentQFPARTemplate.value !== null) {
            loadTemplate(groupItemsBy);
        }
    },
    { immediate: true }
);
</script>

<template>
    <OrderStepTemplate
        is-template
        :is-step-submitting="templateState.isActionProcessing"
    >
        <template #step-content>
            <InPlaceLoader v-if="templateState.isDataLoading" />

            <OrderSummaryCard
                v-else-if="templateState.data !== null"
                :order="templateState.data"
            />
        </template>

        <template #prev-step-link>
            <router-link
                class="pl-btn"
                :to="{ name: SETTINGS_ROUTE_NAMES.PAR.CREATE.QF.ADD_ITEMS }"
            >
                Select Items
            </router-link>
        </template>

        <template #next-step-action>
            <BtnUI
                is-filled
                :disabled="templateState.isDataLoading || templateState.isActionProcessing"
                @click="confirmTemplate"
            >
                Confirm
            </BtnUI>
        </template>
    </OrderStepTemplate>
</template>
