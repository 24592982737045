import emitter from 'tiny-emitter/instance';

export const EVENT_BUS_EVENTS = {
    APP_STATE_LOADING_SUCCESS: 'app-state.success',
    APP_STATE_LOADING_FAILED: 'app-state.failed',
    NOTIFICATION_FLASH: 'notification.flash',
    NOTIFICATION_RECEIVED: 'notification.received',
    NOTIFICATION_READ: 'notification.read',
    GLOBAL_MODAL: 'global-modal',
};

export default {
    on: (...args) => emitter.on(...args),
    once: (...args) => emitter.once(...args),
    off: (...args) => emitter.off(...args),
    emit: (...args) => emitter.emit(...args),
};
