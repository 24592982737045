import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';

import LocationsIndexView from '@/views/dashboard/settings/locations/index';
import CreateLocationView from '@/views/dashboard/settings/locations/create';
import EditLocationView from '@/views/dashboard/settings/locations/edit';

export default [
    {
        path: 'settings/locations',
        name: SETTINGS_ROUTE_NAMES.LOCATIONS.INDEX,
        component: LocationsIndexView,
    },
    {
        path: 'settings/locations/create',
        name: SETTINGS_ROUTE_NAMES.LOCATIONS.CREATE,
        component: CreateLocationView,
    },
    {
        path: 'settings/locations/:locationId/edit',
        name: SETTINGS_ROUTE_NAMES.LOCATIONS.EDIT,
        component: EditLocationView,
        props: true,
    },
];
