<script>
export default {
    name: 'OnboardingStep5View',
};
</script>

<script setup>
import { onBeforeMount, reactive } from 'vue';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { catchUnprocessableEntity } from '@/utils/httpUtils';
import { RESPONSE_DATA_TYPES } from '@/enums/httpEnums';
import { ALERT_TYPES, IMPORT_ENTITIES } from '@/enums/componentsEnums';
import ItemModel from '@/models/Item';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useImportEntityModal from '@/composition/useImportEntityModal';
import OnboardingLayout from '@/components/layouts/OnboardingLayout';
import ImportEntityModal from '@/components/ImportEntityModal';
import OnboardingStepLayout from '@/components/layouts/OnboardingStepLayout';
import ItemForm from '@/components/forms/item-form';
import ItemCard from '@/components/model-specific/item/ItemCard';

const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                                Items state
------------------------------------------------------------------------*/

const itemsState = reactive({
    data: [],
    isDataLoading: true,
});

const loadItems = () => {
    sendAbortableRequest(ItemModel.all(orgId))
        .then((models) => (itemsState.data = models))
        .finally(() => (itemsState.isDataLoading = false));
};

const updateOnboardingStatus = (models) => {
    store.commit('onboarding/updateSettings', {
        has_items: models.length > 0,
    });
};

const handleDeleteSuccess = (models) => {
    itemsState.data = models;

    updateOnboardingStatus(models);

    EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
        type: ALERT_TYPES.SUCCESS,
        message: 'The item has been successfully deleted!',
    });
};

onBeforeMount(loadItems);

/*------------------------------------------------------------------------
                              Item form state
------------------------------------------------------------------------*/

const itemFormState = reactive({
    validationErrors: null,
    isSubmitting: false,
    isResetTriggered: false,
});

const submitCreateItem = (attributes) => {
    itemFormState.isSubmitting = true;

    ItemModel.create(orgId, attributes, { response_data: RESPONSE_DATA_TYPES.LIST })
        .then((models) => {
            itemsState.data = models;
            itemFormState.isResetTriggered = true;

            updateOnboardingStatus(models);

            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: 'Item has been successfully created!',
            });
        })
        .catch((error) =>
            catchUnprocessableEntity(error, (errors) => {
                itemFormState.validationErrors = errors;
            })
        )
        .finally(() => (itemFormState.isSubmitting = false));
};

/*------------------------------------------------------------------------
                         Import entity modal state
------------------------------------------------------------------------*/

const { importEntityState, importEntityModal } = useImportEntityModal(ItemModel, 'items');

const submitImportItems = (payload) => {
    importEntityModal.submit(payload, (isSpinnerVisible) => {
        itemsState.isDataLoading = isSpinnerVisible;

        loadItems();
    });
};
</script>

<template>
    <OnboardingLayout
        title="Items"
        :is-data-loading="itemsState.isDataLoading"
        :is-submitting="itemFormState.isSubmitting"
    >
        <OnboardingStepLayout
            form-title="Create new Item"
            list-title="Items added"
            import-btn-text="import new items"
            :list-items="itemsState.data"
            :is-data-loading="itemsState.isDataLoading"
            @delete-success="handleDeleteSuccess"
            @open-import="importEntityModal.open"
        >
            <template #list-item="{ item, openDelete }">
                <ItemCard :item="item">
                    <template #actions="{ qaPrefix }">
                        <button
                            type="button"
                            :data-testid="`${qaPrefix}_delete_btn`"
                            @click="openDelete(item)"
                        >
                            <DeleteIcon />
                        </button>
                    </template>
                </ItemCard>
            </template>

            <ItemForm
                disable-animation
                submit-btn-text="create item"
                :validation-errors="itemFormState.validationErrors"
                :is-submitting="itemFormState.isSubmitting"
                :is-reset-triggered="itemFormState.isResetTriggered"
                @reset-success="itemFormState.isResetTriggered = false"
                @submit="submitCreateItem"
            />

            <ImportEntityModal
                v-if="importEntityModal.isOpened"
                :entity="IMPORT_ENTITIES.ITEM"
                :data="importEntityState.data"
                :progress="importEntityState.progress"
                :is-action-processing="importEntityState.isActionProcessing"
                @download-template="importEntityModal.downloadTemplate"
                @parse-file="importEntityModal.parseFile"
                @close="importEntityModal.close"
                @submit="submitImportItems"
            >
                <template #title="{ data, importEntitiesSize }">
                    {{ data === null ? 'Import new Items' : `Items to create (${importEntitiesSize})` }}
                </template>
            </ImportEntityModal>
        </OnboardingStepLayout>
    </OnboardingLayout>
</template>

<style lang="scss" scoped>
:deep(.pl-item-card__content > b) {
    color: $black;
    font-weight: $font-weight-bold;
}
</style>
