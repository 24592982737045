<script>
export default {
    name: 'ItemFormUI',
};
</script>

<script setup>
import FormUI from '@/components/UI/FormUI';

const props = defineProps({
    isPrepTask: {
        type: Boolean,
        default: false,
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
    disableAnimation: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['submit']);

/*------------------------------------------------------------------------
                                Class names
------------------------------------------------------------------------*/

const rootClassName = 'pl-item-form';

const classNames = {
    batchTimeWrapper: `${rootClassName}__batch-time-wrapper`,
    batchTimeChart: `${rootClassName}__batch-time-chart`,
    batchTimeChartTypeOptions: `${rootClassName}__chart-type-options`,
    batchTimeChartTypeOption: `${rootClassName}__chart-type-option`,
    batchTimeChartTypeOptionActive: `${rootClassName}__chart-type-option--active`,
    batchTimeChartContainer: `${rootClassName}__chart-container`,
    checkbox: `${rootClassName}__checkbox`,
    reportingCheckbox: `${rootClassName}__reporting-checkbox`,
};

/*------------------------------------------------------------------------
                             Additional props
------------------------------------------------------------------------*/

const qaPrefix = props.isPrepTask ? 'prep_task_form' : 'item_form';

const accordionModifiers = {
    spaceTop: 'space-top',
    spaceLeft: 'space-left',
};
</script>

<template>
    <FormUI
        :class="rootClassName"
        :data-test-id="qaPrefix"
        :disable-animation="disableAnimation"
        :is-loading="isLoading"
        @submit="emit('submit')"
    >
        <template #content="{ classNames: basicClassNames }">
            <slot
                name="content"
                :classNames="{ ...basicClassNames, ...classNames }"
                :qaPrefix="qaPrefix"
                :accordionModifiers="accordionModifiers"
            />
        </template>

        <template #actions>
            <slot
                name="actions"
                :qaPrefix="qaPrefix"
            />
        </template>
    </FormUI>
</template>

<style lang="scss">
.pl-item-form {
    @include media-breakpoint-up(md) {
        max-width: 400px;
    }

    &__batch-time-wrapper {
        position: relative;

        & + div {
            margin-top: custom-space(0.5);
        }

        & > button {
            $size: 40px;

            position: absolute;
            right: 8px;
            top: 50%;
            width: $size;
            height: $size;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $primary;
            padding: 0;
            border: none;
            border-radius: 50%;
            transform: translateY(-50%);
            @include pl-transition(background-color);

            &:disabled,
            &[disabled] {
                background-color: $input-disabled-bg;
            }

            svg,
            svg path {
                fill: $white;
            }
        }
    }

    &__batch-time-chart {
        text-align: center;

        small {
            color: $gray-800;
        }
    }

    &__chart-type-options {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: custom-space(0.4);
    }

    &__chart-type-option {
        font-size: $font-size-base * 0.95;
        padding: 0 custom-space(0.6);
        cursor: pointer;
        border-radius: 0.4rem;
        border: 1px solid $redLight;

        &--active {
            color: $white;
            background-color: $redLight;
        }

        &:disabled,
        &[disabled] {
            color: $gray-200;
            border: 1px solid $gray-200;
            background-color: transparent;
            cursor: not-allowed;
        }
    }

    &__chart-container {
        height: 200px;
        margin: custom-space(0.5) 0;
    }

    &__checkbox {
        z-index: 0;

        label {
            font-size: $font-size-base * 0.95;
        }

        .form-check-input[type='checkbox'] {
            border-color: $black;
            border-radius: 4px;
        }
    }

    &__reporting-checkbox {
        margin-top: custom-space(2.75);

        label {
            font-weight: $font-weight-light;
            font-size: $font-size-base * 0.9;
        }
    }

    .pl-select {
        max-width: 100%;
    }

    .pl-accordion {
        margin-bottom: custom-space(0.25);

        &__content {
            &--space-top {
                padding-top: custom-space(1.25);
            }

            &--space-left {
                padding-left: custom-space(0.75);
            }

            & > label {
                font-size: $font-size-base * 0.9;
                margin-bottom: custom-space(0.75);
                margin-left: custom-space(-0.75);
            }

            .pl-radio label {
                font-size: $font-size-base;
                font-weight: $font-weight-light;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-left: custom-space(0.25);
            }
        }
    }
}
</style>
