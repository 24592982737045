<script>
export default {
    name: 'ItemCard',
};
</script>

<script setup>
import { computed, useSlots } from 'vue';

import { BADGE_TYPES } from '@/enums/componentsEnums';
import Badge from '@/components/Badge';
import ItemCardUI from './ItemCardUI';

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    includeInfo: {
        type: Boolean,
        default: false,
    },
});

const slots = useSlots();

/*------------------------------------------------------------------------
                                Item state
------------------------------------------------------------------------*/

const hasItemPrepTasks = computed(() => {
    if (!props.includeInfo) {
        return false;
    }

    return props.item.prep_tasks.length > 0;
});

/*------------------------------------------------------------------------
                                    QA
------------------------------------------------------------------------*/

const qaPrefix = 'item';
</script>

<template>
    <ItemCardUI
        :class="{
            'pl-item-card--with-prep-tasks': hasItemPrepTasks,
        }"
    >
        <template #content="{ classNames }">
            <Badge
                v-if="hasItemPrepTasks"
                is-absolute
                size="sm"
                :type="BADGE_TYPES.ITEM_CARD"
            >
                Prep Tasks (
                <span :data-testid="`${qaPrefix}_prep_tasks_count`">{{ item.prep_tasks.length }}</span>
                )
            </Badge>

            <b :data-testid="`${qaPrefix}_name`">
                {{ item.name }}
            </b>

            <template v-if="includeInfo">
                <ul :class="classNames.infoList">
                    <li>
                        <span :data-testid="`${qaPrefix}_batch_size_amount`">
                            {{ item.batch_size_amount }}
                        </span>

                        <span :data-testid="`${qaPrefix}_batch_size_unit`">
                            {{ item.batch_size_unit.text }}
                        </span>
                    </li>

                    <li>
                        <span :data-testid="`${qaPrefix}_batch_yield_amount`">
                            {{ item.batch_yield_amount }}
                        </span>

                        <span :data-testid="`${qaPrefix}_batch_yield_unit`">
                            {{ item.batch_yield_unit.text }}
                        </span>
                    </li>

                    <li v-if="item.batch_labor_time">
                        <span :data-testid="`${qaPrefix}_labor_time`">{{ item.batch_labor_time }}m</span>
                    </li>

                    <li v-if="item.batch_weight_amount">
                        <span :data-testid="`${qaPrefix}_batch_weight_amount`">
                            {{ item.batch_weight_amount }}
                        </span>

                        <span :data-testid="`${qaPrefix}_batch_weight_unit`">
                            {{ item.batch_weight_unit.text }}
                        </span>
                    </li>
                </ul>

                <ul :class="classNames.infoList">
                    <li>
                        <span :data-testid="`${qaPrefix}_department_name`">
                            {{ item.department.name }}
                        </span>
                    </li>

                    <li>
                        <span :data-testid="`${qaPrefix}_category_name`">
                            {{ item.category.name }}
                        </span>
                    </li>

                    <li v-if="item.shelf_life">
                        <span :data-testid="`${qaPrefix}_shelf_life`">Shelf life: {{ item.shelf_life }}d</span>
                    </li>
                </ul>

                <ul
                    v-if="item.stations.length > 0"
                    :class="`${classNames.infoList} ${classNames.stationsList}`"
                >
                    <li>
                        <span
                            v-for="station in item.stations"
                            :key="`item station: ${station.id}`"
                            :data-testid="`${qaPrefix}_station`"
                        >
                            {{ station.name }}
                        </span>
                    </li>
                </ul>
            </template>
        </template>

        <template
            v-if="slots['actions']"
            #footer
        >
            <slot
                name="actions"
                :qa-prefix="qaPrefix"
            />
        </template>
    </ItemCardUI>
</template>

<style lang="scss" scoped>
.pl-item-card--with-prep-tasks > .pl-item-card__wrapper {
    padding-top: custom-space(0.25);
}
</style>
