<script>
export default {
    name: 'NotificationCard',
};
</script>

<script setup>
import { ref, defineAsyncComponent } from 'vue';

import { getHumanReadableDate } from '@/utils/dateUtils';
import Tooltip from '@/components/Tooltip';
import EyeIcon from '@/components/icons/EyeIcon';

const props = defineProps({
    notification: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['set-updated-data']);

/*------------------------------------------------------------------------
                            Notification state
------------------------------------------------------------------------*/

const isNotificationUpdating = ref(false);

const markNotificationAsRead = () => {
    isNotificationUpdating.value = true;

    props.notification
        .markAsRead()
        .then(({ data }) => emit('set-updated-data', data))
        .finally(() => (isNotificationUpdating.value = false));
};

const contentComponent = defineAsyncComponent(() => {
    const componentName = props.notification.type;

    return import(`./instances/${componentName}`);
});
</script>

<template>
    <div
        class="pl-notification-card"
        :class="{
            'pl-notification-card--unread': notification.read_at === null,
        }"
    >
        <OverlayLoader v-if="isNotificationUpdating" />

        <div class="pl-notification-card__content">
            <component
                :is="contentComponent"
                :data="notification.data"
            />

            <small v-if="notification.created_at">
                {{ getHumanReadableDate(notification.created_at) }}
            </small>
        </div>

        <Tooltip
            v-if="notification.read_at === null"
            text="Mark as read"
        >
            <button
                type="button"
                :disabled="isNotificationUpdating"
                @click.stop="markNotificationAsRead"
            >
                <EyeIcon
                    :width="18"
                    :height="18"
                />
            </button>
        </Tooltip>
    </div>
</template>

<style lang="scss" scoped>
.pl-notification-card {
    position: relative;
    display: flex;
    align-items: center;
    gap: custom-space(0.5);
    font-size: $font-size-base * 0.875;
    line-height: 1.25;
    padding: custom-space(0.75) custom-space(1);

    &__content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        gap: custom-space(0.25);

        & > small {
            color: $gray-700;
            font-style: italic;
        }
    }

    :deep(.pl-tooltip__invoker) {
        display: flex;

        > button {
            display: flex;
            padding: $click-space;
            margin: -$click-space;
        }
    }

    &--unread {
        background-color: lighten($yellow, 10);
    }
}
</style>
