import ApiClient from '@/plugins/ApiClient';
import Model from './Model';

export default class Supplier extends Model {
    static all(orgId, query = {}) {
        return this.withAbort(ApiClient.get(`organizations/${orgId}/suppliers`, { params: query }), ({ data }) =>
            this.make(data)
        );
    }

    static find(orgId, supplierId, query = {}) {
        return this.withAbort(
            ApiClient.get(`organizations/${orgId}/suppliers/${supplierId}`, { params: query }),
            ({ data }) => this.make(data)
        );
    }

    static async create(orgId, attributes) {
        const { data } = await ApiClient.post(`organizations/${orgId}/suppliers`, attributes);

        return this.make(data);
    }

    async update(orgId, attributes) {
        const { data } = await ApiClient.put(`organizations/${orgId}/suppliers/${this.id}`, attributes);

        return this.fill(data);
    }

    async delete(orgId, query = {}) {
        const { data } = await ApiClient.delete(`organizations/${orgId}/suppliers/${this.id}`, { params: query });

        return Supplier.make(data);
    }
}
