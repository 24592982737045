<script>
export default {
    name: 'TaskReminderView',
};
</script>

<script setup>
import { computed, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { isAdmin } from '@/utils/accountUtils';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import {
    ONBOARDING_REMINDER_CONFIG,
    ONBOARDING_REMINDER_KEY_BY_ROUTE,
    ONBOARDING_INCLUSION_KEY_BY_ROUTE,
} from '@/enums/onboardingEnums';
import AccountModel from '@/models/Account';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useConfirmationModal from '@/composition/useConfirmationModal';
import Alert from '@/components/Alert';
import ListDisplay from '@/components/ListDisplay';
import ConfirmationModal from '@/components/ConfirmationModal';

const router = useRouter();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Reminder state
------------------------------------------------------------------------*/

const getStepEstimationTime = (step) => {
    const { dataKey, multiplier } = step;

    const value = dataKey ? store.state.onboarding.settings[dataKey] : 1;

    const time = Math.ceil((value * multiplier) / 60);

    return `Est. time: ${time}m`;
};

const reminderTaskConfig = computed(() => {
    const { settings } = store.state.onboarding;

    return ONBOARDING_REMINDER_CONFIG.filter(({ name }) => {
        const reminderKey = ONBOARDING_REMINDER_KEY_BY_ROUTE[name];
        const inclusionKey = ONBOARDING_INCLUSION_KEY_BY_ROUTE[name];

        return settings[reminderKey] && !settings[inclusionKey];
    });
});

const areOnboardingSettingsLoading = computed(() => !store.getters['onboarding/areSettingsLoaded']);

onBeforeMount(() => {
    if (!isAdmin(currentAccount)) {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to setup suggestions.',
        });

        return router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }

    store.commit('onboarding/setSettings', null);

    sendAbortableRequest(AccountModel.getOnboardingSettings(orgId)).then((data) => {
        store.commit('onboarding/setSettings', data);
    });
});

/*------------------------------------------------------------------------
                         Confirmation modal state
------------------------------------------------------------------------*/

const { confirmationState, confirmationModal } = useConfirmationModal();

const submitDismissReminderStep = () => {
    const { model } = confirmationState;

    confirmationModal
        .submit(() => {
            const reminderKey = [ONBOARDING_REMINDER_KEY_BY_ROUTE[model.name]];

            return AccountModel.updateOnboardingSettings(orgId, { [reminderKey]: false });
        })
        .then((onboardingSettings) => {
            store.commit('onboarding/setSettings', onboardingSettings);

            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: `${model.title} reminder has been successfully skipped!`,
            });
        });
};
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.INDEX">
        <template #title>
            <h1>Setup Suggestions</h1>
        </template>

        <transition
            appear
            name="down"
            type="transition"
            mode="out-in"
        >
            <InPlaceLoader v-if="areOnboardingSettingsLoading" />

            <Alert
                v-else-if="reminderTaskConfig.length === 0"
                :type="ALERT_TYPES.SUCCESS"
            >
                You have successfully unlocked the full potential of Prep List!
            </Alert>

            <div
                v-else
                class="pl-task-reminder"
            >
                <h5>Unlock the full potential of Prep List</h5>

                <small>
                    Further optimize your work with Prep List by entering the information about other aspects of your
                    business.
                </small>

                <ListDisplay
                    data-testid="reminder_step_list"
                    :items="reminderTaskConfig"
                >
                    <template #item="{ item: step }">
                        <div class="pl-task-reminder-link">
                            <div class="pl-task-reminder-link__wrapper">
                                <p>
                                    <b>{{ step.title }}</b>

                                    <span>{{ getStepEstimationTime(step) }}</span>
                                </p>

                                <div class="pl-task-reminder-link__actions">
                                    <BtnUI
                                        size="sm"
                                        @click="confirmationModal.open(step)"
                                    >
                                        skip
                                    </BtnUI>

                                    <router-link
                                        class="pl-btn pl-btn--filled pl-btn--sm"
                                        :to="{ name: step.name }"
                                    >
                                        start
                                    </router-link>
                                </div>
                            </div>

                            <p>{{ step.description }}</p>
                        </div>
                    </template>
                </ListDisplay>
            </div>
        </transition>

        <ConfirmationModal
            v-if="confirmationState.isOpened"
            qa-prefix="dismiss_reminder_step_modal"
            cancel-btn-text="keep reminder"
            submit-btn-text="skip"
            :is-pending="confirmationState.isPending"
            @close="confirmationModal.close"
            @submit-delete="submitDismissReminderStep"
        >
            <template #title>
                Please confirm
            </template>

            If you skip now, this reminder will disappear, but you will always find the full list of options in app settings.
        </ConfirmationModal>
    </TopBarLayout>
</template>

<style lang="scss" scoped>
.pl-task-reminder {
    display: flex;
    flex-direction: column;
    align-items: center;

    > h5 {
        margin-bottom: custom-space(0);
        text-align: center;
    }

    > small {
        width: 100%;
        max-width: 370px;
        margin-bottom: custom-space(1.5);
        text-align: center;
    }

    > :deep(.pl-list-display) {
        display: flex;
        flex-direction: column;
        gap: custom-space(0.5);
        width: 100%;
    }
}

.pl-task-reminder-link {
    display: flex;
    flex-direction: column;
    gap: custom-space(0.5);
    background-color: $white;
    padding: custom-space(0.5);
    border: 1px solid $gray-300;
    border-radius: $border-radius;

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: custom-space(1);

        > p {
            display: flex;
            flex-direction: column;
            margin: 0;
            color: $gray-900;
            line-height: 1.25;

            > b {
                font-weight: $font-weight-normal;
            }

            > span {
                font-size: $font-size-base * 0.9;
            }
        }
    }

    &__actions {
        flex-shrink: 0;
        display: flex;
        gap: custom-space(0.25);

        > .pl-btn {
            min-width: 65px;
        }
    }

    > p {
        margin: 0;
        padding-top: custom-space(0.5);
        border-top: 1px solid $gray-300;
        color: $black;
        font-size: $font-size-base * 0.875;
        line-height: 1.25;
    }
}

:deep(.pl-modal__actions) {
    @include media-breakpoint-up(md) {
        flex-direction: column-reverse;

        & > button {
            flex: auto;
            width: 100%;
            padding-top: custom-space(0.75);
            padding-bottom: custom-space(0.75);
        }
    }
}
</style>
