<script>
export default {
    name: 'EditQFPARTemplateStep2View',
};
</script>

<script setup>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { mapGetters } from '@/utils/vuexUtils';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import useSelectableItemsQty from '@/composition/useSelectableItemsQty';
import OrderStepTemplate from '@/components/model-specific/orders/OrderStepTemplate';
import SelectableItemsQty from '@/components/model-specific/orders/selectable-items-qty';

const props = defineProps({
    templateId: {
        type: [String, Number],
        required: true,
    },
});

const router = useRouter();
const store = useStore();

const { selectableItemsQtyState, selectableItemsQty } = useSelectableItemsQty();

const { currentQFPARTemplate } = mapGetters(['currentQFPARTemplate']);

/*------------------------------------------------------------------------
                              Template state
------------------------------------------------------------------------*/

const cancelTemplateDraft = () => {
    currentQFPARTemplate.value.cancelDraft().finally(() => {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.SUCCESS,
            message: 'Template editing process has been successfully cancelled',
        });

        router.push({ name: SETTINGS_ROUTE_NAMES.PAR.INDEX });

        store.commit('setCurrentQFPARTemplate', null);
    });
};

const navigateToNextStep = () => {
    router.push({
        name: SETTINGS_ROUTE_NAMES.PAR.EDIT.QF.REVIEW_AND_CONFIRM,
        params: { templateId: props.templateId },
    });
};
</script>

<template>
    <OrderStepTemplate
        is-template
        is-edit-mode
        exclude-content-container
        :is-cancel-process-disabled="selectableItemsQtyState.areActionsDisabled"
        @cancel-process="cancelTemplateDraft"
    >
        <template #step-content>
            <SelectableItemsQty
                is-template
                is-quick-fill
                :order-id="currentQFPARTemplate.id"
                @update-status="selectableItemsQty.update"
            />
        </template>

        <template #next-step-action>
            <BtnUI
                is-filled
                :disabled="selectableItemsQty.isInvalid()"
                @click="navigateToNextStep"
            >
                Review and Confirm
            </BtnUI>
        </template>

        <template
            v-if="!selectableItemsQtyState.isAnyItemAdded"
            #ribbon-text
        >
            Please add at least one item to your template.
        </template>
    </OrderStepTemplate>
</template>
