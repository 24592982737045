<script>
export default {
    name: 'CreateItemEntityFab',
};
</script>

<script setup>
import { ITEM_CONFIGURATION_ENTITIES_NAME } from '@/enums/itemEnums';
import DropDown from '@/components/DropDown';
import Fab from '@/components/Fab';
import GroupedSettings from '@/components/constructions/grouped-settings';

const emit = defineEmits(['set-active-entity']);
</script>

<template>
    <Fab data-testid="create_new_item_entity_btn">
        <DropDown pos-y="top">
            <template #invoker>
                <PlusIcon />
            </template>

            <template #content>
                <GroupedSettings.Root>
                    <GroupedSettings.List>
                        <GroupedSettings.ListItem
                            v-for="entity in ITEM_CONFIGURATION_ENTITIES_NAME"
                            :key="`entity: ${entity}`"
                            @click="emit('set-active-entity', entity)"
                        >
                            {{ `Create new ${entity}` }}
                        </GroupedSettings.ListItem>
                    </GroupedSettings.List>
                </GroupedSettings.Root>
            </template>
        </DropDown>
    </Fab>
</template>
