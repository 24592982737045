import ApiClient from '@/plugins/ApiClient';
import Model from './Model';
import QFOrderModel from './QFOrder';

export default class QFSelectableItem extends Model {
    static all(orgId, orderId) {
        return this.withAbort(
            ApiClient.get(`organizations/${orgId}/quick-fill-orders/${orderId}/selectable-items`),
            ({ data }) => this.make(data)
        );
    }

    static async setQty(orgId, orderId, prototypeId, attributes) {
        const { data } = await ApiClient.post(
            `organizations/${orgId}/quick-fill-orders/${orderId}/selectable-items/${prototypeId}/set-qty`,
            attributes,
        );

        return QFSelectableItem.make(data);
    }

    static async setOnHandQty(orgId, orderId, prototypeId, attributes) {
        const { data } = await ApiClient.post(
            `organizations/${orgId}/quick-fill-orders/${orderId}/selectable-items/${prototypeId}/set-on-hand-qty`,
            attributes,
        );

        return QFSelectableItem.make(data);
    }

    static async fulfill(orgId, orderId, prototypeId, attributes) {
        const { data } = await ApiClient.post(
            `organizations/${orgId}/quick-fill-orders/${orderId}/selectable-items/${prototypeId}/fulfill`,
            attributes
        );

        return QFOrderModel.make(data);
    }

    static async confirmDelivery(orgId, orderId, prototypeId, attributes) {
        const { data } = await ApiClient.post(
            `organizations/${orgId}/quick-fill-orders/${orderId}/selectable-items/${prototypeId}/confirm`,
            attributes
        );

        return QFOrderModel.make(data);
    }
}
