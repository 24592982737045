<script>
export default {
    name: 'FlashNotifications',
};
</script>

<script setup>
import { ref, defineAsyncComponent, shallowRef, onBeforeMount, onBeforeUnmount } from 'vue';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import useCodeGenerator from '@/composition/useCodeGenerator';
import Alert from '@/components/Alert';

const { generateUniqueCode, clearUsedCode } = useCodeGenerator();

/*------------------------------------------------------------------------
                            Notifications state
------------------------------------------------------------------------*/

const currentNotifications = ref(new Map());

onBeforeMount(() => {
    EventBus.on(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, (notification) => {
        const id = generateUniqueCode();

        let component = null;

        if (notification.state) {
            component = defineAsyncComponent(() => {
                const componentName = notification.state.type;

                return import(`../../components/received-notifications/partials/instances/${componentName}`);
            });
        }

        currentNotifications.value.set(id, {
            id,
            component: shallowRef(component),
            ...notification,
        });

        setTimeout(() => {
            currentNotifications.value.delete(id);

            clearUsedCode(id);
        }, 3000);
    });
});

onBeforeUnmount(() => EventBus.off(EVENT_BUS_EVENTS.NOTIFICATION_FLASH));
</script>

<template>
    <transition
        name="down"
        type="transition"
    >
        <div
            v-if="currentNotifications.size > 0"
            class="pl-flash-notifications"
            data-testid="flash_notifications"
        >
            <transition-group
                appear
                name="scale"
                type="transition"
            >
                <Alert
                    v-for="notification in currentNotifications.values()"
                    :key="`notification: ${notification.id}`"
                    :type="notification.type"
                >
                    <component
                        :is="notification.component"
                        v-if="notification.component"
                        :data="notification.state"
                    />

                    <template v-else>
                        {{ notification.message }}
                    </template>
                </Alert>
            </transition-group>
        </div>
    </transition>
</template>

<style lang="scss" scoped>
.pl-flash-notifications {
    position: fixed;
    inset: custom-space(1);
    bottom: auto;
    z-index: 101;

    @include media-breakpoint-up(lg) {
        left: auto;
    }

    .pl-alert {
        width: custom-space(17);
        margin: 0 auto custom-space(0.75) auto;
    }
}
</style>
