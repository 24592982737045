<script>
export default {
    name: 'InfiniteScrollList',
};
</script>

<script setup>
import { ref } from 'vue';

import ApiClient from '@/plugins/ApiClient';

const props = defineProps({
    data: {
        type: Array,
        required: true,
    },
    links: {
        type: Object,
        required: true,
    },
    height: {
        type: Number,
        default: 250,
    },
    threshold: {
        type: Number,
        default: 10,
    },
    debounceTimeout: {
        type: Number,
        default: 10,
    },
    itemsPerPage: {
        type: Number,
        default: 10,
    },
});

const emit = defineEmits(['set-next-page-data']);

/*------------------------------------------------------------------------
                               General state
------------------------------------------------------------------------*/

const rootRef = ref(null);
const isNextPageLoading = ref(false);

const loadNextPage = () => {
    isNextPageLoading.value = true;

    return ApiClient.get(props.links.next)
        .then((response) => emit('set-next-page-data', response.data))
        .finally(() => (isNextPageLoading.value = false));
};

let debounceTimeoutId = null;

const onScroll = () => {
    if (debounceTimeoutId) {
        clearTimeout(debounceTimeoutId);
    }

    const { children } = rootRef.value;

    if (children.length > 0 && !isNextPageLoading.value && props.links.next !== null) {
        debounceTimeoutId = setTimeout(() => {
            const lastChild = children[children.length - 1];

            const { height: rootHeight, top: rootTop } = rootRef.value.getBoundingClientRect();
            const { height: childHeight, top: childTop } = lastChild.getBoundingClientRect();

            if (childTop + childHeight - props.threshold < rootTop + rootHeight) {
                loadNextPage();
            }
        }, props.debounceTimeout);
    }
};
</script>

<template>
    <ul
        ref="rootRef"
        class="pl-infinite-scroll-list"
        :style="{ maxHeight: `${height}px` }"
        @scroll.passive="onScroll"
    >
        <transition-group
            appear
            name="down"
            type="transition"
        >
            <li
                v-for="(item, index) in data"
                :key="`infinite scroll list item: ${index}`"
                :style="`transition-delay: ${(index % itemsPerPage) * 0.5 * 100}ms`"
            >
                <slot
                    name="item"
                    :item="item"
                />
            </li>
        </transition-group>

        <InPlaceLoader v-if="isNextPageLoading" />
    </ul>
</template>

<style lang="scss" scoped>
.pl-infinite-scroll-list {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: auto;

    & > li {
        border-bottom: 1px solid $gray-100;
    }

    :deep(.pl-in-place-loader) {
        height: custom-space(4);
    }
}
</style>
